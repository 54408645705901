import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Modal,
  Button,
  Spin,
  notification,
  Upload,
  Steps,
} from "antd";
import _ from "lodash";
import { EnvironmentFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { UPDATEDGET, UPDATEDPOST, UPDATEDPUT } from "services/common.api";
import { InboxOutlined } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import { s3Upload } from "../../services/s3fileUpload/index";
import style from "./style.module.scss";
import moment from "moment";
import { callbackAppointment } from "../../redux/actions/userActions";

const SchedulePrescription = ({ open, close, title,appoimentData,getpriscriptionList,selectedPatient }) => {
  const dispatch = useDispatch();

  const { Step } = Steps;
  let user = JSON.parse(localStorage.getItem("user"));
  const [certainHours, setCertainHours] = useState("");
  const [slots, setSlots] = useState(false);
  const [slotsTime, setSlotsTime] = useState("");
  const [selectedCard, setSelectedCard] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e: any) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {

    let logbody = {
      message: "Scheduled Prescription - Page Load",
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Scheduled Prescription Page`,
    }
    logEvents(logbody);

    console.log("apoointmenttttttttt",appoimentData)
    setTodayDate(moment().format("dddd, MMM DD, YYYY"));
    dispatch(callbackAppointment(selectedCard));
    generateGreetings();
  }, [open]);

  const cardClick = (selectedCard) => {
    console.log(selectedCard);
    setSelectedCard(selectedCard);
    dispatch(callbackAppointment(selectedCard));
    if (selectedCard == "Morning") {
      setSlotsTime("08:00AM-12:00PM");
    } else if (selectedCard == "Afternoon") {
      setSlotsTime("12:00PM-05:00PM");
    } else if (selectedCard == "Evening") {
      setSlotsTime("05:00PM-09:00PM");
    }
  };

  const leftArraow = () => {
    let C = moment().format("dddd, MMM DD, YYYY");
    setTodayDate(moment(C).format("dddd, MMM DD, YYYY"));
    generateGreetings();
  };
  const rightArraow = () => {
    let C = moment().format("DD-MM-YYYY");
    let A = moment(C, "DD-MM-YYYY").add(1, "days");
    console.log(moment(A).format("dddd, MMM DD, YYYY"));
    setTodayDate(moment(A).format("dddd, MMM DD, YYYY"));
    setSlots(false);
  };
  
  const onFinish = () => {
    // const onFinish = async (item) => {
      setBtnLoader(true);
    console.log(appoimentData,'==================',todayDate,selectedCard)

    try {

      let logBody = {
        message: `User - ${user?.sub} - Update Prescription - Scheduled Prescription Page`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${user?.sub} - Scheduled Prescription Page`,
      }
      logEvents(logBody);

      let body = {
        "id": appoimentData.id,
        "schedule_date": todayDate,
        "schedule_time": slotsTime,
        "status": 5
      }
      console.log("objectttt",body)
      UPDATEDPUT("prescriptions", body).then((response) => {
        console.log("response of submit appointment",response);
        setBtnLoader(false);
        getpriscriptionList(selectedPatient);
        close();
      });
    } catch (err) {
      setBtnLoader(false);
      console.log("err: ", err);
    }

  }
 
  const generateGreetings = () => {
    let currentHour: any = moment().format("HH");
    if (currentHour >= 3 && currentHour < 12) {
      setCertainHours("Morning");
      console.log(currentHour, "event****");
      setSlots(false);
      setSlotsTime("08:00AM-12:00PM");
    } else if (currentHour >= 12 && currentHour < 15) {
      setCertainHours("Afternoon");
      setSelectedCard("Afternoon");
      setSlotsTime("12:00PM-05:00PM");
      console.log(currentHour, "event****");
      setSlots(false);
    } else if (currentHour >= 15 && currentHour < 20) {
      setCertainHours("Evening");
      console.log(currentHour, "event****");
      setSelectedCard("Evening");
      setSlotsTime("05:00PM-09:00PM");
      setSlots(false);
    } else {
      setSlots(true);
      console.log("NO slots available");
    }
  };

  return (
    <>
      <Modal
        title={title}
        visible={open}
        footer={null}
        onOk={close}
        onCancel={close}
        width={1100}
        centered
      >
        <div className="content contentSelect" style={{ height: 600 }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-0 col-md-1 col-xl-2"></div>
              <div className="col-sm-12 col-md-10 col-xl-8">
                <div className="stepDiv d-none d-sm-block">
                  <div className={style.divPayment}>
                    {todayDate !== moment().format("dddd, MMM DD, YYYY") ? (
                      <img
                        onClick={leftArraow}
                        src="Images/problems/arrow-left.png"
                        alt=""
                        className={style.arrowIcon}
                      />
                    ) : null}
                    <h2 className="lblPayment px-3">{todayDate}</h2>
                    {todayDate == moment().format("dddd, MMM DD, YYYY") ? (
                      <img
                        onClick={rightArraow}
                        src="Images/problems/arrow-right.png"
                        alt=""
                        className={style.arrowIcon}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-sm-0 col-md-1 col-xl-2"></div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="row text-left">
                  <div className="col-sm-1 col-md-1 col-xl-2"></div>
                  <div className="col-sm-10 col-md-10 col-xl-8">
                    <div className={style.div_flex}>
                      {certainHours == "Morning" &&
                      todayDate == moment().format("dddd, MMM DD, YYYY") ? (
                        <>
                          <div className={style.div_inner_flex}>
                            <div
                              onClick={() => cardClick("Morning")}
                              className={`${
                                selectedCard === "Morning"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                            >
                              <img
                                src="Images/morning.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Morning</h4>
                                <h6 className="p-0 m-0">8AM - 12PM</h6>
                              </div>
                            </div>
                          </div>
                          <div className={style.div_inner_flex}>
                            <div
                              className={`${
                                selectedCard === "Afternoon"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                              onClick={() => cardClick("Afternoon")}
                            >
                              <img
                                src="Images/afternoon.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Afternoon</h4>
                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                              </div>
                            </div>
                          </div>

                          <div className={style.div_inner_flex}>
                            <div
                              className={`${
                                selectedCard === "Evening"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                              onClick={() => cardClick("Evening")}
                            >
                              <img
                                src="Images/evening.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Evening</h4>
                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {/* next day */}
                      {todayDate !== moment().format("dddd, MMM DD, YYYY") ? (
                        <>
                          <div className={style.div_inner_flex}>
                            <div
                              onClick={() => cardClick("Morning")}
                              className={`${
                                selectedCard === "Morning"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                            >
                              <img
                                src="Images/morning.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Morning</h4>
                                <h6 className="p-0 m-0">8AM - 12PM</h6>
                              </div>
                            </div>
                          </div>
                          <div className={style.div_inner_flex}>
                            <div
                              className={`${
                                selectedCard === "Afternoon"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                              onClick={() => cardClick("Afternoon")}
                            >
                              <img
                                src="Images/afternoon.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Afternoon</h4>
                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                              </div>
                            </div>
                          </div>

                          <div className={style.div_inner_flex}>
                            <div
                              className={`${
                                selectedCard === "Evening"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                              onClick={() => cardClick("Evening")}
                            >
                              <img
                                src="Images/evening.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Evening</h4>
                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {certainHours == "Afternoon" &&
                      todayDate == moment().format("dddd, MMM DD, YYYY") ? (
                        <>
                          <div className={style.div_inner_flex}>
                            <div
                              className={`${
                                selectedCard === "Afternoon"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                              onClick={() => cardClick("Afternoon")}
                            >
                              <img
                                src="Images/afternoon.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Afternoon</h4>
                                <h6 className="p-0 m-0">12PM - 5PM</h6>
                              </div>
                            </div>
                          </div>

                          <div className={style.div_inner_flex}>
                            <div
                              className={`${
                                selectedCard === "Evening"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                              onClick={() => cardClick("Evening")}
                            >
                              <img
                                src="Images/evening.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Evening</h4>
                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {certainHours == "Evening" &&
                      todayDate == moment().format("dddd, MMM DD, YYYY") ? (
                        <>
                          <div className={style.div_inner_flex}>
                            <div
                              className={`${
                                selectedCard === "Evening"
                                  ? `${style.card_selected}`
                                  : `${style.card_static}`
                              }`}
                              onClick={() => cardClick("Evening")}
                            >
                              <img
                                src="Images/evening.png"
                                alt=""
                                className={style.productImg}
                              />
                              <div className="doc-info-center text-center">
                                <h4 className="p-0 m-0">Evening</h4>
                                <h6 className="p-0 m-0">5PM - 9PM</h6>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {slots == true ? (
                        <div className={style.div_inner_flex}>
                          <div className={style.card_static}>
                            <img
                              src="Images/problems/cancel.png"
                              alt=""
                              className={style.productImg}
                            />
                            <div className="doc-info-center text-center">
                              <h4 className="p-0 m-0 pt-2">
                                No slots available
                              </h4>
                              {/* <h6 className="p-0 m-0">5pm - 12am</h6> */}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-1 col-md-1 col-xl-2"></div>
                </div>
              </div>
            </div>

            <div
            className="row ml-1 mr-1"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="pt-4 pr-3">
              <Form.Item>
                <Button
                  style={{ width: 155, height: "auto" }}
                  htmlType="submit"
                  className="btn btn-primary px-5"
                  loading={btnLoader}
                  onClick={() => onFinish()}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
            <div className="pt-4 pr-3">
              <Form.Item>
                <Button
                  style={{ width: 155, height: "auto" }}
                  htmlType="button"
                  onClick={close}
                  className="btn btn-light px-5"
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SchedulePrescription;
