/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToNewMessage = /* GraphQL */ `
  subscription SubscribeToNewMessage($conversationId: String!) {
    subscribeToNewMessage(conversationId: $conversationId) {
      author
      authorName
      authorType
      content
      conversationId
      createdAt
      id
    }
  }
`;
export const subscribeToNewNotification = /* GraphQL */ `
  subscription SubscribeToNewNotification($userId: ID!) {
    subscribeToNewNotification(userId: $userId) {
      userId
      message
      conversationId
      createdAt
    }
  }
`;
