/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'ca-central-1',
	aws_appsync_graphqlEndpoint: 'https://7m3ooue4hngkrll47dwufq3w2e.appsync-api.ca-central-1.amazonaws.com/graphql',
	aws_appsync_region: 'ca-central-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}

export default awsmobile
