import style from "./style.module.css";
import { Link, useHistory } from "react-router-dom";
import { Auth, input } from "aws-amplify";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Form, Input, Button, Checkbox } from "antd";

import ChangePass from "components/changePassword";
import { UPDATED_OPEN_POST } from "services/common.api";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

const currentConfig = Auth.configure();
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCodeView, setShowCodeView] = useState("true");
  const [loader, setLoader] = useState(false);
  const [inputType, setInputType] = useState("");
  const [values, setValues] = useState({ password: "", showPassword: false });
  const [confirmPass, setconfirmPass] = useState({
    confirmPassword: "",
    showConfirmPassword: false,
  });

  const continuee = (e) => {
    console.log("continue per click karyu");
    // event.preventDefault();
    setShowCodeView("false");
    forgotPassword(e);
    setUserName(e.email);
  };

  async function forgotPassword(username) {
    console.log(username, "usernameusernameusername");

    if (inputType == "Email") {
      let dataa = {
        email: username.email,
      };

      const data = await UPDATED_OPEN_POST(`forgotpassward`, dataa);
    } else {
      console.log("usernameusernameusername");
      const data = {
        // id: response?.data?.body?.user?.id,
        phoneNumber: "+1" + username.email,
      };
      const user = await UPDATED_OPEN_POST(`sendverificationcode`, data);
      console.log(user, "user send verification code");
    }
  }

  const reSendOTP = async () => {
    // const doctorFamilyData = await UPDATED_OPEN_POST(`forgotpassward`,userName);

    Auth.forgotPassword(userName)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  // const getConfirmationCode = () => {
  //   setLoader(true);
  //   console.log(code, password, confirmPassword);
  //   if (password == confirmPassword) {
  //     Auth.forgotPasswordSubmit(userName, code, password)
  //       .then(data => {
  //         console.log(data);
  //         setLoader(false)
  //         history.push("/");
  //         setShowCodeView('true')
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         setLoader(false)
  //       });
  //   } else {
  //     setLoader(false);
  //     alert("password do not match")
  //   }
  // }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfirmPassword = () => {
    setconfirmPass({
      ...confirmPass,
      showConfirmPassword: !confirmPass.showConfirmPassword,
    });
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
    continuee(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const validateMobileOrEmail = (rule, value, callback) => {
    const mobileRegex = /^\d{10}$/; // Regex pattern for a 10-digit mobile number
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex pattern for an email address

    if (value && !mobileRegex.test(value) && !emailRegex.test(value)) {
      callback("Please enter a valid mobile number or email address");
    } else {
      callback();
    }

    if (mobileRegex.test(value)) {
      console.log("mobile regex value");
      setInputType("Mobile");
    } else if (emailRegex.test(value)) {
      console.log("email regex value");
      setInputType("Email");
    } else {
      setInputType("");
    }
  };

  return (
    <div className={style.main_container}>
      {showCodeView === "true" ? null : inputType === "Mobile" ? (
        <div className="alert alert-info" role="alert" >
          Please check your phone for the verification code
        </div>
      ) : (
        <div
          className="alert alert-success"
          role="alert"
          // style={{ width: 450 }}
        >
          Please check your email for the verification code
        </div>
      )}

      {/* <div className={style.div_content}> */}
      <div className={style.row}>
        <div className={style.col}>
          <div className={style.div_form}>
            <div className={style.main_form}>
              <img
                src="Images/logo.png"
                className={style.imgFluid}
                alt="Doccure Login"
              />
              <h1
                className="mt-4 mb-5"
                style={{ color: "var(--primary-color)" }}
              >
                Forgot Password ?
              </h1>
              {showCodeView == "true" ? (
                <>
                  <Form
                    onFinish={onFinish}
                    name="basic"
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    className={style.formStyle}
                   
                  >
                    
                    <div style={{ width: "60%", minWidth: "300px" }} >              
                           
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true },
                          { validator: validateMobileOrEmail },
                        ]}
                      >
                        <Input className={style.inputStyle} size="large" placeholder="Email Or Phone" />
                      </Form.Item>
                      {/* <input type="email" className="form-control floating" onChange={(text: any) => setEmail(text.target.value)} />
                  <label className="focus-label">Email</label> */}
                    
                    </div>


                    {!loader ? (
                      <button
                       className={`${style.btnGreen} mt-1`}
                        // type="submit"
                        // onClick={continuee}
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                      className={`${style.btnGreen} mt-1`}
                        // type="submit"
                      >
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white", width: 25, height: 25 }}
                        />
                      </button>
                    )}
                  </Form>
                </>
              ) : (
                <>
                
                
                  <ChangePass
                    username={userName}
                    type={inputType}
                    reSendOTP={reSendOTP}
                    
                  />
                
                </>
              )}
              <div className={`text-center ${style.dontHave}`}>
                <Link to="/" style={{  color: "var(--primary-color)" }}>
                  {" "}
                  Login
                </Link>
              </div>
            </div>
            <div className={`text-center  ${style.copy}`}>
            © 2023 ONRx. All rights reserved.
          </div>
          </div>

          
        </div>
        <div className={style.col} style={{ padding: "20px" }}>
          <div className={style.imgStyle}></div>
        </div>
      </div>

      {/* </div> */}
    </div>

    // <div className={style.div_container}>
    //   {showCodeView === 'true' ? null :
    //     inputType === 'Mobile' ? (
    //       <div className="alert alert-info" role="alert" style={{ width: 450 }}>
    //         Please check your phone for the verification code
    //       </div>
    //     ) : (
    //       <div className="alert alert-success" role="alert" style={{ width: 450 }}>
    //         Please check your email for the verification code
    //       </div>
    //     )
    //   }

    //   <div className={style.div_content}>
    //     <div className={style.div_image}>
    //       <div>
    //         {/* <p className="p_name">Welcome</p> */}
    //         <p className={style.p_name}>Forgot Password ?</p>
    //       </div>
    //       <div className={style.div_round}>
    //         <img
    //           src="Images/logo.png"
    //           className={style.imgFluid}
    //           alt="Doccure Login"
    //         />
    //       </div>
    //     </div>

    //     <div className={style.div_form}>
    //       {
    //         showCodeView == 'true' ? (
    //           <>
    //             <Form
    //               onFinish={onFinish}
    //               onFinishFailed={onFinishFailed}
    //               autoComplete="off"
    //               layout="vertical"
    //             >
    //               <div className="form-group form-focus">
    //                 <Form.Item name="email" rules={[{ required: true }, { validator: validateMobileOrEmail }]} >
    //                   <Input size="large" placeholder="Email Or Phone" />
    //                 </Form.Item>
    //                 {/* <input type="email" className="form-control floating" onChange={(text: any) => setEmail(text.target.value)} />
    //               <label className="focus-label">Email</label> */}
    //               </div>

    //               {
    //                 !loader ?
    //                   <button
    //                     className={`${style.btnDashboard} btn btn-primary btn-block mt-5`}
    //                   // type="submit"
    //                   // onClick={continuee}
    //                   >
    //                     Continue
    //                   </button> :
    //                   <button
    //                     className="btn btn-primary btn-block"
    //                   // type="submit"
    //                   >
    //                     <Spinner animation="border" role="status" style={{ color: "white", width: 25, height: 25 }} />
    //                   </button>
    //               }
    //             </Form>
    //           </>
    //         ) : (
    //           <>
    //             <ChangePass username={userName} type={inputType} reSendOTP={reSendOTP} />
    //           </>
    //         )
    //       }
    //     </div>
    //   </div>
    //   <div className={`text-center ${style.dontHave}`}>
    //     <Link to="/" style={{ color: "#1a6ab1" }}>
    //       {" "}
    //       Login
    //     </Link>
    //   </div>
    //   <div className={`text-center  ${style.copy}`}>© 2023 ONRx. All rights reserved.</div>
    // </div>
  );
};

export default ForgotPassword;
