import { TopBar } from "components";
import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EyeIcon from "../../../images/eye_new.png";
import {
  faCalendar,
  faEquals,
  faEye,
  faNotesMedical,
  faFileMedical,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardSidebar from "../sidebar/sidebar";
import {
  Button,
  Drawer,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
  notification,
  Popover
} from "antd";
import Footer from "components/footerFix";
import moment from "moment";
import { base64toBlob } from "../../../utils/common";
import { UPDATEDGET, UPDATEDPOST } from "services/common.api";
import { useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import PatientDetail from "components/patientDetail";
import {
  defaultLayoutPlugin
} from "@react-pdf-viewer/default-layout";
import AddPrescription from "components/addPrescription";
import config from "config";
import SchedulePrescription from "components/schedulePrescription";
import { s3GetPrivate } from "services/s3fileUpload";
import { Provider, useDispatch } from "react-redux";
import { setUser } from "redux/actions/userActions";
import PageHeader from "components/pageHeader";

const Prescription = () => {
  const [visible, setVisible] = useState(false);
  const [priscriptionsDetails, setPriscriptionsDetails] = useState([] as any);
  let user = useSelector((state: any) => state.userReducer.user);
  const { Text, Title } = Typography;
  const [PdfString, setPdfString] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [type, setType] = useState();
  const [selectAppointmentId, setSelectAppointmentId] = useState({} as any);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [loader, setLoader] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isOpenPdfModal, setIsOpenPdfModal] = useState(false);
  const [openAddPrescription, setOpenAddPrescription] = useState(false);
  const [openSchedulePrescription, setOpenSchedulePrescription] = useState(false);

  const [appoimentData, setAppoimentData] = useState(null);
  const [selFamilyMember, setSelFamilyMember] = useState(null);
  const { Option } = Select;
  const [familyMembers, setFamilyMembers] = useState([]);
  const dispatch = useDispatch();

  const priscriptionsListColumn = [
    {
      title: "Doctor",
      dataIndex: "['doctorFirstName','doctorLastName'],",
      key: "doctorFirstName",
      render: (text, row) => (
        <>
          {text !== "null null" && row.doctorFirstName != null ? (
            <Text style={{ textTransform: "capitalize" }}>
              {row.doctorFirstName} {row.doctorLastName}
            </Text>
          ) : (
            <Text> - </Text>
          )}
        </>
      ),
    },
    {
      title: "Appointment Date",
      dataIndex: "startdatetime",
      key: "startdatetime",
      render: (text) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {text != null ? <>

            <div>
              <p style={{ color: "#009efb", margin: 0, fontSize: '14px' }}>
                {"(" + moment(text).utc().format("hh:mm A") + ")"}
              </p>
            </div>
            {moment(text).utc().format("DD-MM-YYYY")}{" "}
          </>
            : <Text> - </Text>}
        </a>
      ),
    },
    {
      title: "Pharmacy Name",
      dataIndex: "pharmacy_name",
      key: "pharmacy_name",

      render: (text, row) => (
        <Popover content={
          <div>
            <span style={{ fontWeight: 'bold' }}>Name :</span> {row?.pharmacy_name}
            <div>
              <span style={{ fontWeight: 'bold' }}>Accreditation Number :</span> {row?.pharmacy_accreditation_number}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Address :</span> {row?.pharmacy_address}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Fax :</span> {row?.pharmacy_fax}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>PhoneNumber :</span> {row?.pharmacy_phone}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>PostalCode :</span> {row?.pharmacy_postal_code}
            </div>
          </div>
        }
          title="Pharmacy Detail">
          <a style={{ color: "#1A3045", fontSize: 15, cursor: "pointer" }}>
            <div className="row">
              <div style={{ paddingLeft: 13 }}>{text}</div>
            </div>
          </a>
        </Popover>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <>
          {row.status != null ? (
            <Text style={{ textTransform: "capitalize",fontSize: 14 }}>
              {/* {row.status} */}
              {row.status == 1 ? <Tag className={style.tagTextBlue} color="blue">New</Tag> : row.status == 2 ? <Tag className={style.tagTextLightGray} color="#F8F9FA">Received</Tag> : row.status == 3 ? <Tag className={style.tagTextGray} color="#E9ECEF">Filling Prescription</Tag> : row.status == 4 ? <Tag className={style.tagTextGreen} color="#D0E7DD">Ready for Pickup</Tag> : row.status == 5 ? <Tag className={style.tagTextPurple} color="var(--light-primary-bg-color)">Scheduled</Tag> : row.status == 6 ? <Tag className={style.tagTextOrange} color="#FFF3CD">Delivered</Tag> : "-"}
            </Text>
          ) : (
            <Text> - </Text>
          )}
        </>
      ),
    },
    {
      title: "Schedule For Delivery",
      dataIndex: "Schedule",
      key: "Schedule",
      render: (text, row) => (
        <>
          {
            console.log("row", row)
          }
          {row.status != null ? (
            <Text style={{ textTransform: "capitalize" }}>
              {row.status == 4 ?
                <>
                  <button
                    className={style.btnSchedule}
                    role="button"
                    onClick={() => schedulePrescriptionPickup(row)}
                  >
                    Schedule
                  </button>

                </> :
                row.status == 6 ?
                  row.schedule_date != null ? <>
                    <div>
                      <p style={{ color: "#009efb", margin: 0, fontSize: '14px' }}>
                        {"(" + row.schedule_time + ")"}
                      </p>
                    </div>
                    {moment(row.schedule_date).utc().format("DD-MM-YYYY")}{" "}
                  </>
                    : <Text> - </Text> 
                  :
                  row.status == 5 ?
                  row.schedule_date != null ?
              <>
                <div>
                  <p style={{ color: "#009efb", margin: 0, fontSize: '14px' }}>
                    {"(" + row.schedule_time + ")"

                    }
                  </p>
                </div>
                {moment(row.schedule_date).utc().format("DD-MM-YYYY")}{" "}
                {
                  console.log("row", row)

                }
              </> : <Text> - </Text> 
                  :"-"}
            </Text>
          ) :
  (
                <Text> - </Text>
              )}
        </>
      ),
    },
    // {
    //   title: "Schedule Date",
    //   dataIndex: "schedule_date",
    //   key: "schedule_date",
    //   render: (text, row) => (
    //     <>
    //       {row.status != null ? (
    //         <Text style={{ textTransform: "capitalize" }}>
    //           {
    //             row.status == 5 || row.status == 6 ?
    //               row.schedule_date : "-"}
    //           {/* {row.status} */}
    //         </Text>
    //       ) : (
    //         <Text> - </Text>
    //       )}
    //     </>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      dataIndex: "id",
      render: (id, data) => (
        <Space size="middle">
          <button
            disabled={data?.appoinment_id == null}
            style={{
              paddingTop: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={data?.appoinment_id == null ? style.buttonJoinDisabled : style.btnTransparentNew}
            role="button"
            onClick={() => getpriscriptions(data, "prescription")}
          >
            <FontAwesomeIcon
              icon={faNotesMedical}
              className={style.icons}
            />
          </button>

          <button
            className={style.btnTransparentNew}
            role="button"
            onClick={() => showPdf(data)}
          >
            <img
              className={`mt-2 ${style.icon1}`}
              src="Images/note_icon 1.png"
              alt="User"
            />

          </button>

          {data.appointmentNote != null ? (
            <>
              {data.appoinment_id != null ? (
                <Button
                  onClick={(event) => viewPrescribedNote(data)}
                  className={`${style.btnTransparentNew} mt-2`}
                >
                  <img
                    src={EyeIcon}
                    className={style.img_eye}

                  />
                </Button>
              ) : null}
            </>
          ) : null}


          {/* <>
              {data.status == 3 ? (
               <button
                  className={style.buttonView}
                  role="button"
                  onClick={() => schedulePrescriptionPickup(data)}
                >
                  Schedule
                </button>
              ) : null}
            </> */}



        </Space>
      ),
    },
  ];


  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  const showPdf = async (data) => {
    console.log("data: ", data);

    if (data?.appoinment_id == null) {
      console.log(`${config.assetUrl}` + data?.precription_document, "dayatatatatatat");

      const path = await (data?.precription_document)

      console.log(path, "path path path")

      const url = await s3GetPrivate(data?.precription_document)

      console.log(url, "pdf set url")

      setPdfString(url);
      setIsModalVisible(true);


    } else {
      try {

        let logBody = {
          message: `User - ${user?.sub} - Generate Prescription PDF - Prescription Page`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${user?.sub} - Prescription Page`,
        }
    
        logEvents(logBody);

        const paylod = {
          prescriptionId: data?.id,
        };
        UPDATEDPOST("generatepdf", paylod).then((response) => {
          console.log("response: ", response);
          if (response?.data?.statusCode === 200) {
            const url = URL.createObjectURL(base64toBlob(response?.data?.body));
            console.log(url, "url pdf");
            setPdfString(url);
            setIsModalVisible(true);
          } else {
            notification.error({
              message: "Error while save prescription.",
            });
          }
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    }

  };

  const getFamilyMember = async () => {

    let logBody = {
      message: `User - ${user?.sub} - Fetch Family Members - Prescription Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Prescription Page`,
    }

    logEvents(logBody);

    try {
      const {
        data: {
          body: { FamilyMembers },
        },
      } = await UPDATEDGET(`userfamilymembers?id=${user?.id}`);
      console.log(FamilyMembers, "FamilyMembers");
      if (FamilyMembers) {
        setFamilyMembers(FamilyMembers);
      } else {
        setFamilyMembers([]);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const viewPrescribedNote = async (preId) => {

    let logBody = {
      message: `User - ${user?.sub} - Generate Notes PDF - Prescription Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Prescription Page`,
    }

    logEvents(logBody);

    console.log("preId: ", preId);
    try {
      const paylod = {
        appointmentId: preId.appoinment_id,
      };
      UPDATEDPOST("generatenotespdf", paylod).then((response) => {
        console.log("response: ", response);
        if (response?.data?.statusCode === 200) {
          const url = URL.createObjectURL(base64toBlob(response?.data?.body));
          console.log(url, "url pdf");
          setPdfString(url);
          setIsModalVisible(true);
        } else {
          notification.error({
            message: "Error while save prescription.",
          });
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const getpriscriptions = (item, type) => {
    console.log("btn click", item, type);
    setSelectAppointmentId(item);
    setType(type);
    setIsDetailVisible(true);
  };

  const getpriscriptionList = (id) => {

    let logBody = {
      message: `User - ${user?.sub} - Fetch Prescriptions - Prescription Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Prescription Page`,
    }

    logEvents(logBody);

    async function fetchMyAPI() {
      console.log(
        `patientprescriptions?patientid=` + id,
        "getpriscriptionList ma id check"
      );
      setLoader(true);
      var prescription = await UPDATEDGET(
        `patientprescriptions?patientid=` + id
      );
      console.log(prescription?.data, "prescription list data .........");
      if (prescription?.data) {
        prescription?.data?.sort(function (a, b) {
          var dateA = undefined;
          var dateB = undefined;
          const astartdate = moment(
            a.createdate,
            "YYYY-MM-DDTHH:mm:ss.SSSS"
          ).format("YYYY-MM-DD hh:mm a");
          const bstartdate = moment(
            b.createdate,
            "YYYY-MM-DDTHH:mm:ss.SSSS"
          ).format("YYYY-MM-DD hh:mm a");
          dateA = new Date(astartdate);
          dateB = new Date(bstartdate);
          return dateB - dateA;
        });
        setPriscriptionsDetails(prescription?.data);

        setLoader(false);
      } else {
        setLoader(false);
      }
    }
    fetchMyAPI();
  };

  useEffect(() => {

    let logBody = {
      message: "Prescription - Page Load",
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Prescription Page`,
    }

    logEvents(logBody);

    getFamilyMember();
    setSelFamilyMember(user.id);
    getpriscriptionList(user.id);
  }, [user.id]);

  const patientDetailClose = () => {
    setIsDetailVisible(false);
  };

  const addPrescriptionOpen = () => {
    setOpenAddPrescription(true);
  }

  const closeAddPrescription = () => {
    setOpenAddPrescription(false);
  }

  const closeSchedulePrescription = () => {
    setOpenSchedulePrescription(false);
  }

  const schedulePrescriptionPickup = (data) => {
    console.log(data, "datatatatata");
    setAppoimentData(data)
    setOpenSchedulePrescription(true);
  }

  return (
    <div style={{height:'100vh'}}>
      <TopBar onShow={showDrawer} />


      <div className="content" style={{ backgroundColor: "#fff", height:'100%'}}>

        <div className={style.container} style={{ height: '100%' }}>
          <div className="row pb-4" style={{ height: '100%' }}  >
            <div
              className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0 `}
              
            >
              <DashboardSidebar />
            </div>
            <Drawer
              closable={false}
              width={300}
              placement="left"
              onClose={onClose}
              visible={visible}
              style={{ paddingTop: 0 }}
            >
              <div className="widget-profile pro-widget-content pt-0 pb-4">
                <div className="profile-info-widget">
                  <img src="../Images/logo.png" height="50" alt="" />
                </div>
              </div>
              <DashboardSidebar />
            </Drawer>

            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
              <PageHeader
                items={[
                  { label: "Dashboard", link: "/dashboard" },
                  { label: "Prescription", link: "" },
                ]}
                pageTitle="Prescription"
              />
              <div className="mt-4 mb-4">
                <div className={`row ${style.tabHeader}`}>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 align-items-center">
                    <Select
                      showSearch
                      placeholder="Select Patient"
                      optionFilterProp="children"
                      size="large"
                      style={{ width: "100%" }}
                      defaultActiveFirstOption
                      onChange={(e) => {
                        setSelFamilyMember(e);
                        getpriscriptionList(e);
                      }}
                      value={selFamilyMember}
                    >
                      <Option
                        value={user?.id}
                        key='own'
                      >
                        {user?.FirstName} {user?.LastName} {" (" + user?.gender + ")"}
                      </Option>
                      {familyMembers ? (
                        <>
                          {familyMembers?.length != 0 ? (
                            <>
                              {familyMembers?.map((itemm, index) => (
                                <>
                                  {itemm.userName != null ? (
                                    <Option
                                      key={itemm?.familymemberid}
                                      value={itemm?.familymemberid}
                                    >
                                      {itemm?.userName + " (" + itemm?.gender + ")"}
                                    </Option>
                                  ) : null}
                                </>
                              ))}
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </Select>
                  </div>
                  <div className="col-sm-6 col-md-8 col-lg-8 col-xl-8 d-flex align-items-center justify-content-end" >
                    <button
                      type="submit"
                      className={style.btnAddPrescription}
                      onClick={() => addPrescriptionOpen()}
                    >
                      Add Prescription
                    </button>
                  </div>


                </div>
                <div className="table-responsive mt-4 pb-4">
                  {loader ? (
                    <div className="spinner">
                      <Spin />
                    </div>
                  ) : (
                    <>
                      <Table
                        dataSource={priscriptionsDetails}
                        size="small"
                        pagination={{
                          defaultPageSize: 10,
                          pageSizeOptions: ["30", "40"],
                          showSizeChanger: false,
                        }}
                        columns={priscriptionsListColumn}
                        scroll={{ x: 1000 }}
                      />
                    </>
                  )}

                  <>
                    <Modal
                      centered
                      visible={pdfUrl?.length > 0 && isOpenPdfModal}
                      width={1000}
                      onCancel={() => {
                        setPdfUrl(null);
                        setIsOpenPdfModal(false);
                      }}
                    >
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <div style={{ height: "750px" }}>
                          <Viewer
                            fileUrl={pdfUrl}
                            plugins={[defaultLayoutPluginInstance]}
                          />
                        </div>
                      </Worker>
                    </Modal>
                  </>

                  <Modal
                    centered
                    visible={isModalVisible}
                    width={1000}
                    onOk={() => setIsModalVisible(false)}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                  >
                    <div className="pt-4">
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <Viewer
                          fileUrl={PdfString}
                          plugins={[defaultLayoutPluginInstance]}
                        />
                      </Worker>
                    </div>
                  </Modal>

                  <PatientDetail
                    title=""
                    open={isDetailVisible}
                    close={patientDetailClose}
                    appointmentId={selectAppointmentId}
                    type={type}
                  />

                  <AddPrescription
                    title="Add Prescription"
                    open={openAddPrescription}
                    close={closeAddPrescription}
                    getpriscriptionList={getpriscriptionList}
                    selectedPatient={selFamilyMember}
                  />

                  <SchedulePrescription
                    title="Schedule Prescription"
                    open={openSchedulePrescription}
                    close={closeSchedulePrescription}
                    appoimentData={appoimentData}
                    getpriscriptionList={getpriscriptionList}
                    selectedPatient={selFamilyMember}
                  />

                </div>
              </div>
              {/* <div className="card">

                <div className="row">
                      <div className="dependent_center col-sm-3">
                        <h3 className="card-title">Prescriptions</h3>
                      </div>
                      <div className="col-sm-9">  
                        <div className={`${style.div_row_flex} "text-right"`}>
                          <Select
                            showSearch
                            placeholder="Select Patient"
                            optionFilterProp="children"
                            size="large"
                            style={{ marginRight: 10 }}
                            defaultActiveFirstOption
                            onChange={(e) => {
                              setSelFamilyMember(e);
                              getpriscriptionList(e);
                            }}
                            value={selFamilyMember}
                          >
                            <Option
                              value={user?.id}
                              key='own'
                            >
                              {user?.FirstName} {user?.LastName}
                            </Option>
                            {familyMembers ? (
                              <>
                                {familyMembers?.length != 0 ? (
                                  <>
                                    {familyMembers?.map((itemm, index) => (
                                      <>
                                        {itemm.userName != null ? (
                                          <Option
                                            key={itemm?.familymemberid}
                                            value={itemm?.familymemberid}
                                          >
                                            {itemm?.userName}
                                          </Option>
                                        ) : null}
                                      </>
                                    ))}
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </Select>

                          <button
                            type="submit"
                            className={style.btn}
                            onClick={() => addPrescriptionOpen()}
                          >
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Add
                            Prescription
                          </button>
                        </div>
                      </div>
                    </div>


                <div className="card-body">
                  <div className={`row ${style.tabHeader}`}>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 align-items-center">
                      <Select
                        showSearch
                        placeholder="Select Patient"
                        optionFilterProp="children"
                        size="large"
                        style={{ width: "100%" }}
                        defaultActiveFirstOption
                        onChange={(e) => {
                          setSelFamilyMember(e);
                          getpriscriptionList(e);
                        }}
                        value={selFamilyMember}
                      >
                        <Option
                          value={user?.id}
                          key='own'
                        >
                          {user?.FirstName} {user?.LastName}
                        </Option>
                        {familyMembers ? (
                          <>
                            {familyMembers?.length != 0 ? (
                              <>
                                {familyMembers?.map((itemm, index) => (
                                  <>
                                    {itemm.userName != null ? (
                                      <Option
                                        key={itemm?.familymemberid}
                                        value={itemm?.familymemberid}
                                      >
                                        {itemm?.userName}
                                      </Option>
                                    ) : null}
                                  </>
                                ))}
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </Select>
                    </div>
                    <div className="col-sm-6 col-md-8 col-lg-8 col-xl-8 d-flex align-items-center justify-content-end" >
                      <button
                        type="submit"
                        className={style.btnAddPrescription}
                        onClick={() => addPrescriptionOpen()}
                      >
                        Add Prescription
                      </button>
                    </div>


                  </div>
                  <div className="table-responsive">
                    {loader ? (
                      <div className="spinner">
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <Table
                          dataSource={priscriptionsDetails}
                          size="small"
                          pagination={{
                            defaultPageSize: 10,
                            pageSizeOptions: ["30", "40"],
                            showSizeChanger: false,
                          }}
                          columns={priscriptionsListColumn}
                          scroll={{ x: 1000 }}
                        />
                      </>
                    )}

                    <>
                      <Modal
                        centered
                        visible={pdfUrl?.length > 0 && isOpenPdfModal}
                        width={1000}
                        onCancel={() => {
                          setPdfUrl(null);
                          setIsOpenPdfModal(false);
                        }}
                      >
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                          <div style={{ height: "750px" }}>
                            <Viewer
                              fileUrl={pdfUrl}
                              plugins={[defaultLayoutPluginInstance]}
                            />
                          </div>
                        </Worker>
                      </Modal>
                    </>

                    <Modal
                      centered
                      visible={isModalVisible}
                      width={1000}
                      onOk={() => setIsModalVisible(false)}
                      onCancel={() => setIsModalVisible(false)}
                      footer={null}
                    >
                      <div className="pt-4">
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                          <Viewer
                            fileUrl={PdfString}
                            plugins={[defaultLayoutPluginInstance]}
                          />
                        </Worker>
                      </div>
                    </Modal>

                    <PatientDetail
                      title=""
                      open={isDetailVisible}
                      close={patientDetailClose}
                      appointmentId={selectAppointmentId}
                      type={type}
                    />

                    <AddPrescription
                      title="Add Prescription"
                      open={openAddPrescription}
                      close={closeAddPrescription}
                      getpriscriptionList={getpriscriptionList}
                      selectedPatient={selFamilyMember}
                    />

                    <SchedulePrescription
                      title="Schedule Prescription"
                      open={openSchedulePrescription}
                      close={closeSchedulePrescription}
                      appoimentData={appoimentData}
                      getpriscriptionList={getpriscriptionList}
                      selectedPatient={selFamilyMember}
                    />

                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>


      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>


  );
};
export default Prescription;
