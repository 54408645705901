import { TopBar } from "components";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import DashboardSidebar from "../sidebar/sidebar";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import {
  UPDATEDDELETE,
  UPDATEDGET,
  UPDATEDPUT,
  UPDATEDPOST,
} from "../../../services/common.api";
import { v4 as uuid } from "uuid";
import { s3GetPrivate, s3Upload } from "../../../services/s3fileUpload/index";
import { find } from "lodash";
import {
  setUser,
  setProfileComplate,
  setFamilyDoctor,
} from "../../../redux/actions/userActions";
import {
  Form,
  notification,
  Select,
  Spin,
  Modal,
  Space,
  Tag,
  Table,
  Tooltip,
  Button,
  Popconfirm,
  Input,
  Drawer,
  DatePicker,
  Alert,
  Switch,
  Tabs,
  Upload,
} from "antd";
import Footer from "components/footerFix";
// import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
// import "react-datepicker/dist/react-datepicker.css";
import { DeleteFilled } from "@ant-design/icons";
import CountrysJson from "../../../jsonFiles/country.json";
import SearchLocationInput from "components/searchAddress";
import MaskInput from "react-maskinput";
import { IMG01, IMG02 } from "./img";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../graphql/subscriptions";
import { MessageOutlined } from "@ant-design/icons";
import { RINGER } from "../img";
import { Cancel, CancelOutlined, InboxOutlined } from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import pharmacyListStaticArray from "../../../components/addPrescription/pharmacyList.json";
import PageHeader from "components/pageHeader";

// import { select } from "redux-saga/effects";

const Profile = () => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { Option } = Select;
  const [userInfo, setUserInfo] = useState({} as any);
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryJson, setCountryJson] = useState({} as any);
  const [selectCountry, setSelectCountry] = useState({} as any);
  const [validPostCode, setValidPostCode] = useState({} as any);
  // const [familyDoctorDetails, setFamilyDoctorDetails] = useState("" as any);
  const [selectedFamilyMemberID, setSelectFamilyMemberID] = useState("");
  const [insuranceType, setInsuranceType] = useState("OHIP");
  const [allDoctor, setAllDoctor] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [formValues, setFormValues] = useState({} as any);
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [insuranceData, setInsuranceData] = useState([] as any);
  const [privateInsuranceData, setPrivateInsuranceData] = useState([] as any);
  let config = useSelector((state: any) => state.AppConfig.config);
  const userData = useSelector((state: any) => state.userReducer.user);
  const familyD = useSelector((state: any) => state?.userReducer?.familyDoc);
  const [ProfilePath, setprofilePath] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [address, setAddress] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [, setLat] = useState(null);
  const [isInsurance, setIsInsurance] = useState(false);
  const [disableAddNew, setDisableAddNew] = useState(false);
  const [languageArray, setLanguageArray] = useState([]);
  const [, setLng] = useState(null);
  const [uploadedFrontPath, setUploadedFrontPath] = useState(null);
  const [uploadedBackPath, setUploadedBackPath] = useState(null);

  const [userProfileKey, setUserProfileKey] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selFamilyMember, setSelFamilyMember] = useState(null);
  const [activeTab, SetactiveTab] = useState("1");

  const [selPharmacyDetail, setSelPharmacyDetail] = useState({} as any);
  const [selPharmacy, setSelPharmacy] = useState(null);
  const [pharmacyList, setPharmacyList] = useState([]);

  const { Dragger } = Upload;

  const relationShip = [
    { id: "brother", name: "Brother" },
    { id: "cousin", name: "Cousin" },
    { id: "daughter", name: "Daughter" },
    { id: "father", name: "Father" },
    { id: "grandfather", name: "Grandfather" },
    { id: "grandmother", name: "Grandmother" },
    { id: "husband", name: "Husband" },
    { id: "mother", name: "Mother" },
    { id: "nephew", name: "Nephew" },
    { id: "niece", name: "Niece" },
    { id: "son", name: "Son" },
    { id: "sister", name: "Sister" },
    { id: "uncle", name: "Uncle" },
    { id: "wife", name: "Wife" },
    { id: "other", name: "Other" },
  ];

  const languages = [
    {
      label: "Arabic",
      value: "Arabic",
    },
    {
      label: "English",
      value: "English",
    },
    {
      label: "French",
      value: "French",
    },
    {
      label: "Gujarati",
      value: "Gujarati",
    },
    {
      label: "Hindi",
      value: "Hindi",
    },
    {
      label: "Italian",
      value: "Italian",
    },
    {
      label: "Spanish",
      value: "Spanish",
    },
    {
      label: "Urdu",
      value: "Urdu",
    },
    {
      lable: "Punjabi",
      value: "Punjabi",
    },
  ];
  // const audio = new Audio('https://onrx-dev-portal.s3.ca-central-1.amazonaws.com/message.mp3');
  // audio.crossOrigin = "anonymous"

  const playAudio = () => {
    const audio = new Audio();
    // audio.crossOrigin = "anonymous";
    audio.src = "https://www.soundjay.com/phone/sounds/telephone-ring-01a.mp3";
    audio.play().catch((error) => {
      // Handle any playback errors
      console.log(error, "audio play error");
    });
  };

  const props1 = {
    name: "file",
    multiple: false,
    // accept: "application/pdf","application/vnd.openxmlformats-officedocument.wordprocessingm","application/msword",
    accept: ".pdf,.doc,.docx,image/*",
    beforeUpload: async (file) => {
      console.log(file, "first file");
      setUploadedFrontPath(file);
      return false;
    },
  };
  const props2 = {
    name: "file",
    multiple: false,
    // accept: "application/pdf","application/vnd.openxmlformats-officedocument.wordprocessingm","application/msword",
    accept: ".pdf,.doc,.docx,image/*",
    beforeUpload: async (file) => {
      console.log(file, "selected file");
      setUploadedBackPath(file);
      return false;
    },
  };

  useEffect(() => {

    let logBody = {
      message: "Profile - Page Load",
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    if (userData?.id) {
      const subscribeToNotifications = async () => {
        if (subscription && subscription._cleanup !== undefined) {
          // If a subscription already exists, no need to create a new one
          return;
        }

        const newSubscription = (
          API.graphql(
            graphqlOperation(subscriptions.subscribeToNewNotification, {
              userId: userData?.id + "",
            })
          ) as any
        ).subscribe({
          next: (notificationData) => {
            const newNotification =
              notificationData.value.data.subscribeToNewNotification;
            console.log("New notification received:", newNotification);

            console.log(
              localStorage.getItem("chatHead"),
              "localStorage.getItem",
              newNotification?.conversationId
            );
            if (
              parseInt(newNotification?.conversationId) ==
              parseInt(localStorage.getItem("chatHead"))
            ) {
            } else {
              notification.open({
                message: "New Message",
                description: newNotification?.message,
                duration: 5,
                icon: <MessageOutlined style={{ color: "#1890ff" }} />,
                placement: "topRight",
              });
              // audio.play();
              playAudio();
            }
          },
          error: (error) => {
            console.error("Error subscribing to new notification:", error);
          },
        });

        setSubscription(newSubscription);
      };

      subscribeToNotifications();

      return () => {
        if (subscription) {
          subscription?.unsubscribe();
          setSubscription(null);
        }
      };
    }
  }, [subscription, userData?.id]);

  function onChnageLanguage(value) {
    console.log("Valluee--->",value);
    
    setLanguageArray(value);
  }

  const handleChange = (event) => {
    // console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");

    if (event.target.name === "FirstName") {
      console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
      console.log(userData, "userData?.FirstName");
      if (event.target.value !== userData?.FirstName) {
        setDisableAddNew(true);
      } else {
        setDisableAddNew(false);
      }
    } else if (event.target.name === "MiddleName") {
      if (event.target.value !== userData?.MiddleName) {
        setDisableAddNew(true);
      } else {
        setDisableAddNew(false);
      }
    } else if (event.target.name === "LastName") {
      if (event.target.value !== userData?.LastName) {
        setDisableAddNew(true);
      } else {
        setDisableAddNew(false);
      }
    }

    if (
      errorMsg == "Invalid OHIP Card" &&
      event.target.name == "Insurancesvcnum"
    ) {
      setShowError(false);
    } else if (
      errorMsg == "Invalid version code" &&
      event.target.name == "insuranceVersionCode"
    ) {
      setShowError(false);
    } else if (
      (errorMsg == "First name should match with OHIP" ||
        errorMsg ==
        "Your account profile details should match with the OHIP card details") &&
      event.target.name == "fname"
    ) {
      setShowError(false);
    }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == 'Change the profile details according to the OHIP card') && event.target.name == 'fname') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == 'First name should match with profile info') && event.target.name == 'fname') {
    //   setShowError(false);
    // }
    else if (
      (errorMsg == "Last name should match with OHIP" ||
        errorMsg ==
        "Your account profile details should match with the OHIP card details") &&
      event.target.name == "lname"
    ) {
      setShowError(false);
    }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == 'Change the profile details according to the OHIP card') && event.target.name == 'lname') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == 'Last name should match with profile info') && event.target.name == 'lname') {
    //   setShowError(false);
    // }
    else if (errorMsg == "Invalid or Expired health card") {
      setShowError(false);
    }

    const name = event?.target?.name;
    const value = event?.target?.value;
    console.log(name, value);
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    console.log(formValues, "formValues");

    // if(event.target.name == 'insurer'){
    //   console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");

    //   if(event.target.value == undefined){
    //     setFormValues((formValues) => ({ ...formValues, [event.target.name]: undefined }));
    //     console.log(formValues, "formValues again");
    //   }
    // }
  };

  const handleChangePhonenumber = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh ");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    setPhoneNumber(event.target.value);
  };

  const onChangeDateOfBirth = (date, dateString) => {
    console.log("dataeateaeataeata", dateString);
    setDateOfBirth(dateString);
    if (errorMsg == "Date of birth should match with OHIP") {
      setShowError(false);
    }
  };

  // const handleChangeDob = (event) => {
  //     console.log(moment(event).format('YYYY-MM-DD'))
  //     setDob(moment(event).format('YYYY-MM-DD'))
  // }

  const handleChangeMemeber = (event) => {
    console.log(event);
    setSelectFamilyMemberID(event);
  };

  const getInsuranceInfo = async () => {

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - Fetch Insurance Detail - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    try {
      const res = await UPDATEDGET(`insurance/${userData.id}`);
      console.log(res?.data, "getInsuranceInfo");
      if (res?.data.length != 0) {
        form1.setFieldsValue({
          Insurancesvcnum: res?.data[0]?.insurance_number,
        });
        form1.setFieldsValue({
          insuranceVersionCode: res?.data[0]?.insurance_vc,
        });
        setIsInsurance(false);
      } else {
        setIsInsurance(true);
      }
      setInsuranceData(res?.data);
    } catch (err) {
      console.log("error", err);
    }
  };
  const getPrivateInsuranceInfo = async () => {

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - Fetch Private Insurance Detail - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    try {
      const res = await UPDATEDGET(`privateinsurance/${userData.id}`);
      console.log(res?.data, "get private user insurance");
      if (res?.data.length != 0) {
        form1.setFieldsValue({
          insurer: res?.data[0]?.insurer,
        });
        form1.setFieldsValue({
          insuranceNumber: res?.data[0]?.insurance_number,
        });
        form1.setFieldsValue({
          lastname_cardholder: res?.data[0]?.name,
        });
        form1.setFieldsValue({
          relationship: res?.data[0]?.relation,
        });
        // form1.setFieldsValue({
        //   relationship: res?.data[0]?.name,
        // });
      }
      if (res?.data[0]) {
        setPrivateInsuranceData(res?.data[0]);
      } else {
        setPrivateInsuranceData([]);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const getFamilyDoctor = async () => {

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - Fetch Family Doctor Detail - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    try {
      await UPDATEDGET(`patient/familydoc/${userData?.id}`).then((response) => {
        if (response?.data?.body != null) {
          console.log(
            "🚀 ~ file: index.tsx ~ line 110 ~ awaitGET ~ response",
            response
          );
          let N = response?.data?.body?.CompanyEmployee?.CompanyEmployee;
          // setFamilyDoctorDetails(`Dr. ${N?.FirstName} ${N?.LastName}`)
          console.log(
            `Dr. ${N?.FirstName} ${N?.LastName}`,
            "NNNNNNNNNNNNNNNNNNN"
          );
          if (N !== null && N !== "") {
            if (N?.FirstName !== undefined && N?.LastName !== undefined) {
              dispatch(setFamilyDoctor(`Dr. ${N?.FirstName} ${N?.LastName}`));
            } else {
              dispatch(setFamilyDoctor(""));
            }
          }
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  // const getSelectdFamilyDoctor = async () => {
  //     if (userData.id !== "") {
  //         try {
  //             const familyDoctor = await GET(`patient/familydoc/${userData?.id}`);
  //             console.log(familyDoctor.data?.body, "Family Doctor data");
  //             setFamilyDisplayId(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee)
  //             familyDoctor.name = familyDoc.FirstName + familyDoc.MiddleName + familyDoc.LastName
  //             dispatch(setFamilyDoctor(`Dr. ${familyDoctor.data?.body?.CompanyEmployee?.CompanyEmployee?.FirstName} ${familyDoctor.data?.body?.CompanyEmployee?.CompanyEmployee?.LastName}`))
  //             setFamilyDoc(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee);
  //             console.log(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee.ID, "iddididididiididididid")
  //             setSelectFamilyMemberID(familyDoctor?.data?.body?.CompanyEmployee?.ID);
  //         } catch (error) {
  //             console.log(error)
  //         }
  //     }

  // }

  const getUserDetails = async () => {

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - Fetch User Detail - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    try {
      const userInfo = await UPDATEDGET(
        `user?cognitoid=${userData?.cognitoid}`
      );
      console.log(userInfo, "update user");
      console.log(userInfo.data?.body, "update user");

      let ud;
      ud = userInfo.data?.body;
      ud.userId = ud?.cognitoid;
      ud.sub = ud?.cognitoid;

      dispatch(setUser(ud));
      setLanguageArray(userInfo.data?.body.Languages.split(","));

      pharmacyList.forEach((element) => {
        if (element?.id == userInfo.data?.body.preferred_pharmacy) {
          setSelPharmacy(element?.name);
        }
      });

      console.log(
        userData,
        "user user useruser userData",
        userInfo.data?.body.Languages.split(",")
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [pharmacyList]);

  const handleSubmitOhip = async (event) => {
    console.log(event, "event handle submit ohip");
    // if (uploadedFrontPath !== null && uploadedBackPath !== null) {

    if (
      event?.Insurancesvcnum != undefined &&
      event?.Insurancesvcnum != "" &&
      event?.Insurancesvcnum != "____-___-___" &&
      (event?.insuranceVersionCode == undefined ||
        event?.insuranceVersionCode == "")
    ) {
      notification.error({
        message: "You need to select version code first",
      });
    } else if (
      event?.Insurancesvcnum != undefined &&
      event?.Insurancesvcnum != "" &&
      event?.Insurancesvcnum != "____-___-___" &&
      event?.insuranceVersionCode != "" &&
      event?.insuranceVersionCode != ""
    ) {
      console.log("Only ohip info there");
      await handleSubmitModal(event);
    } else if (
      event?.insuranceNumber != undefined &&
      (event?.Insurancesvcnum == undefined ||
        event?.Insurancesvcnum == "" ||
        event?.Insurancesvcnum == "____-___-___")
    ) {
      console.log("Only insurance detail is there no any ohip info there");

      const OhipDuplicate = !!insuranceData.find(
        (item) => item.insurance_type === "OHIP"
      );

      if (OhipDuplicate) {
        if (insuranceData?.length != 0) {

          let logBody = {
            message: `User - ${userData.id} (${userData?.sub}) - Delete Insurance Detail - Profile Page`,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Profile Page`,
          }
      
          logEvents(logBody);

          try {
            UPDATEDDELETE(`insurance/delete/${insuranceData[0]?.id}`).then(
              () => {
                console.log("Insurance Deleted if any exist");
              }
            );
          } catch (error) {
            console.log(error);
          }
        }
      }

      insuranceAddFunctionality(event);
    } else {
      console.log("Both information not selected");

      const OhipDuplicate = !!insuranceData.find(
        (item) => item.insurance_type === "OHIP"
      );

      if (OhipDuplicate) {
        if (insuranceData?.length != 0) {

          let logBody = {
            message: `User - ${userData.id} (${userData?.sub}) - Delete Insurance Detail - Profile Page`,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Profile Page`,
          }
      
          logEvents(logBody);

          try {
            UPDATEDDELETE(`insurance/delete/${insuranceData[0]?.id}`).then(
              () => {
                console.log("Insurance Deleted if any exist");
              }
            );
          } catch (error) {
            console.log(error);
          }
        }
      }

      setDisableAddNew(false);
      history.push("/view-profile");
      getUserDetails();
      dispatch(setProfileComplate("true"));
    }
  };

  const handleSubmit = async (event) => {
    setShowError(false);
    console.log(event, "event eventevent event event event event");

    event.FirstName = event.FirstName.trim();
    event.LastName = event.LastName.trim();

    if (address == null || address === "") {
      setAddress(null);
    } else {

      let logBody = {
        message: `User - ${userData.id} (${userData?.sub}) - Update User Profile - Profile Page`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Profile Page`,
      }
  
      logEvents(logBody);

      setLoader(true);
      try {
        setLoader(true);
        UPDATEDPUT(`user/patient/profile/${userData.cognitoid}`, {
          ...event,
          // medconcent : event?.medconcent == true ? 1 : 0,
          name: event?.FirstName.trim() + " " + event?.LastName.trim(),
          DOB: startDate,
          phoneNumber: phoneNumber,
          Address1: address,
          Address2: event.Address2!,
          languages: languageArray ? languageArray.toString() : "",
          preferred_pharmacy: selPharmacyDetail ? selPharmacyDetail.id : null,
          // profilepicture:
          //   userProfileKey == null ? userData.profilepicture : userProfileKey,
        }).then(() => {
          setLoader(false);
          getUserDetails();

          if (selectedFamilyMemberID) {
            const found = allDoctor.find(
              (element) => element.companyemployeeid == selectedFamilyMemberID
            );
            console.log(found, "-*-*-*-**");
            dispatch(
              setFamilyDoctor(`Dr. ${found?.FirstName} ${found?.LastName}`)
            );
          }
          dispatch(setProfileComplate("true"));
          updateOrCreateFamilyMember();
          notification.success({
            message: "Your Data Successfully Added",
          });
          SetactiveTab("2");
          setLoader(false);
          setDisableAddNew(false);
          getUserDetails();
          dispatch(setProfileComplate("true"));
        });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    }
  };

  const updateOrCreateFamilyMember = async () => {
    console.log(selectedFamilyMemberID);
    const familyAddObject = {
      patient_id: userData.id,
      provider_id: selectedFamilyMemberID,
    };
    console.log(familyAddObject);
    // try {
    //   const res = await UPDATEDPOST("patient/familydoc", familyAddObject);
    //   console.log(res, "family doc api ");
    // } catch (err) {
    //   console.log("error", err);
    // }
  };

  const handlePostalCode = () => {
    if (config?.country == "Canada") {
      // setValidPostCode(/^([A-Za-z][0-9][A-Za-z])\s*([0-9][A-Za-z][0-9])$/);
      setValidPostCode(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else if (config?.country == "United States") {
      setValidPostCode(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    } else if (config?.country == "India") {
      setValidPostCode(/^[1-9][0-9]{5}$/);
    }
  };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {
    
    

    getPharmacyList();
    setSelPharmacy(null);
    setSelPharmacyDetail([]);
    console.log(userData?.Languages?.split(","), "userData userData 11");

    getFamilyMember();
    getFamilyDoctor();
    handlePostalCode();
    setCountryJson(CountrysJson);
    console.log(countryJson);
    console.log(userData, "userDatauserDatauserData");
    console.log(config?.country, "cut");
    console.log(CountrysJson, "CountrysJson");

    // if(userData?.Country == "" || userData?.Country)
    // let G = userData?.Country == null ? config?.country : userData?.Country;
    let G = config?.country;

    console.log(G, "GGGGGGGGGG");

    var obj = CountrysJson?.countries?.filter((obj) => obj.country === G);
    console.log(obj, "call");
    setSelectCountry(obj[0]);

    setPhoneNumber(userData?.phoneNumber);
    console.log("Address2--->",userData?.Address2);
    
    setAddress((userData?.Address1 !== null && userData?.Address1 !== "null" && userData?.Address1 !== "") ? userData?.Address1 : "" );
    getInsuranceInfo();
    getPrivateInsuranceInfo();
    getUserDetails();
    // getSelectdFamilyDoctor();
    if (formValues.DOB !== "") {
      setDob(formValues.DOB);
    }
    if (userData?.DOB !== null && userData?.DOB !== "" && userData?.DOB !== "null") {
      console.log(
        "Ahi andar DOB na if ma avyu",
        moment(userData.DOB, "YYYY-MM-DD").format("YYYY-MM-DD")
      );
      setStartDate(moment(userData.DOB, "YYYY-MM-DD").format("YYYY-MM-DD"));
    }

    setUserInfo(userData);
    getDoctorList();
  }, []);

  const getFamilyMember = async () => {

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - Fetch Family Members Detail - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    try {
      const {
        data: {
          body: { FamilyMembers },
        },
      } = await UPDATEDGET(`userfamilymembers?id=${userData?.id}`);
      console.log(FamilyMembers, "FamilyMembers");
      if (FamilyMembers) {
        setFamilyMembers(FamilyMembers);
      } else {
        setFamilyMembers([]);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const dateFormat = "YYYY/MM/DD";

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const getDoctorList = async () => {

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - Fetch Doctors Detail - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    const doctorList = await UPDATEDGET(`employee/doctors`);
    console.log(doctorList, "-*-*-*All doctorList");
    setAllDoctor(doctorList.data);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
      code: "${label} is not a valid!",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  function handleChangeSelect(value) {
    console.log(value);
    setInsuranceType(value);
  }

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const addOhipCardDetail = async (idetail, event, fromm) => {
    let PreviousFormValue = form.getFieldsValue();

    let ohipNumber = event.Insurancesvcnum;
    let ohip1 = event.Insurancesvcnum.replaceAll("-", "");
    console.log(ohipNumber, "ohipnumberohipnumber");
    let data = {
      // "provider-number": "020497",
      hcn: ohip1,
      vc: event.insuranceVersionCode,
      user:
        PreviousFormValue.FirstName.trim() +
        " " +
        PreviousFormValue.LastName.trim(),
      // "client-id": "1704898766014x798343235103228000"
    };
    console.log(data, "check Api  Data");
    setLoader(true);

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - OHIP Card Check - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);


    await UPDATEDPOST("ohip/check", data)
      .then(
        async (response: any) => {
          console.log(response, "responce");
          let D = moment(startDate).format("YYYY-MM-DD");

          if (response?.data?.response["MOH-card-eligible"] == false) {
            setLoader(false);
            setErrorMsg("Invalid OHIP Card");
            setShowError(true);
            setLoader(false);
          } else {
            if (
              response?.data?.response["MOH-Message"] == "Invalid version code"
            ) {
              setLoader(false);
              setErrorMsg("Invalid version code");
              setShowError(true);
              setLoader(false);
            } else {
              if (response?.data?.response["MOH-card-status"] == "invalid") {
                setLoader(false);
                setErrorMsg("Invalid or Expired health card");
                setShowError(true);
                setLoader(false);
              } else {
                console.log(D, "DDDDD");
                if (response?.data?.response.DOB !== D) {
                  setLoader(false);
                  setErrorMsg("Date of birth should match with OHIP");
                  setShowError(true);
                  setLoader(false);
                } else {
                  if (
                    response?.data?.response["First-name"].toLowerCase() !==
                    PreviousFormValue.FirstName.trim().toLowerCase()
                  ) {
                    setLoader(false);
                    setErrorMsg("First name should match with OHIP");
                    setShowError(true);
                    setLoader(false);
                  } else {
                    if (
                      response?.data?.response["Last-name"].toLowerCase() !==
                      PreviousFormValue.LastName.toLowerCase()
                    ) {
                      setLoader(false);
                      setErrorMsg("Last name should match with OHIP");
                      setShowError(true);
                      setLoader(false);
                    } else {
                      if (fromm == "fromDelete") {

                        let logBody = {
                          message: `User - ${userData.id} (${userData?.sub}) - Delete Insuarance - Profile Page`,
                          logGroupName: "ONRx-Patient-Portal",
                          logStreamName: `User - ${userData?.sub} - Profile Page`,
                        }
                    
                        logEvents(logBody);

                        try {
                          UPDATEDDELETE(`insurance/delete/${idetail?.id}`).then(
                            () => {
                              finalAddOhip(
                                PreviousFormValue,
                                event,
                                ohipNumber
                              );
                            }
                          );
                        } catch (error) {
                          console.log(error);
                        }
                      } else {
                        finalAddOhip(PreviousFormValue, event, ohipNumber);
                      }
                    }
                  }
                }
              }
            }
          }
        },
        (err) => {
          setBtnLoader(false);
          console.log(err);
        }
      );
  };

  const finalAddOhip = async (PreviousFormValue, event, ohipNumber) => {
    console.log(event, "event for add ohip", ohipNumber);

    if (event?.insuranceNumber != undefined) {
      insuranceAddFunctionality(event);
    }

    const Ohip = {
      name:
        PreviousFormValue.FirstName.trim() +
        " " +
        PreviousFormValue.LastName.trim(),
      number: ohipNumber,
      provider: "NA",
      type: insuranceType,
      vc: event.insuranceVersionCode,
      user_id: userData.id,
      is_valid: 1,
      relation: null,
      frontcard_path: null,
      backcard_path: null,
      insurer: null,
    };
    console.log(Ohip, "Ohip");

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - Add Insurance Detail - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    try {
      const res = await UPDATEDPOST("insurance", Ohip);
      console.log(res, "family insurances------0 ");

      setDisableAddNew(false);

      try {
        setLoader(true);

        let logBody = {
          message: `User - ${userData.id} (${userData?.sub}) - Update User Profile - Profile Page`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Profile Page`,
        }
    
        logEvents(logBody);

        UPDATEDPUT(`user/patient/profile/${userData.cognitoid}`, {
          ...PreviousFormValue,
          // medconcent : event?.medconcent == true ? 1 : 0,
          name:
            PreviousFormValue?.FirstName.trim() +
            " " +
            PreviousFormValue?.LastName.trim(),
          DOB: startDate,
          phoneNumber: phoneNumber,
          Address1: address,
          insuranceVersionCode: event?.insuranceVersionCode,
          Insurancesvcnum: event?.Insurancesvcnum,
          // profilepicture:
          //   userProfileKey == null ? userData.profilepicture : userProfileKey,
        }).then(() => {
          setLoader(false);
          getUserDetails();

          if (selectedFamilyMemberID) {
            const found = allDoctor.find(
              (element) => element.companyemployeeid == selectedFamilyMemberID
            );
            console.log(found, "-*-*-*-**");
            dispatch(
              setFamilyDoctor(`Dr. ${found?.FirstName} ${found?.LastName}`)
            );
          }
          dispatch(setProfileComplate("true"));
          updateOrCreateFamilyMember();
          notification.success({
            message: "Your Data Successfully Added",
          });
          setLoader(false);
          setDisableAddNew(false);
          history.push("/view-profile");
          getUserDetails();
          dispatch(setProfileComplate("true"));
        });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }

      // notification.success({
      //   message: "OHIP details added succesfully",
      // });
      getInsuranceInfo();
      getPrivateInsuranceInfo();
      setBtnLoader(false);
      setIsModalVisible(false);
      form.resetFields();
    } catch (err) {
      console.log("error", err);
    }
  };

  const insuranceAddFunctionality = async (event) => {
    if (privateInsuranceData?.length != 0) {

      let logBody = {
        message: `User - ${userData.id} (${userData?.sub}) - Delete Insurance Detail - Profile Page`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Profile Page`,
      }
  
      logEvents(logBody);

      console.log(
        "0000000000000 delete private insurance ma avyu",
        privateInsuranceData
      );
      UPDATEDDELETE(`insurance/delete/${privateInsuranceData?.id}`).then(
        async () => {
          let uploadedDocumentF = null;
          if (uploadedFrontPath != null) {
            const data = {
              userId: userData?.id,
              type: uploadedFrontPath.type,
              fileName: `insurance/${uuid()}_` + uploadedFrontPath.name,
            };

            uploadedDocumentF = await s3Upload(
              data.fileName,
              uploadedFrontPath
            );
            console.log("Upload ma avyu Front", uploadedDocumentF);
          } else {
            uploadedDocumentF = privateInsuranceData.frontcard_path;
          }

          let uploadedDocumentB = null;
          if (uploadedBackPath != null) {
            const dataa = {
              userId: userData?.id,
              type: uploadedBackPath.type,
              fileName: `insurance/${uuid()}_` + uploadedBackPath.name,
            };

            uploadedDocumentB = await s3Upload(
              dataa.fileName,
              uploadedBackPath
            );
            console.log("Upload ma avyu Back", uploadedDocumentB);
          } else {
            uploadedDocumentB = privateInsuranceData.backcard_path;
          }

          const OhipPri = {
            name: event?.lastname_cardholder,
            number: event?.insuranceNumber,
            provider: "NA",
            type: "PRIVATE_INSURANCE",
            user_id: userData.id,
            is_valid: 1,
            relation: event?.relationship,
            frontcard_path: uploadedDocumentF,
            backcard_path: uploadedDocumentB,
            insurer: event?.insurer,
          };

          let logBody = {
            message: `User - ${userData.id} (${userData?.sub}) - Add Insurance Detail - Profile Page`,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Profile Page`,
          }
      
          logEvents(logBody);

          const resIn = await UPDATEDPOST("insurance", OhipPri);
          console.log(resIn, " insurances- OhipPri OhipPri ");

          setLoader(false);
          setDisableAddNew(false);
          history.push("/view-profile");
          getUserDetails();
          dispatch(setProfileComplate("true"));
        }
      );
    } else {
      let uploadedDocumentF = null;
      if (uploadedFrontPath != null) {
        const data = {
          userId: userData?.id,
          type: uploadedFrontPath.type,
          fileName: `insurance/${uuid()}_` + uploadedFrontPath.name,
        };

        uploadedDocumentF = await s3Upload(data.fileName, uploadedFrontPath);
        console.log("Upload ma avyu Front", uploadedDocumentF);
      } else {
        uploadedDocumentF = privateInsuranceData.frontcard_path;
      }

      let uploadedDocumentB = null;
      if (uploadedBackPath != null) {
        const dataa = {
          userId: userData?.id,
          type: uploadedBackPath.type,
          fileName: `insurance/${uuid()}_` + uploadedBackPath.name,
        };

        uploadedDocumentB = await s3Upload(dataa.fileName, uploadedBackPath);
        console.log("Upload ma avyu Back", uploadedDocumentB);
      } else {
        uploadedDocumentB = privateInsuranceData.backcard_path;
      }

      const OhipPri = {
        name: event?.lastname_cardholder,
        number: event?.insuranceNumber,
        provider: "NA",
        type: "PRIVATE_INSURANCE",
        user_id: userData.id,
        is_valid: 1,
        relation: event?.relationship,
        frontcard_path: uploadedDocumentF,
        backcard_path: uploadedDocumentB,
        insurer: event?.insurer,
      };

      let logBody = {
        message: `User - ${userData.id} (${userData?.sub}) - Add Insurance Detail - Profile Page`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Profile Page`,
      }
  
      logEvents(logBody);

      const resIn = await UPDATEDPOST("insurance", OhipPri);
      console.log(resIn, " insurances- OhipPri OhipPri ");

      setLoader(false);
      setDisableAddNew(false);
      history.push("/view-profile");
      getUserDetails();
      dispatch(setProfileComplate("true"));
    }
  };

  const handleSubmitModal = async (event: any) => {
    console.log("Success:", event);

    setLoader(true);
    let ohipNumber = event.Insurancesvcnum.replaceAll("-", "");
    console.log(ohipNumber, "ohipnumberohipnumber");

    const OhipDuplicate = !!insuranceData.find(
      (item) => item.insurance_type === "OHIP"
    );
    if (OhipDuplicate) {
      console.log(insuranceData, "jhkk");

      if (insuranceData?.length != 0) {
        addOhipCardDetail(insuranceData[0], event, "fromDelete");
      }
    } else {
      addOhipCardDetail(null, event, "fromDirect");
    }
  };

  function onChangeCountry(value) {
    console.log(`selected ${value}`);
    console.log(CountrysJson);

    var obj = countryJson?.countries?.filter((obj) => obj.country == value);
    console.log(obj[0]);
    setSelectCountry(obj[0]);
  }

  function onSearchCountry(val) {
    console.log("search:", val);
  }

  function onChangeCity(value) {
    console.log(`selected ${value}`);
  }

  function onSearchCity(val) {
    console.log("search:", val);
  }

  function onDateChange(date, dateString) {
    // console.log(date, dateString);
    console.log(moment(date).format("YYYY-MM-DD"));
    console.log(moment(userData?.DOB).format("YYYY-MM-DD"));
    setStartDate(date);
    if (
      moment(date).format("YYYY-MM-DD") !==
      moment(userData?.DOB).format("YYYY-MM-DD")
    ) {
      console.log("in if");
      setDisableAddNew(true);
    } else {
      console.log("in else");
      setDisableAddNew(false);
    }
  }

  // const getUserImage = (imageUrl) => {
  //   if (
  //     imageUrl?.match(/^http[^\?]*.(jpg|jpeg|gif|png)(\?(.*))?$/gim) != null
  //   ) {
  //     return imageUrl;
  //   }
  //   return `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`;
  // };

  // const getUserImageDoc = (imageUrll) => {
  //   if (
  //     imageUrll?.match(
  //       /^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim
  //     ) != null
  //   ) {
  //     return imageUrll;
  //   }
  //   return `${process.env.REACT_APP_ASSET_URL}/${imageUrll}`;
  // };

  // const userProffile = async (event) => {
  //   const currentFile = event.target.files[0];
  //   const filename = currentFile?.name;
  //   const fileExtension = filename?.substring(
  //     filename.lastIndexOf("."),
  //     filename.length
  //   );
  //   const profilePicFileName = `${uuidv4()}${fileExtension}`;
  //   setImgLoader(true);
  //   if (currentFile.size >= 2097152) {
  //     notification.error({
  //       message: "Please upload 2MB size photo",
  //     });

  //     setImgLoader(false);
  //   } else {
  //     const uplodadedImageKey = await s3Upload(profilePicFileName, currentFile);
  //     setImgLoader(false);
  //     console.log(uplodadedImageKey, "uplodadedImageKey");
  //     setUserProfileKey(uplodadedImageKey);
  //     setprofilePath(URL.createObjectURL(event.target.files[0]));
  //   }
  // };

  const removeProfile = async (event) => {
    console.log(event, "eventetetetette form");
    // try {
    //   setLoader(true);
    //   PUT(`user/patient/profile/${userData.cognitoid}`,
    //     {
    //       profilepicture: null,
    //     }).then(() => {
    //       // notification.success({
    //       //     message: 'Your Data Successfully Added',
    //       // })
    //       setLoader(false);
    //       // history.push("/view-profile");
    //       // getUserDetails();

    //       dispatch(setProfileComplate("true"));
    //       // updateOrCreateFamilyMember();
    //       notification.success({
    //         message: "Your Profile picture removed Successfully",
    //       });
    //       setLoader(false);
    //       // history.push("/view-profile");
    //       getUserDetails();
    //       dispatch(setProfileComplate("true"));
    //     });
    // } catch (error) {
    //   setLoader(false);
    //   console.log(error);
    // }

    // console.log("remove image");
  };

  const getDateFormated = (date) => {
    if (date) {
      return date.replace("/", "-").replace("/", "-");
    } else {
      return date;
    }
  };

  const addressSelect = (datee) => {
    console.log(datee, "eeeeeeee");
    setAddress(datee.formatted_address);

    if (datee && datee.address_components) {
      const city = find(datee.address_components, {
        types: ["locality"],
      });

      console.log(city.long_name, "city");
      if (city) {
        form.setFieldsValue({
          City: city?.long_name,
        });
      }
      const state = find(datee.address_components, {
        types: ["administrative_area_level_1"],
      });

      console.log(state?.long_name, "state");
      if (state) {
        form.setFieldsValue({
          State: state.long_name,
        });
      }
      const postal_code = find(datee.address_components, {
        types: ["postal_code"],
      });
      console.log(postal_code?.long_name, "postal_code");

      if (postal_code) {
        form.setFieldsValue({
          PostalCode: postal_code.long_name,
        });
      }
    }
  };
  

  const openFile = async (path) => {
    console.log(path, "pathhhh");
    try {
      const url = await s3GetPrivate(path);
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      console.log("error: ", error);
      notification.warning({
        message: error.message,
      });
    }
  };

  const getPharmacyList = async () => {

    let logBody = {
      message: `User - ${userData.id} (${userData?.sub}) - Fetch Pharmacy List - Profile Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Profile Page`,
    }

    logEvents(logBody);

    try {
      console.log(
        "pharmacyListStaticArray static ",
        pharmacyListStaticArray.pharmacyListStaticArray
      );

      const pharmacyListReponse = await UPDATEDGET(`company`);
      console.log(pharmacyListReponse, "response response");
      let tempArray = [];
      if (pharmacyListReponse?.data.length > 0) {
        pharmacyListReponse?.data?.forEach((element) => {
          if (element.type == "PHARMACY") {
            tempArray.push(element);
          }
        });
        const arr3 = tempArray.concat(
          pharmacyListStaticArray.pharmacyListStaticArray
        );
        setPharmacyList(arr3);
      } else {
        setPharmacyList(pharmacyListStaticArray.pharmacyListStaticArray);
      }
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  const onChange = async (values) => {
    const indexNumber = values.split("-");
    let index;
    if (indexNumber.length) {
      index = indexNumber[indexNumber.length - 1];
    }
    const pharmacyData = pharmacyList[index];
    if (pharmacyData) {
      console.log("=======,pharmacy data", pharmacyData);
      setSelPharmacy(pharmacyData.name);
      setSelPharmacyDetail(pharmacyData);
    }
  };

  const onKeyChange = (key) => SetactiveTab(key);

  return (
    <div>
      <TopBar onShow={showDrawer} />
      <div className="content" style={{ backgroundColor: "#fff" }}>
        <div className={style.container}>
          <div className="row">
            <div
              className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0`}
            >
              <DashboardSidebar />
            </div>
            <Drawer
              closable={false}
              width={300}
              placement="left"
              onClose={onClose}
              visible={visible}
              style={{ paddingTop: 0 }}
            >
              <div className="widget-profile pro-widget-content pt-0 pb-4">
                <div className="profile-info-widget">
                  <img src="../Images/logo.png" height="50" alt="" />
                </div>
              </div>
              <DashboardSidebar />
            </Drawer>

            {/* {sidebar ?
                                <div className={`d-block d-xl-none ${style.toggle}`}>
                                    <DashboardSidebar />
                                </div> : null
                            } */}
            <div className="col-lg-12 col-xl-10 pb-5">
              <PageHeader
                items={[
                  { label: "Dashboard", link: "/dashboard" },
                  { label: "Profile", link: "/view-profile" },
                  { label: "Personal Information", link: "" },
                ]}
                pageTitle="Profile"
              />

              <Tabs
                onChange={onKeyChange}
                activeKey={activeTab}
                className={` ${style.tabsW} vb-tabs-bold`}
              >
                <Tabs.TabPane tab="Personal Information" key="1">
                  <Form
                    // autoComplete="off"
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={{
                      FirstName: `${userData?.FirstName == null
                        ? ""
                        : userData?.FirstName?.charAt(0).toUpperCase() +
                        userData?.FirstName?.slice(1)
                        }`,
                      MiddleName: `${userData?.MiddleName == null
                        ? ""
                        : userData?.MiddleName?.charAt(0).toUpperCase() +
                        userData?.MiddleName?.slice(1)
                        }`,
                      LastName: `${userData?.LastName == null
                        ? ""
                        : userData?.LastName?.charAt(0).toUpperCase() +
                        userData?.LastName?.slice(1)
                        }`,
                      Email: `${userData?.Email == null ? "" : userData?.Email
                        }`,
                      Email2: `${userData?.Email2 == null ? "" : userData?.Email2
                        }`,
                      phoneNumber: `${userData?.phoneNumber == null
                        // ? userData?.Phone == null ? "":userData?.Phone
                        ?""
                        : userData?.phoneNumber
                        }`,
                      Address1: `${userData?.Address1 == null || userData?.Address1 == "null" || userData?.Address1 == "" ? "" : userData?.Address1
                        }`,
                      Address2: `${(userData?.Address2 == null || userData?.Address1 == "null" || userData?.Address2 == "" ) ? "" : userData?.Address2
                        }`,
                      City: `${userData?.City == null || userData?.City == "null" ? "" : userData?.City}`,
                      State: `${userData?.State == null || userData?.State == "null" ? "" : userData?.State
                        }`,
                      PostalCode: `${userData?.PostalCode == null ? "" : userData?.PostalCode
                        }`,
                      medconcent: `${userData?.medconcent === 1 ? true : false
                        }`,

                      // Country: `${userData?.Country == null ? config?.country : userData?.Country}`,
                      Country: `${config?.country}`,
                      // languages: `${userData?.Languages == null ? "" : userData?.Languages?.split(',')
                      //   }`,
                      languages: userData?.Languages !== null && userData?.Languages !== "" ? userData?.Languages.split(",") : [],
                      gender: `${userData?.gender == null ? "" : userData?.gender
                        }`,
                      // DOB: `${userData?.DOB == moment() ? "" : moment(userData?.DOB)}`,
                      DOB:
                        userData?.DOB &&
                          userData?.DOB != "" &&
                          userData?.DOB != null &&
                          userData?.DOB != "null" &&
                          userData?.DOB != "0000-00-00 00:00:00" &&
                          userData?.DOB != undefined
                          ? moment(userData?.DOB)
                          : null,
                      familyDoctor: `${familyD == null ? "" : familyD}`,
                      // familyDoctor: familyDoctorDetails == "" ? "" : familyDoctorDetails,
                    }}
                    validateMessages={validateMessages}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12 ">
                        <div className={style.personHeading}>
                          <h4>Personal Info</h4>
                        </div>
                        <div className="row" style={{ marginTop: 15 }}>
                          <div className="col-4 ">
                            <div className={`form-group `}>
                              <Form.Item
                                label="First Name"
                                name="FirstName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter First Name!",
                                  },
                                ]}
                              >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  className={`${style.inputbox}`}
                                  name="FirstName"
                                  onChange={handleChange}
                                  placeholder="First Name *"
                                />
                              </Form.Item>
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="form-group">
                              <Form.Item label="Middle Name" name="MiddleName">
                                <input
                                  type="text"
                                  className={`${style.inputbox}`}
                                  name="MiddleName"
                                  onChange={handleChange}
                                  placeholder="Middle Name"
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                              <Form.Item
                                label="Last Name"
                                name="LastName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Last Name!",
                                  },
                                ]}
                              >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  className={`${style.inputbox}`}
                                  name="LastName"
                                  onChange={handleChange}
                                  placeholder="Last Name *"
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <div className={`form-group `}>
                              <Form.Item
                                name="Email"
                                label="Primary Email"
                                rules={[
                                  {
                                    required: true,
                                    type: "email",
                                    message: "Please Enter Primary Email!",
                                  },
                                ]}
                              >
                                <input
                                  disabled={true}
                                  autoComplete="off"
                                  type="text"
                                  className={`${style.inputbox}`}
                                  name="Email"
                                  onChange={handleChange}
                                  style={{
                                    cursor: "not-allowed",
                                    backgroundColor: "#f5f5f5",
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className={`form-group `}>
                              <Form.Item
                                name="Email2"
                                label="Secondary Email"
                                rules={[{ required: false, type: "email" }]}
                              >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="Email2"
                                  className={`${style.inputbox}`}
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <Form.Item
                              name="phoneNumber"
                              label="Phone Number"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter phone number!",
                                },
                                {
                                  max: 12,
                                  message: "must be maximum 12 characters.",
                                },
                              ]}
                            >
                              <MaskInput
                                alwaysShowMask
                                maskChar="_"
                                onChange={handleChangePhonenumber}
                                mask="000-000-0000"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-6">
                            <Form.Item
                              name="DOB"
                              label="Date of Birth"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Date Of Birth",
                                },
                              ]}
                            >
                              <DatePicker
                                className={`${style.inputbox}`}
                                size="large"
                                style={{ width: "100%" }}
                                format="YYYY/MM/DD"
                                onChange={onDateChange}
                                disabledDate={(current) => {
                                  return (
                                    current && current > moment().endOf("day")
                                  );
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-6">
                              <Form.Item
                                name="languages"
                                label="Language"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please input languages",
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  placeholder="Choose Language"
                                  onChange={onChnageLanguage}
                                  optionLabelProp="label"
                                >
                                  {languages.map((lang) => {
                                    return (
                                      <Option
                                        value={lang.value}
                                        label={lang.label}
                                      >
                                        <div className="demo-option-label-item">
                                          {lang.label}
                                        </div>
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="col-sm-6">
                              <Form.Item
                                name="gender"
                                label="Gender"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Gender! !",
                                  },
                                ]}
                              >
                                <select
                                  placeholder="Select gender"
                                  className={`select ${style.select}`}
                                  name="gender"
                                  value={formValues.gender || ""}
                                  onChange={handleChange}
                                >
                                  <option value="" disabled selected>
                                    Select Gender
                                  </option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-xl-12">
                        <div className={style.addressHeading}>
                          <h4>Address Info</h4>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label>
                              {" "}
                              <span className="text-danger">*</span> Address
                              Line 1
                            </label>
                            <Form.Item>
                              <SearchLocationInput
                                styleProps={{ minHeight: 30 }}
                                name="address"
                                address={address}
                                setlat={(e) => setLat(e)}
                                setlng={(e) => setLng(e)}
                                // setAddress={(e) => setAddress(e)}
                                setAddress={(e) => addressSelect(e)}
                                onBlur={(e) => setAddress(e)}
                              />
                              {address == null ? (
                                <span style={{ color: "#ff0000" }}>
                                  Please Select your Address!
                                </span>
                              ) : null}
                            </Form.Item>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-12">

                            <Form.Item
                              name="Address2"
                              label="Address Line 2">
                               {/* <div className="input-group" style={{ flexWrap: "unset", borderRadius: '5px', border: '1px solid var(--primary-color)' }}>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <FontAwesomeIcon className="mr-1" icon={faMapMarker} />
                                  </span>
                                </div>  */}
                                <input
                                  autoComplete="off"
                                  type="text"
                                  name="Address2"
                                  style={{
                                    height: "43px",
                                    margin: '1px'
                                  }}
                                  placeholder="Enter Address"
                                  className={`${style.inputbox}`}
                                  // className="form-control1"
                                  onChange={handleChange}
                                />
                              {/* </div>  */}
                            </Form.Item>


                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className={`form-group `}>
                              <Form.Item
                                name="Country"
                                label="Country"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select your Country!",
                                  },
                                ]}
                              >
                                {/* <input type="text" className={`${style.inputbox}`} name="Country" onChange={handleChange} /> */}
                                <Select
                                  disabled
                                  size="large"
                                  showSearch
                                  placeholder="Select a Country"
                                  optionFilterProp="children"
                                  onChange={onChangeCountry}
                                  onSearch={onSearchCountry}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {CountrysJson?.countries.map((element) => {
                                    return (
                                      <Option value={element?.country}>
                                        {element?.country}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className={`form-group `}>
                              <Form.Item
                                name="State"
                                label="State/Province"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please Select your State/Province!",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  size="large"
                                  placeholder="Select a Province"
                                  optionFilterProp="children"
                                  onChange={onChangeCity}
                                  onSearch={onSearchCity}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {selectCountry?.states?.map((element) => {
                                    return (
                                      <Option value={element}>{element}</Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className={`form-group `}>
                              <Form.Item
                                name="City"
                                label="City"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter your City!",
                                  },
                                ]}
                              >
                                <input
                                  type="text"
                                  placeholder="Enter City"
                                  className={`${style.inputbox}`}
                                  onChange={handleChange}
                                />
                                {/* <input type="text" className={`${style.inputbox}`} name="City" onChange={handleChange} /> */}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <Form.Item
                                name="PostalCode"
                                label="Zip Code"
                                rules={[
                                  {
                                    required: true,
                                    message: "PostalCode is not valid!",
                                    // pattern: new RegExp(validPostCode),
                                  },
                                ]}
                              >
                                <input
                                  type="text"
                                  placeholder="Enter Postal Code"
                                  className={`${style.inputbox}`}
                                  name="PostalCode"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                            <div>
                              <h4>Pharmacy</h4>
                            </div>
                            <Select
                              showSearch
                              placeholder="Select a pharmacy"
                              optionFilterProp="children"
                              size="large"
                              style={{ width: "100%" }}
                              onChange={(value) => onChange(value)}
                              value={selPharmacy}
                              notFoundContent={<Spin size="small" />}
                            >
                              {pharmacyList.length
                                ? pharmacyList.map((pharamcy, index) => (
                                  <Option
                                    key={index}
                                    value={`${pharamcy?.accreditationNumber}-${index}`}
                                  >
                                    {pharamcy?.name}
                                    <p>{pharamcy?.address}</p>
                                  </Option>
                                ))
                                : ""}
                            </Select>
                          </div>
                        </div>

                        <div
                          className={style.div_btn}
                          style={{ paddingBottom: "20px" }}
                        >
                          {loader ? (
                            <div className="spinner">
                              {/* <Spin tip="Loading..."></Spin> */}
                              <Spin tip="Loading ..."></Spin>
                            </div>
                          ) : (
                            <>
                              <Button type="primary" className="btnBgred">
                                Cancel
                              </Button>
                              <button type="submit" className="btnBgGreen ml-4">
                                Update Info
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-sm-12 col-md-6 col-xl-6 "> */}
                      {/* <div className="change-avatar pb-2"> */}
                      {/* 03A */}
                      {/* <div className="profile-img">
                                {imgLoader ? (
                                  <div className={style.spinDiv}>
                                    <Spin />
                                  </div>
                                ) : (
                                  <>
                                    {ProfilePath ? (
                                      <img src={ProfilePath} alt="" />
                                    ) : (
                                      <div
                                        style={{
                                          width: 100,
                                          height: 100,
                                          position: "relative",
                                        }}
                                      >
                                        <img
                                          src={
                                            userData?.profilepicture == null
                                              ? "https://www.pngarts.com/files/6/User-Avatar-in-Suit-PNG.png"
                                              : getUserImage(
                                                  userData?.profilepicture
                                                )
                                          }
                                          alt="User"
                                          style={{ borderRadius: "100%" }}
                                        />
                                        {userData?.profilepicture != null ? (
                                          <img
                                            src="../Images/close.png"
                                            onClick={removeProfile}
                                            style={{
                                              height: 20,
                                              width: 20,
                                              position: "absolute",
                                              top: 0,
                                              right: 10,
                                              cursor: "pointer",
                                            }}
                                            alt=""
                                          />
                                        ) : null} */}
                      {/* <img src="../Images/close.png" onClick={removeProfile} style={{
                                            height: 20,
                                            width: 20,
                                            position: 'absolute',
                                            top: 0,
                                            right: 10,
                                            cursor: 'pointer'
                                          }} alt="" /> */}
                      {/* </div>
                                    )}
                                  </>
                                )}
                              </div> */}
                      {/* <div className="upload-img">
                                <div className="change-photo-btn">
                                  <span>
                                    <FontAwesomeIcon
                                      className="mr-1"
                                      icon={faUpload}
                                    />{" "}
                                    Upload Photo
                                  </span>
                                  <input
                                    type="file"
                                    className="upload"
                                    onChange={userProffile}
                                    accept="image/png, image/gif, image/jpeg"
                                  />
                                </div>
                                <small className="form-text text-muted">
                                  Allowed JPG, GIF or PNG. Max size of 2MB
                                </small>
                              </div> */}
                      {/* </div> */}

                      {/* <div className={style.personHeading}>
                            <h4>Personal Info</h4>
                          </div>
                          <hr></hr> */}
                      {/* <div className="row" style={{ marginTop: 15 }}> */}
                      {/* <div className="col-6 "> */}
                      {/* <div className={`form-group `}> */}
                      {/* {isInsurance} */}
                      {/* <Form.Item
                                  label="First Name"
                                  name="FirstName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter First Name!",
                                    },
                                  ]}
                                > */}
                      {/* <input
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="FirstName"
                                    onChange={handleChange} 
                                    // disabled={!isInsurance}
                                  />*/}
                      {/* </Form.Item> */}
                      {/* </div> */}
                      {/* </div> */}

                      {/* <div className="col-6">
                              <div className="form-group"> */}
                      {/* <Form.Item
                                  label="Middle Name"
                                  name="MiddleName" 
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Please Enter Last Name!",
                                  //   },
                                  // ]}
                                // >*/}
                      {/* <input
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="MiddleName"
                                    onChange={handleChange}
                                  />
                                </Form.Item> */}
                      {/* </div>
                            </div> */}
                      {/* </div> */}

                      {/* <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  label="Last Name"
                                  name="LastName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Last Name!",
                                    },
                                  ]}
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="LastName"
                                    onChange={handleChange}
                                    // disabled={!isInsurance}
                                  />
                                </Form.Item>
                              </div>
                            </div> */}

                      {/* <div className="col-6">
                              <div className={`form-group `}>
                                <Form.Item
                                  name="Email"
                                  label="Email"
                                  rules={[{ required: false, type: "email" }]}
                                >
                                  <input
                                    disabled={true}
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="Email"
                                    onChange={handleChange}
                                    style={{
                                      cursor: "not-allowed",
                                      backgroundColor: "#f5f5f5",
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div> */}
                      {/* </div> */}

                      {/* <div
                            className={`form-group `}
                            style={{ width: "100%" }}
                          >
                            <Form.Item
                              name="Email2"
                              label="Secondary Email"
                              rules={[{ required: false, type: "email" }]}
                            >
                              <input
                                autoComplete="off"
                                type="text"
                                name="Email2"
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </div> */}

                      {/* <div
                            className={`form-group `}
                            style={{ width: "100%" }}
                          >
                            <Form.Item
                              name="phoneNumber"
                              label="Phone Number"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter phone number!",
                                },
                                {
                                  max: 12,
                                  message: "must be maximum 12 characters.",
                                },
                              ]}
                            > */}
                      {/* <PhoneInput
                                  country={config?.code}
                                  value={formValues.phoneNumber || ""}
                                  onChange={(phone) => setPhoneNumber(phone)}
                                /> */}

                      {/* {config?.code == 'ca' ?

                                  <label style={{ position: "absolute", top: 11, left: 15 }}>+1</label>
                                  :
                                  <label style={{ position: "absolute", top: 11, left: 15 }}>+91</label>
                                } */}

                      {/* <input type="text" className={`${style.inputbox}`} name="phoneNumber"
                                  value={phoneNumber || ""} maxLength={12}
                                  onChange={handleChangePhonenumber}  /> */}

                      {/* <MaskInput
                                alwaysShowMask
                                maskChar="_"
                                onChange={handleChangePhonenumber}
                                mask="000-000-0000"
                              />
                            </Form.Item>
                          </div> */}

                      {/* <div className="form-group">
                            <Form.Item
                              name="DOB"
                              label="Date of Birth"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Date Of Birth",
                                },
                              ]}
                            >
                              <DatePicker
                                size="large"
                                style={{ width: "100%" }}
                                format="YYYY/MM/DD"
                                onChange={onDateChange}
                                disabledDate={(current) => {
                                  return (
                                    current && current > moment().endOf("day")
                                  );
                                  // return moment().add(-1, "days") <= current;
                                }}
                                // disabled={!isInsurance}
                              />
                              {/* <DatePicker autocomplete="off" dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                      {/* </Form.Item>
                          </div>  */}

                      {/* <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                <Form.Item
                                  name="languages"
                                  label="Language"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please input languages",
                                    },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder="Choose Language"
                                    onChange={onChnageLanguage}
                                    optionLabelProp="label"
                                  >
                                    {languages.map((lang) => {
                                      return (
                                        <Option
                                          value={lang.value}
                                          label={lang.label}
                                        >
                                          <div className="demo-option-label-item">
                                            {lang.label}
                                          </div>
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                              <div className="col-sm-6">
                                <Form.Item
                                  name="gender"
                                  label="Gender"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Gender! !",
                                    },
                                  ]}
                                >
                                  <select
                                    placeholder="Select gender"
                                    className={`select ${style.select}`}
                                    name="gender"
                                    value={formValues.gender || ""}
                                    onChange={handleChange}
                                  >
                                    <option value="" disabled selected>
                                      Select Gender
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </Form.Item>
                              </div>
                            </div>
                          </div> */}
                      {/* <div className="form-group">
                              <Form.Item
                                name="familyDoctor"
                                label="Family Doctor"
                              >
                                <Select
                                  showSearch
                                  placeholder="Select Service"
                                  className={style.selectMember}
                                  optionFilterProp="children"
                                  size="large"
                                  style={{ width: "100%" }}
                                  onChange={handleChangeMemeber}
                                >
                                  {allDoctor?.map((itemm, index) => (
                                    <>
                                      {itemm.FirstName != null ? (
                                        <Option
                                          key={index}
                                          value={itemm?.companyemployeeid}
                                        >
                                          <img
                                            className={style.bgImg}
                                            src={getUserImageDoc(
                                              itemm?.profilepicture
                                            )}
                                            onError={(e: any) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                {IMG01};
                                            }}
                                            alt=""
                                            height="20"
                                            width="20"
                                          />
                                          Dr. {itemm?.FirstName} {itemm?.LastName}
                                          <p className="p-0 m-0 pl-5">
                                            {itemm?.ClinicAddress}
                                          </p>
                                        </Option>
                                      ) : null}
                                    </>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div> */}
                      {/* <div className="form-group"> */}
                      {/* 03A */}
                      {/* <Form.Item
                                name="medconcent"
                                label="Allow medical history for doctors"
                                >
                              <Switch
                                defaultChecked={userData.medconcent}
                                style={{ textAlign: "right" }}
                              />
                              </Form.Item> */}
                      {/* </div> */}
                      {/* </div> */}
                      {/* <div className="col-sm-12 col-md-6 col-xl-6"> */}
                      {/* <div className={style.addressHeading}>
                            <h4>Address Info</h4>
                          </div>
                          <hr></hr> */}
                      {/* <div className="col-sm-12">
                            <label>
                              {" "}
                              <span className="text-danger">*</span> Address
                            </label>
                            <Form.Item>
                              <SearchLocationInput
                                styleProps={{ minHeight: 30 }}
                                name="address"
                                address={address}
                                setlat={(e) => setLat(e)}
                                setlng={(e) => setLng(e)}
                                // setAddress={(e) => setAddress(e)}
                                setAddress={(e) => addressSelect(e)}
                                onBlur={(e) => setAddress(e)}
                              />
                              {address == null ? (
                                <span style={{ color: "#ff0000" }}>
                                  Please Select your Address!
                                </span>
                              ) : null}
                            </Form.Item>
                          </div> */}
                      {/* <div className="row">
                            <div className="col-6">
                              <div className={`form-group `}>
                                <Form.Item
                                  name="Country"
                                  label="Country"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select your Country!",
                                    },
                                  ]}
                                >
                                  {/* <input type="text" className={`${style.inputbox}`} name="Country" onChange={handleChange} /> */}
                      {/* <Select
                                    disabled
                                    size="large"
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    onChange={onChangeCountry}
                                    onSearch={onSearchCountry}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {CountrysJson?.countries.map((element) => {
                                      return (
                                        <Option value={element?.country}>
                                          {element?.country}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className={`form-group `}>
                                <Form.Item
                                  name="State"
                                  label="State/Province"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please Select your State/Province!",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    size="large"
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    onChange={onChangeCity}
                                    onSearch={onSearchCity}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {selectCountry?.states?.map((element) => {
                                      return (
                                        <Option value={element}>
                                          {element}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>  */}
                      {/* <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  name="PostalCode"
                                  label="Zip Code"
                                  rules={[
                                    {
                                      required: true,
                                      message: "PostalCode is not valid!",
                                      // pattern: new RegExp(validPostCode),
                                    },
                                  ]}
                                >
                                  <input
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="PostalCode"
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className={`form-group `}>
                                <Form.Item
                                  name="City"
                                  label="City"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter your City!",
                                    },
                                  ]}
                                >
                                  <input
                                    type="text"
                                    className={`${style.inputbox}`}
                                    onChange={handleChange}
                                  />
                                  {/* <input type="text" className={`${style.inputbox}`} name="City" onChange={handleChange} /> */}
                      {/* </Form.Item>
                              </div>
                            </div>
                          </div>  */}

                      {/* <hr></hr> */}

                      {/* <div className="row">
                            <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12 mt-2">
                              <label>Select pharmacy</label>
                              <Select
                                showSearch
                                placeholder="Select a pharmacy"
                                optionFilterProp="children"
                                size="large"
                                style={{ width: "100%" }}
                                onChange={(value) => onChange(value)}
                                value={selPharmacy}
                                notFoundContent={<Spin size="small" />}
                              >
                                {pharmacyList.length
                                  ? pharmacyList.map((pharamcy, index) => (
                                      <Option
                                        key={index}
                                        value={`${pharamcy?.accreditationNumber}-${index}`}
                                      >
                                        {pharamcy?.name}
                                        <p>{pharamcy?.address}</p>
                                      </Option>
                                    ))
                                  : ""}
                              </Select>
                            </div>
                          </div> */}
                      {/* <hr></hr>
                          <div className={style.div_btn}>
                            {loader ? (
                              <div className="spinner">
                                {/* <Spin tip="Loading..."></Spin> */}
                      {/* <Spin tip="Loading ..."></Spin>
                              </div>
                            ) : (
                              <button type="submit" className="btn">
                                Update Info
                              </button>
                            )}
                          </div>  */}
                      {/* </div> */}
                    </div>
                  </Form>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Insurance Information" key="2">
                  {showError ? (
                    <Alert
                      style={{
                        marginBottom: "15px",
                        width: "70%",
                      }}
                      message={errorMsg}
                      type="error"
                    />
                  ) : null}

                  <Form
                    form={form1}
                    onFinish={handleSubmitOhip}
                    layout="vertical"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                        <div className={style.addressHeading}>
                          <h4>
                            {" "}
                            <strong>Province insurance</strong>
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label className={style.inputLable}>
                              Province *
                            </label>
                            <Select
                              defaultValue="OHIP"
                              size="large"
                              style={{ width: "100%" }}
                              onChange={handleChangeSelect}
                            >
                              {/* <Option value="PRIVATE_INSURANCE">Private insurance</Option> */}
                              <Option value="OHIP">Ontario - (OHIP)</Option>
                            </Select>
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: 15 }}>
                          <>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <Form.Item
                                  label="Ohip Number *"
                                  name="Insurancesvcnum"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please Enter Insurance Number!",
                                    },
                                    {
                                      max: 12,
                                      message: "must be maximum 12 characters.",
                                    },
                                  ]}
                                >
                                  <MaskInput
                                    alwaysShowMask
                                    maskChar="_"
                                    onChange={handleChange}
                                    mask="0000-000-000"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2">
                              <div className="d-flex align-items-center">
                                <Form.Item
                                  label="Version Code"
                                  name="insuranceVersionCode"
                                  rules={[
                                    {
                                      required: false,
                                      message: "Please Enter Version code!",
                                    },
                                  ]}
                                >
                                  <Input
                                    style={{
                                      textTransform: "uppercase",
                                    }}
                                    size="large"
                                    onChange={handleChange}
                                    name="insuranceVersionCode"
                                    maxLength={2}
                                    className={`${style.inputbox}`}
                                  />
                                </Form.Item>
                                <img
                                  src="Images/i_circle_icon.png"
                                  className="ml-3"
                                  alt="icon"
                                  width={30}
                                  height={30}
                                />
                              </div>
                            </div>
                          </>
                        </div>

                        {/* <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                        
                            <div className={style.div_row_flex}>
                              <div
                                className={style.div_card_main}
                                style={{
                                  backgroundImage: `url("Images/problems/card.png")`,
                                }}
                              >
                                <div>
                                  <div
                                    className={style.div_card_below}
                                    style={{ paddingTop: 0 }}
                                  >
                                    <div style={{ width: "100%" }}>
                                      <label className={style.lbl_card}></label>
                                      <div className={style.div_row_name}>
                                        <label className={style.lbl_card_name}>
                                          {formValues?.FirstName?.charAt(
                                            0
                                          ).toUpperCase() +
                                            formValues?.FirstName?.slice(1) ||
                                            userData?.FirstName?.charAt(
                                              0
                                            ).toUpperCase() +
                                              userData?.FirstName?.slice(
                                                1
                                              )}{" "}
                                          &nbsp;
                                        </label>
                                        <label className={style.lbl_card_name}>
                                          {formValues?.MiddleName != null &&
                                          formValues?.MiddleName
                                            ? formValues?.MiddleName.charAt(
                                                0
                                              ).toUpperCase() +
                                              formValues?.MiddleName?.slice(1)
                                            : "" ||
                                              (userData?.MiddleName != null &&
                                                userData?.MiddleName)
                                            ? userData?.MiddleName?.charAt(
                                                0
                                              ).toUpperCase() +
                                              userData?.MiddleName?.slice(1)
                                            : ""}{" "}
                                          &nbsp;
                                        </label>
                                        <label className={style.lbl_card_name}>
                                          {formValues?.LastName?.charAt(
                                            0
                                          ).toUpperCase() +
                                            formValues?.LastName?.slice(1) ||
                                            userData?.LastName?.charAt(
                                              0
                                            ).toUpperCase() +
                                              userData?.LastName?.slice(1)}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className={style.div_card_below}>
                                    <div style={{ width: "67%" }}>
                                      <label className={style.lbl_card}>
                                        Card Number
                                      </label>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="Insurancesvcnum"
                                      >
                                        <Input
                                          size="large"
                                          onChange={handleChange}
                                          className={style.input_card}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div style={{ width: "25%" }}>
                                      <label className={style.lbl_card}>
                                        Version Code
                                      </label>
                                      <Form.Item
                                        style={{ marginBottom: "5px" }}
                                        name="insuranceVersionCode"
                                      >
                                        <Input
                                          style={{
                                            textTransform: "uppercase",
                                          }}
                                          size="large"
                                          onChange={handleChange}
                                          className={style.input_card_vcode}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>

                                  <div
                                    className={style.div_card_below}
                                    style={{ paddingTop: 0 }}
                                  >
                                    <div style={{ width: "100%" }}>
                                      <label className={style.lbl_card}>
                                        DOB
                                      </label>
                                      <div className={style.div_row_name}>
                                        <label className={style.lbl_card_name}>
                                          {/* {getDateFormated(dateOfBirth)} */}
                        {/* {moment(startDate).format(
                                            "YYYY-MM-DD"
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="row">
                                  <div
                                    className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{
                                      marginTop: 5,
                                      marginBottom: 10,
                                    }}
                                  >
                                    <span className={style.h5Text}>
                                      Front side OHIP card image
                                    </span>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <Dragger
                                      {...props1}
                                      style={{ width: "100%" }}
                                      showUploadList={false}
                                    >
                                      {" "}
                                      <p className="mt-1">
                                        {" "}
                                        <img
                                          src="Images/cloud-upload.png"
                                          alt="Upload icon"
                                        />
                                      </p>{" "}
                                      <p
                                        className="mt-2 "
                                        style={{ fontSize: "16px" }}
                                      >
                                        Drag and Drop file Here
                                      </p>{" "}
                                      <Button
                                        type="primary"
                                        className={`${style.btnBrowse} mt-2`}
                                      >
                                        Browse Files
                                      </Button>
                                    </Dragger>
                                  </div>
                                </div>
                              </div>

                              {/* {privateInsuranceData?.frontcard_path ? (
                                  <>
                                    <div
                                      className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    >
                                      <a
                                        onClick={(e) =>
                                          openFile(
                                            privateInsuranceData?.frontcard_path
                                          )
                                        }
                                      >
                                        See uploaded front card image{" "}
                                        <img
                                          style={{ width: 30, marginLeft: 8 }}
                                          src={IMG02}
                                        />
                                      </a>
                                    </div>
                                  </>
                                ) : null} */}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="row">
                                  <div
                                    className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    style={{
                                      marginTop: 5,
                                      marginBottom: 10,
                                    }}
                                  >
                                    <span className={style.h5Text}>
                                      Back side OHIP card image
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <Dragger
                                      {...props2}
                                      style={{ width: "100%" }}
                                      showUploadList={false}
                                    >
                                      {" "}
                                      <p className="mt-1">
                                        {" "}
                                        <img
                                          src="Images/cloud-upload.png"
                                          alt="Upload icon"
                                        />
                                      </p>{" "}
                                      <p
                                        className="mt-2 "
                                        style={{ fontSize: "16px" }}
                                      >
                                        Drag and Drop file Here
                                      </p>{" "}
                                      <Button
                                        type="primary"
                                        className={`${style.btnBrowse} mt-2`}
                                      >
                                        Browse Files
                                      </Button>
                                    </Dragger>
                                  </div>
                                </div>
                              </div>

                              {/* {privateInsuranceData?.backcard_path ? (
                                  <>
                                    <div
                                      className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    >
                                      <a
                                        onClick={(e) =>
                                          openFile(
                                            privateInsuranceData?.backcard_path
                                          )
                                        }
                                      >
                                        See uploaded back card image
                                        <img
                                          style={{ width: 30, marginLeft: 8 }}
                                          src={IMG02}
                                        />
                                      </a>
                                    </div>
                                  </>
                                ) : null} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  ">
                        <div className={style.addressHeading}>
                          <h4>
                            <strong> Health insurance and benefits</strong>
                          </h4>
                        </div>

                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <Form.Item
                                label="Name of insurer"
                                name="insurer"
                              // rules={[
                              //   {
                              //     required:
                              //       (form1.getFieldValue('insuranceNumber') != undefined &&
                              //         form1.getFieldValue('insuranceNumber') != '') ||
                              //         (form1.getFieldValue('lastname_cardholder') != undefined &&
                              //           form1.getFieldValue('lastname_cardholder') != '') ||
                              //         (form1.getFieldValue('relationship') != undefined &&
                              //           form1.getFieldValue('relationship') != ''
                              //         )
                              //         ? true : false,
                              //     message: "Please Enter Insurer Name!",
                              //   },
                              // ]}
                              >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  className={`${style.inputbox}`}
                                  name="insurer"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <Form.Item
                                label="Numbers on card"
                                name="insuranceNumber"

                              // rules={[
                              //   {
                              //     required:
                              //       (form1.getFieldValue('insurer') != undefined &&
                              //         form1.getFieldValue('insurer') != '') ||
                              //         (form1.getFieldValue('lastname_cardholder') != undefined &&
                              //           form1.getFieldValue('lastname_cardholder') != '') ||
                              //         (form1.getFieldValue('relationship') != undefined &&
                              //           form1.getFieldValue('relationship') != ''
                              //         ) ? true : false,
                              //     message: "Please Enter Insurance Number!",
                              //   },
                              // ]}
                              >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  className={`${style.inputbox}`}
                                  name="insuranceNumber"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <Form.Item
                                label="Cardholder Last Name"
                                name="lastname_cardholder"

                              // rules={[
                              //   {
                              //     required:
                              //       (form1.getFieldValue('insurer') != undefined &&
                              //         form1.getFieldValue('insurer') != '') ||
                              //         (form1.getFieldValue('insuranceNumber') != undefined &&
                              //           form1.getFieldValue('insuranceNumber') != '') ||
                              //         (form1.getFieldValue('relationship') != undefined &&
                              //           form1.getFieldValue('relationship') != ''
                              //         ) ? true : false,

                              //     message: "Please Enter Cardholder last name!",
                              //   },
                              // ]}
                              >
                                <input
                                  autoComplete="off"
                                  type="text"
                                  className={`${style.inputbox}`}
                                  name="lastname_cardholder"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <Form.Item
                                label="Relationship"
                                name="relationship"

                              // rules={[
                              //   {
                              //     required:
                              //     (form1.getFieldValue('insurer') != undefined &&
                              //     form1.getFieldValue('insurer') != '')||
                              //     (form1.getFieldValue('insuranceNumber') != undefined &&
                              //     form1.getFieldValue('insuranceNumber') != '') ||
                              //     (form1.getFieldValue('lastname_cardholder') != undefined &&
                              //     form1.getFieldValue('lastname_cardholder') != ''
                              //     ) ? true : false,

                              //     message: "Please Enter First Name!",
                              //   },
                              // ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Select Relatonship"
                                  optionFilterProp="children"
                                  size="large"
                                  style={{ marginRight: 10 }}
                                  defaultActiveFirstOption
                                  onChange={(e) => {
                                    setSelFamilyMember(e);
                                  }}
                                  value={selFamilyMember}
                                >
                                  {relationShip.map((relation) => (
                                    <Option
                                      value={relation.id}
                                      key={relation.id}
                                    >
                                      {relation.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                  <div className="row">
                                    <div
                                      className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    >
                                      <span className={style.h5Text}>
                                        Frontt side Insurance card image
                                      </span>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                      <Dragger
                                        {...props1}
                                        style={{ width: "100%" }}
                                        showUploadList={false}
                                      >
                                        {" "}
                                        <p className="mt-1">
                                          {" "}
                                          <img
                                            src="Images/cloud-upload.png"
                                            alt="Upload icon"
                                          />
                                        </p>{" "}
                                        <p
                                          className="mt-2 "
                                          style={{ fontSize: "16px" }}
                                        >
                                          Drag and Drop file Here
                                        </p>{" "}
                                        <Button
                                          type="primary"
                                          className={`${style.btnBrowse} mt-2`}
                                        >
                                          Browse Files
                                        </Button>
                                      </Dragger>
                                    </div>
                                  </div>
                                </div>

                                {privateInsuranceData?.frontcard_path ? (
                                  <>
                                    <div
                                      className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        fontSize: 16
                                      }}
                                    >
                                      <a
                                        onClick={(e) =>
                                          openFile(
                                            privateInsuranceData?.frontcard_path
                                          )
                                        }
                                      >
                                        See uploaded front card image{" "}
                                        <img
                                          style={{ width: 30, marginLeft: 8 }}
                                          src={IMG02}
                                        />
                                      </a>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                  <div className="row">
                                    <div
                                      className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 10,

                                      }}
                                    >
                                      <span className={style.h5Text}>
                                        Back side Insurance card image
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                      <Dragger
                                        {...props2}
                                        style={{ width: "100%" }}
                                        showUploadList={false}
                                      >
                                        {" "}
                                        <p className="mt-1">
                                          {" "}
                                          <img
                                            src="Images/cloud-upload.png"
                                            alt="Upload icon"
                                          />
                                        </p>{" "}
                                        <p
                                          className="mt-2 "
                                          style={{ fontSize: "16px" }}
                                        >
                                          Drag and Drop file Here
                                        </p>{" "}
                                        <Button
                                          type="primary"
                                          className={`${style.btnBrowse} mt-2`}
                                        >
                                          Browse Files
                                        </Button>
                                      </Dragger>
                                    </div>
                                  </div>
                                </div>

                                {privateInsuranceData?.backcard_path ? (
                                  <>
                                    <div
                                      className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        fontSize: 16
                                      }}
                                    >
                                      <a
                                        onClick={(e) =>
                                          // openFile(
                                          //   privateInsuranceData?.backcard_path
                                          // )
                                          setShowModal(true)
                                        }
                                      >
                                        See uploaded back card image
                                        <img
                                          style={{ width: 30, marginLeft: 8 }}
                                          src={IMG02}
                                        />
                                      </a>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className={style.div_btn}
                          style={{ paddingBottom: "20px" }}
                        >
                          {loader ? (
                            <div className="spinner">
                              {/* <Spin tip="Loading..."></Spin> */}
                              <Spin tip="Validating Health Card (OHIP Validation in progress) ..."></Spin>
                            </div>
                          ) : (
                            <>
                              <Button type="primary" className="btnBgred">
                                Cancel
                              </Button>
                              <button type="submit" className="btnBgGreen ml-4">
                                Update Info
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <Modal
                      width={900}
                      title="Province Insurance -  Ontario health card"
                      visible={showModal}
                      onOk={() => {
                        setShowModal(false);
                      }}
                      onCancel={() => {
                        setShowModal(false);
                      }}
                      footer={null}
                    >
                      <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                          <div className={style.div_row_flex}>
                            <div
                              className={style.div_card_main}
                              style={{
                                backgroundImage: `url("Images/problems/card.png")`,
                              }}
                            >
                              <div>
                                <div
                                  className={style.div_card_below}
                                  style={{ paddingTop: 0 }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <label className={style.lbl_card}></label>
                                    <div className={style.div_row_name}>
                                      <label className={style.lbl_card_name}>
                                        {formValues?.FirstName?.charAt(
                                          0
                                        ).toUpperCase() +
                                          formValues?.FirstName?.slice(1) ||
                                          userData?.FirstName?.charAt(
                                            0
                                          ).toUpperCase() +
                                          userData?.FirstName?.slice(1)}{" "}
                                        &nbsp;
                                      </label>
                                      <label className={style.lbl_card_name}>
                                        {formValues?.MiddleName != null &&
                                          formValues?.MiddleName
                                          ? formValues?.MiddleName.charAt(
                                            0
                                          ).toUpperCase() +
                                          formValues?.MiddleName?.slice(1)
                                          : "" ||
                                            (userData?.MiddleName != null &&
                                              userData?.MiddleName)
                                            ? userData?.MiddleName?.charAt(
                                              0
                                            ).toUpperCase() +
                                            userData?.MiddleName?.slice(1)
                                            : ""}{" "}
                                        &nbsp;
                                      </label>
                                      <label className={style.lbl_card_name}>
                                        {formValues?.LastName?.charAt(
                                          0
                                        ).toUpperCase() +
                                          formValues?.LastName?.slice(1) ||
                                          userData?.LastName?.charAt(
                                            0
                                          ).toUpperCase() +
                                          userData?.LastName?.slice(1)}
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className={style.div_card_below}>
                                  <div style={{ width: "67%" }}>
                                    <label className={style.lbl_card}>
                                      Card Number
                                    </label>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="Insurancesvcnum"
                                    >
                                      <Input
                                        size="large"
                                        onChange={handleChange}
                                        className={style.input_card}
                                        disabled={true}
                                      />
                                    </Form.Item>
                                  </div>
                                  <div style={{ width: "25%" }}>
                                    <label className={style.lbl_card}>
                                      Version Code
                                    </label>
                                    <Form.Item
                                      style={{ marginBottom: "5px" }}
                                      name="insuranceVersionCode"
                                    >
                                      <Input
                                        style={{
                                          textTransform: "uppercase",
                                        }}
                                        size="large"
                                        onChange={handleChange}
                                        className={style.input_card_vcode}
                                        disabled={true}
                                      />
                                    </Form.Item>
                                  </div>
                                </div>

                                <div
                                  className={style.div_card_below}
                                  style={{ paddingTop: 0 }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <label className={style.lbl_card}>
                                      DOB
                                    </label>
                                    <div className={style.div_row_name}>
                                      <label className={style.lbl_card_name}>
                                        {getDateFormated(dateOfBirth)}
                                        {moment(startDate).format("YYYY-MM-DD")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* <div className="row">
                      <div className="col-sm-12 col-md-6 col-xl-6 ">
                        <div className={style.addressHeading}>
                          <h4>Province insurance</h4>
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <label className={style.inputLable}>Province</label>
                          <Select
                            defaultValue="OHIP"
                            size="large"
                            style={{ width: "100%" }}
                            onChange={handleChangeSelect}
                          >
                            {/* <Option value="PRIVATE_INSURANCE">Private insurance</Option> */}
                    {/* <Option value="OHIP">Ontario - (OHIP)</Option>
                          </Select>

                          <div className="row" style={{ marginTop: 20 }}>
                            <>
                              <div className="col-6">
                                <div className="form-group">
                                  <Form.Item
                                    label="Ohip Number"
                                    name="Insurancesvcnum"
                                    rules={[
                                      {
                                        required: false,
                                        message:
                                          "Please Enter Insurance Number!",
                                      },
                                      {
                                        max: 12,
                                        message:
                                          "must be maximum 12 characters.",
                                      },
                                    ]}
                                  >
                                    <MaskInput
                                      alwaysShowMask
                                      maskChar="_"
                                      onChange={handleChange}
                                      mask="0000-000-000"
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <Form.Item
                                    label="Version Code"
                                    name="insuranceVersionCode"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Please Enter Version code!",
                                      },
                                    ]}
                                  >
                                    <Input
                                      style={{
                                        textTransform: "uppercase",
                                      }}
                                      size="large"
                                      onChange={handleChange}
                                      name="insuranceVersionCode"
                                      maxLength={2}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-xl-6 ">
                        <div className={style.div_row_flex}>
                          <div
                            className={style.div_card_main}
                            style={{
                              backgroundImage: `url("Images/problems/card.png")`,
                            }}
                          >
                            <div>
                              <div
                                className={style.div_card_below}
                                style={{ paddingTop: 0 }}
                              >
                                <div style={{ width: "100%" }}>
                                  <label className={style.lbl_card}></label>
                                  <div className={style.div_row_name}>
                                    <label className={style.lbl_card_name}>
                                      {formValues?.FirstName?.charAt(
                                        0
                                      ).toUpperCase() +
                                        formValues?.FirstName?.slice(1) ||
                                        userData?.FirstName?.charAt(
                                          0
                                        ).toUpperCase() +
                                          userData?.FirstName?.slice(1)}{" "}
                                      &nbsp;
                                    </label>
                                    <label className={style.lbl_card_name}>
                                      {formValues?.MiddleName != null &&
                                      formValues?.MiddleName
                                        ? formValues?.MiddleName.charAt(
                                            0
                                          ).toUpperCase() +
                                          formValues?.MiddleName?.slice(1)
                                        : "" ||
                                          (userData?.MiddleName != null &&
                                            userData?.MiddleName)
                                        ? userData?.MiddleName?.charAt(
                                            0
                                          ).toUpperCase() +
                                          userData?.MiddleName?.slice(1)
                                        : ""}{" "}
                                      &nbsp;
                                    </label>
                                    <label className={style.lbl_card_name}>
                                      {formValues?.LastName?.charAt(
                                        0
                                      ).toUpperCase() +
                                        formValues?.LastName?.slice(1) ||
                                        userData?.LastName?.charAt(
                                          0
                                        ).toUpperCase() +
                                          userData?.LastName?.slice(1)}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className={style.div_card_below}>
                                <div style={{ width: "67%" }}>
                                  <label className={style.lbl_card}>
                                    Card Number
                                  </label>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="Insurancesvcnum"
                                  >
                                    <Input
                                      size="large"
                                      onChange={handleChange}
                                      className={style.input_card}
                                      disabled={true}
                                    />
                                  </Form.Item>
                                </div>
                                <div style={{ width: "25%" }}>
                                  <label className={style.lbl_card}>
                                    Version Code
                                  </label>
                                  <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    name="insuranceVersionCode"
                                  >
                                    <Input
                                      style={{
                                        textTransform: "uppercase",
                                      }}
                                      size="large"
                                      onChange={handleChange}
                                      className={style.input_card_vcode}
                                      disabled={true}
                                    />
                                  </Form.Item>
                                </div>
                              </div>

                              <div
                                className={style.div_card_below}
                                style={{ paddingTop: 0 }}
                              >
                                <div style={{ width: "100%" }}>
                                  <label className={style.lbl_card}>DOB</label>
                                  <div className={style.div_row_name}>
                                    <label className={style.lbl_card_name}> */}
                    {/* {getDateFormated(dateOfBirth)} */}
                    {/* {moment(startDate).format("YYYY-MM-DD")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    {/* </div> */}
                    {/* <div className="row">
                      <div className="col-sm-6 col-md-6 col-xl-6 ">
                        <div className={style.addressHeading}>
                          <h4>Health insurance and benefits</h4>
                        </div>
                        <div>
                          <div className="row" style={{ marginTop: 30 }}>
                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  label="Name of insurer"
                                  name="insurer"

                                  // rules={[
                                  //   {
                                  //     required:
                                  //       (form1.getFieldValue('insuranceNumber') != undefined &&
                                  //         form1.getFieldValue('insuranceNumber') != '') ||
                                  //         (form1.getFieldValue('lastname_cardholder') != undefined &&
                                  //           form1.getFieldValue('lastname_cardholder') != '') ||
                                  //         (form1.getFieldValue('relationship') != undefined &&
                                  //           form1.getFieldValue('relationship') != ''
                                  //         )
                                  //         ? true : false,
                                  //     message: "Please Enter Insurer Name!",
                                  //   },
                                  // ]}
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="insurer"
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  label="Numbers on card"
                                  name="insuranceNumber"

                                  // rules={[
                                  //   {
                                  //     required:
                                  //       (form1.getFieldValue('insurer') != undefined &&
                                  //         form1.getFieldValue('insurer') != '') ||
                                  //         (form1.getFieldValue('lastname_cardholder') != undefined &&
                                  //           form1.getFieldValue('lastname_cardholder') != '') ||
                                  //         (form1.getFieldValue('relationship') != undefined &&
                                  //           form1.getFieldValue('relationship') != ''
                                  //         ) ? true : false,
                                  //     message: "Please Enter Insurance Number!",
                                  //   },
                                  // ]}
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="insuranceNumber"
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  label="Cardholder last name"
                                  name="lastname_cardholder"

                                  // rules={[
                                  //   {
                                  //     required:
                                  //       (form1.getFieldValue('insurer') != undefined &&
                                  //         form1.getFieldValue('insurer') != '') ||
                                  //         (form1.getFieldValue('insuranceNumber') != undefined &&
                                  //           form1.getFieldValue('insuranceNumber') != '') ||
                                  //         (form1.getFieldValue('relationship') != undefined &&
                                  //           form1.getFieldValue('relationship') != ''
                                  //         ) ? true : false,

                                  //     message: "Please Enter Cardholder last name!",
                                  //   },
                                  // ]}
                                >
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className={`${style.inputbox}`}
                                    name="lastname_cardholder"
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <Form.Item
                                  label="Relationship"
                                  name="relationship"

                                  // rules={[
                                  //   {
                                  //     required:
                                  //     (form1.getFieldValue('insurer') != undefined &&
                                  //     form1.getFieldValue('insurer') != '')||
                                  //     (form1.getFieldValue('insuranceNumber') != undefined &&
                                  //     form1.getFieldValue('insuranceNumber') != '') ||
                                  //     (form1.getFieldValue('lastname_cardholder') != undefined &&
                                  //     form1.getFieldValue('lastname_cardholder') != ''
                                  //     ) ? true : false,

                                  //     message: "Please Enter First Name!",
                                  //   },
                                  // ]}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Select Relatonship"
                                    optionFilterProp="children"
                                    size="large"
                                    style={{ marginRight: 10 }}
                                    defaultActiveFirstOption
                                    onChange={(e) => {
                                      setSelFamilyMember(e);
                                    }}
                                    value={selFamilyMember}
                                  >
                                    {relationShip.map((relation) => (
                                      <Option
                                        value={relation.id}
                                        key={relation.id}
                                      >
                                        {relation.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-xl-6">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-xl-12">
                            <div className="row">
                              <div
                                className="col-sm-12 col-md-12 col-xl-12"
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                1. Choose Front Insurance card image
                              </div>
                              <div className="col-sm-12 col-md-12 col-xl-12">
                                <Dragger
                                  {...props1}
                                  style={{ width: "100%" }}
                                  showUploadList={false}
                                >
                                  {" "}
                                  <p className="ant-upload-drag-icon">
                                    {" "}
                                    <InboxOutlined />{" "}
                                  </p>{" "}
                                  <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                  </p>{" "}
                                  <p className="ant-upload-hint">
                                    {" "}
                                    Support for a .pdf,.doc,.docx,images file
                                  </p>{" "}
                                </Dragger>
                              </div>
                            </div>
                          </div>

                          {privateInsuranceData?.frontcard_path ? (
                            <>
                              <div
                                className="col-sm-12 col-md-12 col-xl-12"
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <a
                                  onClick={(e) =>
                                    openFile(
                                      privateInsuranceData?.frontcard_path
                                    )
                                  }
                                >
                                  See uploaded front card image{" "}
                                  <img
                                    style={{ width: 30, marginLeft: 8 }}
                                    src={IMG02}
                                  />
                                </a>
                              </div>
                            </>
                          ) : null}
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-xl-12">
                            <div className="row">
                              <div
                                className="col-sm-12 col-md-12 col-xl-12"
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                2. Choose Back Insurance card image
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-xl-12">
                                <Dragger
                                  {...props2}
                                  style={{ width: "100%" }}
                                  showUploadList={false}
                                >
                                  {" "}
                                  <p className="ant-upload-drag-icon">
                                    {" "}
                                    <InboxOutlined />{" "}
                                  </p>{" "}
                                  <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                  </p>{" "}
                                  <p className="ant-upload-hint">
                                    {" "}
                                    Support for a .pdf,.doc,.docx,images file
                                  </p>{" "}
                                </Dragger>
                              </div>
                            </div>
                          </div>

                          {privateInsuranceData?.backcard_path ? (
                            <>
                              <div
                                className="col-sm-12 col-md-12 col-xl-12"
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <a
                                  onClick={(e) =>
                                    openFile(
                                      privateInsuranceData?.backcard_path
                                    )
                                  }
                                >
                                  See uploaded back card image
                                  <img
                                    style={{ width: 30, marginLeft: 8 }}
                                    src={IMG02}
                                  />
                                </a>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="row">
                      <div className="col-md-12">
                        <hr></hr>
                        <div className={style.div_btn}>
                          {loader ? (
                            <div className="spinner">
                              {/* <Spin tip="Loading..."></Spin> */}
                    {/* <Spin tip="Validating Health Card (OHIP Validation in progress) ..."></Spin>
                            </div>
                          ) : (
                            <button type="submit" className="btn">
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>  */}
                  </Form>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
