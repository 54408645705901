import { TopBar, Button } from "components";
import DashboardSidebar from "../sidebar/sidebar";
import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEquals,
  faInfo,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { UPDATEDDELETE, UPDATEDGET, UPDATEDPOST } from "../../../services/common.api";
import AddFamilyMember from "components/addFamilyMember";
import {
  notification,
  Pagination,
  Table,
  Tag,
  Space,
  Popconfirm,
  Tooltip,
  Spin,
  Drawer,
} from "antd";
import { Link } from "react-router-dom";
import Footer from "components/footerFix";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AppointmentRequestType, setSelectedFamilyMember } from "redux/actions/userActions";
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions';
import { MessageOutlined } from "@ant-design/icons";
import { RINGER } from "../img";
import PageHeader from "components/pageHeader";

const Dependent = () => {
  const dispatch = useDispatch();
  // let user = JSON.parse(localStorage.getItem("user"));
  const user: any = useSelector((state: any) => state.userReducer.user);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [familyModel, setFamilyModel] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMemberr] = useState(null);
  // const [click, setClick] = useState(false);
  const [subscription, setSubscription] = useState(null)


  const [addClick, setAddClick] = useState(false);
  const [editClick, setEditClick] = useState(false);

  const [loader, setLoader] = useState(false);
  // const audio = new Audio('https://onrx-dev-portal.s3.ca-central-1.amazonaws.com/message.mp3');
  // audio.crossOrigin="anonymous"

  const playAudio = () => {
    const audio = new Audio();
    // audio.crossOrigin = "anonymous";
    audio.src = 'https://www.soundjay.com/phone/sounds/telephone-ring-01a.mp3';
    audio.play()
      .catch(error => {
        // Handle any playback errors
        console.log(error, "audio play error");
      });
  }

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {
    let logBody = {
      message: "Dependent - Page Load",
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Dependent Page`,
    }

    logEvents(logBody);

    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    window.scrollTo(0, 0);
    console.log(user);
    getFamilyMember();
  }, []);

  useEffect(() => {

    if (user?.id) {
      const subscribeToNotifications = async () => {
        if (subscription && subscription._cleanup !== undefined) {
          // If a subscription already exists, no need to create a new one
          return;
        }

        const newSubscription = (API.graphql(graphqlOperation(subscriptions.subscribeToNewNotification, { userId: user?.id + "" })) as any).subscribe({
          next: (notificationData) => {
            const newNotification = notificationData.value.data.subscribeToNewNotification;
            console.log('New notification received:', newNotification);

            console.log(localStorage.getItem("chatHead"), "localStorage.getItem", newNotification?.conversationId);
            if (parseInt(newNotification?.conversationId) == parseInt(localStorage.getItem("chatHead"))) { } else {
              notification.open({
                message: 'New Message',
                description: newNotification?.message,
                duration: 5,
                icon: <MessageOutlined style={{ color: '#1890ff' }} />,
                placement: 'topRight',
              });
              // audio.play();
              playAudio()
            }

          },
          error: (error) => {
            console.error('Error subscribing to new notification:', error);
          },
        });

        setSubscription(newSubscription);
      };

      subscribeToNotifications();

      return () => {
        if (subscription) {
          (subscription?.value as any)?.unsubscribe();
          setSubscription(null);
        }
      };
    }

  }, [subscription, user?.id]);

  const getFamilyMember = async () => {

    let logBody = {
      message: `Dependent - ${user?.sub} - Fetch Family Members - Dependent Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Dependent Page`,
    }

    logEvents(logBody);

    console.log("??????????????", `userfamilymembers?id=${user.id}`)
    try {
      setLoader(true);
      const {
        data: {
          body: { FamilyMembers },
        },
      } = await UPDATEDGET(`userfamilymembers?id=${user.id}`);
      if (FamilyMembers) {
        setLoader(true);
        setFamilyMembers(FamilyMembers);
        setLoader(false);
      } else {
        setLoader(true);
        setFamilyMembers([]);
        setLoader(false);
      }
    } catch (err) {
      setFamilyMembers([]);
      setLoader(false);
      console.log("err: ", err);
    }
  };

  // const familyModelOpen = () => {
  //   setFamilyModel(true);
  //   setSelectedFamilyMemberr({});
  //   setClick(false);
  // };

  const addFamilyModelOpen = () => {
    setAddClick(true);
    setEditClick(false);
    dispatch(setSelectedFamilyMember({}));
    setSelectedFamilyMemberr(null);
    console.log("addaddaddaddaddaddadd")
    setFamilyModel(true);
  };

  const familyModelClose = () => {
    setFamilyModel(false);
    setSelectedFamilyMemberr(null);
  };

  const deleteFamilyMember = async (deleteId) => {

    let logBody = {
      message: `Dependent - ${user?.sub} - Delete Family Members - Dependent Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Dependent Page`,
    }

    logEvents(logBody);

    UPDATEDDELETE(`userfamilymembers?id=${deleteId}`)
      .then(() => {
        setLoader(true);
        notification.success({
          message: "Family Member Deleted Successfully",
        });
        getFamilyMember();
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err: ", err);
      });
  };

  // const editModelOpen = (familyMemberId) => {
  //   console.log(familyMemberId, "=====================");
  //   setClick(true);
  //   const familyMember = familyMembers.filter(
  //     (familyMemberData) => familyMemberData.id === familyMemberId
  //   )[0];

  //   console.log(familyMember, "=====================");
  //   setSelectedFamilyMemberr(familyMember);
  //   setFamilyModel(true);
  // };

  const editFamilyModelOpen = (familyMemberId) => {
    console.log("editeditediteditedit")
    console.log(familyMemberId, "=====================");
    const familyMember = familyMembers.filter(
      (familyMemberData) => familyMemberData.id === familyMemberId
    )[0];
    //
    console.log(familyMember, "========gregrfiue==");
    setSelectedFamilyMemberr(familyMember);
    setEditClick(true);
    setFamilyModel(true);
  };

  // useEffect(() => {
  //   if (editClick == true) {
  //     console.log(familyMember, "fafafafafafaffafaffaaffafafafafafaafaf")
  //     setSelectedFamilyMemberr(familyMember);
  //   }
  // }, [editClick])

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  function confirmPop(e) {
    console.log(e);
    deleteFamilyMember(e);
  }

  function cancelPop(e) {
    console.log(e);
  }

  const familyMember = [
    {
      title: "Family Member",
      dataIndex: "userName",
      key: "userName",
      render: (userName) => <span className="text-capitalize">{userName}</span>,
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
      render: (relationship) => (
        <Tag color="var(--light-primary-bg-color)" style={{ borderRadius: 5 }}>
          <p style={{ margin: 0 }} className="text-capitalize">{relationship}</p>
        </Tag>
      ),
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (dateOfBirth) => <>{moment(dateOfBirth).format("DD-MMM-YYYY")}</>,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (gender) => <span className="text-capitalize">{gender}</span>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => (
        <>
          <span
            style={{
              width: 140,
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </span>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "",
      render: (data) => (
        <Space size="middle">
          <Tooltip placement="bottom" title="Edit" key={"#1a6ab1"}>
            <button
              className="button-Edit-Icon"
              role="button"
              onClick={() => {
                editFamilyModelOpen(data.id);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </Tooltip>

          <Popconfirm
            title="Are you sure?"
            onConfirm={() => confirmPop(data.id)}
            onCancel={cancelPop}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="bottom" title="Delete" key={"red"}>
              <button className="button-Delete" role="button">
                <FontAwesomeIcon color="white" icon={faTrash} />
              </button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div style={{height:'100vh'}} >
      <TopBar onShow={showDrawer} />
     
      <div className="content" style={{ height: '100%' }}>
        <div className="container-fluid" style={{ height: '100%' }} >
          <div className="row pb-4" style={{ height: '100%' }}>
            <div
              className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0  `}
              style={{ height: '100%' }}
            >
              <DashboardSidebar />
            </div>

            <Drawer
              closable={false}
              width={300}
              placement="left"
              onClose={onClose}
              visible={visible}
              style={{ paddingTop: 0 }}
            >
              <div className="widget-profile pro-widget-content pt-0 pb-4">
                <div className="profile-info-widget">
                  <img src="../Images/logo.png" height="50" alt="" />
                </div>
              </div>
              <DashboardSidebar />
            </Drawer>

            {/* {sidebar ?
              <div className={`d-block d-xl-none ${style.toggle}`}>
                <DashboardSidebar />
              </div> : null
            } */}

            <div className="col-lg-12 col-xl-10" style={{ height: '100%' }}>
            <PageHeader
                  items={[
                    { label: "Dashboard", link: "/dashboard" },
                    { label: "Family Member", link: "" },
                    
                  ]}
                  pageTitle="Family Member"
                />
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="dependent_center col-sm-6">
                      <h3 className="card-title">Family Member</h3>
                    </div>
                    <div className="col-sm-6">
                      <div className="text-right">
                        {/* <div className="div_book_appoiment"> */}
                        <button
                          type="submit"
                          className={style.btn}
                          // onClick={() => familyModelOpen()}
                          onClick={() => addFamilyModelOpen()}
                        >
                          <FontAwesomeIcon className="mr-1" icon={faPlus} /> 
                          Add Family Member
                        </button>
                        {/* </div> */}
                        {/* <Button
                          className="btn btn-primary btn-sm addBtn "
                          onClick={() => {
                            familyModelOpen();
                          }}
                        >
                          Add Family Member
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body ">
                  {/* Dependent Tab */}
                  {/* <div className="card card-table mb-0">
                    <div className="card-body"> */}
                  <div className="table-responsive">
                    {/* <table className="table table-hover table-center mb-0">
                          <thead>
                            <tr>
                              <th>Family Member</th>
                              <th>Relationship</th>
                              <th>Date Of Birth</th>
                              <th>Gender</th>
                              <th>Address</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {familyMembers?.map((item, index) => {
                              return (
                                <tr key={item.id}>
                                  <td>
                                    <h2 className="table-avatar">
                                      {item?.userName}
                                    </h2>
                                  </td>
                                  <td>{item.relationship}</td>
                                  <td>{item.dateOfBirth}</td>
                                  <td>{item.gender}</td>
                                  <td>{item.address}</td>
                                  <td>
                                    <div className="table-action">
                                      <Button
                                        onClick={() => {
                                          editModelOpen(item.id)
                                        }}
                                        className="btn btn-sm bg-info-light customButton"
                                      >
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faEdit}
                                        />{" "}
                                        Edit
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          deleteFamilyMember(item.id)
                                        }}
                                        className="btn btn-sm bg-danger-light customButton"
                                      >
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faTrash}
                                        />{" "}
                                        Delete
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table> */}

                    {loader ? (
                      <div className="spinner">
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <Table
                          dataSource={familyMembers}
                          columns={familyMember}
                          style={{ whiteSpace: "pre" }}
                          pagination={{
                            defaultPageSize: 4,
                            showSizeChanger: true,
                            pageSizeOptions: ["4", "10", "20", "30"],
                          }}
                          rowKey={(obj) => obj.id}
                        />
                      </>
                    )}
                  </div>
                  {/* </div>
                  </div> */}
                  {/* /Dependent Tab */}
                </div>
              </div>


              {/* <div className="row" style={{
                width: '100%',
                position: 'absolute',
                bottom: '0'
              }
              }>
                <div className={`col-lg-12 col-xl-12`} style={{ backgroundColor: "#F0F0F0 " }}>
                  <div className="row">
                    <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 ${style.logoDiv}`}>
                      <p className={style.footer_label}>© 2023 ONRx. All rights reserved.</p>
                    </div>
                    <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 ${style.logoDiv}`}>
                      <p className={style.footer_label2}> <a target="_blank" href="/term-and-conditions" className={style.linkA}>Terms and Conditions | Policy </a> </p>
                    </div>
                  </div>
                </div>
              </div> */}





            </div>
          </div>
          {/* {click !== true ? (
            <AddFamilyMember
              title="Add Family Member"
              open={familyModel}
              close={familyModelClose}
              getFamilyMember={getFamilyMember}
              editFamilyMember={{}}
            />
          ) : (
            <AddFamilyMember
              title="Edit Family Member"
              open={familyModel}
              close={familyModelClose}
              getFamilyMember={getFamilyMember}
              editFamilyMember={selectedFamilyMember}
            />
          )} */}

          {addClick == true ? (
            <AddFamilyMember
              title="Add Family Member"
              open={familyModel}
              close={familyModelClose}
              getFamilyMember={getFamilyMember}
              editFamilyMember={{}}
            />
          ) : null}

          {editClick == true ? (
            <AddFamilyMember
              title="Edit Family Member"
              open={familyModel}
              close={familyModelClose}
              getFamilyMember={getFamilyMember}
              editFamilyMember={selectedFamilyMember}
            />
          ) : null}

        </div>
      </div>
            <div className="footer">
              <Footer/>
            </div>
    </div>

  );
};

export default Dependent;
