import React, { useState, useEffect, useRef } from "react";
import { TopBar } from "components";
import {
  Steps,
  Card,
  Input,
  Form as FormAntd,
  Typography,
  Radio,
  notification,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  List,
  DatePicker,
  Table,
  Tag,
  Spin,
  Popconfirm,
  Drawer,
  Modal,
  Upload,
  UploadProps,
  Select,
  Switch,
  Tabs,
  Tooltip,
  Collapse,
} from "antd";

import type { CollapseProps } from 'antd';
import moment from "moment";
import { Formik, FieldArray, Form, Field } from "formik";
import CanvasDraw from "react-canvas-draw";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import PATIENT_DATA from "../../../jsonFiles/patientHealthHistory.json";
import PERSONAL_HEALTH_DATA from "../../../jsonFiles/personalHealth.json";
import FAMILY_HEALTH_DATA from "../../../jsonFiles/familyHealth.json";
import OTHER_HEALTH_DATA from "../../../jsonFiles/otherProblem.json";
import "primereact/resources/primereact.min.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { relationShip } from "../../../constants/patientProfile.constant";
import { v4 as uuid } from "uuid";
import {
  UPDATEDFILEUPLOAD,
  UPDATEDGET,
  UPDATEDPOST,
  UPDATEDPUT,
} from "../../../services/common.api";
import DashboardSidebar from "../sidebar/sidebar";
import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faUndo,
  faEquals,
  faCross,
} from "@fortawesome/free-solid-svg-icons";
import { s3Upload, s3GetPrivate } from "../../../services/s3fileUpload/index";
import { Link, useHistory } from "react-router-dom";
import Footer from "components/footerFix";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "config";
import { IMG01 } from "./img";
import { Cancel, CancelOutlined, InboxOutlined } from "@material-ui/icons";
import PersonalHealthHistoryCard from "../../../components/personalHealthHistory";
import HealthHistoryCard from "../../../components/HealthHistory";
import FamilyHealthHistoryCard from "../../../components/familyHealthHistory";
import OtherProblemHealthHistoryCard from "../../../components/OtherProbleHistory";
import _ from "lodash";

import { setUser } from "../../../redux/actions/userActions";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../../graphql/subscriptions";
import { MessageOutlined } from "@ant-design/icons";
import { RINGER } from "../img";
import PageHeader from "components/pageHeader";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
const { Dragger } = Upload;
const { Panel } = Collapse;

const MedicaldDetails = () => {
  const { TabPane } = Tabs;
  const { Step } = Steps;
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const history = useHistory();
  const [healthHistoryModule, setHealthHistoryModule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clinicId, setClientId] = useState(60);
  const [signatureUrl, setSignatureUrl] = useState(null);
  const [current, setCurrent] = useState(0);
  const _sketch = useRef(null as any);
  const [image, setImage] = useState(null);
  const [canvass, setCanvass] = useState([] as any);
  const [edit, setEdit] = useState(false);
  const [errorSignature, setErrorSignature] = useState(false);
  const [unshareLoader, setUnshareLoader] = useState(false);
  const userData = useSelector((state: any) => state.userReducer.user);
  console.log("userData", userData);

  const userType = useSelector(
    (state: any) => state.curentAppointment?.appointmentRequestType
  );
  const familyMemeberData = useSelector(
    (state: any) => state.curentAppointment?.familyMember
  );
  const user: any = useSelector((state: any) => state.userReducer.user);

  const [selectedPayload, setSelectedPayload] = useState(null);
  const [selectedKey, setSelectedKey] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [allDocumentHistory, setAllDocumentHistory] = useState([1, 2]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [form] = FormAntd.useForm();
  const { Option } = Select;
  const [familyMembers, setFamilyMembers] = useState([]);
  const [uploadedFor, setUploadedFor] = useState(null);
  const [uploadedFilePath, setUploadedFilePath] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [doctorList, setdoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [shareLoader, setshareLoader] = useState(false);
  const [dataSetAll, setDataSetAll] = useState([]);
  const [bloodPressureData, setBloodPressureData] = useState([]);
  const [pulseRateData, setPulseRateData] = useState([]);
  const [oxygenData, setOxygenData] = useState([]);
  const [tempratureData, setTempratureData] = useState([]);
  const [respiratoryData, setRespiratoryData] = useState([]);
  const [glucoseData, setGlucoseData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState([]);
  const [selectPatientName, setSelectPatientName] = useState("");
  const [pdfLoader, setPdfLoader] = useState(false);
  const [multipleDoctorShare, setMultipleDoctorShare] = useState([]);
  const [allSharedDocuments, setAllSharedDocuments] = useState([]);
  const [subscription, setSubscription] = useState(null);

  const [selFamilyMember, setSelFamilyMember] = useState(userData?.id);
  const [switchStatus, setSwitchStatus] = useState(userData.medconcent == 1 ? true : false);
  const [isMasterCheckboxChecked, setMasterCheckboxChecked] = useState(false);

  const [breadcrumbTitle, setbreadcrumbTitle] = useState("Medical History");
  // const audio = new Audio('https://onrx-dev-portal.s3.ca-central-1.amazonaws.com/message.mp3');
  // audio.crossOrigin="anonymous"

  console.log("familyMemeberData", familyMembers)
  const playAudio = () => {
    const audio = new Audio();
    // audio.crossOrigin = "anonymous";
    audio.src = "https://www.soundjay.com/phone/sounds/telephone-ring-01a.mp3";
    audio.play().catch((error) => {
      // Handle any playback errors
      console.log(error, "audio play error");
    });
  };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {

    let logBody = {
      message: "Medical Details - Page Load",
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    if (userData?.id) {
      const subscribeToNotifications = async () => {
        if (subscription && subscription._cleanup !== undefined) {
          // If a subscription already exists, no need to create a new one
          return;
        }

        const newSubscription = (
          API.graphql(
            graphqlOperation(subscriptions.subscribeToNewNotification, {
              userId: userData?.id + "",
            })
          ) as any
        ).subscribe({
          next: (notificationData) => {
            const newNotification =
              notificationData.value.data.subscribeToNewNotification;
            console.log("New notification received:", newNotification);

            console.log(
              localStorage.getItem("chatHead"),
              "localStorage.getItem",
              newNotification?.conversationId
            );
            if (
              parseInt(newNotification?.conversationId) ==
              parseInt(localStorage.getItem("chatHead"))
            ) {
            } else {
              notification.open({
                message: "New Message",
                description: newNotification?.message,
                duration: 5,
                icon: <MessageOutlined style={{ color: "#1890ff" }} />,
                placement: "topRight",
              });
              // audio.play();
              playAudio();
            }
          },
          error: (error) => {
            console.error("Error subscribing to new notification:", error);
          },
        });

        setSubscription(newSubscription);
      };

      subscribeToNotifications();

      return () => {
        if (subscription) {
          subscription?.unsubscribe();
          setSubscription(null);
        }
      };
    }
  }, [subscription, userData?.id]);

  const dispatch = useDispatch();

  const props = {
    name: "file",
    multiple: false,
    // accept: "application/pdf","application/vnd.openxmlformats-officedocument.wordprocessingm","application/msword",
    accept: ".pdf,.doc,.docx",
    beforeUpload: async (file) => {
      console.log(file, "selected file");
      setUploadedFilePath(file);
      return false;
    },
  };

  const submitDate = (period) => {
    console.log("submitDate");
    let start, end;
    switch (period) {
      case 7:
        start = new Date(moment().subtract(7, "d").format("YYYY-MM-DD"));
        end = new Date(moment().format("YYYY-MM-DD"));
        var datBp = bloodPressureData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datBp);
        amchartVitalsBp(datBp);

        var datPulse = pulseRateData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datPulse);
        amchartVitalsPlus(datPulse);

        var datOxygen = oxygenData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datOxygen);
        amchartVitalsOxygen(datOxygen);

        var datTemp = tempratureData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datTemp);
        amchartVitalsTemp(datTemp);

        var datResp = respiratoryData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datResp);
        amchartVitalsResp(datResp);

        var datGlucose = glucoseData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datGlucose);
        amchartVitalsGlucose(datGlucose);

        break;
      case 30:
        start = new Date(moment().subtract(1, "months").format("YYYY-MM-DD"));
        end = new Date(moment().format("YYYY-MM-DD"));

        var datBp = bloodPressureData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datBp);
        amchartVitalsBp(datBp);

        var datPulse = pulseRateData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datPulse);
        amchartVitalsPlus(datPulse);

        var datOxygen = oxygenData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datOxygen);
        amchartVitalsOxygen(datOxygen);

        var datTemp = tempratureData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datTemp);
        amchartVitalsTemp(datTemp);

        var datResp = respiratoryData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datResp);
        amchartVitalsResp(datResp);

        var datGlucose = glucoseData.filter((item: any) => {
          let date = new Date(item.date);
          return date >= start && date <= end;
        });
        console.log(datGlucose);
        amchartVitalsGlucose(datGlucose);

        break;
      default:
    }
  };

  const amchartVitalsBp = (dat) => {
    const c1 = am4core.create("chartdivBp", am4charts.XYChart);
    c1.colors.step = 2;

    const xAxis = c1.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.grid.template.location = 0;
    const yAxis = c1.yAxes.push(new am4charts.ValueAxis());
    var title = c1.titles.create();
    title.fontSize = 20;

    yAxis.min = 5;
    yAxis.max = 180;

    title.text = "Blood Pressure (mmHg)";
    var range = yAxis.axisRanges.create();
    range.value = 80;
    range.endValue = 120;
    range.axisFill.fill = am4core.color("#d7f3b5");
    range.axisFill.fillOpacity = 0.2;
    range.grid.strokeOpacity = 0;
    var range = yAxis.axisRanges.create();
    range.value = 80;
    range.grid.stroke = am4core.color("#396478");
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;
    range.label.inside = true;
    range.label.text = "[bold]Diastolic [/]";
    range.label.fill = range.grid.stroke;
    //range.label.align = "right";
    range.label.verticalCenter = "bottom";

    var range2 = yAxis.axisRanges.create();
    range2.value = 120;
    range2.grid.stroke = am4core.color("#A96478");
    range2.grid.strokeWidth = 2;
    range2.grid.strokeOpacity = 1;
    range2.label.inside = true;
    range2.label.text = "[bold]systolic[/]";
    range2.label.fill = range2.grid.stroke;
    //range2.label.align = "right";
    range2.label.verticalCenter = "bottom";
    c1.data = dat;

    // Create series
    var lineSeries = c1.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "sysbp";
    lineSeries.dataFields.dateX = "date";
    lineSeries.name = "systolic";
    lineSeries.strokeWidth = 2;
    lineSeries.tensionX = 0.8;
    lineSeries.tensionY = 1;
    var lineSeries1 = c1.series.push(new am4charts.LineSeries());
    lineSeries1.dataFields.valueY = "dysbp";
    lineSeries1.dataFields.dateX = "date";
    lineSeries1.name = "DIASTOLIC";
    lineSeries1.strokeWidth = 2;
    lineSeries1.tensionX = 0.8;
    lineSeries1.tensionY = 1;
    var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 0.5;
    circleBullet.circle.scale = 0.5;
    circleBullet.tooltipText = "BP:{sysbp} / {dysbp}";
    var circleBullet = lineSeries1.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.strokeWidth = 0.5;
    circleBullet.circle.scale = 0.5;
  };

  const amchartVitalsPlus = (dat) => {
    const c1 = am4core.create("chartdivHeartRate", am4charts.XYChart);
    c1.colors.step = 2;

    const xAxis = c1.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.grid.template.location = 0;
    const yAxis = c1.yAxes.push(new am4charts.ValueAxis());
    var title = c1.titles.create();
    title.fontSize = 20;

    yAxis.min = 5;
    yAxis.max = 150;
    title.text = "Heart Rate (bpm)";
    var range = yAxis.axisRanges.create();
    range.value = 60;
    range.endValue = 90;
    range.axisFill.fill = am4core.color("#d7f3b5");
    range.axisFill.fillOpacity = 0.2;
    range.grid.strokeOpacity = 0;
    var range = yAxis.axisRanges.create();
    range.value = 60;
    range.grid.stroke = am4core.color("#396478");
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;
    range.label.inside = true;
    range.label.fill = range.grid.stroke;
    //range.label.align = "right";
    range.label.verticalCenter = "bottom";

    var range2 = yAxis.axisRanges.create();
    range2.value = 90;
    range2.grid.stroke = am4core.color("#A96478");
    range2.grid.strokeWidth = 2;
    range2.grid.strokeOpacity = 1;
    range2.label.inside = true;
    range2.label.fill = range2.grid.stroke;
    //range2.label.align = "right";
    range2.label.verticalCenter = "bottom";

    c1.data = dat;

    // Create series
    var lineSeries = c1.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "vitalvalue";
    lineSeries.dataFields.dateX = "date";
    lineSeries.name = "Pulse";
    lineSeries.strokeWidth = 2;
    lineSeries.tensionX = 0.8;
    lineSeries.tensionY = 1;

    var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 0.5;
    circleBullet.circle.scale = 0.5;
    circleBullet.tooltipText = "Pulse: {vitalvalue}bpm";

    var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{vitalvalue}";
    labelBullet.label.dy = -20;
    labelBullet.label.fontFamily = "Roboto";
  };

  const amchartVitalsOxygen = (dat) => {
    const c1 = am4core.create("chartdivOxygen", am4charts.XYChart);
    c1.colors.step = 2;

    const xAxis = c1.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.grid.template.location = 0;
    const yAxis = c1.yAxes.push(new am4charts.ValueAxis());
    var title = c1.titles.create();
    title.fontSize = 20;

    yAxis.min = 90;
    yAxis.max = 100;
    title.text = "Oxygen Saturation (% SpO2)";

    var range = yAxis.axisRanges.create();
    range.value = 94;
    range.endValue = 100;
    range.axisFill.fill = am4core.color("#d7f3b5");
    range.axisFill.fillOpacity = 0.2;
    range.grid.strokeOpacity = 0;
    var range = yAxis.axisRanges.create();
    range.value = 100;
    range.grid.stroke = am4core.color("#396478");
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;
    range.label.inside = true;
    range.label.fill = range.grid.stroke;
    //range.label.align = "right";
    range.label.verticalCenter = "bottom";

    var range2 = yAxis.axisRanges.create();
    range2.value = 94;
    range2.grid.stroke = am4core.color("#A96478");
    range2.grid.strokeWidth = 2;
    range2.grid.strokeOpacity = 1;
    range2.label.inside = true;
    range2.label.fill = range2.grid.stroke;
    //range2.label.align = "right";
    range2.label.verticalCenter = "bottom";

    c1.data = dat;

    // Create series
    var lineSeries = c1.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "vitalvalue";
    lineSeries.dataFields.dateX = "date";
    lineSeries.name = "spo2";
    lineSeries.strokeWidth = 2;
    lineSeries.tensionX = 0.8;
    lineSeries.tensionY = 1;

    var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 0.5;
    circleBullet.circle.scale = 0.5;
    circleBullet.tooltipText = "spo2: {vitalvalue}%";

    var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{vitalvalue}";
    labelBullet.label.dy = -20;
    labelBullet.label.fontFamily = "Roboto";
  };

  const amchartVitalsTemp = (dat) => {
    const c1 = am4core.create("chartdivTemp", am4charts.XYChart);
    c1.colors.step = 2;

    const xAxis = c1.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.grid.template.location = 0;
    const yAxis = c1.yAxes.push(new am4charts.ValueAxis());
    var title = c1.titles.create();
    title.fontSize = 20;

    yAxis.max = 102;
    title.text = "Temperature (F)";
    var range2 = yAxis.axisRanges.create();
    range2.value = 98.6;
    range2.grid.stroke = am4core.color("#A96478");
    range2.grid.strokeWidth = 2;
    range2.grid.strokeOpacity = 1;
    range2.label.fill = range2.grid.stroke;
    c1.data = dat;

    // Create series
    var lineSeries = c1.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "vitalvalue";
    lineSeries.dataFields.dateX = "date";
    lineSeries.name = "Temprature";
    lineSeries.strokeWidth = 2;
    lineSeries.tensionX = 0.8;
    lineSeries.tensionY = 1;

    var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 0.5;
    circleBullet.circle.scale = 0.5;
    circleBullet.tooltipText = "Temprature: {vitalvalue}F";

    var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{vitalvalue}";
    labelBullet.label.dy = -20;
    labelBullet.label.fontFamily = "Roboto";
  };

  const amchartVitalsResp = (dat) => {
    const c1 = am4core.create("chartdivResp", am4charts.XYChart);
    c1.colors.step = 2;

    const xAxis = c1.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.grid.template.location = 0;
    const yAxis = c1.yAxes.push(new am4charts.ValueAxis());
    var title = c1.titles.create();
    title.fontSize = 20;

    title.text = "Respiratory Rate (Rpm)";
    var range = yAxis.axisRanges.create();
    range.value = 12;
    range.endValue = 16;
    range.axisFill.fill = am4core.color("#d7f3b5");
    range.axisFill.fillOpacity = 0.2;
    range.grid.strokeOpacity = 0;
    var range = yAxis.axisRanges.create();
    range.value = 12;
    range.grid.stroke = am4core.color("#396478");
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;
    range.label.inside = true;
    range.label.fill = range.grid.stroke;
    //range.label.align = "right";
    range.label.verticalCenter = "bottom";

    var range2 = yAxis.axisRanges.create();
    range2.value = 16;
    range2.grid.stroke = am4core.color("#A96478");
    range2.grid.strokeWidth = 2;
    range2.grid.strokeOpacity = 1;
    range2.label.inside = true;
    range2.label.fill = range2.grid.stroke;
    //range2.label.align = "right";
    range2.label.verticalCenter = "bottom";

    c1.data = dat;

    // Create series
    var lineSeries = c1.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "vitalvalue";
    lineSeries.dataFields.dateX = "date";
    lineSeries.name = "Raspiration";
    lineSeries.strokeWidth = 2;
    lineSeries.tensionX = 0.8;
    lineSeries.tensionY = 1;

    var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 0.5;
    circleBullet.circle.scale = 0.5;
    circleBullet.tooltipText = "Raspiration: {vitalvalue}bpm";

    var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{vitalvalue}";
    labelBullet.label.dy = -20;
    labelBullet.label.fontFamily = "Roboto";
  };

  const amchartVitalsGlucose = (dat) => {
    const c1 = am4core.create("chartdivHeartGlucose", am4charts.XYChart);
    c1.colors.step = 2;

    const xAxis = c1.xAxes.push(new am4charts.DateAxis());
    xAxis.renderer.grid.template.location = 0;
    const yAxis = c1.yAxes.push(new am4charts.ValueAxis());
    var title = c1.titles.create();
    title.fontSize = 20;

    yAxis.max = 9.0;

    title.text = "Glucose Levels (mmol/L)";
    var range2 = yAxis.axisRanges.create();
    range2.value = 6;
    range2.grid.stroke = am4core.color("#A96478");
    range2.grid.strokeWidth = 2;
    range2.grid.strokeOpacity = 1;
    range2.label.fill = range2.grid.stroke;
    c1.data = dat;

    // Create series
    var lineSeries = c1.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "vitalvalue";
    lineSeries.dataFields.dateX = "date";
    lineSeries.name = "glucose";
    lineSeries.strokeWidth = 2;
    lineSeries.tensionX = 0.8;
    lineSeries.tensionY = 1;

    var circleBullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 0.5;
    circleBullet.circle.scale = 0.5;
    circleBullet.tooltipText = "glucose: {vitalvalue}mmol/L";

    var labelBullet = lineSeries.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{vitalvalue}";
    labelBullet.label.dy = -20;
    labelBullet.label.fontFamily = "Roboto";
  };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top' as const,
  //     },
  //     title: {
  //       display: true,
  //       text: 'Chart.js Line Chart',
  //     },
  //   },
  // };

  // const labels = bloodPressureData?.map(o => o.date);

  // const data = {
  //   labels,
  //   datasetKeyProvider:Math.random(),
  //   datasets: [
  //     {
  //       label: bloodPressureData?.length != 0 && bloodPressureData[0]?.vital == 'bp' ? 'Sysbp' : (bloodPressureData[0]?.vital ? bloodPressureData[0]?.vital : ""),
  //       data: bloodPressureData?.length != 0 && bloodPressureData[0]?.vital == 'bp' ? bloodPressureData.map(o => parseInt(o.sysbp)) : bloodPressureData.map(o => o.vitalvalue),
  //       borderColor: 'rgb(60, 179, 113)',
  //       backgroundColor: 'transparent',
  //     },
  //     {
  //       label: bloodPressureData?.length != 0 && bloodPressureData[0]?.vital == 'bp' ? 'Dysbp' : '',
  //       data: bloodPressureData?.length != 0 && bloodPressureData[0]?.vital == 'bp' ? bloodPressureData.map(o => parseInt(o.dysbp)) : bloodPressureData.map(o => o.vitalvalue),
  //       borderColor: 'rgb(53, 162, 235)',
  //       backgroundColor: 'transparent',
  //     },
  //   ]
  // };

  // const labelsRate = pulseRateData?.map(o => o.date);

  // const dataRate = {
  //   labels: labelsRate,
  //   datasets: [
  //     {
  //       label: pulseRateData?.length != 0 && pulseRateData[0]?.vital == 'bp' ? 'Sysbp' : (pulseRateData[0]?.vital ? pulseRateData[0]?.vital : ""),
  //       data: pulseRateData?.length != 0 && pulseRateData[0]?.vital == 'bp' ? pulseRateData.map(o => parseInt(o.sysbp)) : pulseRateData.map(o => o.vitalvalue),
  //       borderColor: 'rgb(60, 179, 113)',
  //       backgroundColor: 'transparent',
  //     }
  //   ]
  // };

  // const labelsOxyen = oxygenData?.map(o => o.date);

  // const dataOxygen = {
  //   labels: labelsOxyen,
  //   datasets: [
  //     {
  //       label: oxygenData?.length != 0 && oxygenData[0]?.vital == 'bp' ? 'Sysbp' : (oxygenData[0]?.vital ? oxygenData[0]?.vital : ""),
  //       data: oxygenData?.length != 0 && oxygenData[0]?.vital == 'bp' ? oxygenData.map(o => parseInt(o.sysbp)) : oxygenData.map(o => o.vitalvalue),
  //       borderColor: 'rgb(60, 179, 113)',
  //       backgroundColor: 'transparent',
  //     }
  //   ]
  // };

  // const labelsTemp = tempratureData?.map(o => o.date);

  // const dataTemp = {
  //   labels: labelsTemp,
  //   datasets: [
  //     {
  //       label: tempratureData?.length != 0 && tempratureData[0]?.vital == 'bp' ? 'Sysbp' : (tempratureData[0]?.vital ? tempratureData[0]?.vital : ""),
  //       data: tempratureData?.length != 0 && tempratureData[0]?.vital == 'bp' ? tempratureData.map(o => parseInt(o.sysbp)) : tempratureData.map(o => o.vitalvalue),
  //       borderColor: 'rgb(60, 179, 113)',
  //       backgroundColor: 'transparent',
  //     }
  //   ]
  // };

  // const labelsRes = respiratoryData?.map(o => o.date);

  // const dataRes = {
  //   labels: labelsRes,
  //   datasets: [
  //     {
  //       label: respiratoryData?.length != 0 && respiratoryData[0]?.vital == 'bp' ? 'Sysbp' : (respiratoryData[0]?.vital ? respiratoryData[0]?.vital : ""),
  //       data: respiratoryData?.length != 0 && respiratoryData[0]?.vital == 'bp' ? respiratoryData.map(o => parseInt(o.sysbp)) : respiratoryData.map(o => o.vitalvalue),
  //       borderColor: 'rgb(60, 179, 113)',
  //       backgroundColor: 'transparent',
  //     }
  //   ]
  // };

  // const labelsGlu = glucoseData?.map(o => o.date);

  // const dataGlu = {
  //   labels: labelsGlu,
  //   datasets: [
  //     {
  //       label: glucoseData?.length != 0 && glucoseData[0]?.vital == 'bp' ? 'Sysbp' : (glucoseData[0]?.vital ? glucoseData[0]?.vital : ""),
  //       data: glucoseData?.length != 0 && glucoseData[0]?.vital == 'bp' ? glucoseData.map(o => parseInt(o.sysbp)) : glucoseData.map(o => o.vitalvalue),
  //       borderColor: 'rgb(60, 179, 113)',
  //       backgroundColor: 'transparent',
  //     }
  //   ]
  // };

  // const datasetKeyProvider = () =>{
  //   return Math.random();
  // }

  const onChange = (current) => {
    setCurrent(current);
    if (current === 0) {
      setbreadcrumbTitle("Medical History");
    }
    if (current === 1) {
      setbreadcrumbTitle("Personal Health");
    }
    if (current === 2) {
      setbreadcrumbTitle("Family Health");
    }
    if (current === 3) {
      setbreadcrumbTitle("Other Problems");
    }
    if (current === 4) {
      setbreadcrumbTitle("Upload Report");
    }
    if (current === 5) {
      setbreadcrumbTitle("Medical Summary");
    }
    if (current === 6) {
      setbreadcrumbTitle("Consent Form");
    }
  };

  const unshare = async (data) => {

    let logBody = {
      message: `User - ${user?.sub} - Unshare Document - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    setUnshareLoader(true);
    let dataa = {
      id: data.id,
      status: 0,
    };
    console.log("data", data)

    try {
      const responseEdit = await UPDATEDPUT(`patient/document/unshare`, dataa);
      console.log(responseEdit, "unshare response");
      setUnshareLoader(false);
      getSharedDocument();
    } catch (err) {
      setUnshareLoader(false);
      console.log(err);
    }
  };

  const handleMasterCheckboxChange = (e) => {
    const checked = e.target.checked;
    const allIds = allDocumentHistory.map((item: any) => item.id);

    if (checked) {
      setSelectedDocuments(allIds);
    } else {
      setSelectedDocuments([]);
    }
    setMasterCheckboxChecked(checked);
  };
  const historyListColumn = [
    {
      title: <div style={{ textAlign: 'center' }}>
        <Checkbox
          style={{ textAlign: 'center' }}
          onChange={handleMasterCheckboxChange}
          checked={isMasterCheckboxChecked}
        >
        </Checkbox>
      </div>,
      dataIndex: "id",
      key: "id",
      render: (text, row) => (
        <div style={{ textAlign: 'center' }}>
          <Checkbox
            style={{ textAlign: 'center' }}
            value={row.id}
            checked={selectedDocuments.includes(row.id)}
            // checked={row.checked}
            onChange={(e) => {
              if (e.target.checked) {
                row.checked = true;
                setSelectedDocuments((selectedDocuments) => [
                  ...selectedDocuments,
                  row.id,
                ]);
              } else {
                row.checked = false;
                setSelectedDocuments(
                  selectedDocuments.filter((document) => document !== row.id)
                );
              }
            }}
          ></Checkbox>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>{text}</a>
      ),
    },
    {
      title: "Document Type",
      dataIndex: "document_type",
      key: "document_type",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>{text}</a>
      ),
    },
    // {
    //   title: "Uploaded For",
    //   dataIndex: ["uploaded_firstname", "uploaded_lastname"],
    //   key: "uploaded_firstname",
    //   render: (text, row) => (
    //     <a style={{ fontSize: 15, cursor: "unset" }}>
    //       {row.uploaded_firstname} {row.uploaded_lastname}
    //     </a>
    //   ),
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, Speciality) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>{text}</a>
      ),
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (text, row) => (
        <a
          // href={`${config.assetUrl}/${row.file}`} target='_blank' rel='noopener noreferrer'
          onClick={(e) => openFile(row.file)}
        >
          <img style={{ width: 30, marginLeft: 8 }} src={IMG01} />
        </a>
      ),
    },

  ];

  const sharedListColumn = [
    {
      title: "Doctor Name",
      dataIndex: ["doctorFirstName", "doctorLastName"],
      key: "dname",
      render: (text, row) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {row.doctorFirstName} {row.doctorLastName}
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (text, row) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>
          {moment(text).utc().format("YYYY-MM-DD")}
        </a>
      ),
    },
    {
      title: "Document Type",
      dataIndex: "document_type",
      key: "document_type",
      render: (text, row) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>{text}</a>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, row) => (
        <a style={{ fontSize: 15, cursor: "unset" }}>{text}</a>
      ),
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (text, row) => (
        <a
          // href={`${config.assetUrl}/${row.file}`} target="_blank" rel="noopener noreferrer"
          onClick={(e) => openFile(row.file)}
        >
          <img style={{ width: 30, marginLeft: 8 }} src={IMG01} />
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "",
      render: (data) => (
        <Button
          className={style.unshareBtn}
          // loading={unshareLoader && data.id}
          onClick={async () => {
            unshare(data);
          }}

        >
          Unshare
        </Button>
      ),
    },
  ];

  const openFile = async (file) => {
    const url = await s3GetPrivate(file);

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const getDoctors = async () => {

    let logBody = {
      message: `User - ${user?.sub} - Fetch All Doctor - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    console.log("ok");
    try {
      const doctorData = await UPDATEDGET(`employee/doctors?type=all`);
      console.log(doctorData, "All Doctor List");
      setdoctorList(doctorData.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDoctors();
    getVitalsHistory("bp");
  }, []);

  useEffect(() => {
    console.log("useEffect")
    if (clinicId) {
      getUserFamilyMember();
      getHealthHistory();
      getUploadedDocument();
      getSharedDocument();
    }
  }, [clinicId, selFamilyMember]);

  const getUploadedDocument = async () => {

    let logBody = {
      message: `User - ${user?.sub} - Fetch Uploaded Document - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    let patientID;
    if (userType == "familyMember") {
      patientID = familyMemeberData?.familymemberid;
    } else {
      // patientID = userData.id;
      patientID = selFamilyMember
    }

    try {
      const data = await UPDATEDGET(`patient/document?patientid=${patientID}`);
      console.log(data, "Document data");
      if (data?.data.hasOwnProperty("errorMessage")) {
        setAllDocumentHistory([]);
      } else {
        data.data.forEach((element) => {
          element.checked = false;
        });
        setAllDocumentHistory(data?.data);
        console.log(data, "Document data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getPreSignedUrl = (path,e) => {
  // 	console.log(path,"pathhhh");
  // 	e.preventDefault();
  // 	try {
  // 		let body = {
  // 			file : path
  // 		}
  // 		UPDATEDPOST('getfilefrombucket', body).then((data) => {
  // 			console.log(data?.data?.url,"pre signed data");
  // 			const newWindow = window.open(data?.data?.url, '_blank', 'noopener,noreferrer')
  // 			if (newWindow) newWindow.opener = null
  // 		})
  // 	} catch (error) {
  // 		console.log('error: ', error)
  // 		notification.warning({
  // 			message: error.message,
  // 		})
  // 	}
  // }

  const getSharedDocument = async () => {

    let logBody = {
      message: `User - ${user?.sub} - Fetch Shared Document - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    let patientID;
    if (userType == "familyMember") {
      patientID = familyMemeberData?.familymemberid;
    } else {
      // patientID = userData.id;
      patientID = selFamilyMember
    }

    console.log(patientID, "patientIDpatientID");
    try {
      const data = await UPDATEDGET(
        `patient/shareddocument?patient_id=${patientID}`
      );
      console.log(data, "Shared Document data");
      if (data?.data.hasOwnProperty("errorMessage")) {
        setAllSharedDocuments([]);
      } else {
        setAllSharedDocuments(data?.data || []);
        console.log(data, "Document data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVitalsHistory = async (vital) => {
    // setDataSetAll([]);
    setBloodPressureData([]);
    setPulseRateData([]);
    setOxygenData([]);
    setTempratureData([]);
    setRespiratoryData([]);
    setGlucoseData([]);

    let logBody = {
      message: `User - ${user?.sub} - Get Vitals History - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    let data = {
      id:
        userType == "familyMember"
          ? familyMemeberData?.familymemberid
          : userData.id,
      vital: "all",
      timestamps: moment().add(-1, "months").format("x"),
    };

    await UPDATEDPOST("patient/vitals/history/" + data.id, data).then(
      (res) => {
        console.log(res, "success vitals/history");
        if (res?.data?.hasOwnProperty("errorMessage")) {
          // setDataSetAll([]);
          setBloodPressureData([]);
          setPulseRateData([]);
          setOxygenData([]);
          setTempratureData([]);
          setRespiratoryData([]);
          setGlucoseData([]);
        } else {
          console.log(res?.data, "MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM");

          res?.data.forEach((ele, index) => {
            var timeStamp = parseInt(ele?.timestamps);
            let datee = moment(timeStamp).format("DD-MMM-YYYY");
            let dateeTime = moment(timeStamp).format("DD-MMM-YYYY hh:mm A");
            ele.date = datee;
            ele.dateeTime = dateeTime;

            console.log(ele, "ELE");

            if (res?.data?.length - 1 == index) {
              let vitals = _.chain(res?.data)
                .groupBy("vital")
                .map((value: any, key) => ({ vital: key, items: value }))
                .value();
              console.log(vitals, "MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM");

              vitals.forEach((ele) => {
                if (ele.vital == "bp") {
                  let newlatestData = ele.items.sort(function (a, b) {
                    return (
                      new Date(a.dateeTime).getTime() -
                      new Date(b.dateeTime).getTime()
                    );
                  });
                  setBloodPressureData(newlatestData);
                }
                if (ele.vital == "heartrate") {
                  let newlatestData = ele.items.sort(function (a, b) {
                    return (
                      new Date(a.dateeTime).getTime() -
                      new Date(b.dateeTime).getTime()
                    );
                  });
                  setPulseRateData(newlatestData);
                }
                if (ele.vital == "spo2") {
                  let newlatestData = ele.items.sort(function (a, b) {
                    return (
                      new Date(a.dateeTime).getTime() -
                      new Date(b.dateeTime).getTime()
                    );
                  });
                  setOxygenData(newlatestData);
                }
                if (ele.vital == "temp") {
                  let newlatestData = ele.items.sort(function (a, b) {
                    return (
                      new Date(a.dateeTime).getTime() -
                      new Date(b.dateeTime).getTime()
                    );
                  });
                  setTempratureData(newlatestData);
                }
                if (ele.vital == "respiratory") {
                  let newlatestData = ele.items.sort(function (a, b) {
                    return (
                      new Date(a.dateeTime).getTime() -
                      new Date(b.dateeTime).getTime()
                    );
                  });
                  setRespiratoryData(newlatestData);
                }
                if (ele.vital == "glucose") {
                  let newlatestData = ele.items.sort(function (a, b) {
                    return (
                      new Date(a.dateeTime).getTime() -
                      new Date(b.dateeTime).getTime()
                    );
                  });
                  setGlucoseData(newlatestData);
                }
              });
            }
          });
        }
      },
      (err) => {
        console.log(err, "Error in get Vital history");
      }
    );
  };

  const getUserFamilyMember = async () => {

    let logBody = {
      message: `User - ${user?.sub} - Fetch Family Members - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    try {
      const {
        data: {
          body: { FamilyMembers },
        },
      } = await UPDATEDGET(`userfamilymembers?id=${userData?.id}`);
      console.log(FamilyMembers, "FamilyMembers");
      if (FamilyMembers) {
        setFamilyMembers(FamilyMembers);
      } else {
        setFamilyMembers([]);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  function extractFilenameFromUrl(url) {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const filename = pathname.substring(pathname.lastIndexOf("/") + 1);
    return filename;
  }

  const getHealthHistory = async () => {

    let logBody = {
      message: `User - ${user?.sub} - Fetch Health - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    console.log(userType, "userTypeuserTypeuserTypeuserType");
    if (userType == "familyMember") {
      try {
        const {
          data: {
            data: { Item },
          },
        } = await UPDATEDGET(
          `healthhistory/${60}/${familyMemeberData?.familymemberid}`
        );
        console.log(
          Item?.consentForm?.consentForm,
          "health historyuy family member data dataaa"
        );
        // setImage(Item?.consentForm?.consentForm?.signatureUrl);

        const urll = await extractFilenameFromUrl(
          Item?.consentForm?.consentForm?.signatureUrl
        );

        const url = await s3GetPrivate(urll);
        setImage(url);

        setHealthHistoryModule(Item);
      } catch (err) {
        console.log("err: ", err);
      }
    } else {
      try {
        console.log("selectedFamilyMember", selFamilyMember)
        const {
          data: {
            data: { Item },
          },
        } = await UPDATEDGET(`healthhistory/${60}/${selFamilyMember}`);
        console.log(Item?.consentForm, "health historyuy dataaa");

        const urll = await extractFilenameFromUrl(
          Item?.consentForm?.consentForm?.signatureUrl
        );

        console.log(urll!, "urllurllurllurllurll");

        const url = await s3GetPrivate(urll!);
        console.log(url!, "urlurlurlurlurlurlurlurlurlurlurlurl");
        setImage(url!);
        console.log("item", Item)
        setHealthHistoryModule(Item);
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  const saveDetail = () => {
    if (userType == "familyMember") {
      const patientId = familyMemeberData?.familymemberid;
      const healthHistory = {
        clinicId,
        patientId,
        createdBy: "abcc",
        createdById: patientId,
        ...selectedPayload,
      };
      console.log(
        "health History for family member Module",
        healthHistoryModule
      );
      const mergeHealthHistory = Object.assign(
        {},
        healthHistoryModule,
        healthHistory
      );
      setHealthHistoryModule(mergeHealthHistory);
    } else {
      const patientId = userData?.id;
      const healthHistory = {
        clinicId,
        patientId,
        createdBy: "abcc",
        createdById: patientId,
        ...selectedPayload,
      };
      console.log("health History Module", healthHistoryModule);
      const mergeHealthHistory = Object.assign(
        {},
        healthHistoryModule,
        healthHistory
      );
      setHealthHistoryModule(mergeHealthHistory);
    }

    setOpenModal(false);
    setCurrent(current + 1);
    setImage(null);
  };

  const onCancel = () => {
    setOpenModal(false);
    setCurrent(current + 1);
  };

  const addDocument = () => {
    setIsUploadModalVisible(true);
  };

  const shareWith = async () => {

    let logBody = {
      message: `User - ${user?.sub} - Share Document with - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    console.log("Share with me", selectedDocuments);
    console.log("multipleDoctorShare", multipleDoctorShare);
    try {
      setshareLoader(true);
      const requestBody = {
        doctor_id: multipleDoctorShare,
        document_id: selectedDocuments,
        // patient_id: userData.id,
        patient_id: selFamilyMember,
        status: "1",
      };
      console.log(requestBody);
      await UPDATEDPOST(`patient/sharedocument`, requestBody).then((data) => {
        console.log(data, "Data === ");
        if (data?.status == 200) {
          setshareLoader(false);
          setSelectedDocuments([]);
          setSelectedDoctor(null);
          setSelectPatientName("");
          setName([]);
          setMultipleDoctorShare([]);

          notification.success({
            message: "Document shared with selected doctor",
          });
          getUploadedDocument();
          getSharedDocument();
          setIsShareModalVisible(false);
        }
      });
    } catch (error) {
      setshareLoader(false);
      console.log(error);
      setIsShareModalVisible(false);
    }
  };

  const handleCancelUpload = () => {
    setIsUploadModalVisible(false);
  };
  const handleCancelShare = () => {
    setIsShareModalVisible(false);
    setSelectedDoctor(null);
    setMultipleDoctorShare([]);
  }

  const onFinishUpload = async (values) => {
    console.log(values, "all values ahiya avyu");
    console.log(uploadedFilePath, "uploaded file path");

    if (uploadedFilePath != null) {
      setBtnLoader(true);
      const data = {
        // userId: userData?.id,
        userId: selFamilyMember,
        type: uploadedFilePath.type,
        fileName: `documents/${uuid()}_` + uploadedFilePath.name,
      };
      setLoading(true);
      // console.log("data: ", data);
      // console.log(data.fileName, uploadedFilePath, "** file dataaaa");

      try {
        // let data = {
        //   location : "documents",
        //   // fileName : `${uuid()}_` + uploadedFilePath.name,
        //   fileName : uploadedFilePath.name,
        //   file : uploadedFilePath
        // }
        console.log("try ma avyu", data);
        const uploadedDocument = await s3Upload(
          data.fileName,
          uploadedFilePath
        );
        // const uploadedDocument = await UPDATEDFILEUPLOAD("upload",data);

        console.log("Upload ma avyu", uploadedDocument);

        if (uploadedDocument) {
          console.log(uploadedDocument, "uploadedDocument");
          // let d = `${config.assetUrl}/${uploadedDocument}`;
          // let d = `${config.assetUrl}/${uploadedDocument?.Location}`;
          // console.log("d: ", d);

          const docData = {
            date: moment().format("YYYY-MM-DD"),
            document_type: values?.doctype,
            // userid: userData?.id,
            userid: selFamilyMember,
            description: values?.description,
            // "file": uploadedDocument?.data?.file,
            file: uploadedDocument,
            uploaded_for: selFamilyMember,
          };

          let logBody = {
            message: `User - ${user?.sub} - Upload patient document - Medical Details Page`,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${user?.sub} - Medical Details Page`,
          }
      
          logEvents(logBody);

          try {
            const res = UPDATEDPOST("patient/uploaddocument", docData);
            console.log(res, "Upload document cretion");
            notification.success({
              message: "Document uploaded succesfully",
            });
            setBtnLoader(false);
            form.resetFields();
            setIsUploadModalVisible(false);

            setTimeout(() => {
              getUploadedDocument();
            }, 300);
          } catch (err) {
            setBtnLoader(false);
            console.log("error", err);
          }
        }
      } catch (err) {
        setBtnLoader(false);
        console.log("err: ", err);
      }
    } else {
      notification.error({
        message: "Please Upload File First.",
      });
    }
  };

  const addPatientHealthHistory = async (key, payload) => {
    console.log("helllooo", payload, key);
    delete payload.values;

    if (key == "Consent form") {
      setOpenModal(false);

      if (image != null && image != "") {
        setErrorSignature(false);
        if (userType == "familyMember") {
          // const clinicId = 60;
          const patientId = familyMemeberData?.familymemberid;
          const healthHistory = {
            clinicId,
            patientId,
            createdBy: "abcc",
            createdById: patientId,
            ...payload,
          };
          console.log(
            "health History for family member Module",
            healthHistoryModule
          );
          const mergeHealthHistory = Object.assign(
            {},
            healthHistoryModule,
            healthHistory
          );
          setHealthHistoryModule(mergeHealthHistory);
          saveMedicalHistory(mergeHealthHistory, key);
        } else {
          // const patientId = userData?.id;
          const patientId = selFamilyMember;
          const healthHistory = {
            clinicId,
            patientId,
            createdBy: "abcd",
            createdById: patientId,
            ...payload,
          };
          console.log("health History Module", healthHistoryModule);
          const mergeHealthHistory = Object.assign(
            {},
            healthHistoryModule,
            healthHistory
          );
          setHealthHistoryModule(mergeHealthHistory);
          saveMedicalHistory(mergeHealthHistory, key);
        }
      } else {
        setErrorSignature(true);
      }
    } else {
      setSelectedPayload(payload);
      setSelectedKey(key);

      if (key == "Medical Health History") {
        console.log(
          healthHistoryModule?.medicalHistory,
          "healthHistoryModule?.medicalHistory"
        );
        if (healthHistoryModule?.medicalHistory != undefined) {
          if (
            JSON.stringify(payload?.medicalHistory) ===
            JSON.stringify(healthHistoryModule?.medicalHistory)
          ) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true);
            }
          }
        } else {
          console.log(PATIENT_DATA?.medicalHistory, "dfjksafaff");
          console.log(payload?.medicalHistory);
          if (
            JSON.stringify(payload?.medicalHistory) ===
            JSON.stringify(PATIENT_DATA?.medicalHistory)
          ) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true);
            }
          }
        }
      } else if (key == "Personal Health History") {
        console.log("avv", payload?.personalHealthHistory);
        console.log("avv", healthHistoryModule?.personalHealthHistory);
        if (healthHistoryModule?.personalHealthHistory != undefined) {
          if (
            JSON.stringify(payload?.personalHealthHistory) ===
            JSON.stringify(healthHistoryModule?.personalHealthHistory)
          ) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true);
            }
          }
        } else {
          if (
            JSON.stringify(payload?.personalHealthHistory) ===
            JSON.stringify(PERSONAL_HEALTH_DATA?.personalHealthHistory)
          ) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true);
            }
          }
        }
      } else if (key == "Family Health History") {
        if (healthHistoryModule?.familyHealthHistory != undefined) {
          if (
            JSON.stringify(payload?.familyHealthHistory) ===
            JSON.stringify(healthHistoryModule?.familyHealthHistory)
          ) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true);
            }
          }
        } else {
          if (
            JSON.stringify(payload?.familyHealthHistory) ===
            JSON.stringify(FAMILY_HEALTH_DATA?.familyHealthHistory)
          ) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true);
            }
          }
        }
      } else if (key == "Other Problems History") {
        if (healthHistoryModule?.otherProblemsHistory != undefined) {
          if (
            JSON.stringify(payload?.otherProblemsHistory) ===
            JSON.stringify(healthHistoryModule?.otherProblemsHistory)
          ) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true);
            }
          }
        } else {
          if (
            JSON.stringify(payload?.otherProblemsHistory) ===
            JSON.stringify(OTHER_HEALTH_DATA?.otherProblemsHistory)
          ) {
            setCurrent(current + 1);
          } else {
            if (!openModal) {
              setOpenModal(true);
            }
          }
        }
      }
    }
  };

  const saveMedicalHistory = async (mergeHealthHistory, key) => {

    let logBody = {
      message: `User - ${user?.sub} - Save Medical History - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    setOpenModal(false);
    try {
      await UPDATEDPOST(`healthhistory/${clinicId}`, mergeHealthHistory);

      if (current === 4) {
        history.push("/");
      } else {
        setCurrent(current + 1);
      }
      if (current < 4) {
        notification.success({ message: `${key} added successfully` });
      } else {
        notification.success({
          message: `Medical histroy successfully submitted`,
        });
      }
    } catch (err) {
      console.log("err: ", err);
    }
    // }
  };

  const tabChange = (key) => {
    if (key == 1) {
      // setEventWorkShopList(futureEvents);
    }
    if (key == 2) {
      // setEventWorkShopList(pastEvents);
    }
  };

  useEffect(() => {
    
   

    saveWhileUrlChange();
  }, [canvass]);

  const saveWhileUrlChange = async () => {
    let localImages = [];
    for (let c of canvass) {
      localImages.push(c?.toDataURL());
    }

    if (localImages.length != 0) {
      const dataUrl = localImages[1];
      console.log("saved data", dataUrl);
      // const name = `userSignature/${uuid()}.` + `png`;
      const name = `${uuid()}.` + `png`;
      console.log("name::", name);
      let file: any;
      const arr = dataUrl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      file = new Blob([u8arr], { type: mime });
      console.log("......file", file);

      const data = {
        userId: 148,
        type: mime,
        fileName: name,
        formId: "ConsentForm",
      };
      setLoading(true);
      // console.log("data: ", data);
      // console.log(data.fileName, file, "** file dataaaa");

      try {
        const uplodadedImageKey = await s3Upload(data.fileName, file);
        // let data = {
        //   location : "userSignature",
        //   fileName : name,
        //   file : file,
        //   type: file.type,
        // }

        // const uplodadedImageKey = await UPDATEDFILEUPLOAD('upload',data);
        console.log(uplodadedImageKey, "uplodadedImageKey");
        if (uplodadedImageKey) {
          setEdit(false);
          // setSignatureUrl(uplodadedImageKey?.data?.file);
          setSignatureUrl(uplodadedImageKey);
          console.log(uplodadedImageKey, "check img key");

          const url = await s3GetPrivate(uplodadedImageKey);
          setImage(url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log("err: ", err);
      }
    }
  };

  const _save = async () => {
    console.log("_save", document.getElementsByTagName("canvas"));
    setCanvass(document.getElementsByTagName("canvas"));
    saveWhileUrlChange();
    setEdit(true);
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  function cancel(e) {
    console.log(e);
  }

  useEffect(() => {

    console.log(searchTerm);
    setSelectPatientName(searchTerm);
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      if (searchTerm !== "") {

        let logBody = {
          message: `User - ${user?.sub} -Search Doctor - Medical Details Page`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${user?.sub} - Medical Details Page`,
        }
    
        logEvents(logBody);

        try {
          UPDATEDGET("searchuser?searchstr=" + searchTerm + "&type=DOC").then(
            (data) => {
              if (data?.data?.body?.message != "No Result Found") {
                if (data?.data != null) {
                  const filterData = data?.data;
                  console.log(filterData, "---");

                  if (filterData.hasOwnProperty("errorMessage")) {
                    setName([]);
                  } else {
                    const mapData = filterData?.map((el) => {
                      el.name = el.FirstName + " " + el.LastName;
                      el.id = el.id;
                      return el;
                    });
                    setName(mapData);
                  }
                }
              }
            }
          );
        } catch (error) {
          notification.warning({
            message: error.message,
          });
        }
      } else {
        setName([]);
      }
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const selectName = (event) => {
    console.log(event, "event click thayu");
    setMultipleDoctorShare((multipleDoctorShare) => [
      ...multipleDoctorShare,
      event,
    ]);
    setSelectPatientName("");
    setSelectedDoctor(event.companyemployeeid);
    setName([]);
  };

  const removeDoctorFromChip = (dataa) => {
    console.log(dataa, "datatatatatyatat");
    setMultipleDoctorShare(
      multipleDoctorShare.filter((item) => item.id !== dataa.id)
    );
  };

  const printDocument = () => {
    setPdfLoader(true);
    const input = document.getElementById("medicalHistoryCapture");
    html2canvas(input, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);

      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm", "a4");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("medical-summary.pdf");
      setPdfLoader(false);
    });
  };

  const onChangeAllowHistory = async (event) => {

    let logBody = {
      message: `User - ${user?.sub} - Update Patient Profile - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    console.log(event, "ON CHNAGE");
    try {
      UPDATEDPUT(`user/patient/profile/${userData.cognitoid}`, {
        Address1: userData?.Address1,
        City: userData?.City,
        Country: userData?.Country,
        DOB: userData?.DOB,
        Email: userData?.Email,
        FirstName: userData?.FirstName,
        Insurancesvcnum: userData?.Insurancesvcnum,
        Languages: userData?.Languages,
        LastName: userData?.LastName,
        MiddleName: userData?.MiddleName,
        PostalCode: userData?.PostalCode,
        State: userData?.State,
        familyDoctor: userData?.familyDoctor,
        gender: userData?.gender,
        insuranceVersionCode: userData?.insuranceVersionCode,
        name: userData?.name,
        phoneNumber: userData?.phoneNumber,
        medconcent: event == true ? 1 : 0,
      }).then(() => {
        getUserDetails();
        // dispatch(setProfileComplate("true"));
        notification.success({
          message: "Your Data Successfully Added",
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getUserDetails = async () => {

    let logBody = {
      message: `User - ${user?.sub} - Fetch User Details - Medical Details Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Medical Details Page`,
    }

    logEvents(logBody);

    try {
      const userInfo = await UPDATEDGET(`user?cognitoid=${userData.cognitoid}`);
      console.log(userInfo, "update user");
      console.log(userInfo.data?.body, "update user");
      let ud;
      ud = userInfo.data?.body;
      ud.userId = ud?.cognitoid;
      ud.sub = ud?.cognitoid;
      dispatch(setUser(ud));
    } catch (error) {
      console.log(error);
    }
  };





  return (
    <div>
      <TopBar onShow={showDrawer} />
      <div>
        <div className="content" style={{ backgroundColor: "#f1f5f9" }}>
          <div className={style.container}>
            <div className="row" style={{ paddingBottom: "38px" }}>
              <div
                className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0 `}
              >
                <DashboardSidebar />
              </div>
              <Drawer
                closable={false}
                width={300}
                placement="left"
                onClose={onClose}
                visible={visible}
                style={{ paddingTop: 0 }}
              >
                <div className="widget-profile pro-widget-content pt-0 pb-4">
                  <div className="profile-info-widget">
                    <img src="../Images/logo.png" height="50" alt="" />
                  </div>
                </div>
                <DashboardSidebar />
              </Drawer>

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
                <PageHeader
                  items={[
                    { label: "Dashboard", link: "/dashboard" },
                    { label: "Medical History", link: "/medicaldDetails" },
                    { label: breadcrumbTitle, link: "" },
                  ]}
                  pageTitle="Medical History"
                />

                <Card className={style.Sidebar_div}>
                  <Steps
                    current={current}
                    onChange={onChange}
                    responsive={true}
                    size="small"
                  >
                    <Step title="Medical History" />
                    <Step title="Personal Health" />
                    <Step title="Family Health" />
                    <Step title="Other Problems" />
                    <Step title="Upload Report" />
                    <Step title="Medical Summary" />
                    <Step title="Consent Form" />
                  </Steps>
                  <div className={`row mt-4 ${style.tabHeader}`} style={{ marginLeft: '20px', marginRight: '20px' }} >
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 align-items-center">

                      <Select
                        showSearch
                        placeholder="Select Patient"
                        size="large"
                        style={{ width: "100%" }}
                        defaultActiveFirstOption
                        onChange={(e) => {
                          console.log("EEE", e);
                          setSelFamilyMember(e)
                          // console.log("selectedFam",selFamilyMember)
                        }}
                        value={selFamilyMember}
                      >
                        <Option
                          value={user?.id}
                          key={user?.id}
                        >
                          {user?.FirstName} {user?.LastName}
                        </Option>
                        {familyMembers ? (
                          <>
                            {familyMembers?.length != 0 ? (
                              <>
                                {familyMembers?.map((itemm, index) => (
                                  <>
                                    {itemm.userName != null ? (
                                      <Option
                                        key={itemm?.familymemberid}
                                        value={itemm?.familymemberid}
                                      >
                                        {itemm?.userName}
                                      </Option>
                                    ) : null}
                                  </>
                                ))}
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </Select>
                    </div>
                    <div className="col-sm-6 col-md-8 col-lg-8 col-xl-8 d-flex align-items-center justify-content-end" >
                      <Text style={{ marginRight: 20,fontSize:16 }}>
                        Allow medical history for doctors
                      </Text>
                      <div className="d-flex" >
                        <div

                          className={`${switchStatus ? style.greenSwitchBtn : style.borderedSwitchBtn}`}
                          onClick={() => {
                            setSwitchStatus(true);
                            onChangeAllowHistory(true);
                          }}
                        >
                          Yes
                        </div>
                        <div
                          className={`${switchStatus ? style.borderedSwitchBtn : style.greenSwitchBtn}`}
                          onClick={() => {
                            setSwitchStatus(false);
                            onChangeAllowHistory(false);
                          }}
                        >No</div>
                      </div>
                    </div>
                  </div>



                  <>
                    {current === 0 ? (
                      <>
                        <Card
                          className="mt-2 mb-0 pb-0"
                        >
                          <div className="col-sm-12 col-md-12">
                            <div className="row">
                              {/* formik start */}

                              <Formik
                                enableReinitialize
                                initialValues={
                                  healthHistoryModule?.medicalHistory
                                    ? {
                                      medicalHistory:
                                        healthHistoryModule?.medicalHistory,
                                    }
                                    : {
                                      medicalHistory:
                                        PATIENT_DATA.medicalHistory,
                                    }
                                }
                                onSubmit={(values) => {
                                  values.medicalHistory[5].signatureUrl = `${signatureUrl}`;
                                  addPatientHealthHistory(
                                    "Medical Health History",
                                    values
                                  );
                                  console.log(signatureUrl, "sign 1");
                                }}
                                render={({ values, setFieldValue }) => {
                                  return (
                                    <Form>
                                      <FieldArray
                                        name="medicalHistory"
                                        render={(arrayHelpers) => (
                                          <>
                                            <h5
                                              className={
                                                style.diseasesHeader
                                              }
                                            >
                                              Childhood Diseases
                                            </h5>
                                            {console.log("values---", values)}
                                            {values?.medicalHistory?.map(
                                              (renderTemplate, index1) => {
                                                if (
                                                  renderTemplate.type ===
                                                  "checkboxes" &&
                                                  index1 ===
                                                  renderTemplate.index
                                                ) {
                                                  return (
                                                    <div key={Math.random()}>

                                                      <FieldArray
                                                        name={`medicalHistory[${index1}].checkboxes`}
                                                        render={(
                                                          arrayHelpers2
                                                        ) => (
                                                          <>
                                                            <div className="row">
                                                              {renderTemplate.checkboxes.map(
                                                                (
                                                                  checkBoxes,
                                                                  index2
                                                                ) => (
                                                                  <>

                                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                      <div
                                                                        className={
                                                                          checkBoxes.answer ===
                                                                            "yes"
                                                                            ? style.static_active_card
                                                                            : style.static_card
                                                                        }
                                                                      >
                                                                        <Checkbox
                                                                          value={
                                                                            checkBoxes.label
                                                                          }
                                                                          checked={
                                                                            checkBoxes.answer ===
                                                                              "yes"
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            console.log(
                                                                              e,
                                                                              "ee"
                                                                            );
                                                                            arrayHelpers2.replace(
                                                                              index2,
                                                                              {
                                                                                ...checkBoxes,
                                                                                answer:
                                                                                  e
                                                                                    .target
                                                                                    .checked ==
                                                                                    true
                                                                                    ? "yes"
                                                                                    : "no",
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <Text
                                                                            className={
                                                                              style.textNormal
                                                                            }
                                                                          >
                                                                            {
                                                                              checkBoxes.label
                                                                            }
                                                                          </Text>
                                                                        </Checkbox>
                                                                      </div>
                                                                    </div>
                                                                  </>

                                                                )
                                                              )}
                                                            </div>
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  );
                                                }
                                                if (
                                                  renderTemplate.type ===
                                                  "textArea" &&
                                                  index1 === 1
                                                ) {
                                                  return (
                                                    <div key={Math.random()}>
                                                      <div className="col-12 ml-n4 mt-3">
                                                        <div
                                                          className="row"
                                                          style={{
                                                            paddingLeft: "10px",
                                                          }}
                                                        >
                                                          <div className="col-12">
                                                            <Text
                                                              className={
                                                                style.diseasesHeader
                                                              }
                                                            >
                                                              {renderTemplate
                                                                .textArea
                                                                .label + " ? "}
                                                            </Text>
                                                          </div>
                                                          <div className="col-12 mt-2">
                                                            <TextArea
                                                              className={
                                                                style.textArea
                                                              }
                                                              defaultValue={
                                                                renderTemplate
                                                                  .textArea
                                                                  .answer
                                                              }
                                                              rows={4}
                                                              onBlur={(e) => {
                                                                const modifyTextArea =
                                                                {
                                                                  ...renderTemplate,
                                                                };
                                                                modifyTextArea.textArea.answer =
                                                                  e.target.value;
                                                                arrayHelpers.replace(
                                                                  index1,
                                                                  modifyTextArea
                                                                );

                                                                console.log(
                                                                  modifyTextArea,
                                                                  "andar"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                if (
                                                  renderTemplate.type ===
                                                  "radio" &&
                                                  index1 === 2
                                                ) {
                                                  return (
                                                    <>
                                                      <h5 className={`${style.diseasesHeader} mt-4`}> Other </h5>
                                                      <div
                                                        className="row"
                                                        key={Math.random()}
                                                      >
                                                        <FieldArray
                                                          name={`medicalHistory[${index1}].radio`}
                                                          render={(
                                                            arrayHelpers3
                                                          ) => (
                                                            <>
                                                              {renderTemplate.radio.map(
                                                                (
                                                                  radio,
                                                                  index4
                                                                ) => (
                                                                  <>

                                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                      <div
                                                                        className={
                                                                          radio.answer ===
                                                                            "yes"
                                                                            ? style.static_active_card
                                                                            : style.static_card
                                                                        }
                                                                      >
                                                                        <Checkbox
                                                                          value={
                                                                            radio.label
                                                                          }
                                                                          checked={
                                                                            radio.answer ===
                                                                              "yes"
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            console.log(
                                                                              e,
                                                                              "ee"
                                                                            );
                                                                            arrayHelpers3.replace(
                                                                              index4,
                                                                              {
                                                                                ...radio,
                                                                                answer:
                                                                                  e
                                                                                    .target
                                                                                    .checked ==
                                                                                    true
                                                                                    ? "yes"
                                                                                    : "no",
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <Text
                                                                            className={
                                                                              style.textNormal
                                                                            }
                                                                          >
                                                                            {
                                                                              radio.label
                                                                            }
                                                                          </Text>
                                                                        </Checkbox>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                )
                                                              )}
                                                            </>
                                                          )}
                                                        />
                                                      </div>
                                                    </>

                                                  );
                                                }
                                                if (
                                                  renderTemplate.type ===
                                                  "checkboxQuestions" &&
                                                  index1 === 3
                                                ) {
                                                  return (
                                                    <div
                                                      className="row"
                                                      key={Math.random()}
                                                    >
                                                      {console.log(
                                                        "I am checkbox Questions"
                                                      )}
                                                      {renderTemplate.checkboxQuestions.map(
                                                        (checkboxLabel) => (
                                                          <div
                                                            key={Math.random()}
                                                          >
                                                            <div className="col-md-3 mt-5">
                                                              <Text>
                                                                {checkboxLabel.label +
                                                                  "Hello"}
                                                              </Text>
                                                            </div>
                                                            <div className="col-md-3 mt-5">
                                                              <Radio.Group className="ml-5">
                                                                <Radio.Button value="large">
                                                                  Yes
                                                                </Radio.Button>
                                                                <Radio.Button value="default">
                                                                  No
                                                                </Radio.Button>
                                                              </Radio.Group>
                                                            </div>
                                                            <hr />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  );
                                                }

                                                if (
                                                  renderTemplate.type ===
                                                  "textArea" &&
                                                  index1 === 4
                                                ) {
                                                  return (
                                                    <div
                                                      className="row mt-4"
                                                      key={Math.random()}
                                                    >
                                                      <div className="col-12">
                                                        <Text
                                                          className={
                                                            style.diseasesHeader
                                                          }
                                                        >
                                                          {
                                                            renderTemplate
                                                              .textArea.label
                                                          }
                                                        </Text>
                                                      </div>
                                                      <div className="col-12 mt-2">
                                                        <TextArea
                                                          className={
                                                            style.textArea
                                                          }
                                                          defaultValue={
                                                            renderTemplate
                                                              .textArea.answer
                                                          }
                                                          rows={4}
                                                          onBlur={(e) => {
                                                            const modifyTextArea =
                                                            {
                                                              ...renderTemplate,
                                                            };
                                                            modifyTextArea.textArea.answer =
                                                              e.target.value;
                                                            arrayHelpers.replace(
                                                              index1,
                                                              modifyTextArea
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                return null;
                                              }
                                            )}
                                          </>
                                        )}
                                      />

                                      {/* <div className=" mt-4 rounded">
                                      <Text className={style.textNormal}>
                                        Signature Of Responsible Party
                                      </Text>

                                      {(image == "" ||
                                        image == null ||
                                        image == false) &&
                                        !hideHead ? (
                                        <div>
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              width: "250px",
                                            }}
                                            className="ml-0 border border-primary border-1 text-left"
                                          >
                                            <div
                                              // id={`unique500`}
                                              style={{
                                                overflow: "hidden",
                                                width: "300px",
                                              }}
                                            >
                                              <CanvasDraw
                                                id="unique500"
                                                width="250px"
                                                ref={_sketch}
                                                brushColor="black"
                                                brushRadius={2}
                                                lazyRadius={2}
                                                canvasWidth={250}
                                                canvasHeight={150}
                                              // done={this.props.done(this.state.key)}
                                              />
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              backgroundColor: "#246ec63b",
                                              width: 250,
                                              padding: 7,
                                            }}
                                          >
                                            <Button
                                              style={{ borderRadius: "100%" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                if (_sketch.current) {
                                                  _sketch.current?.undo();
                                                  // console.log(_sketch.current);
                                                  setCanvass([]);
                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faUndo} />{" "}
                                            </Button>
                                            <div className="float-right">
                                              <Button
                                                style={{ borderRadius: "100%" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  _save();
                                                }}
                                              >
                                                {loading ? (
                                                  <Spin />
                                                ) : (
                                                  <FontAwesomeIcon
                                                    icon={faSave}
                                                  />
                                                )}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              width: "250px",
                                            }}
                                            className="ml-0 border border-primary border-1 text-left"
                                          >
                                            <div
                                              style={{
                                                overflow: "hidden",
                                                width: "250px",
                                                height: "150px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {loading ? (
                                                <Spin />
                                              ) : (
                                                <img
                                                  src={image || ""}
                                                  width=" "
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor: "#246ec63b",
                                              width: 250,
                                              padding: 7,
                                            }}
                                          >
                                            <div>
                                              <Button
                                                style={{ borderRadius: "100%" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  // this.edit()
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faEdit}
                                                />{" "}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row mt-4">
                                        <div className="col-md-3 col-lg-2 mt-1">
                                          <Text>Date:</Text>
                                          { }
                                        </div>
                                        <div className="col-md-5 col-lg-5">
                                          {values?.medicalHistory?.signDate ==
                                            null ? (
                                            <DatePicker
                                              onChange={(date, dateString) => {
                                                values.medicalHistory.signDate =
                                                  moment(dateString).format(
                                                    "x"
                                                  );
                                                setFieldValue(
                                                  `values.medicalHistory.signDate`,
                                                  values
                                                );
                                              }}
                                              style={{ width: "100%" }}
                                            />
                                          ) : (
                                            <DatePicker
                                              onChange={(date, dateString) => {
                                                values.medicalHistory.signDate =
                                                  moment(dateString).format(
                                                    "x"
                                                  );
                                                setFieldValue(
                                                  `values.medicalHistory.signDate`,
                                                  values
                                                );
                                              }}
                                              defaultValue={moment(
                                                values?.medicalHistory?.signDate
                                              )}
                                              style={{ width: "100%" }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div> */}

                                      <div className="row pb-5 align-items-center justify-content-center mt-5">
                                        <Button
                                          size="large"
                                          style={{ width: 200 }}
                                          className={`mt-4 ml-2 mr-3 ${style.borderedBtn}`}
                                          onClick={() => history.push("/")}
                                        >
                                          Cancel
                                        </Button>
                                        <Button

                                          htmlType="submit"
                                          size="large"
                                          style={{ width: 200 }}
                                          className={`mt-4 ml-n1 ${style.greenBtn}`}
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    </Form>
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Card>

                        {/* <Card
                          title={
                            <Select placeholder="Ben Dove(Male)">
                              <option value="Ben Dove(Male)"></option>
                            </Select>
                          }
                          className="mt-4 mb-0 pb-0"
                          extra={
                            <>
                              <Text style={{ marginRight: 10 }}>
                                Allow medical history for doctors
                              </Text>
                              <Switch
                                defaultChecked={
                                  userData.medconcent == 1 ? true : false
                                }
                                onChange={onChangeAllowHistory}
                              />
                            </>
                          }
                        >
                          <div className="col-sm-12 col-md-12">
                            <div className="row">
                              {/* formik start */}
                        {/* <Formik
                                enableReinitialize
                                initialValues={
                                  healthHistoryModule?.medicalHistory
                                    ? {
                                      medicalHistory:
                                        healthHistoryModule?.medicalHistory,
                                    }
                                    : {
                                      medicalHistory:
                                        PATIENT_DATA.medicalHistory,
                                    }
                                }
                                onSubmit={(values) => {
                                  values.medicalHistory[5].signatureUrl = `${signatureUrl}`;
                                  addPatientHealthHistory(
                                    "Medical Health History",
                                    values
                                  );
                                  console.log(signatureUrl, "sign 1");
                                }}
                                render={({ values, setFieldValue }) => {
                                  return (
                                    <Form>
                                      <FieldArray
                                        name="medicalHistory"
                                        render={(arrayHelpers) => (
                                          <>
                                            {values?.medicalHistory?.map(
                                              (renderTemplate, index1) => {
                                                if (
                                                  renderTemplate.type ===
                                                  "checkboxes" &&
                                                  index1 ===
                                                  renderTemplate.index
                                                ) {
                                                  return (
                                                    <div key={Math.random()}>
                                                      <FieldArray
                                                        name={`medicalHistory[${index1}].checkboxes`}
                                                        render={(
                                                          arrayHelpers2
                                                        ) => (
                                                          <div className="row">
                                                            {renderTemplate.checkboxes.map(
                                                              (
                                                                checkBoxes,
                                                                index2
                                                              ) => (
                                                                <>
                                                                  <div
                                                                    className="col-4 col-sm-6 col-md-2 col-lg-3 mt-3 mb-4"
                                                                    key={Math.random()}
                                                                  >
                                                                    <Text
                                                                      className={
                                                                        // style.text
                                                                        style.textNormal
                                                                      }
                                                                      style={{
                                                                        textTransform:
                                                                          "lowercase",
                                                                      }}
                                                                    >
                                                                      {
                                                                        checkBoxes.label
                                                                      }
                                                                    </Text>
                                                                  </div>

                                                                  <div className="col-8 col-sm-6 col-md-4 col-lg-3 mt-3">
                                                                    <Switch
                                                                      defaultChecked={
                                                                        checkBoxes.answer ===
                                                                          "yes"
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        console.log(
                                                                          e,
                                                                          "ee"
                                                                        );
                                                                        arrayHelpers2.replace(
                                                                          index2,
                                                                          {
                                                                            ...checkBoxes,
                                                                            answer:
                                                                              e ==
                                                                                true
                                                                                ? "yes"
                                                                                : "no",
                                                                          }
                                                                        );
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <hr />
                                                                </>
                                                              )
                                                            )}
                                                          </div>
                                                        )}
                                                      />
                                                    </div>
                                                  );
                                                }
                                                if (
                                                  renderTemplate.type ===
                                                  "textArea" &&
                                                  index1 === 1
                                                ) {
                                                  return (
                                                    <div key={Math.random()}>
                                                      <div className="col-md-12 ml-n4 mt-5">
                                                        <div className="row">
                                                          <div className="col-md-3">
                                                            <Text
                                                              className={
                                                                style.textNormal
                                                              }
                                                            >
                                                              {
                                                                renderTemplate
                                                                  .textArea
                                                                  .label
                                                              }
                                                            </Text>
                                                          </div>
                                                          <div className="col-md-9">
                                                            <TextArea
                                                              className="textArea"
                                                              defaultValue={
                                                                renderTemplate
                                                                  .textArea
                                                                  .answer
                                                              }
                                                              onBlur={(e) => {
                                                                const modifyTextArea =
                                                                {
                                                                  ...renderTemplate,
                                                                };
                                                                modifyTextArea.textArea.answer =
                                                                  e.target.value;
                                                                arrayHelpers.replace(
                                                                  index1,
                                                                  modifyTextArea
                                                                );

                                                                console.log(
                                                                  modifyTextArea,
                                                                  "andar"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                if (
                                                  renderTemplate.type ===
                                                  "radio" &&
                                                  index1 === 2
                                                ) {
                                                  return (
                                                    <div
                                                      className="row"
                                                      key={Math.random()}
                                                    >
                                                      <FieldArray
                                                        name={`medicalHistory[${index1}].radio`}
                                                        render={(
                                                          arrayHelpers3
                                                        ) => (
                                                          <>
                                                            {renderTemplate.radio.map(
                                                              (
                                                                radio,
                                                                index4
                                                              ) => (
                                                                <>
                                                                  <div className="col-6 pt-4 ">
                                                                    <div
                                                                      className="row"
                                                                      key={Math.random()}
                                                                    >
                                                                      <div className="col-6 ">
                                                                        <Text
                                                                          className={`mr-1 ${style.textNormal}`}
                                                                        >
                                                                          {
                                                                            radio.label
                                                                          }
                                                                        </Text>
                                                                      </div>
                                                                      <div className="col-6">
                                                                        <Switch
                                                                          defaultChecked={
                                                                            radio.answer ===
                                                                              "yes"
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpers3.replace(
                                                                              index4,
                                                                              {
                                                                                ...radio,
                                                                                answer:
                                                                                  e ==
                                                                                    true
                                                                                    ? "yes"
                                                                                    : "no",
                                                                              }
                                                                            );
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <hr />
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              )
                                                            )}
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  );
                                                }
                                                if (
                                                  renderTemplate.type ===
                                                  "checkboxQuestions" &&
                                                  index1 === 3
                                                ) {
                                                  return (
                                                    <div
                                                      className="row"
                                                      key={Math.random()}
                                                    >
                                                      {console.log(
                                                        "I am checkbox Questions"
                                                      )}
                                                      {renderTemplate.checkboxQuestions.map(
                                                        (checkboxLabel) => (
                                                          <div
                                                            key={Math.random()}
                                                          >
                                                            <div className="col-md-3 mt-5">
                                                              <Text>
                                                                {
                                                                  checkboxLabel.label
                                                                }
                                                              </Text>
                                                            </div>
                                                            <div className="col-md-3 mt-5">
                                                              <Radio.Group className="ml-5">
                                                                <Radio.Button value="large">
                                                                  Yes
                                                                </Radio.Button>
                                                                <Radio.Button value="default">
                                                                  No
                                                                </Radio.Button>
                                                              </Radio.Group>
                                                            </div>
                                                            <hr />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  );
                                                }

                                                if (
                                                  renderTemplate.type ===
                                                  "textArea" &&
                                                  index1 === 4
                                                ) {
                                                  return (
                                                    <div
                                                      className="row mt-4"
                                                      key={Math.random()}
                                                    >
                                                      <div className="col-md-4">
                                                        <Text
                                                          className={
                                                            style.textNormal
                                                          }
                                                        >
                                                          {
                                                            renderTemplate
                                                              .textArea.label
                                                          }
                                                        </Text>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <TextArea
                                                          className="textArea"
                                                          defaultValue={
                                                            renderTemplate
                                                              .textArea.answer
                                                          }
                                                          onBlur={(e) => {
                                                            const modifyTextArea =
                                                            {
                                                              ...renderTemplate,
                                                            };
                                                            modifyTextArea.textArea.answer =
                                                              e.target.value;
                                                            arrayHelpers.replace(
                                                              index1,
                                                              modifyTextArea
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                return null;
                                              }
                                            )}
                                          </>
                                        )}
                                      />

                                      {/* <div className=" mt-4 rounded">
                                      <Text className={style.textNormal}>
                                        Signature Of Responsible Party
                                      </Text>

                                      {(image == "" ||
                                        image == null ||
                                        image == false) &&
                                        !hideHead ? (
                                        <div>
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              width: "250px",
                                            }}
                                            className="ml-0 border border-primary border-1 text-left"
                                          >
                                            <div
                                              // id={`unique500`}
                                              style={{
                                                overflow: "hidden",
                                                width: "300px",
                                              }}
                                            >
                                              <CanvasDraw
                                                id="unique500"
                                                width="250px"
                                                ref={_sketch}
                                                brushColor="black"
                                                brushRadius={2}
                                                lazyRadius={2}
                                                canvasWidth={250}
                                                canvasHeight={150}
                                              // done={this.props.done(this.state.key)}
                                              />
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              backgroundColor: "#246ec63b",
                                              width: 250,
                                              padding: 7,
                                            }}
                                          >
                                            <Button
                                              style={{ borderRadius: "100%" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                if (_sketch.current) {
                                                  _sketch.current?.undo();
                                                  // console.log(_sketch.current);
                                                  setCanvass([]);
                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faUndo} />{" "}
                                            </Button>
                                            <div className="float-right">
                                              <Button
                                                style={{ borderRadius: "100%" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  _save();
                                                }}
                                              >
                                                {loading ? (
                                                  <Spin />
                                                ) : (
                                                  <FontAwesomeIcon
                                                    icon={faSave}
                                                  />
                                                )}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              width: "250px",
                                            }}
                                            className="ml-0 border border-primary border-1 text-left"
                                          >
                                            <div
                                              style={{
                                                overflow: "hidden",
                                                width: "250px",
                                                height: "150px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {loading ? (
                                                <Spin />
                                              ) : (
                                                <img
                                                  src={image || ""}
                                                  width=" "
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              backgroundColor: "#246ec63b",
                                              width: 250,
                                              padding: 7,
                                            }}
                                          >
                                            <div>
                                              <Button
                                                style={{ borderRadius: "100%" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  // this.edit()
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faEdit}
                                                />{" "}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row mt-4">
                                        <div className="col-md-3 col-lg-2 mt-1">
                                          <Text>Date:</Text>
                                          { }
                                        </div>
                                        <div className="col-md-5 col-lg-5">
                                          {values?.medicalHistory?.signDate ==
                                            null ? (
                                            <DatePicker
                                              onChange={(date, dateString) => {
                                                values.medicalHistory.signDate =
                                                  moment(dateString).format(
                                                    "x"
                                                  );
                                                setFieldValue(
                                                  `values.medicalHistory.signDate`,
                                                  values
                                                );
                                              }}
                                              style={{ width: "100%" }}
                                            />
                                          ) : (
                                            <DatePicker
                                              onChange={(date, dateString) => {
                                                values.medicalHistory.signDate =
                                                  moment(dateString).format(
                                                    "x"
                                                  );
                                                setFieldValue(
                                                  `values.medicalHistory.signDate`,
                                                  values
                                                );
                                              }}
                                              defaultValue={moment(
                                                values?.medicalHistory?.signDate
                                              )}
                                              style={{ width: "100%" }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div> */}
                        {/* <hr />
                                      <div className="row pb-5">
                                        <Button
                                          size="large"
                                          style={{ width: 110 }}
                                          className="mt-4 ml-2 mr-3"
                                          onClick={() => history.push("/")}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          size="large"
                                          style={{ width: 110 }}
                                          className="mt-4 ml-n1"
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    </Form>
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Card>  */}
                      </>
                    ) : current === 1 ? (
                      <>
                        <Card className="mt-1" >
                          <Formik
                            enableReinitialize
                            initialValues={
                              healthHistoryModule?.personalHealthHistory
                                ? {
                                  personalHealthHistory:
                                    healthHistoryModule?.personalHealthHistory,
                                }
                                : // : { personalHealthHistory: PATIENT_DATA.personalHealthHistory }
                                {
                                  personalHealthHistory:
                                    PERSONAL_HEALTH_DATA?.personalHealthHistory,
                                }
                            }
                            onSubmit={(values) => {
                              addPatientHealthHistory(
                                "Personal Health History",
                                values
                              );
                            }}
                            render={({ values, setFieldValue }) => (
                              <Form>
                                <FieldArray
                                  name="personalHealthHistory"
                                  render={(personalHealthHistoryHelper) => (
                                    <div key={Math.random()}>
                                      {values.personalHealthHistory?.map(
                                        (personalHealthHistoryData, index) => {
                                          if (
                                            personalHealthHistoryData.type ===
                                            "checkboxes"
                                          ) {
                                            return (
                                              <div>
                                                <h5
                                                  className={style.diseasesHeader}>
                                                  {
                                                    personalHealthHistoryData.heading
                                                  }
                                                </h5>
                                                <FieldArray
                                                  name={`personalHealthHistory[${index}].checkboxes`}
                                                  render={(arrayHelpers) => (
                                                    <>
                                                      <div className="row">
                                                        {personalHealthHistoryData.checkboxes.map(
                                                          (
                                                            checkboxData,
                                                            checkBoxIndex
                                                          ) => (
                                                            <>
                                                              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                                                <div
                                                                  className={
                                                                    checkboxData.isActive
                                                                      ? style.static_active_card
                                                                      : style.static_card
                                                                  }
                                                                >
                                                                  <Checkbox
                                                                    value={
                                                                      checkboxData.label
                                                                    }
                                                                    checked={
                                                                      checkboxData.isActive
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      if (
                                                                        e.target
                                                                          .checked
                                                                      ) {
                                                                        arrayHelpers.replace(
                                                                          checkBoxIndex,
                                                                          {
                                                                            ...checkboxData,
                                                                            isActive:
                                                                              true,
                                                                          }
                                                                        );
                                                                      } else {
                                                                        arrayHelpers.replace(
                                                                          checkBoxIndex,
                                                                          {
                                                                            ...checkboxData,
                                                                            isActive:
                                                                              false,
                                                                          }
                                                                        );
                                                                      }
                                                                    }}
                                                                  >
                                                                    <Text
                                                                      className={
                                                                        style.textNormal
                                                                      }
                                                                    >
                                                                      {
                                                                        checkboxData.label
                                                                      }
                                                                    </Text>
                                                                  </Checkbox>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )
                                                        )}
                                                      </div>
                                                    </>
                                                  )}
                                                />
                                              </div>
                                            );
                                          }
                                          if (
                                            personalHealthHistoryData.type ===
                                            "checkboxdate"
                                          ) {
                                            return (
                                              <div key={Math.random()}>
                                                <h5 className={`pt-3 ${style.diseasesHeader}`} >
                                                  {
                                                    personalHealthHistoryData.heading
                                                  }{" "}
                                                </h5>
                                                <FieldArray
                                                  name={`personalHealthHistory[${index}].checkboxdate`}
                                                  render={(arrayHelpers1) => (
                                                    <>
                                                      <Row>
                                                        <div className="col-md-12 ml-n3">
                                                          <div className="row align-items-center">
                                                            {personalHealthHistoryData.checkboxdate.map(
                                                              (
                                                                checkboxData,
                                                                checkBoxDateIndex
                                                              ) => (
                                                                <>
                                                                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                    <div className={` row justify-content-between g-2 align-items-center ${checkboxData.isActive
                                                                      ? style.static_active_card
                                                                      : style.static_card}`

                                                                    }
                                                                      style={{ marginLeft: '5px' }}>

                                                                      <div className="col-md-6 col-lg-6 col-sm-6">
                                                                        <Checkbox
                                                                          style={{
                                                                            borderRadius:
                                                                              "0%",
                                                                          }}
                                                                          checked={
                                                                            checkboxData.isActive
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            if (
                                                                              e.target
                                                                                .checked
                                                                            ) {
                                                                              arrayHelpers1.replace(
                                                                                checkBoxDateIndex,
                                                                                {
                                                                                  ...checkboxData,
                                                                                  isActive:
                                                                                    true,
                                                                                }
                                                                              );
                                                                            } else {
                                                                              arrayHelpers1.replace(
                                                                                checkBoxDateIndex,
                                                                                {
                                                                                  ...checkboxData,
                                                                                  isActive:
                                                                                    false,
                                                                                }
                                                                              );
                                                                            }
                                                                          }}
                                                                        >
                                                                          <Text
                                                                            className={
                                                                              style.textNormal
                                                                            }
                                                                          >
                                                                            {
                                                                              checkboxData.label
                                                                            }
                                                                          </Text>
                                                                        </Checkbox>
                                                                      </div>
                                                                      <div className="col-md-6 col-lg-6 col-sm-6">
                                                                        <DatePicker
                                                                          disabledDate={(
                                                                            current
                                                                          ) => {
                                                                            let customDate =
                                                                              moment().format(
                                                                                "YYYY-MM-DD"
                                                                              );
                                                                            return (
                                                                              current &&
                                                                              current >
                                                                              moment(
                                                                                customDate,
                                                                                "YYYY-MM-DD"
                                                                              )
                                                                            );
                                                                          }}
                                                                          value={
                                                                            checkboxData.checkboxDate
                                                                              ? moment(
                                                                                checkboxData.checkboxDate
                                                                              )
                                                                              : null
                                                                          }
                                                                          onChange={(
                                                                            date,
                                                                            dateString
                                                                          ) => {
                                                                            arrayHelpers1.replace(
                                                                              checkBoxDateIndex,
                                                                              {
                                                                                ...checkboxData,
                                                                                checkboxDate:
                                                                                  date,
                                                                              }
                                                                            );
                                                                          }}
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>

                                                                  {/* <div className="col-md-3 mt-3">
                                                                  
                                                                  <Checkbox
                                                                    style={{
                                                                      borderRadius:
                                                                        "0%",
                                                                    }}
                                                                    checked={
                                                                      checkboxData.isActive
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      if (
                                                                        e.target
                                                                          .checked
                                                                      ) {
                                                                        arrayHelpers1.replace(
                                                                          checkBoxDateIndex,
                                                                          {
                                                                            ...checkboxData,
                                                                            isActive:
                                                                              true,
                                                                          }
                                                                        );
                                                                      } else {
                                                                        arrayHelpers1.replace(
                                                                          checkBoxDateIndex,
                                                                          {
                                                                            ...checkboxData,
                                                                            isActive:
                                                                              false,
                                                                          }
                                                                        );
                                                                      }
                                                                    }}
                                                                  >
                                                                    <Text
                                                                      className={
                                                                        style.textNormal
                                                                      }
                                                                    >
                                                                      {
                                                                        checkboxData.label
                                                                      }
                                                                    </Text>
                                                                  </Checkbox>
                                                                </div>
                                                                <div className="col-md-3 mt-3">
                                                                  <DatePicker
                                                                    disabledDate={(
                                                                      current
                                                                    ) => {
                                                                      let customDate =
                                                                        moment().format(
                                                                          "YYYY-MM-DD"
                                                                        );
                                                                      return (
                                                                        current &&
                                                                        current >
                                                                        moment(
                                                                          customDate,
                                                                          "YYYY-MM-DD"
                                                                        )
                                                                      );
                                                                    }}
                                                                    value={
                                                                      checkboxData.checkboxDate
                                                                        ? moment(
                                                                          checkboxData.checkboxDate
                                                                        )
                                                                        : null
                                                                    }
                                                                    onChange={(
                                                                      date,
                                                                      dateString
                                                                    ) => {
                                                                      arrayHelpers1.replace(
                                                                        checkBoxDateIndex,
                                                                        {
                                                                          ...checkboxData,
                                                                          checkboxDate:
                                                                            date,
                                                                        }
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                  />
                                                                </div> */}
                                                                </>
                                                              )
                                                            )}
                                                          </div>
                                                        </div>
                                                      </Row>
                                                    </>
                                                  )}
                                                />
                                              </div>
                                            );
                                          }
                                          if (
                                            personalHealthHistoryData.type ===
                                            "textarea"
                                          ) {
                                            return (
                                              <div key={Math.random()}>
                                                <div className="col-md-12 mt-3 ml-n3">
                                                  <div className="row">
                                                    <h5
                                                      className={`ml-3 ${style.diseasesHeader}`}
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </h5>
                                                    <div className="col-md-12">
                                                      <TextArea
                                                        className={
                                                          style.textArea
                                                        }

                                                        defaultValue={
                                                          personalHealthHistoryData.answer
                                                        }
                                                        rows={4}
                                                        onBlur={(e) => {
                                                          personalHealthHistoryHelper.replace(
                                                            index,
                                                            {
                                                              ...personalHealthHistoryData,
                                                              answer:
                                                                e.target.value,
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          if (
                                            personalHealthHistoryData.type ===
                                            "table" &&
                                            personalHealthHistoryData.uniqueId ===
                                            "surgeriesTable"
                                          ) {
                                            return (
                                              <div key={Math.random()}>
                                                <div className="col-md-12 mt-3 ml-n3">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <h5
                                                        className={`mt-3 ${style.diseasesHeader}`}
                                                      >
                                                        {
                                                          personalHealthHistoryData.heading
                                                        }
                                                      </h5>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <FieldArray
                                                        name={`personalHealthHistory[${index}].table.answer`}
                                                        render={(
                                                          arrayHelpersOfPersonalHealth
                                                        ) => (
                                                          <>
                                                            <table className="table" style={{fontSize:16}}>
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">
                                                                    Year
                                                                  </th>
                                                                  <th scope="col">
                                                                    Reason
                                                                  </th>
                                                                  <th scope="col">
                                                                    Hospital
                                                                  </th>
                                                                  <th scope="col">
                                                                    Action
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {personalHealthHistoryData.table.answer.map(
                                                                  (
                                                                    tableData,
                                                                    tableIndex
                                                                  ) => (
                                                                    <tr
                                                                      key={
                                                                        tableIndex
                                                                      }
                                                                    >
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Year"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfPersonalHealth.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                year: e
                                                                                  .target
                                                                                  .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData?.year
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Reason"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfPersonalHealth.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                reason:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData?.reason
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Hospital"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfPersonalHealth.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                hospital:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData?.hospital
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfPersonalHealth.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "#000",
                                                                                }}
                                                                              // onClick={() => {
                                                                              //   arrayHelpersOfPersonalHealth.remove(tableIndex);
                                                                              // }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                )}
                                                              </tbody>
                                                            </table>
                                                            <Button
                                                              type="dashed"
                                                              className="mt-2"
                                                              style={{fontSize:16}}
                                                              onClick={() => {
                                                                arrayHelpersOfPersonalHealth.push(
                                                                  {
                                                                    year: "",
                                                                    reason: "",
                                                                    hospital: "",
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Add New
                                                            </Button>
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          if (
                                            personalHealthHistoryData.type ===
                                            "table" &&
                                            personalHealthHistoryData.uniqueId ===
                                            "otherHospitalizationTable"
                                          ) {
                                            return (
                                              <div key={Math.random()}>
                                                <div className="col-md-12 mt-3 ml-n3">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <h5
                                                        className={`mt-4 ${style.diseasesHeader}`}
                                                      >
                                                        {
                                                          personalHealthHistoryData.heading
                                                        }
                                                      </h5>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <FieldArray
                                                        name={`personalHealthHistory[${index}].table.answer`}
                                                        render={(
                                                          arrayHelpersOfOtherPersonalization
                                                        ) => (
                                                          <>
                                                            <table className="table" style={{fontSize:16}}>
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">
                                                                    Year
                                                                  </th>
                                                                  <th scope="col">
                                                                    Reason
                                                                  </th>
                                                                  <th scope="col">
                                                                    Hospital
                                                                  </th>
                                                                  <th scope="col">
                                                                    Action
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {personalHealthHistoryData.table.answer.map(
                                                                  (
                                                                    tableData,
                                                                    tableIndex
                                                                  ) => (
                                                                    <>
                                                                      <tr
                                                                        key={Math.random()}
                                                                      >
                                                                        <td>
                                                                          <Input
                                                                            placeholder="Year"
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfOtherPersonalization.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  year: e
                                                                                    .target
                                                                                    .value,
                                                                                }
                                                                              );
                                                                            }}
                                                                            defaultValue={
                                                                              tableData.year
                                                                            }
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Input
                                                                            placeholder="Reason"
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfOtherPersonalization.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  reason:
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                }
                                                                              );
                                                                            }}
                                                                            defaultValue={
                                                                              tableData.reason
                                                                            }
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Input
                                                                            placeholder="Hospital"
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfOtherPersonalization.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  hospital:
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                }
                                                                              );
                                                                            }}
                                                                            defaultValue={
                                                                              tableData.hospital
                                                                            }
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Popconfirm
                                                                            title="Are you sure ?"
                                                                            onConfirm={() =>
                                                                              arrayHelpersOfOtherPersonalization.remove(
                                                                                tableIndex
                                                                              )
                                                                            }
                                                                            onCancel={
                                                                              cancel
                                                                            }
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                          >
                                                                            <Button
                                                                              icon={
                                                                                <DeleteOutlined
                                                                                  className={
                                                                                    style.delIconInner
                                                                                  }
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "16px",
                                                                                    color:
                                                                                      "#000",
                                                                                  }}
                                                                                />
                                                                              }
                                                                              size="middle"
                                                                              onClick={() => {
                                                                                console.log();
                                                                              }}
                                                                              className="mr-2 mb-n5"
                                                                            />
                                                                          </Popconfirm>
                                                                        </td>
                                                                      </tr>
                                                                    </>
                                                                  )
                                                                )}
                                                              </tbody>
                                                            </table>
                                                            <Button
                                                              type="dashed"
                                                              className="mt-3"
                                                              style={{fontSize:16}}
                                                              onClick={() => {
                                                                arrayHelpersOfOtherPersonalization.push(
                                                                  {
                                                                    year: "",
                                                                    reason: "",
                                                                    hospital: "",
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Add New
                                                            </Button>
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          if (
                                            personalHealthHistoryData.type ===
                                            "table" &&
                                            personalHealthHistoryData.uniqueId ===
                                            "prescribedDrugsTable"
                                          ) {
                                            return (
                                              <div key={Math.random()}>
                                                <div className="col-md-12 mt-3 ml-n3">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <h5
                                                        className={`mt-4 ${style.diseasesHeader}`}
                                                      >
                                                        {
                                                          personalHealthHistoryData.heading
                                                        }
                                                      </h5>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <FieldArray
                                                        name={`personalHealthHistory[${index}].table.answer`}
                                                        render={(
                                                          arrayHelpersOfDrugs
                                                        ) => (
                                                          <>
                                                            <table className="table" style={{fontSize:16}}>
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">
                                                                    Name the drug
                                                                  </th>
                                                                  <th scope="col">
                                                                    Strength
                                                                  </th>
                                                                  <th scope="col">
                                                                    Frequency
                                                                    taken
                                                                  </th>
                                                                  <th scope="col">
                                                                    Action
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {personalHealthHistoryData.table.answer.map(
                                                                  (
                                                                    tableData,
                                                                    tableIndex
                                                                  ) => (
                                                                    <>
                                                                      <tr
                                                                        key={Math.random()}
                                                                      >
                                                                        <td>
                                                                          <Input
                                                                            placeholder="Name the drug"
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfDrugs.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  nametheDrug:
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                }
                                                                              );
                                                                            }}
                                                                            defaultValue={
                                                                              tableData.nametheDrug
                                                                            }
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Input
                                                                            placeholder="Strength"
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfDrugs.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  strength:
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                }
                                                                              );
                                                                            }}
                                                                            defaultValue={
                                                                              tableData.strength
                                                                            }
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Input
                                                                            placeholder="Frequency taken"
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfDrugs.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  frequencyTaken:
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                }
                                                                              );
                                                                            }}
                                                                            defaultValue={
                                                                              tableData.frequencyTaken
                                                                            }
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Popconfirm
                                                                            title="Are you sure ?"
                                                                            onConfirm={() =>
                                                                              arrayHelpersOfDrugs.remove(
                                                                                tableIndex
                                                                              )
                                                                            }
                                                                            onCancel={
                                                                              cancel
                                                                            }
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                          >
                                                                            <Button
                                                                              icon={
                                                                                <DeleteOutlined
                                                                                  className={
                                                                                    style.delIconInner
                                                                                  }
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "16px",
                                                                                    color:
                                                                                      "#000",
                                                                                  }}
                                                                                />
                                                                              }
                                                                              size="middle"
                                                                              onClick={() => {
                                                                                console.log();
                                                                              }}
                                                                              className="mr-2 mb-n5"
                                                                            />
                                                                          </Popconfirm>
                                                                        </td>
                                                                      </tr>
                                                                    </>
                                                                  )
                                                                )}
                                                              </tbody>
                                                            </table>
                                                            <Button
                                                              type="dashed"
                                                              className="mt-3"
                                                              style={{fontSize:16}}
                                                              onClick={() => {
                                                                arrayHelpersOfDrugs.push(
                                                                  {
                                                                    nametheDrug:
                                                                      "",
                                                                    strength: "",
                                                                    frequencyTaken:
                                                                      "",
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Add New
                                                            </Button>
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          if (
                                            personalHealthHistoryData.type ===
                                            "table" &&
                                            personalHealthHistoryData.uniqueId ===
                                            "allergiesTable"
                                          ) {
                                            return (
                                              <div key={Math.random()}>
                                                <div className="col-md-12 mt-3 ml-n3">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <h5
                                                        className={`mt-4 ${style.diseasesHeader}`}
                                                      >
                                                        {
                                                          personalHealthHistoryData.heading
                                                        }
                                                      </h5>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <FieldArray
                                                        name={`personalHealthHistory[${index}].table.answer`}
                                                        render={(
                                                          arrayHelpersOfDrugs
                                                        ) => (
                                                          <>
                                                            <table className="table" style={{fontSize:16}}>
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">
                                                                    Name the drug
                                                                  </th>
                                                                  <th scope="col">
                                                                    Reaction you
                                                                    had
                                                                  </th>
                                                                  <th scope="col">
                                                                    Action
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {personalHealthHistoryData.table.answer.map(
                                                                  (
                                                                    tableData,
                                                                    tableIndex
                                                                  ) => (
                                                                    <>
                                                                      <tr
                                                                        key={Math.random()}
                                                                      >
                                                                        <td>
                                                                          <Input
                                                                            placeholder="Name the drug"
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfDrugs.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  nametheDrug:
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                }
                                                                              );
                                                                            }}
                                                                            defaultValue={
                                                                              tableData.nametheDrug
                                                                            }
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Input
                                                                            placeholder="Reaction you had"
                                                                            onBlur={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfDrugs.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  reactionYouHad:
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                }
                                                                              );
                                                                            }}
                                                                            defaultValue={
                                                                              tableData.reactionYouHad
                                                                            }
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Popconfirm
                                                                            title="Are you sure ?"
                                                                            onConfirm={() =>
                                                                              arrayHelpersOfDrugs.remove(
                                                                                tableIndex
                                                                              )
                                                                            }
                                                                            onCancel={
                                                                              cancel
                                                                            }
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                          >
                                                                            <Button
                                                                              icon={
                                                                                <DeleteOutlined
                                                                                  className={
                                                                                    style.delIconInner
                                                                                  }
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "16px",
                                                                                    color:
                                                                                      "#000",
                                                                                  }}
                                                                                />
                                                                              }
                                                                              size="middle"
                                                                              onClick={() => {
                                                                                console.log();
                                                                              }}
                                                                              className="mr-2 mb-n5"
                                                                            />
                                                                          </Popconfirm>
                                                                        </td>
                                                                      </tr>
                                                                    </>
                                                                  )
                                                                )}
                                                              </tbody>
                                                            </table>
                                                            <Button
                                                              type="dashed"
                                                              className="mb-5  mt-3"
                                                              style={{fontSize:16}}
                                                              onClick={() => {
                                                                arrayHelpersOfDrugs.push(
                                                                  {
                                                                    nametheDrug:
                                                                      "",
                                                                    reactionYouHad:
                                                                      "",
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Add New
                                                            </Button>
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return "";
                                        }
                                      )}
                                    </div>
                                  )}
                                />
                                <div className="row pb-5 align-items-center justify-content-center mt-4">
                                  <Button
                                    size="large"
                                    style={{ width: 200 }}
                                    htmlType="button"
                                    className={`mt-4 ml-2 mr-3 ${style.borderedBtn}`}
                                    onClick={() => setCurrent(0)}
                                  >
                                    Previous
                                  </Button>
                                  <Button
                                    htmlType="submit"
                                    size="large"
                                    style={{ width: 200 }}
                                    className={`mt-4 ml-n1 ${style.greenBtn}`}
                                  >
                                    Next
                                  </Button>
                                </div>

                                {/* <div className="row ml-1 pb-5">
                                  <Button
                                    size="large"
                                    style={{ width: 110 }}
                                    htmlType="button"
                                    className="ml-2 mr-3"
                                    onClick={() => setCurrent(0)}
                                  >
                                    Previous
                                  </Button>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    style={{ width: 110 }}
                                    className="ml-n1"
                                  >
                                    Next
                                  </Button>
                                </div> */}
                              </Form>
                            )}
                          />
                        </Card>
                        {/* <Card title="Personal Health" className="mt-4">
                        <Formik
                          enableReinitialize
                          initialValues={
                            healthHistoryModule?.personalHealthHistory
                              ? {
                                personalHealthHistory:
                                  healthHistoryModule?.personalHealthHistory,
                              }
                              : // : { personalHealthHistory: PATIENT_DATA.personalHealthHistory }
                              {
                                personalHealthHistory:
                                  PERSONAL_HEALTH_DATA?.personalHealthHistory,
                              }
                          }
                          onSubmit={(values) => {
                            addPatientHealthHistory(
                              "Personal Health History",
                              values
                            );
                          }}
                          render={({ values, setFieldValue }) => (
                            <Form>
                              <FieldArray
                                name="personalHealthHistory"
                                render={(personalHealthHistoryHelper) => (
                                  <div key={Math.random()}>
                                    {values.personalHealthHistory?.map(
                                      (personalHealthHistoryData, index) => {
                                        if (
                                          personalHealthHistoryData.type ===
                                          "checkboxes"
                                        ) {
                                          return (
                                            <div>
                                              <Title level={4}>
                                                {
                                                  personalHealthHistoryData.heading
                                                }
                                              </Title>
                                              <FieldArray
                                                name={`personalHealthHistory[${index}].checkboxes`}
                                                render={(arrayHelpers) => (
                                                  <>
                                                    <div className="row">
                                                      {personalHealthHistoryData.checkboxes.map(
                                                        (
                                                          checkboxData,
                                                          checkBoxIndex
                                                        ) => (
                                                          <>
                                                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                              <Checkbox
                                                                value={
                                                                  checkboxData.label
                                                                }
                                                                checked={
                                                                  checkboxData.isActive
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    e.target
                                                                      .checked
                                                                  ) {
                                                                    arrayHelpers.replace(
                                                                      checkBoxIndex,
                                                                      {
                                                                        ...checkboxData,
                                                                        isActive:
                                                                          true,
                                                                      }
                                                                    );
                                                                  } else {
                                                                    arrayHelpers.replace(
                                                                      checkBoxIndex,
                                                                      {
                                                                        ...checkboxData,
                                                                        isActive:
                                                                          false,
                                                                      }
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                <Text
                                                                  className={
                                                                    style.textNormal
                                                                  }
                                                                >
                                                                  {
                                                                    checkboxData.label
                                                                  }
                                                                </Text>
                                                              </Checkbox>
                                                            </div>
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              />
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "checkboxdate"
                                        ) {
                                          return (
                                            <div key={Math.random()}>
                                              <Title className="pt-3" level={4}>
                                                {
                                                  personalHealthHistoryData.heading
                                                }{" "}
                                              </Title>
                                              <FieldArray
                                                name={`personalHealthHistory[${index}].checkboxdate`}
                                                render={(arrayHelpers1) => (
                                                  <>
                                                    <Row>
                                                      <div className="col-md-12 ml-n3">
                                                        <div className="row">
                                                          {personalHealthHistoryData.checkboxdate.map(
                                                            (
                                                              checkboxData,
                                                              checkBoxDateIndex
                                                            ) => (
                                                              <>
                                                                <div className="col-md-3 mt-3">
                                                                  <Checkbox
                                                                    style={{
                                                                      borderRadius:
                                                                        "0%",
                                                                    }}
                                                                    checked={
                                                                      checkboxData.isActive
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      if (
                                                                        e.target
                                                                          .checked
                                                                      ) {
                                                                        arrayHelpers1.replace(
                                                                          checkBoxDateIndex,
                                                                          {
                                                                            ...checkboxData,
                                                                            isActive:
                                                                              true,
                                                                          }
                                                                        );
                                                                      } else {
                                                                        arrayHelpers1.replace(
                                                                          checkBoxDateIndex,
                                                                          {
                                                                            ...checkboxData,
                                                                            isActive:
                                                                              false,
                                                                          }
                                                                        );
                                                                      }
                                                                    }}
                                                                  >
                                                                    <Text
                                                                      className={
                                                                        style.textNormal
                                                                      }
                                                                    >
                                                                      {
                                                                        checkboxData.label
                                                                      }
                                                                    </Text>
                                                                  </Checkbox>
                                                                </div>
                                                                <div className="col-md-3 mt-3">
                                                                  <DatePicker
                                                                    disabledDate={(
                                                                      current
                                                                    ) => {
                                                                      let customDate =
                                                                        moment().format(
                                                                          "YYYY-MM-DD"
                                                                        );
                                                                      return (
                                                                        current &&
                                                                        current >
                                                                        moment(
                                                                          customDate,
                                                                          "YYYY-MM-DD"
                                                                        )
                                                                      );
                                                                    }}
                                                                    value={
                                                                      checkboxData.checkboxDate
                                                                        ? moment(
                                                                          checkboxData.checkboxDate
                                                                        )
                                                                        : null
                                                                    }
                                                                    onChange={(
                                                                      date,
                                                                      dateString
                                                                    ) => {
                                                                      arrayHelpers1.replace(
                                                                        checkBoxDateIndex,
                                                                        {
                                                                          ...checkboxData,
                                                                          checkboxDate:
                                                                            date,
                                                                        }
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                  />
                                                                </div>
                                                              </>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    </Row>
                                                  </>
                                                )}
                                              />
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "textarea"
                                        ) {
                                          return (
                                            <div key={Math.random()}>
                                              <div className="col-md-12 mt-5 ml-n3">
                                                <div className="row">
                                                  <Title
                                                    level={4}
                                                    className="ml-3"
                                                  >
                                                    {
                                                      personalHealthHistoryData.heading
                                                    }
                                                  </Title>
                                                  <div className="col-md-12">
                                                    <TextArea
                                                      className="textArea"
                                                      defaultValue={
                                                        personalHealthHistoryData.answer
                                                      }
                                                      onBlur={(e) => {
                                                        personalHealthHistoryHelper.replace(
                                                          index,
                                                          {
                                                            ...personalHealthHistoryData,
                                                            answer:
                                                              e.target.value,
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "table" &&
                                          personalHealthHistoryData.uniqueId ===
                                          "surgeriesTable"
                                        ) {
                                          return (
                                            <div key={Math.random()}>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`personalHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfPersonalHealth
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Year
                                                                </th>
                                                                <th scope="col">
                                                                  Reason
                                                                </th>
                                                                <th scope="col">
                                                                  Hospital
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {personalHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <tr
                                                                    key={
                                                                      tableIndex
                                                                    }
                                                                  >
                                                                    <td>
                                                                      <Input
                                                                        placeholder="Year"
                                                                        onBlur={(
                                                                          e
                                                                        ) => {
                                                                          arrayHelpersOfPersonalHealth.replace(
                                                                            tableIndex,
                                                                            {
                                                                              ...tableData,
                                                                              year: e
                                                                                .target
                                                                                .value,
                                                                            }
                                                                          );
                                                                        }}
                                                                        defaultValue={
                                                                          tableData?.year
                                                                        }
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      <Input
                                                                        placeholder="Reason"
                                                                        onBlur={(
                                                                          e
                                                                        ) => {
                                                                          arrayHelpersOfPersonalHealth.replace(
                                                                            tableIndex,
                                                                            {
                                                                              ...tableData,
                                                                              reason:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          );
                                                                        }}
                                                                        defaultValue={
                                                                          tableData?.reason
                                                                        }
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      <Input
                                                                        placeholder="Hospital"
                                                                        onBlur={(
                                                                          e
                                                                        ) => {
                                                                          arrayHelpersOfPersonalHealth.replace(
                                                                            tableIndex,
                                                                            {
                                                                              ...tableData,
                                                                              hospital:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          );
                                                                        }}
                                                                        defaultValue={
                                                                          tableData?.hospital
                                                                        }
                                                                      />
                                                                    </td>
                                                                    <td>
                                                                      <Popconfirm
                                                                        title="Are you sure ?"
                                                                        onConfirm={() =>
                                                                          arrayHelpersOfPersonalHealth.remove(
                                                                            tableIndex
                                                                          )
                                                                        }
                                                                        onCancel={
                                                                          cancel
                                                                        }
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                      >
                                                                        <Button
                                                                          icon={
                                                                            <DeleteOutlined
                                                                              className={
                                                                                style.delIconInner
                                                                              }
                                                                              style={{
                                                                                fontSize:
                                                                                  "16px",
                                                                                color:
                                                                                  "red",
                                                                              }}
                                                                            // onClick={() => {
                                                                            //   arrayHelpersOfPersonalHealth.remove(tableIndex);
                                                                            // }}
                                                                            />
                                                                          }
                                                                          size="middle"
                                                                          onClick={() => {
                                                                            console.log();
                                                                          }}
                                                                          className="mr-2 mb-n5"
                                                                        />
                                                                      </Popconfirm>
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-2"
                                                            onClick={() => {
                                                              arrayHelpersOfPersonalHealth.push(
                                                                {
                                                                  year: "",
                                                                  reason: "",
                                                                  hospital: "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "table" &&
                                          personalHealthHistoryData.uniqueId ===
                                          "otherHospitalizationTable"
                                        ) {
                                          return (
                                            <div key={Math.random()}>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`personalHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfOtherPersonalization
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Year
                                                                </th>
                                                                <th scope="col">
                                                                  Reason
                                                                </th>
                                                                <th scope="col">
                                                                  Hospital
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {personalHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={Math.random()}
                                                                    >
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Year"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfOtherPersonalization.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                year: e
                                                                                  .target
                                                                                  .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.year
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Reason"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfOtherPersonalization.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                reason:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.reason
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Hospital"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfOtherPersonalization.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                hospital:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.hospital
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfOtherPersonalization.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "red",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfOtherPersonalization.push(
                                                                {
                                                                  year: "",
                                                                  reason: "",
                                                                  hospital: "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "table" &&
                                          personalHealthHistoryData.uniqueId ===
                                          "prescribedDrugsTable"
                                        ) {
                                          return (
                                            <div key={Math.random()}>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`personalHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfDrugs
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Name the drug
                                                                </th>
                                                                <th scope="col">
                                                                  Strength
                                                                </th>
                                                                <th scope="col">
                                                                  Frequency
                                                                  taken
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {personalHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={Math.random()}
                                                                    >
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Name the drug"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                nametheDrug:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.nametheDrug
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Strength"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                strength:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.strength
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Frequency taken"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                frequencyTaken:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.frequencyTaken
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfDrugs.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "red",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfDrugs.push(
                                                                {
                                                                  nametheDrug:
                                                                    "",
                                                                  strength: "",
                                                                  frequencyTaken:
                                                                    "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (
                                          personalHealthHistoryData.type ===
                                          "table" &&
                                          personalHealthHistoryData.uniqueId ===
                                          "allergiesTable"
                                        ) {
                                          return (
                                            <div key={Math.random()}>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        personalHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`personalHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfDrugs
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Name the drug
                                                                </th>
                                                                <th scope="col">
                                                                  Reaction you
                                                                  had
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {personalHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={Math.random()}
                                                                    >
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Name the drug"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                nametheDrug:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.nametheDrug
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Input
                                                                          placeholder="Reaction you had"
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfDrugs.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                reactionYouHad:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                          defaultValue={
                                                                            tableData.reactionYouHad
                                                                          }
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfDrugs.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "red",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mb-5  mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfDrugs.push(
                                                                {
                                                                  nametheDrug:
                                                                    "",
                                                                  reactionYouHad:
                                                                    "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return "";
                                      }
                                    )}
                                  </div>
                                )}
                              />
                              <div className="row ml-1 pb-5">
                                <Button
                                  size="large"
                                  style={{ width: 110 }}
                                  htmlType="button"
                                  className="ml-2 mr-3"
                                  onClick={() => setCurrent(0)}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  size="large"
                                  style={{ width: 110 }}
                                  className="ml-n1"
                                >
                                  Next
                                </Button>
                              </div>
                            </Form>
                          )}
                        />
                      </Card> */}
                      </>

                    ) : current === 2 ? (
                      <>
                        <Card className="mt-1">
                          <Formik
                            enableReinitialize
                            initialValues={
                              healthHistoryModule?.familyHealthHistory
                                ? {
                                  familyHealthHistory:
                                    healthHistoryModule?.familyHealthHistory,
                                }
                                : {
                                  familyHealthHistory:
                                    FAMILY_HEALTH_DATA?.familyHealthHistory,
                                }
                            }
                            onSubmit={(values) => {
                              addPatientHealthHistory(
                                "Family Health History",
                                values
                              );
                            }}
                            render={({ values, setFieldValue }) => (
                              <Form>
                                {console.log("values", values)}
                                <FieldArray
                                  name="familyHealthHistory"
                                  render={(familyHealthHistoryHelper) => (
                                    <>
                                      {values.familyHealthHistory?.map(
                                        (familyHealthHistoryData, index) => {
                                          console.log(
                                            "familyHealthHistoryData: ",
                                            familyHealthHistoryData
                                          );
                                          if (
                                            familyHealthHistoryData.type ===
                                            "tableWithInput"
                                          ) {
                                            return (
                                              <>
                                                <div className="col-md-12 ml-n3">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <h5 className={style.diseasesHeader}
                                                      >
                                                        Family Details
                                                      </h5>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <FieldArray
                                                        name={`familyHealthHistory[${index}].relationTable.answer`}
                                                        render={(
                                                          familyHealthHistoryRelationTable
                                                        ) => (
                                                          <>
                                                            <table className="table" style={{fontSize:16}}>
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">
                                                                    Relation
                                                                  </th>
                                                                  <th scope="col">
                                                                    Age
                                                                  </th>
                                                                  <th
                                                                    scope="col"
                                                                    className={
                                                                      style.textNormal
                                                                    }
                                                                  >
                                                                    Significant
                                                                    Health
                                                                    Problems
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {familyHealthHistoryData.relationTable.answer.map(
                                                                  (
                                                                    relation,
                                                                    indexOfRelationArray
                                                                  ) => (
                                                                    <>
                                                                      <tr
                                                                        key={
                                                                          indexOfRelationArray
                                                                        }

                                                                      >
                                                                        <td
                                                                          style={{ verticalAlign: 'middle' }}>
                                                                          {
                                                                            relation.relationName
                                                                          }
                                                                        </td>
                                                                        <td>
                                                                          <Field
                                                                            placeholder="Age"
                                                                            type="text"
                                                                            maxLength={
                                                                              3
                                                                            }
                                                                            onKeyPress={(
                                                                              event
                                                                            ) => {
                                                                              if (
                                                                                !/[0-9]/.test(
                                                                                  event.key
                                                                                )
                                                                              ) {
                                                                                event.preventDefault();
                                                                              }
                                                                            }}
                                                                            onKeyDown={(
                                                                              evt
                                                                            ) =>
                                                                              (evt.key ===
                                                                                "-" ||
                                                                                evt.key ===
                                                                                "e") &&
                                                                              evt.preventDefault()
                                                                            }
                                                                            name={`familyHealthHistory[${index}].relationTable.answer[${indexOfRelationArray}].age`}
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Field
                                                                            placeholder="Significant health problems"
                                                                            name={`familyHealthHistory[${index}].relationTable.answer[${indexOfRelationArray}].significantHealth`}
                                                                          />
                                                                        </td>
                                                                      </tr>
                                                                    </>
                                                                  )
                                                                )}
                                                              </tbody>
                                                            </table>
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                          if (
                                            familyHealthHistoryData.type ===
                                            "table" &&
                                            familyHealthHistoryData.heading ===
                                            "Children"
                                          ) {
                                            return (
                                              <>
                                                <div className="col-md-12 mt-3 ml-n3">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <Title
                                                        level={4}
                                                        className="mt-4"
                                                        style={{fontSize:16}}
                                                      >
                                                        {
                                                          familyHealthHistoryData.heading
                                                        }
                                                      </Title>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <FieldArray
                                                        name={`familyHealthHistory[${index}].table.answer`}
                                                        render={(
                                                          arrayHelpersOfFamilyHealth
                                                        ) => (
                                                          <>
                                                            <table className="table table-striped" style={{fontSize:16}}>
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">
                                                                    Gender
                                                                  </th>
                                                                  <th scope="col">
                                                                    Age
                                                                  </th>
                                                                  <th
                                                                    scope="col"
                                                                    className={
                                                                      style.textNormal
                                                                    }
                                                                  >
                                                                    Significant
                                                                    Health
                                                                    Problems
                                                                  </th>
                                                                  <th scope="col">
                                                                    Action
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody style={{fontSize:16}}>
                                                                {familyHealthHistoryData.table.answer.map(
                                                                  (
                                                                    tableData,
                                                                    tableIndex
                                                                  ) => (
                                                                    <>
                                                                      <tr
                                                                        key={
                                                                          tableIndex
                                                                        }
                                                                      >
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                          <Select
                                                                            style={{ width: '150px' }}
                                                                            defaultValue={tableData?.Gender}
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfFamilyHealth.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  Gender:
                                                                                    e,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <option value="Male">Male</option>
                                                                            <option value="Female">Female</option>
                                                                          </Select>

                                                                        </td>
                                                                        <td>
                                                                          <Field
                                                                            placeholder="Age"
                                                                            type="text"
                                                                            maxLength={
                                                                              3
                                                                            }
                                                                            onKeyPress={(
                                                                              event
                                                                            ) => {
                                                                              if (
                                                                                !/[0-9]/.test(
                                                                                  event.key
                                                                                )
                                                                              ) {
                                                                                event.preventDefault();
                                                                              }
                                                                            }}
                                                                            onKeyDown={(
                                                                              evt
                                                                            ) =>
                                                                              (evt.key ===
                                                                                "-" ||
                                                                                evt.key ===
                                                                                "e") &&
                                                                              evt.preventDefault()
                                                                            }
                                                                            name={`familyHealthHistory[${index}].table.answer[${tableIndex}].Age`}
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Field
                                                                            placeholder="Significant health problems"
                                                                            name={`familyHealthHistory[${index}].table.answer[${tableIndex}].SignificantHealthProblems`}
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Popconfirm
                                                                            title="Are you sure ?"
                                                                            onConfirm={() =>
                                                                              arrayHelpersOfFamilyHealth.remove(
                                                                                tableIndex
                                                                              )
                                                                            }
                                                                            onCancel={
                                                                              cancel
                                                                            }
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                          >
                                                                            <Button
                                                                              icon={
                                                                                <DeleteOutlined
                                                                                  className={
                                                                                    style.delIconInner
                                                                                  }
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "16px",
                                                                                    color:
                                                                                      "#000",
                                                                                  }}
                                                                                />
                                                                              }
                                                                              size="middle"
                                                                              onClick={() => {
                                                                                console.log();
                                                                              }}
                                                                              className="mr-2 mb-n5"
                                                                            />
                                                                          </Popconfirm>
                                                                        </td>
                                                                      </tr>
                                                                    </>
                                                                  )
                                                                )}
                                                              </tbody>
                                                            </table>
                                                            <Button
                                                              type="dashed"
                                                              className="mt-3"
                                                              style={{fontSize:16}}
                                                              onClick={() => {
                                                                arrayHelpersOfFamilyHealth.push(
                                                                  {
                                                                    Age: "",
                                                                    SignificantHealthProblems:
                                                                      "",
                                                                    Gender: "",
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Add New
                                                            </Button>
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                          if (
                                            familyHealthHistoryData.type ===
                                            "table" &&
                                            familyHealthHistoryData.heading ===
                                            "Sibling"
                                          ) {
                                            return (
                                              <>
                                                <div className="col-md-12 mt-3 ml-n3">
                                                  <div className="row">
                                                    <div className="col-md-12">
                                                      <Title
                                                        level={4}
                                                        className="mt-4"
                                                      >
                                                        {
                                                          familyHealthHistoryData.heading
                                                        }
                                                      </Title>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <FieldArray
                                                        name={`familyHealthHistory[${index}].table.answer`}
                                                        render={(
                                                          arrayHelpersOfFamilyHealth
                                                        ) => (
                                                          <>
                                                            <table className="table table-striped" style={{fontSize:16}}>
                                                              <thead>
                                                                <tr>
                                                                  <th scope="col">
                                                                    Gender
                                                                  </th>
                                                                  <th scope="col">
                                                                    Age
                                                                  </th>
                                                                  <th
                                                                    scope="col"
                                                                    className={
                                                                      style.textNormal
                                                                    }
                                                                  >
                                                                    Significant
                                                                    Health
                                                                    Problems
                                                                  </th>
                                                                  <th scope="col">
                                                                    Action
                                                                  </th>
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {familyHealthHistoryData.table.answer.map(
                                                                  (
                                                                    tableData,
                                                                    tableIndex
                                                                  ) => (
                                                                    <>
                                                                      <tr
                                                                        key={
                                                                          tableIndex
                                                                        }
                                                                      >
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                          <Select
                                                                            style={{ width: '150px' }}
                                                                            defaultValue={tableData?.Gender}
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              arrayHelpersOfFamilyHealth.replace(
                                                                                tableIndex,
                                                                                {
                                                                                  ...tableData,
                                                                                  Gender:
                                                                                    e,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            <option value="Male">Male</option>
                                                                            <option value="Female">Female</option>
                                                                          </Select>

                                                                        </td>
                                                                        <td>
                                                                          <Field
                                                                            placeholder="Age"
                                                                            type="text"
                                                                            maxLength={
                                                                              3
                                                                            }
                                                                            onKeyPress={(
                                                                              event
                                                                            ) => {
                                                                              if (
                                                                                !/[0-9]/.test(
                                                                                  event.key
                                                                                )
                                                                              ) {
                                                                                event.preventDefault();
                                                                              }
                                                                            }}
                                                                            onKeyDown={(
                                                                              evt
                                                                            ) =>
                                                                              (evt.key ===
                                                                                "-" ||
                                                                                evt.key ===
                                                                                "e") &&
                                                                              evt.preventDefault()
                                                                            }
                                                                            name={`familyHealthHistory[${index}].table.answer[${tableIndex}].Age`}
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Field
                                                                            placeholder="Significant health problems"
                                                                            name={`familyHealthHistory[${index}].table.answer[${tableIndex}].SignificantHealthProblems`}
                                                                          />
                                                                        </td>
                                                                        <td>
                                                                          <Popconfirm
                                                                            title="Are you sure ?"
                                                                            onConfirm={() =>
                                                                              arrayHelpersOfFamilyHealth.remove(
                                                                                tableIndex
                                                                              )
                                                                            }
                                                                            onCancel={
                                                                              cancel
                                                                            }
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                          >
                                                                            <Button
                                                                              icon={
                                                                                <DeleteOutlined
                                                                                  className={
                                                                                    style.delIconInner
                                                                                  }
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "16px",
                                                                                    color:
                                                                                      "#000",
                                                                                  }}
                                                                                />
                                                                              }
                                                                              size="middle"
                                                                              onClick={() => {
                                                                                console.log();
                                                                              }}
                                                                              className="mr-2 mb-n5"
                                                                            />
                                                                          </Popconfirm>
                                                                        </td>
                                                                      </tr>
                                                                    </>
                                                                  )
                                                                )}
                                                              </tbody>
                                                            </table>
                                                            <Button
                                                              type="dashed"
                                                              className="mt-3"
                                                              style={{fontSize:16}}
                                                              onClick={() => {
                                                                arrayHelpersOfFamilyHealth.push(
                                                                  {
                                                                    Age: "",
                                                                    SignificantHealthProblems:
                                                                      "",
                                                                    Gender: "",
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Add New
                                                            </Button>
                                                          </>
                                                        )}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                          return "";
                                        }
                                      )}
                                    </>
                                  )}
                                />
                                <div className="row pb-5 align-items-center justify-content-center mt-4">
                                  <Button
                                    size="large"
                                    style={{ width: 200 }}
                                    htmlType="button"
                                    className={`mt-4 ml-2 mr-3 ${style.borderedBtn}`}
                                    onClick={() => setCurrent(1)}
                                  >
                                    Previous
                                  </Button>
                                  <Button
                                    htmlType="submit"
                                    size="large"
                                    style={{ width: 200 }}
                                    className={` mt-4 ml-n1 ${style.greenBtn}`}
                                  >
                                    Next
                                  </Button>
                                </div>

                              </Form>
                            )}
                          />
                        </Card>

                        {/* <Card title="Family Health" className="mt-4">
                        <Formik
                          enableReinitialize
                          initialValues={
                            healthHistoryModule?.familyHealthHistory
                              ? {
                                familyHealthHistory:
                                  healthHistoryModule?.familyHealthHistory,
                              }
                              : {
                                familyHealthHistory:
                                  FAMILY_HEALTH_DATA?.familyHealthHistory,
                              }
                          }
                          onSubmit={(values) => {
                            addPatientHealthHistory(
                              "Family Health History",
                              values
                            );
                          }}
                          render={({ values, setFieldValue }) => (
                            <Form>
                              {console.log("values", values)}
                              <FieldArray
                                name="familyHealthHistory"
                                render={(familyHealthHistoryHelper) => (
                                  <>
                                    {values.familyHealthHistory?.map(
                                      (familyHealthHistoryData, index) => {
                                        console.log(
                                          "familyHealthHistoryData: ",
                                          familyHealthHistoryData
                                        );
                                        if (
                                          familyHealthHistoryData.type ===
                                          "tableWithInput"
                                        ) {
                                          return (
                                            <>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title level={4}>
                                                      Family Details
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`familyHealthHistory[${index}].relationTable.answer`}
                                                      render={(
                                                        familyHealthHistoryRelationTable
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Relation
                                                                </th>
                                                                <th scope="col">
                                                                  Age
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className={
                                                                    style.textNormal
                                                                  }
                                                                >
                                                                  Significant
                                                                  Health
                                                                  Problems
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {familyHealthHistoryData.relationTable.answer.map(
                                                                (
                                                                  relation,
                                                                  indexOfRelationArray
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={
                                                                        indexOfRelationArray
                                                                      }
                                                                    >
                                                                      <td>
                                                                        {
                                                                          relation.relationName
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Age"
                                                                          type="text"
                                                                          maxLength={
                                                                            3
                                                                          }
                                                                          onKeyPress={(
                                                                            event
                                                                          ) => {
                                                                            if (
                                                                              !/[0-9]/.test(
                                                                                event.key
                                                                              )
                                                                            ) {
                                                                              event.preventDefault();
                                                                            }
                                                                          }}
                                                                          onKeyDown={(
                                                                            evt
                                                                          ) =>
                                                                            (evt.key ===
                                                                              "-" ||
                                                                              evt.key ===
                                                                              "e") &&
                                                                            evt.preventDefault()
                                                                          }
                                                                          name={`familyHealthHistory[${index}].relationTable.answer[${indexOfRelationArray}].age`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Significant health problems"
                                                                          name={`familyHealthHistory[${index}].relationTable.answer[${indexOfRelationArray}].significantHealth`}
                                                                        />
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                        if (
                                          familyHealthHistoryData.type ===
                                          "table" &&
                                          familyHealthHistoryData.heading ===
                                          "Children"
                                        ) {
                                          return (
                                            <>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        familyHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`familyHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfFamilyHealth
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Gender
                                                                </th>
                                                                <th scope="col">
                                                                  Age
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className={
                                                                    style.textNormal
                                                                  }
                                                                >
                                                                  Significant
                                                                  Health
                                                                  Problems
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {familyHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={
                                                                        tableIndex
                                                                      }
                                                                    >
                                                                      <td>
                                                                        <Radio.Group
                                                                          value={
                                                                            tableData.Gender
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfFamilyHealth.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                Gender:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <Radio value="male">
                                                                            Male
                                                                          </Radio>
                                                                          <Radio value="female">
                                                                            Female
                                                                          </Radio>
                                                                        </Radio.Group>
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Age"
                                                                          type="text"
                                                                          maxLength={
                                                                            3
                                                                          }
                                                                          onKeyPress={(
                                                                            event
                                                                          ) => {
                                                                            if (
                                                                              !/[0-9]/.test(
                                                                                event.key
                                                                              )
                                                                            ) {
                                                                              event.preventDefault();
                                                                            }
                                                                          }}
                                                                          onKeyDown={(
                                                                            evt
                                                                          ) =>
                                                                            (evt.key ===
                                                                              "-" ||
                                                                              evt.key ===
                                                                              "e") &&
                                                                            evt.preventDefault()
                                                                          }
                                                                          name={`familyHealthHistory[${index}].table.answer[${tableIndex}].Age`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Significant health problems"
                                                                          name={`familyHealthHistory[${index}].table.answer[${tableIndex}].SignificantHealthProblems`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfFamilyHealth.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "#000",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfFamilyHealth.push(
                                                                {
                                                                  Age: "",
                                                                  SignificantHealthProblems:
                                                                    "",
                                                                  Gender: "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                        if (
                                          familyHealthHistoryData.type ===
                                          "table" &&
                                          familyHealthHistoryData.heading ===
                                          "Sibling"
                                        ) {
                                          return (
                                            <>
                                              <div className="col-md-12 mt-3 ml-n3">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <Title
                                                      level={4}
                                                      className="mt-4"
                                                    >
                                                      {
                                                        familyHealthHistoryData.heading
                                                      }
                                                    </Title>
                                                  </div>
                                                  <div className="col-md-12">
                                                    <FieldArray
                                                      name={`familyHealthHistory[${index}].table.answer`}
                                                      render={(
                                                        arrayHelpersOfFamilyHealth
                                                      ) => (
                                                        <>
                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">
                                                                  Gender
                                                                </th>
                                                                <th scope="col">
                                                                  Age
                                                                </th>
                                                                <th
                                                                  scope="col"
                                                                  className={
                                                                    style.textNormal
                                                                  }
                                                                >
                                                                  Significant
                                                                  Health
                                                                  Problems
                                                                </th>
                                                                <th scope="col">
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {familyHealthHistoryData.table.answer.map(
                                                                (
                                                                  tableData,
                                                                  tableIndex
                                                                ) => (
                                                                  <>
                                                                    <tr
                                                                      key={
                                                                        tableIndex
                                                                      }
                                                                    >
                                                                      <td>
                                                                        <Radio.Group
                                                                          value={
                                                                            tableData?.Gender
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            arrayHelpersOfFamilyHealth.replace(
                                                                              tableIndex,
                                                                              {
                                                                                ...tableData,
                                                                                Gender:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <Radio value="male">
                                                                            Male
                                                                          </Radio>
                                                                          <Radio value="female">
                                                                            Female
                                                                          </Radio>
                                                                        </Radio.Group>
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Age"
                                                                          type="text"
                                                                          maxLength={
                                                                            3
                                                                          }
                                                                          onKeyPress={(
                                                                            event
                                                                          ) => {
                                                                            if (
                                                                              !/[0-9]/.test(
                                                                                event.key
                                                                              )
                                                                            ) {
                                                                              event.preventDefault();
                                                                            }
                                                                          }}
                                                                          onKeyDown={(
                                                                            evt
                                                                          ) =>
                                                                            (evt.key ===
                                                                              "-" ||
                                                                              evt.key ===
                                                                              "e") &&
                                                                            evt.preventDefault()
                                                                          }
                                                                          name={`familyHealthHistory[${index}].table.answer[${tableIndex}].Age`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Field
                                                                          placeholder="Significant health problems"
                                                                          name={`familyHealthHistory[${index}].table.answer[${tableIndex}].SignificantHealthProblems`}
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <Popconfirm
                                                                          title="Are you sure ?"
                                                                          onConfirm={() =>
                                                                            arrayHelpersOfFamilyHealth.remove(
                                                                              tableIndex
                                                                            )
                                                                          }
                                                                          onCancel={
                                                                            cancel
                                                                          }
                                                                          okText="Yes"
                                                                          cancelText="No"
                                                                        >
                                                                          <Button
                                                                            icon={
                                                                              <DeleteOutlined
                                                                                className={
                                                                                  style.delIconInner
                                                                                }
                                                                                style={{
                                                                                  fontSize:
                                                                                    "16px",
                                                                                  color:
                                                                                    "#000",
                                                                                }}
                                                                              />
                                                                            }
                                                                            size="middle"
                                                                            onClick={() => {
                                                                              console.log();
                                                                            }}
                                                                            className="mr-2 mb-n5"
                                                                          />
                                                                        </Popconfirm>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                          <Button
                                                            type="dashed"
                                                            className="mt-3"
                                                            onClick={() => {
                                                              arrayHelpersOfFamilyHealth.push(
                                                                {
                                                                  Age: "",
                                                                  SignificantHealthProblems:
                                                                    "",
                                                                  Gender: "",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Add New
                                                          </Button>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                        return "";
                                      }
                                    )}
                                  </>
                                )}
                              />
                              <div className="row ml-1 mt-5 ml-n2 pb-5">
                                <Button
                                  size="large"
                                  style={{ width: 110 }}
                                  htmlType="button"
                                  className="ml-2 mr-3"
                                  onClick={() => setCurrent(1)}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  size="large"
                                  style={{ width: 110 }}
                                  className="ml-n1"
                                >
                                  Next
                                </Button>
                              </div>
                            </Form>
                          )}
                        />
                      </Card> */}
                      </>
                    ) : current === 3 ? (
                      <>
                        <Card className="mt-1">
                          <div className="col-md-12 mt-1 ml-n3">
                            <div className="row">
                              <div className="col-md-12">
                                <h5 className={style.diseasesHeader}>
                                  Mental Health
                                </h5>
                              </div>
                            </div>
                          </div>
                          <Formik
                            enableReinitialize
                            initialValues={
                              healthHistoryModule?.otherProblemsHistory
                                ? {
                                  otherProblemsHistory:
                                    healthHistoryModule?.otherProblemsHistory,
                                }
                                : {
                                  otherProblemsHistory:
                                    OTHER_HEALTH_DATA?.otherProblemsHistory,
                                }
                            }
                            onSubmit={(values) => {
                              addPatientHealthHistory(
                                "Other Problems History",
                                values
                              );
                            }}
                            render={({ values, setFieldValue }) => (
                              <Form>
                                {console.log("values", values)}
                                <FieldArray
                                  name="otherProblemsHistory"
                                  render={(familyHealthHistoryHelper) => (
                                    <>
                                      {values.otherProblemsHistory.map(
                                        (otherProblemsHistoryData, index) => {
                                          if (
                                            otherProblemsHistoryData.type ===
                                            "checkboxes"
                                          ) {
                                            return (
                                              <FieldArray
                                                name={`otherProblemsHistory[${index}].checkboxes`}
                                                render={(
                                                  otherProblemsHistoryHelper
                                                ) => (
                                                  <>
                                                    <div className="row">
                                                      {otherProblemsHistoryData.checkboxes.map(
                                                        (
                                                          checkboxData,
                                                          checkBoxIndex
                                                        ) => (
                                                          <>
                                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                              <div
                                                                className={
                                                                  checkboxData.answer ===
                                                                    "yes"
                                                                    ? style.static_active_card
                                                                    : style.static_card
                                                                }
                                                              >
                                                                <Checkbox
                                                                  value={
                                                                    checkboxData.label
                                                                  }
                                                                  checked={
                                                                    checkboxData.answer ===
                                                                      "yes"
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    console.log(
                                                                      e,
                                                                      "ee"
                                                                    );
                                                                    otherProblemsHistoryHelper.replace(
                                                                      checkBoxIndex,
                                                                      {
                                                                        ...checkboxData,
                                                                        answer:
                                                                          e
                                                                            .target
                                                                            .checked ==
                                                                            true
                                                                            ? "yes"
                                                                            : "no",
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  <Text
                                                                    className={
                                                                      style.textNormal
                                                                    }
                                                                  >
                                                                    {
                                                                      checkboxData.label
                                                                    }
                                                                  </Text>
                                                                </Checkbox>
                                                              </div>
                                                            </div>




                                                            {/* <div
                                                            className="row"
                                                            style={{
                                                              display: "flex",
                                                              alignContent:
                                                                "center",
                                                              justifyContent:
                                                                "center",
                                                            }}
                                                          >
                                                            <div className="col-12 col-sm-6 col-md-8 col-lg-9 col-xl-9 mt-2">
                                                              <Text>
                                                                {
                                                                  checkboxData.label
                                                                }
                                                              </Text>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3  mt-2">
                                                              <Switch
                                                                defaultChecked={
                                                                  checkboxData.answer ===
                                                                    "yes"
                                                                    ? true
                                                                    : false
                                                                }
                                                                onChange={(e) => {
                                                                  console.log(
                                                                    "e: ",
                                                                    e == true
                                                                      ? "yes"
                                                                      : "no"
                                                                  );
                                                                  otherProblemsHistoryHelper.replace(
                                                                    checkBoxIndex,
                                                                    {
                                                                      ...checkboxData,
                                                                      answer:
                                                                        e == true
                                                                          ? "yes"
                                                                          : "no",
                                                                    }
                                                                  );
                                                                }}
                                                              />

                                                             
                                                            </div>
                                                          </div> */}

                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              />
                                            );
                                          }
                                          return "";
                                        }
                                      )}
                                    </>
                                  )}
                                />

                                <div className="row pb-5 align-items-center justify-content-center mt-5">
                                  <Button
                                    size="large"
                                    style={{ width: 200 }}
                                    htmlType="button"
                                    className={`mt-4 ml-2 mr-3 ${style.borderedBtn}`}
                                    onClick={() => setCurrent(2)}
                                  >
                                    Previous
                                  </Button>
                                  <Button
                                    htmlType="submit"
                                    size="large"
                                    style={{ width: 200 }}
                                    className={`mt-4 ml-n1 ${style.greenBtn}`}
                                  >
                                    Next
                                  </Button>
                                </div>

                              </Form>
                            )}
                          />
                        </Card>
                        {/* <Card title="Other Problems" className="mt-4">
                        <div className="col-md-12 mt-3 ml-n3">
                          <div className="row">
                            <div className="col-md-12">
                              <Title style={{ paddingBottom: 20 }} level={4}>
                                Mental Health
                              </Title>
                            </div>
                          </div>
                        </div>
                        <Formik
                          enableReinitialize
                          initialValues={
                            healthHistoryModule?.otherProblemsHistory
                              ? {
                                otherProblemsHistory:
                                  healthHistoryModule?.otherProblemsHistory,
                              }
                              : {
                                otherProblemsHistory:
                                  OTHER_HEALTH_DATA?.otherProblemsHistory,
                              }
                          }
                          onSubmit={(values) => {
                            addPatientHealthHistory(
                              "Other Problems History",
                              values
                            );
                          }}
                          render={({ values, setFieldValue }) => (
                            <Form>
                              {console.log("values", values)}
                              <FieldArray
                                name="otherProblemsHistory"
                                render={(familyHealthHistoryHelper) => (
                                  <>
                                    {values.otherProblemsHistory.map(
                                      (otherProblemsHistoryData, index) => {
                                        if (
                                          otherProblemsHistoryData.type ===
                                          "checkboxes"
                                        ) {
                                          return (
                                            <FieldArray
                                              name={`otherProblemsHistory[${index}].checkboxes`}
                                              render={(
                                                otherProblemsHistoryHelper
                                              ) => (
                                                <>
                                                  {otherProblemsHistoryData.checkboxes.map(
                                                    (
                                                      checkboxData,
                                                      checkBoxIndex
                                                    ) => (
                                                      <>
                                                        <div
                                                          className="row"
                                                          style={{
                                                            display: "flex",
                                                            alignContent:
                                                              "center",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          <div className="col-12 col-sm-6 col-md-8 col-lg-9 col-xl-9 mt-2">
                                                            <Text>
                                                              {
                                                                checkboxData.label
                                                              }
                                                            </Text>
                                                          </div>
                                                          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3  mt-2">
                                                            <Switch
                                                              defaultChecked={
                                                                checkboxData.answer ===
                                                                  "yes"
                                                                  ? true
                                                                  : false
                                                              }
                                                              onChange={(e) => {
                                                                console.log(
                                                                  "e: ",
                                                                  e == true
                                                                    ? "yes"
                                                                    : "no"
                                                                );
                                                                otherProblemsHistoryHelper.replace(
                                                                  checkBoxIndex,
                                                                  {
                                                                    ...checkboxData,
                                                                    answer:
                                                                      e == true
                                                                        ? "yes"
                                                                        : "no",
                                                                  }
                                                                );
                                                              }}
                                                            />

                                                            {/* <Radio.Group
                                                              className=""
                                                              onChange={(e) => {
                                                                console.log(
                                                                  "e: ",
                                                                  e.target.value
                                                                );
                                                                e.preventDefault();
                                                                otherProblemsHistoryHelper.replace(
                                                                  checkBoxIndex,
                                                                  {
                                                                    ...checkboxData,
                                                                    answer:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <Radio.Button
                                                                style={
                                                                  checkboxData.answer ===
                                                                    "yes"
                                                                    ? {
                                                                      backgroundColor:
                                                                        "red",
                                                                      color:
                                                                        "#ffff",
                                                                    }
                                                                    : {}
                                                                }
                                                                value="yes"
                                                              >
                                                                Yes
                                                              </Radio.Button>
                                                              <Radio.Button
                                                                style={
                                                                  checkboxData.answer ===
                                                                    "no"
                                                                    ? {
                                                                      backgroundColor:
                                                                        "#689f38",
                                                                      color:
                                                                        "#ffff",
                                                                    }
                                                                    : {}
                                                                }
                                                                value="no"
                                                              >
                                                                No
                                                              </Radio.Button>
                                                            </Radio.Group> */}
                        {/* </div>
                                                        </div>
                                                        <hr />
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            />
                                          );
                                        }
                                        return "";
                                      }
                                    )}
                                  </>
                                )}
                              />

                              <div className="row ml-1 pb-5">
                                <Button
                                  size="large"
                                  style={{ width: 110 }}
                                  htmlType="button"
                                  className="ml-2 mr-3"
                                  onClick={() => setCurrent(2)}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  size="large"
                                  style={{ width: 110 }}
                                  className="ml-n1"
                                >
                                  Next
                                </Button>
                              </div>
                            </Form>
                          )}
                        />
                      </Card>  */}
                      </>

                    ) : current == 4 ? (
                      <>
                        <Card className="mt-1">
                          <Tabs defaultActiveKey="1" onChange={tabChange}>
                            <TabPane tab="Upload/Share Documents" key="1">
                              <div className="col-md-12 mt-1">
                                <div className="row">
                                  <div className="col-md-6">

                                  </div>
                                  <div
                                    className={`${style.btnDocument} col-md-6 pb-3`}
                                  >
                                    <Button
                                      style={{ width: "90px", height: "40px", marginRight: '10px',fontSize:16 }}
                                      htmlType="submit"
                                      className={style.borderedBtn}
                                      onClick={addDocument}
                                    >
                                      Add
                                    </Button>

                                    <Button
                                      style={{ width: "90px", height: "40px" ,fontSize:16}}
                                      htmlType="submit"
                                      className={style.borderedBtn}
                                      onClick={() => {
                                        setIsShareModalVisible(true);
                                      }}
                                      disabled={selectedDocuments?.length == 0}
                                    >
                                      Share
                                    </Button>
                                  </div>
                                </div>
                              </div>

                              <Table
                                size="small"
                                pagination={{
                                  defaultPageSize: 10,
                                  pageSizeOptions: ["30", "40"],
                                  showSizeChanger: false,
                                }}
                                columns={historyListColumn}
                                dataSource={allDocumentHistory}
                                scroll={{ x: 1000 }}
                                rowKey={(obj) => obj.id}
                              />

                              {/* <div className={style.div_row_flex}>
                                <label style={{ marginRight: 20 }}>
                                  Share With
                                </label>

                                <div style={{ width: "70%" }}>
                                  <div className={style.div_mainChip}>
                                    {multipleDoctorShare?.map(function (item) {
                                      return (
                                        <div className={style.chips}>
                                          {item.FirstName} {item.LastName}
                                          <Button
                                            style={{
                                              backgroundColor: "transparent",
                                              borderWidth: 0,
                                              marginLeft: 10,
                                              paddingLeft: 0,
                                              paddingRight: 0,
                                            }}
                                            onClick={() =>
                                              removeDoctorFromChip(item)
                                            }
                                          >
                                            <Cancel className={style.iconCross} />
                                          </Button>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <Input
                                    className={style.inputSearch}
                                    placeholder="Enter Doctor Name"
                                    // onChange={searchPatient}
                                    onChange={(e) =>
                                      setSearchTerm(e.target.value)
                                    }
                                    autoComplete="off"
                                    value={selectPatientName}
                                  />

                                  {name?.length != 0 ? (
                                    <div className={style.div_list}>
                                      {name?.map(function (item) {
                                        return (
                                          <Button
                                            className={style.filterName}
                                            style={{ minWidth: 125 }}
                                            onClick={() => selectName(item)}
                                            key={item.id}
                                          >
                                            <div className={style.filterName}>
                                              {" "}
                                              <div className={style.div_main_li}>
                                                {item.name}
                                                <div
                                                  style={{
                                                    fontSize: ".9rem",
                                                    color: "#6E87E9",
                                                    // boxShadow: ' 0 10px 6px -6px #777;',
                                                  }}
                                                >
                                                  {item.Email}
                                                </div>
                                                <div
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: ".9rem",
                                                  }}
                                                >
                                                  {item.Phone}
                                                </div>
                                                <div
                                                  style={{
                                                    fontSize: ".9rem",
                                                    color: "#6E87E9",

                                                    whiteSpace: "pre-wrap",
                                                  }}
                                                >
                                                  {item.Address1}
                                                </div>
                                              </div>
                                              <hr />
                                            </div>
                                          </Button>
                                        );
                                      })}
                                    </div>
                                  ) : null}
                                </div>

                                <Button
                                  style={{ width: "auto", height: "auto" }}
                                  htmlType="submit"
                                  className="btn btn-primary px-5 ml-4"
                                  onClick={shareWith}
                                  loading={shareLoader}
                                  disabled={selectedDocuments?.length == 0}
                                >
                                  Share
                                </Button>
                              </div> */}
                            </TabPane>
                            <TabPane tab="Shared Documents" key="2">
                              <Table
                                size="small"
                                pagination={{
                                  defaultPageSize: 10,
                                  pageSizeOptions: ["30", "40"],
                                  showSizeChanger: false,
                                }}
                                columns={sharedListColumn}
                                dataSource={allSharedDocuments}
                                scroll={{ x: 1000 }}
                                rowKey={(obj) => obj.id}
                              />
                            </TabPane>
                          </Tabs>

                          <div className="row pt-3 pb-5 align-items-center justify-content-center mt-5">
                            <Button
                              size="large"
                              style={{ width: 200 }}
                              htmlType="button"
                              className={`mt-4 ml-2 mr-3 ${style.borderedBtn}`}
                              onClick={() => setCurrent(3)}
                            >
                              Previous
                            </Button>
                            <Button
                              htmlType="submit"
                              size="large"
                              style={{ width: 200 }}
                              className={`mt-4 ml-n1 ${style.greenBtn}`}
                              onClick={() => {
                                setCurrent(5);
                              }}
                            >
                              Next
                            </Button>
                          </div>

                          {/* <div>
                            <Button
                              size="large"
                              style={{ width: 110 }}
                              className="mt-4 ml-2 mr-3"
                              onClick={() => setCurrent(3)}
                            >
                              Previous
                            </Button>
                            <Button
                              type="primary"
                              size="large"
                              style={{ width: 110 }}
                              className="mt-4 ml-n1"
                              onClick={() => {
                                setCurrent(5);
                              }}
                            >
                              Next
                            </Button>
                          </div> */}
                        </Card>
                        {/* <Card className="mt-4">
                        <Tabs defaultActiveKey="1" onChange={tabChange}>
                          <TabPane tab="Upload/Share Documents" key="1">
                            <div className="col-md-12 mt-3 ml-n3">
                              <div className="row">
                                <div className="col-md-6">
                                  <Title
                                    style={{ paddingBottom: 20 }}
                                    level={4}
                                  >
                                    History
                                  </Title>
                                </div>
                                <div
                                  className={`${style.btnDocument} col-md-6`}
                                >
                                  <Button
                                    style={{ width: "auto", height: "auto" }}
                                    htmlType="submit"
                                    className="btn btn-primary px-5"
                                    onClick={addDocument}
                                  >
                                    Add Document/Report
                                  </Button>
                                </div>
                              </div>
                            </div>

                            <Table
                              size="small"
                              pagination={{
                                defaultPageSize: 10,
                                pageSizeOptions: ["30", "40"],
                                showSizeChanger: false,
                              }}
                              columns={historyListColumn}
                              dataSource={allDocumentHistory}
                              scroll={{ x: 1000 }}
                              rowKey={(obj) => obj.id}
                            />

                            <div className={style.div_row_flex}>
                              <label style={{ marginRight: 20 }}>
                                Share With
                              </label>

                              <div style={{ width: "70%" }}>
                                <div className={style.div_mainChip}>
                                  {multipleDoctorShare?.map(function (item) {
                                    return (
                                      <div className={style.chips}>
                                        {item.FirstName} {item.LastName}
                                        <Button
                                          style={{
                                            backgroundColor: "transparent",
                                            borderWidth: 0,
                                            marginLeft: 10,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                          }}
                                          onClick={() =>
                                            removeDoctorFromChip(item)
                                          }
                                        >
                                          <Cancel className={style.iconCross} />
                                        </Button>
                                      </div>
                                    );
                                  })}
                                </div>

                                <Input
                                  className={style.inputSearch}
                                  placeholder="Enter Doctor Name"
                                  // onChange={searchPatient}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                  autoComplete="off"
                                  value={selectPatientName}
                                />

                                {name?.length != 0 ? (
                                  <div className={style.div_list}>
                                    {name?.map(function (item) {
                                      return (
                                        <Button
                                          className={style.filterName}
                                          style={{ minWidth: 125 }}
                                          onClick={() => selectName(item)}
                                          key={item.id}
                                        >
                                          <div className={style.filterName}>
                                            {" "}
                                            <div className={style.div_main_li}>
                                              {item.name}
                                              <div
                                                style={{
                                                  fontSize: ".9rem",
                                                  color: "#6E87E9",
                                                  // boxShadow: ' 0 10px 6px -6px #777;',
                                                }}
                                              >
                                                {item.Email}
                                              </div>
                                              <div
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: ".9rem",
                                                }}
                                              >
                                                {item.Phone}
                                              </div>
                                              <div
                                                style={{
                                                  fontSize: ".9rem",
                                                  color: "#6E87E9",

                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {item.Address1}
                                              </div>
                                            </div>
                                            <hr />
                                          </div>
                                        </Button>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>

                              <Button
                                style={{ width: "auto", height: "auto" }}
                                htmlType="submit"
                                className="btn btn-primary px-5 ml-4"
                                onClick={shareWith}
                                loading={shareLoader}
                                disabled={selectedDocuments?.length == 0}
                              >
                                Share
                              </Button>
                            </div>
                          </TabPane>
                          <TabPane tab="Shared Documents" key="2">
                            <Table
                              size="small"
                              pagination={{
                                defaultPageSize: 10,
                                pageSizeOptions: ["30", "40"],
                                showSizeChanger: false,
                              }}
                              columns={sharedListColumn}
                              dataSource={allSharedDocuments}
                              scroll={{ x: 1000 }}
                              rowKey={(obj) => obj.id}
                            />
                          </TabPane>
                        </Tabs>

                        <div>
                          <Button
                            size="large"
                            style={{ width: 110 }}
                            className="mt-4 ml-2 mr-3"
                            onClick={() => setCurrent(3)}
                          >
                            Previous
                          </Button>
                          <Button
                            type="primary"
                            size="large"
                            style={{ width: 110 }}
                            className="mt-4 ml-n1"
                            onClick={() => {
                              setCurrent(5);
                            }}
                          >
                            Next
                          </Button>
                        </div>
                      </Card> */}
                      </>
                    ) : current == 5 ? (
                      <>
                        <div className="mt-4" id="medicalHistoryCapture">
                          <div className={style.titleHeader}>
                            Patient Information
                          </div>
                          <div className={`row ${style.flex_div2}`} style={{ margin: '10px 20px' }}>
                            <div className="col" style={{ borderRight: '1px solid #ccc', padding: '10px' }}>
                              <h4 className={style.subtitleHeaderNew}>Name</h4>
                              {

                                (userData?.id === selFamilyMember) ? (<Text style={{fontSize:16}}>
                                  {userData?.FirstName +
                                    " " +
                                    userData?.LastName}
                                </Text>) : null
                              }
                              {
                                familyMembers.map((item) => (
                                  (item?.familymemberid === selFamilyMember) ? (
                                    <Text style={{fontSize:16}}>{item.userName}</Text>
                                  ) : null
                                ))


                              }

                              {/* {userType == "familyMember" ? (
                                <Text>{familyMemeberData?.userName}</Text>
                              ) : (
                                <Text>
                                  {userData?.FirstName +
                                    " " +
                                    userData?.LastName}
                                </Text>
                              )} */}
                            </div>

                            <div className="col" style={{ borderRight: '1px solid #ccc', padding: '10px' }}>
                              <h4 className={style.subtitleHeaderNew}>
                                Date of Birth
                              </h4>

                              {

                                (userData?.id === selFamilyMember) ? (
                                  <Text style={{fontSize:16}}>
                                    {userData?.DOB == null || userData?.DOB == ""
                                      ? "-"
                                      : moment(userData?.DOB).format(
                                        "DD-MMM-YYYY"
                                      )}
                                  </Text>
                                ) : null
                              }
                              {
                                familyMembers.map((item) => (
                                  (item?.familymemberid === selFamilyMember) ? (
                                    <Text style={{fontSize:16}}>{item.dateOfBirth}</Text>
                                  ) : null
                                ))


                              }



                              {/* {userType == "familyMember" ? (
                                <Text>{familyMemeberData?.dateOfBirth}</Text>
                              ) : (
                                <Text>
                                  {userData?.DOB == null || userData?.DOB == ""
                                    ? "-"
                                    : moment(userData?.DOB).format(
                                      "DD-MMM-YYYY"
                                    )}
                                </Text>
                              )} */}
                            </div>
                            <div className="col" style={{ padding: '10px' }}>
                              <h4 className={style.subtitleHeaderNew}>
                                Gender
                              </h4>


                              {

                                (userData?.id === selFamilyMember) ? (
                                  <Text style={{ textTransform: "capitalize",fontSize:16 }} >
                                    {userData?.gender}
                                  </Text>
                                ) : null
                              }
                              {
                                familyMembers.map((item) => (
                                  (item?.familymemberid === selFamilyMember) ? (
                                    <Text style={{fontSize:16}}>{item.gender}</Text>
                                  ) : null
                                ))


                              }

                              {/* {userType == "familyMember" ? (
                                <Text style={{ textTransform: "capitalize" }}>
                                  {familyMemeberData?.gender}
                                </Text>
                              ) : (
                                <Text style={{ textTransform: "capitalize" }} >
                                  {userData?.gender}
                                </Text>
                              )} */}
                            </div>
                          </div>

                          <PersonalHealthHistoryCard
                            loading={loading}
                            setOfQuestionsAnswers={healthHistoryModule || []}
                            title="Personal Health History"
                          />
                          <HealthHistoryCard
                            loading={loading}
                            setOfQuestionsAnswers={healthHistoryModule || []}
                            title="Medical History"
                          />
                          <FamilyHealthHistoryCard
                            loading={loading}
                            setOfQuestionsAnswers={healthHistoryModule || []}
                            title="Patient Family Health History"
                          />
                          <OtherProblemHealthHistoryCard
                            loading={loading}
                            setOfQuestionsAnswers={healthHistoryModule || []}
                            title="Other Problem Health History"
                          />

                          <div className={`mt-4  ${style.titleHeader}`}>
                            Vitals History
                          </div>

                          <div className="card-body">
                            <div className={style.wrapper}>
                              <div className="card">
                                <div className="card-header ">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary mr-2 mb-2"
                                    onClick={() => {
                                      submitDate(7);
                                    }}
                                  >
                                    7-days
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary mr-2 mb-2"
                                    onClick={() => {
                                      submitDate(30);
                                    }}
                                  >
                                    1-Mon
                                  </button>
                                </div>

                                <div className="card-body">
                                  <div
                                    className="row"
                                    style={{ marginTop: 15 }}
                                  >
                                    <div className="col-sm-12 col-md-8 col-xl-5">
                                      <div
                                        id="chartdivBp"
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-12 col-md-8 col-xl-5">
                                      <div
                                        id="chartdivHeartRate"
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: 15 }}
                                  >
                                    <div className="col-sm-12 col-md-8 col-xl-5">
                                      <div
                                        id="chartdivOxygen"
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-12 col-md-8 col-xl-5">
                                      <div
                                        id="chartdivTemp"
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="row"
                                    style={{ marginTop: 15 }}
                                  >
                                    <div className="col-sm-12 col-md-8 col-xl-5">
                                      <div
                                        id="chartdivResp"
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-12 col-md-8 col-xl-5">
                                      <div
                                        id="chartdivHeartGlucose"
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-sm-12 col-md-8 col-xl-5">
                                <p className={`${style.vitals_title} mt-4`}>
                                  Blood Pressure
                                </p>
                                <Line options={options} data={data} />
                              </div>
                              <div className="col-1"></div>
                              <div className="col-sm-12 col-md-8 col-xl-5">
                                <p className={`${style.vitals_title} mt-4`}>
                                  Pulse Rate
                                </p>
                                <Line options={options} data={dataRate} />
                              </div>
                            </div> */}

                            {/* <div className="row">
                              <div className="col-sm-12 col-md-8 col-xl-5">
                                <p className={`${style.vitals_title} mt-4`}>
                                  Oxygen Saturation
                                </p>
                                <Line options={options} data={dataOxygen} />
                              </div>
                              <div className="col-1"></div>
                              <div className="col-sm-12 col-md-8 col-xl-5">
                                <p className={`${style.vitals_title} mt-4`}>
                                  Temperature
                                </p>
                                <Line options={options} data={dataTemp} />
                              </div>
                            </div> */}

                            {/* <div className="row">
                              <div className="col-sm-12 col-md-8 col-xl-5">
                                <p className={`${style.vitals_title} mt-4`}>
                                  Respiratory Rate
                                </p>
                                <Line options={options} data={dataRes} />
                              </div>
                              <div className="col-1"></div>
                              <div className="col-sm-12 col-md-8 col-xl-5">
                                <p className={`${style.vitals_title} mt-4`}>
                                  Glucose Levels
                                </p>
                                <Line options={options} data={dataGlu} />
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="row pt-3 pb-5 align-items-center justify-content-center mt-5">
                          <Button
                            size="large"
                            style={{ width: 200 }}
                            htmlType="button"
                            className={`mt-4 ml-2 mr-3 ${style.borderedBtn}`}
                            onClick={() => setCurrent(4)}
                          >
                            Previous
                          </Button>
                          <Button
                            htmlType="submit"
                            size="large"
                            style={{ width: 200 }}
                            className={`mt-4 ml-n1 ${style.greenBtn}`}
                            onClick={() => {
                              setCurrent(6);
                            }}
                          >
                            Next
                          </Button>
                          <Button
                            type="primary"
                            size="large"
                            className={`mt-4 ml-2 mr-3 ${style.borderedBtn}`}
                            style={{ width: 200 }}
                            loading={pdfLoader}
                            onClick={printDocument}
                          >
                            Export
                          </Button>
                        </div>
                        {/* <div className={style.flex_div}>
                          <div>
                            <Button
                              size="large"
                              style={{ width: 110 }}
                              className="mt-4 ml-2 mr-3"
                              onClick={() => setCurrent(4)}
                            >
                              Previous
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="large"
                              style={{ width: 110 }}
                              className="mt-4 ml-2 mr-3"
                              onClick={() => setCurrent(6)}
                            >
                              Next
                            </Button>
                          </div>
                          <Button
                            type="primary"
                            size="large"
                            className="mt-4 ml-2 mr-3"
                            style={{ width: "120px" }}
                            loading={pdfLoader}
                            onClick={printDocument}
                          >
                            Export
                          </Button>
                        </div> */}
                      </>
                    ) : (
                      <>



                        <Card className="mt-1">
                          <Collapse expandIconPosition={"right"}>
                            <Panel header="Introduction" key="1" style={{fontSize:15}}>

                              <p className={style.pText}>
                                Telehealth involves the use of electronic
                                communications to enable providers at different
                                locations to share individual client information for
                                the purpose of improving client care. Providers may
                                include primary care practitioners, specialists,
                                and/or subspecialists. The information may be used
                                for diagnosis, therapy, follow-up and/or education,
                                and may include any of the following:
                              </p>
                              <ul className={style.ulText}>
                                <li>Client health records</li>
                                <li>Live two-way audio and video</li>
                                <li>
                                  Output data from health devices, sound and video
                                  files
                                </li>
                              </ul>
                              <p className={style.pText}>
                                Electronic systems used will incorporate network and
                                software security protocols to protect the
                                confidentiality of client identification and imaging
                                data and will include measures to safeguard the data
                                and to ensure its integrity against intentional or
                                unintentional corruption.{" "}
                              </p>

                            </Panel>
                            <Panel header="Expected Benefits" key="2" style={{fontSize:15}}>

                              <ul className={style.ulText}>
                                <li>
                                  Improved access to care by enabling a client to
                                  remain in his/her provider's office (or at a
                                  remote site) while the providers obtains test
                                  results and consults from practitioners at
                                  distant/other sites.
                                </li>
                                <li>
                                  More efficient client evaluation and management.
                                </li>
                                <li>
                                  Obtaining expertise of a distant specialist.
                                </li>
                              </ul>
                            </Panel>
                            <Panel header="Possible Risks" key="3" style={{fontSize:15}}>
                              <p className={style.pText}>
                                There are potential risks associated with the use of
                                telehealth. These risks include, but may not be
                                limited to:{" "}
                              </p>
                              <ul className={style.ulText}>
                                <li>
                                  Improved access to care by enabling a client to
                                  remain in his/her provider's office (or at a
                                  remote site) while the providers obtains test
                                  results and consults from practitioners at
                                  distant/other sites.
                                </li>
                                <li>
                                  Delays in evaluation and treatment could occur due
                                  to deficiencies or failures of the equipment
                                </li>
                                <li>
                                  In very rare instances, security protocols could
                                  fail, causing a breach of privacy of personal
                                  health information.
                                </li>
                                <li>
                                  In rare cases, a lack of access to complete health
                                  records may result in interactions or allergic
                                  reactions or other judgment errors.
                                </li>
                              </ul>
                            </Panel>
                            <Panel header="By signing this form, I understand the following" key="4" style={{fontSize:15}}>
                              <ol className={style.ulText}>
                                <li>
                                  I understand that the laws that protect privacy
                                  and the confidentiality of health information also
                                  apply to telehealth, and that no information
                                  obtained in the use of telehealth which identifies
                                  me will be disclosed to researchers or other
                                  entities without my consent.
                                </li>
                                <li>
                                  I understand that I have the right to withhold or
                                  withdraw my consent to the use of telehealth in
                                  the course of my care at any time, without
                                  affecting my right to future care or treatment.
                                </li>
                                <li>
                                  I understand that I have the right to inspect all
                                  information obtained and recorded in the course of
                                  a telehealth interaction, and may receive copies
                                  of this information for a reasonable fee.
                                </li>
                                <li>
                                  I understand that a variety of alternative methods
                                  of health care may be available to me, and that I
                                  may choose one or more of these at any time. My
                                  provider has explained the alternatives to my
                                  satisfaction.
                                </li>
                                <li>
                                  I understand that telehealth may involve
                                  electronic communication of my personal health
                                  information to other practitioners who may be
                                  located in other areas, including out of state.
                                </li>
                                <li>
                                  I understand that it is my duty to inform my
                                  provider of electronic interactions regarding my
                                  care that I may have with other healthcare
                                  providers.
                                </li>
                                <li>
                                  I understand that I may expect the anticipated
                                  benefits from the use of telehealth in my care,
                                  but that no results can be guaranteed or assured.
                                </li>
                              </ol>
                            </Panel>
                            <Panel header="Patient consent to the use of telehealth" key="5" style={{fontSize:15}}>
                              {userType == "familyMember" ? (
                                <p className={style.pText}>
                                  I, {familyMemeberData?.userName} have read and
                                  understand the information provided above
                                  regarding telehealth, have discussed it with my
                                  provider or such assistants as may be designated,
                                  and all of my questions have been answered to my
                                  satisfaction. I hereby give my informed consent
                                  for the use of telehealth in my care.{" "}
                                </p>
                              ) : (
                                <p className={style.pText}>
                                  I, {userData?.FirstName} {userData?.LastName},
                                  have read and understand the information provided
                                  above regarding telehealth, have discussed it with
                                  my provider or such assistants as may be
                                  designated, and all of my questions have been
                                  answered to my satisfaction. I hereby give my
                                  informed consent for the use of telehealth in my
                                  care.{" "}
                                </p>
                              )}


                            </Panel>
                          </Collapse>

                          <div
                            style={{ marginTop: 16 }}

                          >
                            {errorSignature ? (
                              <span className={style.errorMessage}>
                                Signature is Required
                              </span>
                            ) : null}

                            <Formik
                              initialValues={
                                healthHistoryModule?.consentForm?.consentForm
                                  ? {
                                    // consentForm:
                                    //   // healthHistoryModule?.consentForm,
                                    //   healthHistoryModule?.consentForm?.consentForm,
                                    // consentForm:{
                                    //   consentForm:{
                                    signatureUrl:
                                      healthHistoryModule?.consentForm
                                        ?.consentForm?.signatureUrl,
                                    signatureDate:
                                      healthHistoryModule?.consentForm
                                        ?.consentForm?.signatureDate,
                                    authorizedPerson:
                                      healthHistoryModule?.consentForm
                                        ?.consentForm?.authorizedPerson,
                                    //   }
                                    // }
                                  }
                                  : {
                                    signatureDate: "",
                                    authorizedPerson: "",
                                  }
                              }
                              onSubmit={(values) => {
                                console.log(values, "vallluueeess");
                                values = {
                                  // consentForm: {
                                  //   consentForm: {
                                  signatureUrl: image,
                                  signatureDate: moment().format("YYYY-MM-DD"),
                                  authorizedPerson: values.authorizedPerson,
                                  //   }
                                  // },
                                };

                                let dattt = {
                                  consentForm: {
                                    consentForm: {
                                      signatureUrl: values.signatureUrl,
                                      signatureDate: values.signatureDate,
                                      authorizedPerson: values.authorizedPerson,
                                    },
                                  },
                                };
                                addPatientHealthHistory("Consent form", dattt);
                                console.log(image, "check imag");
                              }}
                              render={({
                                values,
                                setFieldValue,
                                handleChange,
                              }) => (
                                <Form>
                                  <div>
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        width: "250px",
                                      }}
                                      className="ml-0 border border-primary border-1 text-left mt-4"
                                    >
                                      <div
                                        // id={`unique500`}
                                        style={{
                                          overflow: "hidden",
                                          width: "300px",
                                        }}
                                      >
                                        {values?.signatureUrl === "" ||
                                          values?.signatureUrl === null ||
                                          image == null ||
                                          image == "" ||
                                          edit ? (
                                          <CanvasDraw
                                            id="unique500"
                                            width="250px"
                                            ref={_sketch}
                                            brushColor="black"
                                            brushRadius={2}
                                            lazyRadius={2}
                                            canvasWidth={250}
                                            canvasHeight={150}
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              overflow: "hidden",
                                              width: "250px",
                                              height: "150px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {console.log(
                                              "consentForm ***",
                                              image
                                            )}
                                            <img
                                              src={
                                                image
                                                  ? image
                                                  : values?.signatureUrl || ""
                                              }
                                              width=" "
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        backgroundColor: "var(--light-primary-bg-color)",
                                        width: 250,
                                        padding: 7,
                                        borderBottom: "1px solid var(--primary-color)",
                                        borderLeft: " 1px solid var(--primary-color)",
                                        borderRight: " 1px solid var(--primary-color)",

                                      }}
                                    >
                                      <Button
                                        className={style.redoBtn}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (_sketch?.current) {
                                            _sketch?.current?.undo();
                                            // console.log(_sketch.current);
                                            setCanvass([]);
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faUndo} />{" "}
                                      </Button>
                                      <div className="float-right">
                                        <Button
                                          className={style.redoBtn}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            _save();
                                          }}
                                        >
                                          {loading ? (
                                            <Spin />
                                          ) : (
                                            <FontAwesomeIcon icon={faSave} />
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    {/* <div className="col-sm-4">
                                    <div className="pt-2">
                                      <Text>Date:</Text>
                                      <DatePicker
                                        disabledDate={(current) => {
                                          let customDate =
                                            moment().format("YYYY-MM-DD");
                                          return (
                                            current &&
                                            current <
                                            moment(customDate, "YYYY-MM-DD")
                                          );
                                        }}
                                        className="w-100"
                                        name="signatureDate"
                                        onChange={(date, dateString) => {
                                          console.log(
                                            "dateString: ",
                                            dateString
                                          );
                                          setFieldValue(
                                            "signatureDate",
                                            dateString
                                          );
                                        }}
                                        value={moment(moment(values.consentForm.signatureDate).format('YYYY-MM-DD'))}
                                      />
                                    </div>
                                  </div> */}

                                    <div className="col-sm-12 mt-2">
                                      <div className="w-100 pt-2" style={{fontSize:16}}>
                                        <Text>
                                          If authorized Signature, relationship to
                                          client:
                                        </Text>
                                      </div>

                                      <div className="form-group mt-2" style={{ width: '300px' }}>
                                        <Field
                                          as="select"
                                          name="authorizedPerson"
                                          className="form-control"


                                        >
                                          <option
                                            value="Select Relationship"
                                            key="Select Relationship"
                                          >
                                            Select Relationship
                                          </option>

                                          {relationShip.map((relation) => (
                                            <option value={relation.name}>
                                              {relation.name}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>

                                      {/* <div>
                                      <Input
                                        placeholder="Relationship"
                                        name="authorizedPerson"
                                        onChange={handleChange}
                                      // value={
                                      //   values?.consentForm?.clientSignature
                                      // }
                                      />
                                    </div> */}
                                    </div>
                                    <div className="col-sm-2"></div>
                                  </div>

                                  <div className="row pt-3 pb-5align-items-center justify-content-center mt-5">
                                    <Button
                                      size="large"
                                      style={{ width: 200 }}
                                      htmlType="button"
                                      className={`mt-4 ml-2 mr-3 ${style.borderedBtn}`}
                                      onClick={() => setCurrent(5)}
                                    >
                                      Previous
                                    </Button>
                                    <Button
                                      disabled={image == null}
                                      htmlType="submit"
                                      size="large"
                                      style={{ width: 200 }}
                                      className={`mt-4 ml-n1 ${style.greenBtn}`}

                                    >
                                      Submit
                                    </Button>
                                  </div>

                                </Form>
                              )}
                            />
                          </div>
                        </Card>
                        {/* <Card title="Consent Form" className="mt-4">
                        <Card type="inner" title="Introduction">
                          <p>
                            Telehealth involves the use of electronic
                            communications to enable providers at different
                            locations to share individual client information for
                            the purpose of improving client care. Providers may
                            include primary care practitioners, specialists,
                            and/or subspecialists. The information may be used
                            for diagnosis, therapy, follow-up and/or education,
                            and may include any of the following:
                          </p>
                          <ul>
                            <li>Client health records</li>
                            <li>Live two-way audio and video</li>
                            <li>
                              Output data from health devices, sound and video
                              files
                            </li>
                          </ul>
                          <p>
                            Electronic systems used will incorporate network and
                            software security protocols to protect the
                            confidentiality of client identification and imaging
                            data and will include measures to safeguard the data
                            and to ensure its integrity against intentional or
                            unintentional corruption.{" "}
                          </p>
                        </Card>
                        <Card
                          style={{ marginTop: 16 }}
                          type="inner"
                          title="Expected Benefits"
                        >
                          <ul>
                            <li>
                              Improved access to care by enabling a client to
                              remain in his/her provider's office (or at a
                              remote site) while the providers obtains test
                              results and consults from practitioners at
                              distant/other sites.
                            </li>
                            <li>
                              More efficient client evaluation and management.
                            </li>
                            <li>
                              Obtaining expertise of a distant specialist.
                            </li>
                          </ul>
                        </Card>
                        <Card
                          style={{ marginTop: 16 }}
                          type="inner"
                          title="Possible Risks"
                        >
                          <p>
                            There are potential risks associated with the use of
                            telehealth. These risks include, but may not be
                            limited to:{" "}
                          </p>
                          <ul>
                            <li>
                              Improved access to care by enabling a client to
                              remain in his/her provider's office (or at a
                              remote site) while the providers obtains test
                              results and consults from practitioners at
                              distant/other sites.
                            </li>
                            <li>
                              Delays in evaluation and treatment could occur due
                              to deficiencies or failures of the equipment
                            </li>
                            <li>
                              In very rare instances, security protocols could
                              fail, causing a breach of privacy of personal
                              health information.
                            </li>
                            <li>
                              In rare cases, a lack of access to complete health
                              records may result in interactions or allergic
                              reactions or other judgment errors.
                            </li>
                          </ul>
                        </Card>
                        <Card
                          style={{ marginTop: 16 }}
                          type="inner"
                          title="By signing this form, I understand the following"
                        >
                          <ol>
                            <li>
                              I understand that the laws that protect privacy
                              and the confidentiality of health information also
                              apply to telehealth, and that no information
                              obtained in the use of telehealth which identifies
                              me will be disclosed to researchers or other
                              entities without my consent.
                            </li>
                            <li>
                              I understand that I have the right to withhold or
                              withdraw my consent to the use of telehealth in
                              the course of my care at any time, without
                              affecting my right to future care or treatment.
                            </li>
                            <li>
                              I understand that I have the right to inspect all
                              information obtained and recorded in the course of
                              a telehealth interaction, and may receive copies
                              of this information for a reasonable fee.
                            </li>
                            <li>
                              I understand that a variety of alternative methods
                              of health care may be available to me, and that I
                              may choose one or more of these at any time. My
                              provider has explained the alternatives to my
                              satisfaction.
                            </li>
                            <li>
                              I understand that telehealth may involve
                              electronic communication of my personal health
                              information to other practitioners who may be
                              located in other areas, including out of state.
                            </li>
                            <li>
                              I understand that it is my duty to inform my
                              provider of electronic interactions regarding my
                              care that I may have with other healthcare
                              providers.
                            </li>
                            <li>
                              I understand that I may expect the anticipated
                              benefits from the use of telehealth in my care,
                              but that no results can be guaranteed or assured.
                            </li>
                          </ol>
                        </Card>
                        <Card
                          style={{ marginTop: 16 }}
                          type="inner"
                          title="Patient consent to the use of telehealth"
                        >
                          {userType == "familyMember" ? (
                            <p>
                              I, {familyMemeberData?.userName} have read and
                              understand the information provided above
                              regarding telehealth, have discussed it with my
                              provider or such assistants as may be designated,
                              and all of my questions have been answered to my
                              satisfaction. I hereby give my informed consent
                              for the use of telehealth in my care.{" "}
                            </p>
                          ) : (
                            <p>
                              I, {userData?.FirstName} {userData?.LastName},
                              have read and understand the information provided
                              above regarding telehealth, have discussed it with
                              my provider or such assistants as may be
                              designated, and all of my questions have been
                              answered to my satisfaction. I hereby give my
                              informed consent for the use of telehealth in my
                              care.{" "}
                            </p>
                          )}

                          {errorSignature ? (
                            <span className={style.errorMessage}>
                              Signature is Required
                            </span>
                          ) : null}

                          <Formik
                            initialValues={
                              healthHistoryModule?.consentForm?.consentForm
                                ? {
                                  // consentForm:
                                  //   // healthHistoryModule?.consentForm,
                                  //   healthHistoryModule?.consentForm?.consentForm,
                                  // consentForm:{
                                  //   consentForm:{
                                  signatureUrl:
                                    healthHistoryModule?.consentForm
                                      ?.consentForm?.signatureUrl,
                                  signatureDate:
                                    healthHistoryModule?.consentForm
                                      ?.consentForm?.signatureDate,
                                  authorizedPerson:
                                    healthHistoryModule?.consentForm
                                      ?.consentForm?.authorizedPerson,
                                  //   }
                                  // }
                                }
                                : {
                                  signatureDate: "",
                                  authorizedPerson: "",
                                }
                            }
                            onSubmit={(values) => {
                              console.log(values, "vallluueeess");
                              values = {
                                // consentForm: {
                                //   consentForm: {
                                signatureUrl: image,
                                signatureDate: moment().format("YYYY-MM-DD"),
                                authorizedPerson: values.authorizedPerson,
                                //   }
                                // },
                              };

                              let dattt = {
                                consentForm: {
                                  consentForm: {
                                    signatureUrl: values.signatureUrl,
                                    signatureDate: values.signatureDate,
                                    authorizedPerson: values.authorizedPerson,
                                  },
                                },
                              };
                              addPatientHealthHistory("Consent form", dattt);
                              console.log(image, "check imag");
                            }}
                            render={({
                              values,
                              setFieldValue,
                              handleChange,
                            }) => (
                              <Form>
                                <div>
                                  <div
                                    style={{
                                      overflow: "hidden",
                                      width: "250px",
                                    }}
                                    className="ml-0 border border-primary border-1 text-left"
                                  >
                                    <div
                                      // id={`unique500`}
                                      style={{
                                        overflow: "hidden",
                                        width: "300px",
                                      }}
                                    >
                                      {values?.signatureUrl === "" ||
                                        values?.signatureUrl === null ||
                                        image == null ||
                                        image == "" ||
                                        edit ? (
                                        <CanvasDraw
                                          id="unique500"
                                          width="250px"
                                          ref={_sketch}
                                          brushColor="black"
                                          brushRadius={2}
                                          lazyRadius={2}
                                          canvasWidth={250}
                                          canvasHeight={150}
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            overflow: "hidden",
                                            width: "250px",
                                            height: "150px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {console.log(
                                            "consentForm ***",
                                            image
                                          )}
                                          <img
                                            src={
                                              image
                                                ? image
                                                : values?.signatureUrl || ""
                                            }
                                            width=" "
                                            alt=""
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      backgroundColor: "#246ec63b",
                                      width: 250,
                                      padding: 7,
                                    }}
                                  >
                                    <Button
                                      style={{ borderRadius: "100%" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (_sketch?.current) {
                                          _sketch?.current?.undo();
                                          // console.log(_sketch.current);
                                          setCanvass([]);
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faUndo} />{" "}
                                    </Button>
                                    <div className="float-right">
                                      <Button
                                        style={{ borderRadius: "100%" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          _save();
                                        }}
                                      >
                                        {loading ? (
                                          <Spin />
                                        ) : (
                                          <FontAwesomeIcon icon={faSave} />
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {/* <div className="col-sm-4">
                                    <div className="pt-2">
                                      <Text>Date:</Text>
                                      <DatePicker
                                        disabledDate={(current) => {
                                          let customDate =
                                            moment().format("YYYY-MM-DD");
                                          return (
                                            current &&
                                            current <
                                            moment(customDate, "YYYY-MM-DD")
                                          );
                                        }}
                                        className="w-100"
                                        name="signatureDate"
                                        onChange={(date, dateString) => {
                                          console.log(
                                            "dateString: ",
                                            dateString
                                          );
                                          setFieldValue(
                                            "signatureDate",
                                            dateString
                                          );
                                        }}
                                        value={moment(moment(values.consentForm.signatureDate).format('YYYY-MM-DD'))}
                                      />
                                    </div>
                                  </div> */}
                        {/* 
                                  <div className="col-sm-12">
                                    <div className="w-100 pt-2">
                                      <Text>
                                        If authorized Signature, relationship to
                                        client:
                                      </Text>
                                    </div>

                                    <div className="form-group">
                                      <Field
                                        as="select"
                                        name="authorizedPerson"
                                        className="form-control"
                                      >
                                        <option
                                          value="Select Relationship"
                                          key="Select Relationship"
                                        >
                                          Select Relationship
                                        </option>

                                        {relationShip.map((relation) => (
                                          <option value={relation.name}>
                                            {relation.name}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>

                                   
                                  </div>
                                  <div className="col-sm-2"></div>
                                </div>
                                <div className="row">
                                  <Button
                                    size="large"
                                    style={{ width: 110 }}
                                    htmlType="button"
                                    className="mt-5 ml-2 mr-2"
                                    onClick={() => setCurrent(5)}
                                  >
                                    Previous
                                  </Button>
                                  <Button
                                    disabled={image == null}
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    style={{ width: 110 }}
                                    className="mt-5 ml-2"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Form>
                            )}
                          />
                        </Card>
                      </Card>  */}
                      </>
                    )}
                  </>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        width={600}
        footer={null}
        title="Share Document/Report"
        visible={isShareModalVisible}
        onCancel={handleCancelShare}
      >

        <div className={style.div_row_flex}>

          <div style={{ width: "70%" }}>


            <Input
              className={style.inputSearch}
              placeholder="Enter Doctor Name"
              // onChange={searchPatient}
              onChange={(e) =>
                setSearchTerm(e.target.value)
              }
              autoComplete="off"
              value={selectPatientName}
            />


            {name?.length != 0 ? (
              <div className={style.div_list}>
                {name?.map(function (item) {
                  return (
                    <Button
                      className={style.filterName}
                      style={{ minWidth: 125 }}
                      onClick={() => selectName(item)}
                      key={item.id}
                    >
                      <div className={style.filterName}>
                        {" "}
                        <div className={style.div_main_li}>
                          {item.name}
                          <div
                            style={{
                              fontSize: ".9rem",
                              color: "#6E87E9",
                              // boxShadow: ' 0 10px 6px -6px #777;',
                            }}
                          >
                            {item.Email}
                          </div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: ".9rem",
                            }}
                          >
                            {item.Phone}
                          </div>
                          <div
                            style={{
                              fontSize: ".9rem",
                              color: "#6E87E9",

                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item.Address1}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </Button>
                  );
                })}
              </div>
            ) : null}
          </div>

          {/* <Button
            style={{ width: "auto", height: "auto" }}
            htmlType="submit"
            className="btn btn-primary px-5 ml-4"
            onClick={shareWith}
            loading={shareLoader}
            disabled={selectedDocuments?.length == 0}
          >
            Share
          </Button> */}



        </div>

        <div className={style.div_mainChip}>
          {multipleDoctorShare?.map(function (item) {
            return (
              <div className={style.chips}>
                {item.FirstName} {item.LastName}
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 0,
                    marginLeft: 10,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  onClick={() =>
                    removeDoctorFromChip(item)
                  }
                >
                  <Cancel className={style.iconCross} />
                </Button>
              </div>
            );
          })}
        </div>

        <div className="row  pb-5 align-items-center justify-content-center mt-5">
          <Button
            size="large"
            style={{ width: 150 }}
            htmlType="button"
            className={`ml-2 mr-3 ${style.borderedBtn}`}
            onClick={handleCancelShare}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            size="large"
            style={{ width: 150 }}
            className={`ml-n1 ${style.greenBtn}`}
            onClick={shareWith}
            loading={shareLoader}
            disabled={selectedDocuments?.length == 0}
          >
            Share
          </Button>
        </div>

      </Modal>

      <Modal
        footer={null}
        width={600}
        title="Upload Document/Report"
        visible={isUploadModalVisible}
        onCancel={handleCancelUpload}
      >
        <FormAntd
          style={{ width: "100%" }}
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinishUpload}
          initialValues={{}}
        >
          <div className="col-12">
            <Dragger
              {...props}
              style={{ width: "100%", padding: 20, marginBottom: 20 }}
              showUploadList={false}
            >
              {" "}
              <p className="mt-1">
                {" "}
                <img
                  src="Images/cloud-upload.png"
                  alt="Upload icon"
                />
              </p>{" "}
              <p
                className="mt-2 "
                style={{ fontSize: "16px" }}
              >
                Drag and Drop file Here
              </p>{" "}
              <Button
                type="primary"
                className={`${style.btnBrowse} mt-2`}
              >
                Browse Files
              </Button>
            </Dragger>
          </div>

          <div className="col-12">
            <div className="row">
              {/* <div className="col-6">
                <FormAntd.Item
                  name="uploadedfor"
                  label="Uploaded For"
                  rules={[
                    {
                      required: true,
                      message: "Please Select user for which we want to upload",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Uploaded For"
                    optionFilterProp="children"
                    onChange={(e) => {
                      console.log(e);
                      setUploadedFor(e);
                    }}
                  >
                    <Option value={userData.id} key="own">
                      Own
                    </Option>
                    {familyMembers.map((fam) => (
                      <Option
                        value={fam.familymemberid}
                        key={fam.familymemberid}
                      >
                        {fam.userName}
                      </Option>
                    ))}
                  </Select>
                </FormAntd.Item>
              </div> */}

              <div className="col-8">
                <FormAntd.Item
                  name="doctype"
                  label="Document Type"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Document type",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Document Type"
                    optionFilterProp="children"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  >
                    <Option value="Doctor Report">Doctor Report</Option>
                    <Option value="Medical Summary">Medical Summary</Option>
                    <Option value="X RAY Report">X RAY Report</Option>
                    <Option value="Medical Report">Medical Report</Option>
                    <Option value="Others">Others</Option>
                  </Select>
                </FormAntd.Item>
              </div>

              <div className="col-12">
                <FormAntd.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    className="form-control"
                    rows={4}
                    placeholder="Enter decription"
                  />
                </FormAntd.Item>
              </div>
            </div>

            <div className="row pt-2 pb-2 align-items-center justify-content-center mt-2">
              <Button
                size="large"
                style={{ width: 150 }}
                htmlType="button"
                className={`ml-2 mr-3 ${style.borderedBtn}`}
                onClick={handleCancelUpload}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                size="large"
                style={{ width: 150 }}
                className={`ml-n1 ${style.greenBtn}`}
                loading={btnLoader}
              >
                Upload
              </Button>
            </div>

            {/* <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: 110 }}
              className="mt-4 ml-n1"
              loading={btnLoader}
            >
              Upload
            </Button> */}
          </div>
        </FormAntd>
      </Modal>

      {openModal && (
        <SweetAlert
          warning
          title="Do you want to save changes?"
          onConfirm={saveDetail}
          onCancel={onCancel}
          showCancel={true}
          // focusCancelBtn={true}
          // confirmBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      )}
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default MedicaldDetails;
