import { useState, useEffect } from "react";
import { TopBar } from "components";
import DashboardSidebar from "../sidebar/sidebar";
import Amplify, { Auth } from "aws-amplify";
import * as Yup from "yup";
import { Drawer, notification } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Footer from "components/footerFix";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals } from "@fortawesome/free-solid-svg-icons";
import { Link,useHistory } from "react-router-dom";
import style from "./style.module.scss";
import { AppointmentRequestType, setSelectedFamilyMember } from "redux/actions/userActions";
import { useDispatch } from "react-redux";
import { UPDATEDPOST } from "../../../services/common.api";
import { useSelector } from "react-redux";
import store from 'store'
import PageHeader from "components/pageHeader";
const ChangePassword = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const changePassword = async (newPasword, oldPassword) => {
    const accessToken = store.get("accessToken")
    console.log("newPasword, oldPassword: ", newPasword, oldPassword);

    let logBody = {
      message: `User - ${userData?.sub} - Change Password - Change Password Page`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Change Password Page`,
    }

    logEvents(logBody);

    try {
      // const user = await Auth.currentAuthenticatedUser();
      // if (user) {
        // const changedPasswordResponse = await Auth.changePassword(
        //   user,
        //   oldPassword,
        //   newPasword
        // );
        let data = {
          oldPassword : oldPassword,
          newPassword : newPasword,
          accessToken : accessToken
        }
        const changedPasswordResponse = await UPDATEDPOST(`changepassword`, data)
        if (changedPasswordResponse) {
          console.log("changedPasswordResponse: ", changedPasswordResponse);
          notification.success({
            message: "Password Successfully Changed",
          });
          history.push("/dashboard"); 
          // setSubmitting(false);
          return true;
        }
        // return false;
      // }
      return false;
    } catch (error) {
      console.log("error : ", error);
      // setSubmitting(false);
      notification.error({
        message: "Incorrect Old password",
      });
      return false;
    }
  };

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const userData = useSelector((state: any) => state.userReducer.user);

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {

    console.log(userData,"userdatatatatatata");

    let logBody = {
      message: "Change Password Page - Page Load",
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Change Password Page`,
    }

    logEvents(logBody);

    dispatch(setSelectedFamilyMember({}));
    dispatch(AppointmentRequestType("Own"));

    window.scrollTo(0, 0);
  }, []);


  return (
    <div style={{height:'100vh'}}>
      <TopBar onShow={showDrawer}  />
     
     
        <div className="content" style={{ height: '100%' }}>
          <div className="container-fluid" style={{ height: '100%' }}>
            <div className="row" style={{ height: '100%' }}>
              <div className={`col-lg-4 col-xl-2 col-sm-12 d-none d-xl-block theiaStickySidebar pr-0`} style={{ height: '100%' }}>
                <DashboardSidebar />
              </div>
              <Drawer closable={false} width={300} placement="left" onClose={onClose} visible={visible} style={{ paddingTop: 0 }}>
                <div className="widget-profile pro-widget-content pt-0 pb-4">
                  <div className="profile-info-widget">
                    <img src="../Images/logo.png" height="50" alt="" />
                  </div>
                </div>
                <DashboardSidebar />
              </Drawer>
              <div className="col-lg-12 col-xl-10">
              <PageHeader
                  items={[
                    { label: "Dashboard", link: "/dashboard" },
                    { label: "Change Password", link: "" },
                  ]}
                  pageTitle="Change Password"
                />
                <div className="card">
                  <div className="card-body">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        oldPassword: "",
                        newPasword: "",
                        confirmPassword: "",
                      }}
                      validationSchema={Yup.object().shape({
                        oldPassword: Yup.string().required(
                          "Old Password is Required"
                        ),
                        newPasword: Yup.string()
                          .required("Please Enter your password")
                          .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                            "Please choose a stronger password. Try a mix of letters, numbers, and symbols"
                          ),
                        confirmPassword: Yup.string().oneOf(
                          [Yup.ref("newPasword"), null],
                          "Passwords must match"
                        ),
                      })}
                      onSubmit={(formData, { resetForm }) => {
                        console.log("formData: ", formData);
                        resetForm({})
                        // setSubmitting(true);

                        const { newPasword, oldPassword } = formData;
                        changePassword(newPasword, oldPassword);
                      }}
                    >
                      {({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                        handleChange,
                        handleBlur,
                      }) => {
                        return (
                          <Form>
                            <div className="form-group required">
                              <label>Old Password</label>
                              <Field
                                name="oldPassword"
                                type="password"
                                placeholder="Old Password"
                                autoComplete="off"
                                
                                className={
                                  "form-control" +
                                  (errors.oldPassword && touched.oldPassword
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="oldPassword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label>New Password</label>
                              <Field
                                name="newPasword"
                                type="password"
                                placeholder="New Password"
                                className={
                                  "form-control" +
                                  (errors.newPasword && touched.newPasword
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="newPasword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label>Confirm Password</label>
                              <Field
                                name="confirmPassword"
                                type="password"
                                placeholder="Confirm Password"
                                className={
                                  "form-control" +
                                  (errors.confirmPassword &&
                                    touched.confirmPassword
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="confirmPassword"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group text-center mb-0 m-t-20">
                              <div className="offset-md-3 col-md-6 col-xs-12 d-flex justify-content-center w-100">
                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="btn btn-primary btn-lg btn-block pt-2 "
                                  style={{ width: '200px', fontSize: 16 }}
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default ChangePassword;
