import { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  notification,
  Button,
  Alert,
  Upload,
  Tabs
} from "antd";
import _ from "lodash";
import SearchLocationInput from "../searchAddress/index";
import { UPDATEDDELETE, UPDATEDGET, UPDATEDPOST, UPDATEDPUT } from "../../services/common.api";
import {
  relationShip,
  province
} from "../../constants/patientProfile.constant";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import style from "../../pages/dashboard/dependent/style.module.css";
import { setFamilyDoctor } from "redux/actions/userActions";
import MaskInput from "react-maskinput";
import { find } from 'lodash'
import { IMG01, IMG02 } from "./img";
import { Cancel, CancelOutlined, InboxOutlined } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import { s3Upload, s3GetPrivate } from "../../services/s3fileUpload/index";


const AddFamilyMember = ({
  open,
  close,
  title,
  editFamilyMember,
  getFamilyMember,
}) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const { Option } = Select;
  const dispatch = useDispatch();
  const [, setLat] = useState(null);
  const [, setLng] = useState(null);
  const [address, setAddress] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [familyDoc, setFamilyDoc] = useState(undefined);
  // const familyD = useSelector((state: any) => state?.userReducer?.familyDoc);
  const [form] = Form.useForm();
  const [familyMemberDetails, setFamilyMemberDetails] = useState({} as any);
  const [btnLoader, setBtnLoader] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [relationship, setRelationship] = useState({} as any);
  const [formValues, setFormValues] = useState({} as any);
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [allDoctor, setAllDoctor] = useState([]);
  const [selectedFamilyMemberID, setSelectFamilyMemberID] = useState("");
  let config = useSelector((state: any) => state.AppConfig.config);

  const userData = useSelector((state: any) => state.userReducer.user);

  console.log(userData, ")_________________(");
  

  const { Dragger } = Upload;
  const [isMinor, setIsMinor] = useState(false);
  const [isMajor, setIsMajor] = useState(false);

  const [isMinorVisible, setIsMinorVisible] = useState(false);
  const [isMajorVisible, setIsMajorVisible] = useState(false);
  const [valuesForFirstForm, setValuesForFirstForm] = useState({} as any);
  const [createdFamily, setCreateFamilyData] = useState({} as any);

  const [isMinorVisibleEdit, setIsMinorVisibleEdit] = useState(false);
  const [isMajorVisibleEdit, setIsMajorVisibleEdit] = useState(false);

  const [uploadedFrontPath, setUploadedFrontPath] = useState(null);
  const [uploadedBackPath, setUploadedBackPath] = useState(null);
  const [activeTab, SetactiveTab] = useState('1');
  const [privateInsuranceData, setPrivateInsuranceData] = useState([] as any);
  const [selFamilyMember, setSelFamilyMember] = useState(null);

  // const [concent, setConcent] = useState(false);
  const currentAppointmentInfo = useSelector(
    (state: any) => state.curentAppointment
  );

  const onKeyChange = (key) => SetactiveTab(key)

  const props1 = {
    name: "file",
    multiple: false,
    // accept: "application/pdf","application/vnd.openxmlformats-officedocument.wordprocessingm","application/msword",
    accept: ".pdf,.doc,.docx,image/*",
    beforeUpload: async (file) => {
      console.log(file, "first file");
      setUploadedFrontPath(file);
      return false;
    },
  };
  const props2 = {
    name: "file",
    multiple: false,
    // accept: "application/pdf","application/vnd.openxmlformats-officedocument.wordprocessingm","application/msword",
    accept: ".pdf,.doc,.docx,image/*",
    beforeUpload: async (file) => {
      console.log(file, "selected file");
      setUploadedBackPath(file);
      return false;
    },
  };

  // const getPreSignedUrl = (path, e) => {
  //   console.log(path, "pathhhh");
  //   e.preventDefault();
  //   try {
  //     let body = {
  //       file: path
  //     }
  //     UPDATEDPOST('getfilefrombucket', body).then((data) => {
  //       console.log(data?.data?.url, "pre signed data");
  //       const newWindow = window.open(data?.data?.url, '_blank', 'noopener,noreferrer')
  //       if (newWindow) newWindow.opener = null
  //     })
  //   } catch (error) {
  //     console.log('error: ', error)
  //     notification.warning({
  //       message: error.message,
  //     })
  //   }
  // }

  const getPrivateInsuranceInfo = async () => {


    let logbody = {
      message: `User - ${userData?.sub} - Fetch Private Insuarance - Add Family Member Component`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
    }
    logEvents(logbody);

    try {
      const res = await UPDATEDGET(`privateinsurance/${editFamilyMember?.familymemberid}`);
      console.log(res?.data, "get private user insurance");
      if (res?.data.length != 0) {
        form.setFieldsValue({
          insurer: res?.data[0]?.insurer,
        });
        form.setFieldsValue({
          insuranceNumber: res?.data[0]?.insurance_number,
        });
        form.setFieldsValue({
          lastname_cardholder: res?.data[0]?.name,
        });
        form.setFieldsValue({
          relation: res?.data[0]?.relation,
        });
      }
      if (res?.data[0]) {
        setPrivateInsuranceData(res?.data[0]);
      } else {
        setPrivateInsuranceData([]);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  console.log("PrivateInsuranceData",privateInsuranceData);
  

  const insuranceAddFunctionality = async (event, id) => {

    console.log(event, "event event event", id, "familyMemberDetails", familyMemberDetails)

    if (privateInsuranceData?.length != 0) {

      let logbody = {
        message: `User - ${userData?.sub} - Delete Private Insuarance - Add Family Member Component`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
      }
      logEvents(logbody);

      console.log("0000000000000 delete private insurance ma avyu", privateInsuranceData)
      UPDATEDDELETE(`insurance/delete/${privateInsuranceData?.id}`).then(
        async () => {

          let uploadedDocumentF = null;
          if (uploadedFrontPath != null) {
            const data = {
              userId: familyMemberDetails?.id ? familyMemberDetails?.id : id,
              type: uploadedFrontPath.type,
              fileName: `insurance/${uuid()}_` + uploadedFrontPath.name
            };

            uploadedDocumentF = await s3Upload(data.fileName, uploadedFrontPath);
            console.log("Upload ma avyu Front", uploadedDocumentF)
          } else {
            uploadedDocumentF = privateInsuranceData.frontcard_path
          }

          let uploadedDocumentB = null;
          if (uploadedBackPath != null) {
            const dataa = {
              userId: familyMemberDetails?.id ? familyMemberDetails?.id : id,
              type: uploadedBackPath.type,
              fileName: `insurance/${uuid()}_` + uploadedBackPath.name
            }

            uploadedDocumentB = await s3Upload(dataa.fileName, uploadedBackPath);
            console.log("Upload ma avyu Back", uploadedDocumentB)
          } else {
            uploadedDocumentB = privateInsuranceData.backcard_path
          }


          const OhipPri = {
            name: event?.lastname_cardholder,
            number: event?.insuranceNumber,
            provider: "NA",
            type: "PRIVATE_INSURANCE",
            user_id: familyMemberDetails?.id ? familyMemberDetails?.id : id,
            is_valid: 1,
            relation: event?.relation,
            frontcard_path: uploadedDocumentF,
            backcard_path: uploadedDocumentB,
            insurer: event?.insurer
          };

          let logbody = {
            message: `User - ${userData?.sub} - Add Private Insuarance - Add Family Member Component`,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
          }
          logEvents(logbody);

          const resIn = await UPDATEDPOST("insurance", OhipPri);
          console.log(resIn, " insurances- OhipPri OhipPri ");

        }

      )
    } else {
      console.log("insurance elsse ma avyu")
      let uploadedDocumentF = null;
      if (uploadedFrontPath != null) {
        const data = {
          userId: familyMemberDetails?.id ? familyMemberDetails?.id : id,
          type: uploadedFrontPath.type,
          fileName: `insurance/${uuid()}_` + uploadedFrontPath.name
        };

        uploadedDocumentF = await s3Upload(data.fileName, uploadedFrontPath);
        console.log("Upload ma avyu Front", uploadedDocumentF)
      } else {
        uploadedDocumentF = privateInsuranceData.frontcard_path
      }

      let uploadedDocumentB = null;
      if (uploadedBackPath != null) {
        const dataa = {
          userId: familyMemberDetails?.id ? familyMemberDetails?.id : id,
          type: uploadedBackPath.type,
          fileName: `insurance/${uuid()}_` + uploadedBackPath.name
        };

        uploadedDocumentB = await s3Upload(dataa.fileName, uploadedBackPath);
        console.log("Upload ma avyu Back", uploadedDocumentB)
      } else {
        uploadedDocumentB = privateInsuranceData.backcard_path
      }

      const OhipPri = {
        name: event?.lastname_cardholder,
        number: event?.insuranceNumber,
        provider: "NA",
        type: "PRIVATE_INSURANCE",
        user_id: familyMemberDetails?.id ? familyMemberDetails?.id : id,
        is_valid: 1,
        relation: event?.relation,
        frontcard_path: uploadedDocumentF,
        backcard_path: uploadedDocumentB,
        insurer: event?.insurer
      };

      let logbody = {
        message: `User - ${userData?.sub} - Add Private Insuarance - Add Family Member Component`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
      }
      logEvents(logbody);

      const resIn = await UPDATEDPOST("insurance", OhipPri);
      console.log(resIn, " insurances- OhipPri OhipPri ");

    }
  }

  const getPatientDetails = async () => {

    console.log(editFamilyMember, "/////??????")

    let logbody = {
      message: `User - ${userData?.sub} - Fetch Family Member Detail By ID - Add Family Member Component`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
    }
    logEvents(logbody);

    try {
      const data = await UPDATEDGET(
        `user/profile/${editFamilyMember?.familymemberid}`
      );
      console.log(data?.data[0][0], "Family data");
      // setFamilyMemberDetails(data?.data[0][0]);
      setFamilyMemberDetails(data?.data[0][0]);
    } catch (error) {
      console.log(error);
    }
  };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e: any) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {
    // alert(",kl")

    let body = {
      message: "Add Family Member - Component Load",
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
    }
    logEvents(body);

    console.log(editFamilyMember, familyMemberDetails, "999999999999999");
    if (editFamilyMember?.familymemberid) {
      getFamilyDoctor(editFamilyMember?.familymemberid);

    }
    console.log(_.isEmpty(editFamilyMember), "editFamilyMember?.familymemberid editFamilyMember?.familymemberid")
    if (title == "Add Family Member") {
      form.resetFields();
      setPhoneNumber("");
    }
    if (!_.isEmpty(editFamilyMember)) {

      console.log("editFamilyMember.dateOfBirth");

      getPatientDetails();
      getPrivateInsuranceInfo()


      // form.setFieldsValue(editFamilyMember);
      form.setFieldsValue({
        FirstName: familyMemberDetails?.firstname,
        LastName: familyMemberDetails?.lastname,
        Email: familyMemberDetails?.email,
        // phoneNumber: familyMemberDetails?.phoneNumber,
        birth_date: editFamilyMember?.dateOfBirth == undefined ? undefined : moment(editFamilyMember?.dateOfBirth).utc(),
        gender: editFamilyMember?.gender != null ? editFamilyMember?.gender : "",
        relationship: editFamilyMember.relationship != null ? editFamilyMember?.relationship : "",
        province: editFamilyMember?.province != null ? editFamilyMember?.province : "",
        // ohipNumber_name: editFamilyMember?.ohipNumber != null ? editFamilyMember?.ohipNumber : "",
      });

      // setFormValues((formValues) => ({['FirstName']: familyMemberDetails?.fisrtname }));
      // setFormValues((formValues) => ({['LastName']: familyMemberDetails?.lastname }));

      getInsuranceDetails(editFamilyMember?.familymemberid);
      // birth_date: dateOfBirth == undefined ? undefined : moment(dateOfBirth),


      setAddress(editFamilyMember?.address);
      setPhoneNumber(familyMemberDetails?.phoneNumber)
      setDateOfBirth(editFamilyMember?.dateOfBirth);
      // console.log("editFamilyMember.dateOfBirth", dateOfBirth);

    } else {
      console.log(dateOfBirth)
      setDateOfBirth(undefined);
      console.log(dateOfBirth)
      setAddress("");
      setFormValues((formValues) => ({ ['FirstName']: '' }));
      setFormValues((formValues) => ({ ['LastName']: '' }));
    }
  }, [open]);

  const getInsuranceDetails = async (familyMemberID) => {

    let logbody = {
      message: `User - ${userData?.sub} - Get Insuarance Detail- Add Family Member Component`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
    }
    logEvents(logbody);

    try {
      const res = await UPDATEDGET(`insurance/${familyMemberID}`);
      console.log(res?.data[0], "getInsuranceInfo");

      let iData = res?.data[0];
      form.setFieldsValue({
        ohipNumber_name: iData?.insurance_number != null ? iData?.insurance_number : "",
        insuranceVersionCode: iData?.insurance_vc != null ? iData?.insurance_vc : "",
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  const getFamilyDoctor = async (id) => {
    
    let logbody = {
      message: `User - ${userData?.sub} - Fetch Famiy Doctor - Add Family Member Component`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
    }
    logEvents(logbody);

    try {
      await UPDATEDGET(`patient/familydoc/${id}`).then((response) => {

        if (response?.data?.body != null && response?.data?.body != 'Family member not linked.') {
          console.log("🚀 ~ file: index.tsx ~ line 110 ~ awaitGET ~ response", response)
          let N = response?.data?.body?.CompanyEmployee?.CompanyEmployee;
          form.setFieldsValue({ familyDoctor: `Dr.${N?.FirstName} ${N?.LastName}` })
          console.log(`Dr.${N?.FirstName} ${N?.LastName}`, "NNNNNNNNNNNNNNNNNNN");
        } else {
          form.setFieldsValue({ familyDoctor: `` })
        }
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  }

  useEffect(() => {

    console.log(familyMemberDetails, "familyMemberDetailsfamilyMemberDetailsfamilyMemberDetailsfamilyMemberDetails")

    form.setFieldsValue({
      Email: familyMemberDetails?.email,
      FirstName: familyMemberDetails?.firstname,
      LastName: familyMemberDetails?.lastname,
      // phoneNumber: familyMemberDetails?.phoneNumber
    });
    setPhoneNumber(familyMemberDetails?.phoneNumber)
    getDoctorList();
  }, [familyMemberDetails]);

  const handleChangeMemeber = (event) => {
    console.log(event);
    setSelectFamilyMemberID(event);
  };

  const getDoctorList = async () => {

    let logbody = {
      message: `User - ${userData?.sub} - Fetch Doctor List - Add Family Member Component`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
    }
    logEvents(logbody);

    const doctorList = await UPDATEDGET(`employee/doctors`);
    console.log(doctorList, "-*-*-*All doctorList");

    if (doctorList?.data?.hasOwnProperty("errorMessage")) {

    } else {

      setAllDoctor(doctorList?.data);
    }
  };

  const checkAddress = () => {
    // if (address == "" || address == null) {
    //   setAddress(null);
    // }
  }

  const onFinish = async (values) => {
    console.log(values, "values values values")
    setValuesForFirstForm(values);
    setBtnLoader(true);

    if (values?.insuranceNumber != undefined && !_.isEmpty(editFamilyMember)) {
      console.log("uper na if ma avyu insurance mate")
      insuranceAddFunctionality(values, '')
    }

    let ohipNumber = values?.ohipNumber_name?.replaceAll('-', '');
    if ((ohipNumber == undefined || ohipNumber == "") && (values.insuranceVersionCode == undefined || values.insuranceVersionCode == "")) {
      if (_.isEmpty(editFamilyMember)) {
        if (isMajor == true) {
          setIsMajorVisible(true);
        }

        if (isMinor == true) {
          setIsMinorVisible(true);
        }

        setBtnLoader(false);
      } else {

        let logbody = {
          message: `User - ${userData?.sub} - Update Family Member Profile - Add Family Member Component`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
        }
        logEvents(logbody);

        const data1 = {
          FirstName: values.FirstName,
          LastName: values.LastName,
          Email: values.Email ? values.Email : '',
          phoneNumber: phoneNumber,
          DOB: moment(values.birth_date).format("YYYY-MM-DD"),
          familyDoctor: values.familyPhysician,
          gender: values.gender,
          province: values.province,
          Address1: address ? address : '',
        };
        console.log(data1, "dataaaaaaaa undefined");

        const responseEdit = await UPDATEDPUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
        console.log(responseEdit, "ressrssrrs");

        let abc = relationship == "other" ? values.addRelation : values.relationship
        console.log(abc, "relationship relationship")

        let familyAddObject = {
          userName: `${values.FirstName} ${values.LastName}`,
          relationship: abc,
          province: values.province,
          ohipNumber: ohipNumber ? ohipNumber : null,
          insuranceServiceNumber: '',
          insuranceProviderName: values.FirstName + "" + values.LastName,
          gender: values.gender,
          familyPhysician: values.familyPhysician,
          address,
          dateOfBirth: moment(values.birth_date).format("YYYY-MM-DD"),
          userid: user?.id,
          familymemberid: editFamilyMember?.familymemberid,
          active: 1,
          createdBy: user.role,
          id: undefined,
        };
        console.log(familyAddObject, "familyAddObject");
        setBtnLoader(false);
        Addfamily(familyAddObject);
      }
    } else if ((ohipNumber == undefined || ohipNumber == "") || (values.insuranceVersionCode == undefined || values.insuranceVersionCode == "")) {
      if (ohipNumber == undefined || values.insuranceVersionCode == undefined || ohipNumber == "" || values.insuranceVersionCode == "") {
        setBtnLoader(false);
        setErrorMsg("Please Enter all Ohip Details")
        setShowError(true);

        setTimeout(() => {
          setShowError(false);
        }, 5000);
      } else {

        let ohipNumberNew = ohipNumber?.replaceAll('-', '');

        let data = {
          // "provider-number": "020497",
          hcn: ohipNumber ? ohipNumberNew : null,
          vc: values.insuranceVersionCode ? values.insuranceVersionCode : null,
          user: values.FirstName + " " + values.LastName,
          // "client-id": "1704898766014x798343235103228000"
        };

        let logbody = {
          message: `User - ${userData?.sub} - Check OHIP Card - Add Family Member Component`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
        }
        logEvents(logbody);

        await UPDATEDPOST("ohip/check", data)
          .then(
            async (response: any) => {
              console.log(response, "responce");
              if (response?.data?.response["MOH-card-eligible"] == false) {
                setBtnLoader(false);
                setErrorMsg("Invalid OHIP Card")
                setShowError(true);
              } else {
                if (response?.data?.response["MOH-Message"] == 'Invalid version code') {
                  setErrorMsg("Invalid version code");
                  setShowError(true);
                  setBtnLoader(false);
                } else {
                  if (response?.data?.response['MOH-card-status'] == 'invalid') {
                    setErrorMsg("Invalid or Expired health card");
                    setShowError(true);
                    setBtnLoader(false);
                  } else {

                    let D = values.birth_date.format("YYYY-MM-DD");

                    if (response?.data?.response.DOB !== D) {
                      setErrorMsg("Date of birth should match with OHIP")
                      setShowError(true);
                      setBtnLoader(false);
                    } else {

                      if (
                        response?.data?.response["First-name"].toLowerCase() !==
                        values?.FirstName.toLowerCase()
                      ) {
                        setErrorMsg("First name should match with OHIP")
                        setShowError(true);
                        setBtnLoader(false);

                      } else {
                        if (
                          response?.data?.response["Last-name"].toLowerCase() !==
                          values?.LastName.toLowerCase()
                        ) {
                          setErrorMsg("Last name should match with OHIP")
                          setShowError(true);
                          setBtnLoader(false);
                        } else {

                          if (_.isEmpty(editFamilyMember)) {
                            if (isMajor == true) {
                              setIsMajorVisible(true);
                            }

                            if (isMinor == true) {
                              setIsMinorVisible(true);
                            }

                            setBtnLoader(false);

                          } else {

                            let logbody = {
                              message: `User - ${userData?.sub} - Update Family Member Pofile - Add Family Member Component`,
                              logGroupName: "ONRx-Patient-Portal",
                              logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
                            }
                            logEvents(logbody);

                            const data1 = {
                              FirstName: values.FirstName,
                              LastName: values.LastName,
                              Email: values.Email ? values.Email : '',
                              phoneNumber: phoneNumber,
                              DOB: moment(values.birth_date).format("YYYY-MM-DD"),
                              familyDoctor: values.familyPhysician,
                              gender: values.gender,
                              province: values.province,
                              Address1: address ? address : '',
                            };
                            console.log(data1, "dataaaaaaaa undefined");

                            const responseEdit = await UPDATEDPUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
                            console.log(responseEdit, "ressrssrrs");

                            const Ohip = {
                              name: values.FirstName + " " + values.LastName,
                              number: ohipNumber,
                              provider: "NA",
                              type: 'OHIP',
                              vc: values.insuranceVersionCode,
                              user_id: editFamilyMember?.familymemberid,
                              DOB: dateOfBirth,
                              is_valid: 1,

                            };
                            console.log(Ohip, "Ohip");
                            try {

                              let logbody = {
                                message: `User - ${userData?.sub} - Add Insuarance Details - Add Family Member Component`,
                                logGroupName: "ONRx-Patient-Portal",
                                logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
                              }
                              logEvents(logbody);

                              const res = await UPDATEDPOST("insurance", Ohip);
                              console.log(res, "family insurances------0 ");

                              let abc = relationship == "other" ? values.addRelation : values.relationship
                              console.log(abc, "relationship relationship")

                              let familyAddObject = {
                                userName: `${values.FirstName} ${values.LastName}`,
                                relationship: abc,
                                province: values.province,
                                ohipNumber: ohipNumber,
                                insuranceServiceNumber: '',
                                insuranceProviderName: values.FirstName + "" + values.LastName,
                                gender: values.gender,
                                familyPhysician: values.familyPhysician,
                                address,
                                dateOfBirth: moment(values.birth_date).format("YYYY-MM-DD"),
                                userid: user?.id,
                                familymemberid: editFamilyMember?.familymemberid,
                                active: 1,
                                createdBy: user.role,
                                id: undefined,
                              };
                              console.log(familyAddObject, "familyAddObject");
                              setBtnLoader(false);
                              Addfamily(familyAddObject);
                            } catch (err) {
                              setBtnLoader(false);
                              console.log("error", err);
                            }

                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            (err) => {
              setBtnLoader(false);
              setErrorMsg("Please Enter Ohip Details")
              setShowError(true);

              setTimeout(() => {
                setShowError(false);
              }, 5000);
              console.log(err, "error in on finish");
            }
          );
      }
    } else if (ohipNumber != undefined && ohipNumber != "" && values.insuranceVersionCode != undefined && values.insuranceVersionCode != undefined) {
      let ohipNumberNew = ohipNumber?.replaceAll('-', '');
      let data = {
        // "provider-number": "020497",
        hcn: ohipNumber ? ohipNumberNew : null,
        vc: values.insuranceVersionCode,
        user: values.FirstName + " " + values.LastName,
        // "client-id": "1704898766014x798343235103228000"
      };

      let logbody = {
        message: `User - ${userData?.sub} - Check OHIP Card - Add Family Member Component`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
      }
      logEvents(logbody);

      await UPDATEDPOST("ohip/check", data)
        .then(
          async (response: any) => {
            console.log(response, "responce");

            if (response?.data?.response["MOH-card-eligible"] == false) {
              setBtnLoader(false);
              setErrorMsg("Invalid OHIP Card")
              setShowError(true);
            } else {
              if (response?.data?.response["MOH-Message"] == 'Invalid version code') {
                setErrorMsg("Invalid version code");
                setShowError(true);
                setBtnLoader(false);
              } else {
                if (response?.data?.response['MOH-card-status'] == 'invalid') {
                  setErrorMsg("Invalid or Expired health card");
                  setShowError(true);
                  setBtnLoader(false);
                } else {
                  let D = values.birth_date.format("YYYY-MM-DD");

                  if (response?.data?.response.DOB !== D) {
                    setErrorMsg("Date of birth should match with OHIP")
                    setShowError(true);
                    setBtnLoader(false);
                  } else {

                    if (
                      response?.data?.response["First-name"].toLowerCase() !==
                      values?.FirstName.toLowerCase()
                    ) {
                      setErrorMsg("First name should match with OHIP")
                      setShowError(true);
                      setBtnLoader(false);

                    } else {
                      if (
                        response?.data?.response["Last-name"].toLowerCase() !==
                        values?.LastName.toLowerCase()
                      ) {
                        setErrorMsg("Last name should match with OHIP")
                        setShowError(true);
                        setBtnLoader(false);
                      } else {
                        if (_.isEmpty(editFamilyMember)) {
                          if (isMajor == true) {
                            setIsMajorVisible(true);
                          }

                          if (isMinor == true) {
                            setIsMinorVisible(true);
                          }

                          setBtnLoader(false);
                        } else {
                          const data1 = {
                            FirstName: values.FirstName,
                            LastName: values.LastName,
                            Email: values.Email ? values.Email : '',
                            phoneNumber: phoneNumber,
                            DOB: moment(values.birth_date).format("YYYY-MM-DD"),
                            familyDoctor: values.familyPhysician,
                            gender: values.gender,
                            province: values.province,
                            Address1: address ? address : '',
                          };
                          console.log(data1, "dataaaaaaaa undefined");

                          let logbody = {
                            message: `User - ${userData?.sub} - Update Family Member Profile - Add Family Member Component`,
                            logGroupName: "ONRx-Patient-Portal",
                            logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
                          }
                          logEvents(logbody);


                          const responseEdit = await UPDATEDPUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
                          console.log(responseEdit, "ressrssrrs");

                          const Ohip = {
                            name: values.FirstName + " " + values.LastName,
                            number: values?.ohipNumber_name,
                            provider: "NA",
                            type: 'OHIP',
                            vc: values.insuranceVersionCode,
                            user_id: editFamilyMember?.familymemberid,
                            DOB: dateOfBirth,
                            is_valid: 1,

                          };
                          console.log(Ohip, "Ohip");
                          try {

                            let logbody = {
                              message: `User - ${userData?.sub} - Add Insuarance Details - Add Family Member Component`,
                              logGroupName: "ONRx-Patient-Portal",
                              logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
                            }
                            logEvents(logbody);

                            const res = await UPDATEDPOST("insurance", Ohip);
                            console.log(res, "family insurances------0 ");

                            let abc = relationship == "other" ? values.addRelation : values.relationship
                            console.log(abc, "relationship relationship")

                            let familyAddObject = {
                              userName: `${values.FirstName} ${values.LastName}`,
                              relationship: abc,
                              province: values.province,
                              ohipNumber: ohipNumber,
                              insuranceServiceNumber: '',
                              insuranceProviderName: values.FirstName + "" + values.LastName,
                              gender: values.gender,
                              familyPhysician: values.familyPhysician,
                              address,
                              dateOfBirth: moment(values.birth_date).format("YYYY-MM-DD"),
                              userid: user?.id,
                              familymemberid: editFamilyMember?.familymemberid,
                              active: 1,
                              createdBy: user.role,
                              id: undefined,
                            };
                            console.log(familyAddObject, "familyAddObject");
                            setBtnLoader(false);
                            Addfamily(familyAddObject);
                          } catch (err) {
                            setBtnLoader(false);
                            console.log("error", err);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          (err) => {
            setBtnLoader(false);
            console.log(err);
          }
        );
    }

  }

  const majorClose = () => {
    setIsMajorVisible(false);
  }

  const minorClose = () => {
    setIsMinorVisible(false);
  }

  const familyOK = async () => {

    let logbody = {
      message: `User - ${userData?.sub} - Add Family Member - Add Family Member Component`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
    }
    logEvents(logbody);

    setLoader(true);

    let values = valuesForFirstForm as any;

    let ohipNumber = values?.ohipNumber_name?.replaceAll('-', '');
    if ((ohipNumber == undefined || ohipNumber == "") && (values.insuranceVersionCode == undefined || values.insuranceVersionCode == "")) {
      if (_.isEmpty(editFamilyMember)) {
        let responseOfPatien;
        const data = {
          Type: "PATIENT",
          FirstName: values.FirstName,
          LastName: values.LastName,
          Email: values.Email ? values.Email : userData.Email,
          Phone: phoneNumber ? phoneNumber : '',
          createDate: moment().utc(),
          // "cognitoid": user.userSub,
          gender: values.gender,
          address: address ? address : '',
        };
        console.log(data, "dataaaaaaaa undefined");

        let logbody = {
          message: `User - ${userData?.sub} - Add Family Member as Patient- Add Family Member Component`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
        }
        logEvents(logbody);

        const response = await UPDATEDPOST("user/patient", data);
        console.log(response, "ressrssrrs");
        responseOfPatien = response;
        setCreateFamilyData(response?.data?.body?.user)

        if (values?.insuranceNumber != undefined) {
          console.log("niche na iff ma avyu insurance and fmily add karva")
          insuranceAddFunctionality(values, response?.data?.body?.user?.id)
        }
        let abc = relationship == "other" ? values.addRelation : values.relationship
        console.log(abc, "relationship relationship")

        let familyAddObject = {
          userName: `${values.FirstName} ${values.LastName}`,
          relationship: abc,
          province: values.province,
          ohipNumber: ohipNumber ? ohipNumber : null,
          insuranceServiceNumber: '',
          insuranceProviderName: values.FirstName + "" + values.LastName,
          gender: values.gender,
          familyPhysician: values.familyPhysician,
          address,
          // phoneNumber: phoneNumber,
          dateOfBirth: moment(values.birth_date).format("YYYY-MM-DD"),
          userid: user?.id,
          familymemberid: responseOfPatien?.data?.body?.user?.id,
          // familymemberid: null,
          active: 1,
          createdBy: user.role,
          id: undefined,
        };
        console.log(familyAddObject, "familyAddObject");
        // setBtnLoader(false);
        setLoader(false);
        Addfamily(familyAddObject);

      }
    } else if ((ohipNumber == undefined || ohipNumber == "") || (values.insuranceVersionCode == undefined || values.insuranceVersionCode == "")) {

      let logbody = {
        message: `User - ${userData?.sub} - Add Family Member as Patient - Add Family Member Component`,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
      }
      logEvents(logbody);

      if (_.isEmpty(editFamilyMember)) {
        let responseOfPatien;
        const data = {
          Type: "PATIENT",
          FirstName: values.FirstName,
          LastName: values.LastName,
          Email: values.Email ? values.Email : userData.Email,
          Phone: phoneNumber ? phoneNumber : '',
          createDate: moment().utc(),
          gender: values.gender,
          address: address ? address : '',
        };

        const response = await UPDATEDPOST("user/patient", data);
        console.log(response);
        responseOfPatien = response;
        setCreateFamilyData(response?.data?.body?.user)
        if (values?.insuranceNumber != undefined) {
          console.log("niche na iff ma avyu insurance and fmily add karva")
          insuranceAddFunctionality(values, response?.data?.body?.user?.id)
        }

        const Ohip = {
          name: values.FirstName + " " + values.LastName,
          number: ohipNumber,
          provider: "NA",
          type: 'OHIP',
          vc: values.insuranceVersionCode,
          user_id: responseOfPatien?.data?.body?.user?.id,
          DOB: dateOfBirth,
          is_valid: 1,

        };
        console.log(Ohip, "Ohip");
        try {

          let logbody = {
            message: `User - ${userData?.sub} - Add Insurance Detail - Add Family Member Component`,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
          }
          logEvents(logbody);

          const res = await UPDATEDPOST("insurance", Ohip);
          console.log(res, "family insurances------0 ");

          let abc = relationship == "other" ? values.addRelation : values.relationship
          console.log(abc, "relationship relationship")

          let familyAddObject = {
            userName: `${values.FirstName} ${values.LastName}`,
            relationship: abc,
            province: values.province,
            ohipNumber: ohipNumber,
            insuranceServiceNumber: '',
            insuranceProviderName: values.FirstName + "" + values.LastName,
            gender: values.gender,
            familyPhysician: values.familyPhysician,
            address,
            dateOfBirth: moment(values.birth_date).format("YYYY-MM-DD"),
            userid: user?.id,
            familymemberid: responseOfPatien?.data?.body?.user?.id,
            active: 1,
            createdBy: user.role,
            id: undefined,
          };
          console.log(familyAddObject, "familyAddObject");
          // setBtnLoader(false);
          setLoader(false);
          Addfamily(familyAddObject);
        } catch (err) {
          // setBtnLoader(false);
          setLoader(false);
          console.log("error", err);
        }
      }
    } else if (ohipNumber != undefined && ohipNumber != "" && values.insuranceVersionCode != undefined && values.insuranceVersionCode != undefined) {
      if (_.isEmpty(editFamilyMember)) {

        let logbody = {
          message: `User - ${userData?.sub} - Add Family Member as Patient - Add Family Member Component`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
        }
        logEvents(logbody);

        let responseOfPatien;
        const data = {
          Type: "PATIENT",
          FirstName: values.FirstName,
          LastName: values.LastName,
          Email: values.Email ? values.Email : userData.Email,
          Phone: phoneNumber ? phoneNumber : '',
          createDate: moment().utc(),
          // "cognitoid": user.userSub,
          gender: values.gender,
          address: address ? address : '',
        };
        const response = await UPDATEDPOST("user/patient", data);
        console.log(response);
        responseOfPatien = response;
        setCreateFamilyData(response?.data?.body?.user)
        if (values?.insuranceNumber != undefined) {
          console.log("niche na iff ma avyu insurance and fmily add karva")
          insuranceAddFunctionality(values, response?.data?.body?.user?.id)
        }

        const Ohip = {
          name: values.FirstName + " " + values.LastName,
          number: values?.ohipNumber_name,
          provider: "NA",
          type: 'OHIP',
          vc: values.insuranceVersionCode,
          user_id: responseOfPatien?.data?.body?.user?.id,
          DOB: dateOfBirth,
          is_valid: 1,

        };

        let logbody2 = {
          message: `User - ${userData?.sub} - Add Insuarance - Add Family Member Component`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
        }
        logEvents(logbody2);

        console.log(Ohip, "Ohip");
        try {
          const res = await UPDATEDPOST("insurance", Ohip);
          console.log(res, "family insurances------0 ");

          let abc = relationship == "other" ? values.addRelation : values.relationship
          console.log(abc, "relationship relationship")

          let familyAddObject = {
            userName: `${values.FirstName} ${values.LastName}`,
            relationship: abc,
            province: values.province,
            ohipNumber: ohipNumber,
            insuranceServiceNumber: '',
            insuranceProviderName: values.FirstName + "" + values.LastName,
            gender: values.gender,
            familyPhysician: values.familyPhysician,
            address,
            dateOfBirth: moment(values.birth_date).format("YYYY-MM-DD"),
            userid: user?.id,
            familymemberid: responseOfPatien?.data?.body?.user?.id,
            active: 1,
            createdBy: user.role,
            id: undefined,
          };
          console.log(familyAddObject, "familyAddObject");
          // setBtnLoader(false);
          setLoader(false);
          Addfamily(familyAddObject);
        } catch (err) {
          // setBtnLoader(false);
          setLoader(false);
          console.log("error", err);
        }
      }
    }
  }

  // const onFinish1 = async (values) => {
  //   // let abc = relationship == "other" ? values.addRelation : values.relationship
  //   // console.log(abc, "relationship relationship")

  //   // if (address == "" || address == null) {
  //   //   setAddress(null);
  //   // } else {
  //     setLoader(true);
  //     console.log(values);
  //     setBtnLoader(true);
  //     // let ohipNumber = values?.ohipNumber?.replaceAll('-', '');
  //     let ohipNumber = values?.ohipNumber_name?.replaceAll('-', '');
  //     console.log(ohipNumber, "ohipnumberohipnumber")
  //     if ((ohipNumber == undefined || ohipNumber == "") && (values.insuranceVersionCode == undefined || values.insuranceVersionCode == "")) {

  //       let responseOfPatien;
  //       if (_.isEmpty(editFamilyMember)) {
  //         const data = {
  //           Type: "PATIENT",
  //           FirstName: values.FirstName,
  //           LastName: values.LastName,
  //           Email: values.Email ? values.Email : '',
  //           Phone: phoneNumber ? phoneNumber : '',
  //           createDate: moment().utc(),
  //           // "cognitoid": user.userSub,
  //           gender: values.gender,
  //           address: address ? address : '',
  //         };
  //         console.log(data, "dataaaaaaaa undefined");

  //         const response = await UPDATEDPOST("user/patient", data);
  //         console.log(response, "ressrssrrs");
  //         responseOfPatien = response;

  //         let abc = relationship == "other" ? values.addRelation : values.relationship
  //         console.log(abc, "relationship relationship")

  //         let familyAddObject = {
  //           userName: `${values.FirstName} ${values.LastName}`,
  //           relationship: abc,
  //           province: values.province,
  //           ohipNumber: ohipNumber ? ohipNumber : null,
  //           insuranceServiceNumber: '',
  //           insuranceProviderName: values.FirstName + "" + values.LastName,
  //           gender: values.gender,
  //           familyPhysician: values.familyPhysician,
  //           address,
  //           // phoneNumber: phoneNumber,
  //           dateOfBirth: moment(values.birth_date).local().format("YYYY-MM-DD"),
  //           userid: user?.id,
  //           familymemberid: responseOfPatien?.data?.body?.user?.id,
  //           // familymemberid: null,
  //           active: 1,
  //           createdBy: user.role,
  //           id: undefined,
  //         };
  //         console.log(familyAddObject, "familyAddObject");
  //         setLoader(false);
  //         setBtnLoader(false);
  //         Addfamily(familyAddObject);

  //       }else{
  //         const data1 = {
  //           FirstName: values.FirstName,
  //           LastName: values.LastName,
  //           Email: values.Email ? values.Email : '',
  //           phoneNumber: phoneNumber,
  //           DOB:moment(values.birth_date).local().format("YYYY-MM-DD"),
  //           familyDoctor : values.familyPhysician,
  //           gender: values.gender,
  //           province: values.province,
  //           Address1: address ? address : '',
  //         };
  //         console.log(data1, "dataaaaaaaa undefined");

  //         const responseEdit = await UPDATEDPUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
  //         console.log(responseEdit, "ressrssrrs");

  //         let abc = relationship == "other" ? values.addRelation : values.relationship
  //         console.log(abc, "relationship relationship")

  //         let familyAddObject = {
  //           userName: `${values.FirstName} ${values.LastName}`,
  //           relationship: abc,
  //           province: values.province,
  //           ohipNumber: ohipNumber ? ohipNumber : null,
  //           insuranceServiceNumber: '',
  //           insuranceProviderName: values.FirstName + "" + values.LastName,
  //           gender: values.gender,
  //           familyPhysician: values.familyPhysician,
  //           address,
  //           // phoneNumber: phoneNumber,
  //           dateOfBirth: moment(values.birth_date).local().format("YYYY-MM-DD"),
  //           userid: user?.id,
  //           familymemberid: editFamilyMember?.familymemberid,
  //           // familymemberid: null,
  //           active: 1,
  //           createdBy: user.role,
  //           id: undefined,
  //         };
  //         console.log(familyAddObject, "familyAddObject");
  //         setLoader(false);
  //         setBtnLoader(false);
  //         Addfamily(familyAddObject);
  //       }

  //       // let abc = relationship == "other" ? values.addRelation : values.relationship
  //       // console.log(abc, "relationship relationship")

  //       // let familyAddObject = {
  //       //   userName: `${values.FirstName} ${values.LastName}`,
  //       //   relationship: abc,
  //       //   province: values.province,
  //       //   ohipNumber: ohipNumber ? ohipNumber : null,
  //       //   insuranceServiceNumber: '',
  //       //   insuranceProviderName: values.FirstName + "" + values.LastName,
  //       //   gender: values.gender,
  //       //   familyPhysician: values.familyPhysician,
  //       //   address,
  //       //   // phoneNumber: phoneNumber,
  //       //   dateOfBirth: moment(values.birth_date).local().format("YYYY-MM-DD"),
  //       //   userid: user?.id,
  //       //   familymemberid: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
  //       //   // familymemberid: null,
  //       //   active: 1,
  //       //   createdBy: user.role,
  //       //   id: undefined,
  //       // };
  //       // console.log(familyAddObject, "familyAddObject");
  //       // setLoader(false);
  //       // setBtnLoader(false);
  //       // Addfamily(familyAddObject);

  //     } else if ((ohipNumber == undefined || ohipNumber == "") || (values.insuranceVersionCode == undefined || values.insuranceVersionCode == "")) {
  //       if (ohipNumber == undefined || values.insuranceVersionCode == undefined || ohipNumber == "" || values.insuranceVersionCode == "") {
  //         setLoader(false);
  //         setBtnLoader(false);
  //         setErrorMsg("Please Enter all Ohip Details")
  //         setShowError(true);

  //         setTimeout(() => {
  //           setShowError(false);
  //         }, 5000);
  //       } else {

  //         let ohipNumberNew = ohipNumber?.replaceAll('-', '');

  //         let data = {
  //           "Provider-number": "020497",
  //           HCN: ohipNumber ? ohipNumberNew : null,
  //           VC: values.insuranceVersionCode ? values.insuranceVersionCode : null,
  //           User: values.FirstName + " " + values.LastName,
  //         };

  //         await axios
  //           .post("https://api.mdmax.ca/api/1.1/wf/api-validation-call", data, {
  //             headers: {
  //               Authorization: `Bearer cee53e7aa78674f078e300e6d96d98ec`,
  //               // Authorization: `Bearer 894da2b4b1760319ae94cbfa73db5a10`,
  //             },
  //           })
  //           .then(
  //             async (response: any) => {
  //               console.log(response, "responce");
  //               if (response?.data?.response["MOH-card-eligible"] == false) {
  //                 setLoader(false);
  //                 setBtnLoader(false);
  //                 setErrorMsg("Invalid OHIP Card")
  //                 setShowError(true);
  //               } else {
  //                 if (response?.data?.response["MOH-Message"] == 'Invalid version code') {
  //                   setLoader(false);
  //                   setErrorMsg("Invalid version code");
  //                   setShowError(true);
  //                   setBtnLoader(false);
  //                 } else {
  //                   if (response?.data?.response['MOH-card-status'] == 'invalid') {
  //                     setLoader(false);
  //                     setErrorMsg("Invalid or Expired health card");
  //                     setShowError(true);
  //                     setBtnLoader(false);
  //                   } else {

  //                     let D = values.birth_date.format("YYYY-MM-DD");

  //                     if (response?.data?.response.DOB !== D) {
  //                       setLoader(false);
  //                       setErrorMsg("Date of birth should match with OHIP")
  //                       setShowError(true);
  //                       setBtnLoader(false);
  //                     } else {

  //                       if (
  //                         response?.data?.response["First-name"].toLowerCase() !==
  //                         values?.FirstName.toLowerCase()
  //                       ) {
  //                         setLoader(false);
  //                         setErrorMsg("First name should match with OHIP")
  //                         setShowError(true);
  //                         setBtnLoader(false);

  //                       } else {
  //                         if (
  //                           response?.data?.response["Last-name"].toLowerCase() !==
  //                           values?.LastName.toLowerCase()
  //                         ) {
  //                           setLoader(false);
  //                           setErrorMsg("Last name should match with OHIP")
  //                           setShowError(true);
  //                           setBtnLoader(false);
  //                         } else {

  //                           let responseOfPatien;
  //                           if (_.isEmpty(editFamilyMember)) {
  //                             const data = {
  //                               Type: "PATIENT",
  //                               FirstName: values.FirstName,
  //                               LastName: values.LastName,
  //                               Email: values.Email ? values.Email : '',
  //                               Phone: phoneNumber ? phoneNumber : '',
  //                               createDate: moment().utc(),
  //                               // "cognitoid": user.userSub,
  //                               gender: values.gender,
  //                               address: address ? address : '',
  //                             };
  //                             console.log(data, "dataaaaaaaa or undefined");

  //                             const response = await UPDATEDPOST("user/patient", data);
  //                             console.log(response);
  //                             responseOfPatien = response;
  //                           }else{
  //                             const data1 = {
  //                               FirstName: values.FirstName,
  //                               LastName: values.LastName,
  //                               Email: values.Email ? values.Email : '',
  //                               phoneNumber: phoneNumber,
  //                               DOB:moment(values.birth_date).local().format("YYYY-MM-DD"),
  //                               familyDoctor : values.familyPhysician,
  //                               gender: values.gender,
  //                               province: values.province,
  //                               Address1: address ? address : '',
  //                             };
  //                             console.log(data1, "dataaaaaaaa undefined");

  //                             const responseEdit = await UPDATEDPUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
  //                             console.log(responseEdit, "ressrssrrs");

  //                             // `user/profile/${editFamilyMember?.familymemberid}`
  //                           }

  //                           const Ohip = {
  //                             name: values.FirstName + " " + values.LastName,
  //                             number: ohipNumber,
  //                             provider: "NA",
  //                             type: 'OHIP',
  //                             vc: values.insuranceVersionCode,
  //                             user_id: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
  //                             DOB: dateOfBirth,
  //                             is_valid: 1,

  //                           };
  //                           console.log(Ohip, "Ohip");
  //                           try {
  //                             const res = await UPDATEDPOST("insurance", Ohip);
  //                             console.log(res, "family insurances------0 ");

  //                             let abc = relationship == "other" ? values.addRelation : values.relationship
  //                             console.log(abc, "relationship relationship")

  //                             let familyAddObject = {
  //                               userName: `${values.FirstName} ${values.LastName}`,
  //                               relationship: abc,
  //                               province: values.province,
  //                               ohipNumber: ohipNumber,
  //                               insuranceServiceNumber: '',
  //                               insuranceProviderName: values.FirstName + "" + values.LastName,
  //                               gender: values.gender,
  //                               familyPhysician: values.familyPhysician,
  //                               address,
  //                               dateOfBirth: moment(values.birth_date).local().format("YYYY-MM-DD"),
  //                               userid: user?.id,
  //                               familymemberid: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
  //                               active: 1,
  //                               createdBy: user.role,
  //                               id: undefined,
  //                             };
  //                             console.log(familyAddObject, "familyAddObject");
  //                             setLoader(false);
  //                             setBtnLoader(false);
  //                             Addfamily(familyAddObject);
  //                           } catch (err) {
  //                             setLoader(false);
  //                             setBtnLoader(false);
  //                             console.log("error", err);
  //                           }
  //                         }
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //             },
  //             (err) => {
  //               setBtnLoader(false);
  //               setLoader(false);
  //               setErrorMsg("Please Enter Ohip Details")
  //               setShowError(true);

  //               setTimeout(() => {
  //                 setShowError(false);
  //               }, 5000);
  //               console.log(err, "error in on finish");
  //             }
  //           );
  //       }
  //     } else if (ohipNumber != undefined && ohipNumber != "" && values.insuranceVersionCode != undefined && values.insuranceVersionCode != undefined) {
  //       let ohipNumberNew = ohipNumber?.replaceAll('-', '');
  //       let data = {
  //         "Provider-number": "020497",
  //         HCN: ohipNumber ? ohipNumberNew : null,
  //         VC: values.insuranceVersionCode,
  //         User: values.FirstName + " " + values.LastName,
  //       };

  //       await axios
  //         .post("https://api.mdmax.ca/api/1.1/wf/api-validation-call", data, {
  //           headers: {
  //             Authorization: `Bearer cee53e7aa78674f078e300e6d96d98ec`,
  //             // Authorization: `Bearer 894da2b4b1760319ae94cbfa73db5a10`,
  //           },
  //         })
  //         .then(
  //           async (response: any) => {
  //             console.log(response, "responce");

  //             if (response?.data?.response["MOH-card-eligible"] == false) {
  //               setLoader(false);
  //               setBtnLoader(false);
  //               setErrorMsg("Invalid OHIP Card")
  //               setShowError(true);
  //             } else {
  //               if (response?.data?.response["MOH-Message"] == 'Invalid version code') {
  //                 setLoader(false);
  //                 setErrorMsg("Invalid version code");
  //                 setShowError(true);
  //                 setBtnLoader(false);
  //               } else {
  //                 if (response?.data?.response['MOH-card-status'] == 'invalid') {
  //                   setLoader(false);
  //                   setErrorMsg("Invalid or Expired health card");
  //                   setShowError(true);
  //                   setBtnLoader(false);
  //                 } else {

  //                   let D = values.birth_date.format("YYYY-MM-DD");

  //                   console.log(values.birth_date)
  //                   console.log(D)
  //                   console.log(response?.data?.response.DOB)

  //                   if (response?.data?.response.DOB !== D) {
  //                     setLoader(false);
  //                     setErrorMsg("Date of birth should match with OHIP")
  //                     setShowError(true);
  //                     setBtnLoader(false);
  //                   } else {

  //                     if (
  //                       response?.data?.response["First-name"].toLowerCase() !==
  //                       values?.FirstName.toLowerCase()
  //                     ) {
  //                       setLoader(false);
  //                       setErrorMsg("First name should match with OHIP")
  //                       setShowError(true);
  //                       setBtnLoader(false);

  //                     } else {
  //                       if (
  //                         response?.data?.response["Last-name"].toLowerCase() !==
  //                         values?.LastName.toLowerCase()
  //                       ) {
  //                         setLoader(false);
  //                         setErrorMsg("Last name should match with OHIP")
  //                         setShowError(true);
  //                         setBtnLoader(false);
  //                       } else {
  //                         let responseOfPatien;
  //                         if (_.isEmpty(editFamilyMember)) {
  //                           const data = {
  //                             Type: "PATIENT",
  //                             FirstName: values.FirstName,
  //                             LastName: values.LastName,
  //                             Email: values.Email ? values.Email : '',
  //                             Phone: phoneNumber ? phoneNumber : '',
  //                             createDate: moment().utc(),
  //                             // "cognitoid": user.userSub,
  //                             gender: values.gender,
  //                             address: address ? address : '',
  //                           };
  //                           const response = await UPDATEDPOST("user/patient", data);
  //                           console.log(response);
  //                           responseOfPatien = response;
  //                         }else{
  //                           const data1 = {
  //                             FirstName: values.FirstName,
  //                             LastName: values.LastName,
  //                             Email: values.Email ? values.Email : '',
  //                             phoneNumber: phoneNumber,
  //                             DOB:moment(values.birth_date).local().format("YYYY-MM-DD"),
  //                             familyDoctor : values.familyPhysician,
  //                             gender: values.gender,
  //                             province: values.province,
  //                             Address1: address ? address : '',
  //                           };
  //                           console.log(data1, "dataaaaaaaa undefined");

  //                           const responseEdit = await UPDATEDPUT(`user/profile/${editFamilyMember?.familymemberid}`, data1);
  //                           console.log(responseEdit, "ressrssrrs");

  //                           // `user/profile/${editFamilyMember?.familymemberid}`
  //                         }

  //                         const Ohip = {
  //                           name: values.FirstName + " " + values.LastName,
  //                           number: values?.ohipNumber_name,
  //                           provider: "NA",
  //                           type: 'OHIP',
  //                           vc: values.insuranceVersionCode,
  //                           user_id: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
  //                           DOB: dateOfBirth,
  //                           is_valid: 1,

  //                         };
  //                         console.log(Ohip, "Ohip");
  //                         try {
  //                           const res = await UPDATEDPOST("insurance", Ohip);
  //                           console.log(res, "family insurances------0 ");

  //                           let abc = relationship == "other" ? values.addRelation : values.relationship
  //                           console.log(abc, "relationship relationship")

  //                           let familyAddObject = {
  //                             userName: `${values.FirstName} ${values.LastName}`,
  //                             relationship: abc,
  //                             province: values.province,
  //                             ohipNumber: ohipNumber,
  //                             insuranceServiceNumber: '',
  //                             insuranceProviderName: values.FirstName + "" + values.LastName,
  //                             gender: values.gender,
  //                             familyPhysician: values.familyPhysician,
  //                             address,
  //                             dateOfBirth: moment(values.birth_date).local().format("YYYY-MM-DD"),
  //                             userid: user?.id,
  //                             familymemberid: responseOfPatien?.data?.body?.user?.id || editFamilyMember?.familymemberid,
  //                             active: 1,
  //                             createdBy: user.role,
  //                             id: undefined,
  //                           };
  //                           console.log(familyAddObject, "familyAddObject");
  //                           setLoader(false);
  //                           setBtnLoader(false);
  //                           Addfamily(familyAddObject);
  //                         } catch (err) {
  //                           setLoader(false);
  //                           setBtnLoader(false);
  //                           console.log("error", err);
  //                         }
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           },
  //           (err) => {
  //             setBtnLoader(false);
  //             setLoader(false);
  //             console.log(err);
  //           }
  //         );
  //     }
  // };

  const getDateFormated = (date) => {
    console.log(date, "dataeateataetaeata")
    if (date) {
      return date.replace("/", "-").replace("/", "-")
    } else {
      return date
    }
  }

  const handleChange = (event) => {
    console.log(event.target.name, "jsdfhjsfhhdsfhfgjh handle chaneg");
    console.log(event.target.value, "jsdfhjsfhhdsfhfgjh ");
    // if (errorMsg == 'Invalid OHIP Card' && event.target.name == 'ohipNumber') {
    if (errorMsg == 'Invalid OHIP Card' && event.target.name == 'ohipNumber_name') {
      setShowError(false);
    } else if (errorMsg == 'Invalid version code' && event.target.name == 'insuranceVersionCode') {
      setShowError(false);
    }
    else if ((errorMsg == 'First name should match with OHIP' || errorMsg == "Your account profile details should match with the OHIP card details") && event.target.name == 'FirstName') {
      setShowError(false);
    }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == "Change the profile details according to the OHIP card") && event.target.name == 'FirstName') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'First name should match with OHIP' || errorMsg == 'First name should match with profile info') && event.target.name == 'FirstName') {
    //   setShowError(false);
    // }
    else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == "Your account profile details should match with the OHIP card details") && event.target.name == 'LastName') {
      setShowError(false);
    }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == "Change the profile details according to the OHIP card") && event.target.name == 'LastName') {
    //   setShowError(false);
    // }
    // else if ((errorMsg == 'Last name should match with OHIP' || errorMsg == 'Last name should match with profile info') && event.target.name == 'LastName') {
    //   setShowError(false);
    // } 
    else if (errorMsg == 'Invalid or Expired health card') {
      setShowError(false);
    }

    const name = event?.target?.name;
    const value = event?.target?.value;
    console.log(name, value, "-=-=-=-=-=-=-==-=--=-==-=--=-=-=-==--=-==--==-");
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    console.log(formValues, "formValues of Ohip Card");
  };

  const updateOrCreateFamilyMember = async (id) => {
    console.log(selectedFamilyMemberID, id, "-=-=-=-=-=-=-=- avyu");
    const familyAddObject = {
      patient_id: id,
      provider_id: selectedFamilyMemberID,
    };
    console.log(familyAddObject);
    // try {
    //   const res = await UPDATEDPOST("patient/familydoc", familyAddObject);
    //   console.log(res, "family doc api ");
    // } catch (err) {
    //   console.log("error", err);
    // }
  };

  const Addfamily = async (familyAddObject: any) => {


    try {
      if (_.isEmpty(editFamilyMember)) {
        setLoader(true);
        console.log("in isEmpty edit family member");

        let logbody = {
          message: `User - ${userData?.sub} - Add Family Member - Add Family Member Component`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
        }
        logEvents(logbody);

        let resultt = await UPDATEDPOST("userfamilymembers", familyAddObject);
        setDateOfBirth(undefined);
        console.log(resultt)

        // Add Consent
        let dataConsent = {
          added_by: user?.id,
          familymember_id: familyAddObject?.familymemberid,
          type: isMajorVisible == true ? 'Major' : 'Minor',
          DOB: familyAddObject?.dateOfBirth
        }

        let logbody2 = {
          message: `User - ${userData?.sub} - Add Family Member - Add Family Member Component`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
        }
        logEvents(logbody2);


        let resultConsent = await UPDATEDPOST("consent", dataConsent);
        console.log(resultConsent, "resultConsentresultConsentresultConsent")

        updateOrCreateFamilyMember(familyAddObject?.familymemberid);
        notification.success({
          message: "Your Data Successfully Added",
        });

        setBtnLoader(false);
        setLoader(false);

        if (isMajorVisible == true) {
          setIsMajorVisible(false);
          setIsMajor(false);
        } else {
          setIsMinorVisible(false);
          setIsMinor(false);
        }
      }
      else {
        const { id } = editFamilyMember;
        familyAddObject.id = id;

        let logbody2 = {
          message: `User - ${userData?.sub} - Add Family Member - Add Family Member Component`,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
        }
        logEvents(logbody2);

        await UPDATEDPUT("userfamilymembers", familyAddObject);
        // setDateOfBirth(undefined);
        setFamilyDoc(undefined)
        // setPhoneNumber()

        if (selectedFamilyMemberID) {
          const found = allDoctor.find(
            (element) => element.companyemployeeid == selectedFamilyMemberID
          );
          console.log(found, "-*-*-*-**");
          dispatch(
            setFamilyDoctor(`Dr. ${found?.FirstName} ${found?.LastName}`)
          );
        }
        // updateOrCreateFamilyMember(familyAddObject.id);
        updateOrCreateFamilyMember(familyAddObject.familymemberid);


        notification.success({
          message: "Details updated successfully ",
        });
        setBtnLoader(false);
      }
      form.resetFields();
      setPhoneNumber("");
      setAddress(null);
      getFamilyMember();
      close();
    } catch (err) {
      setBtnLoader(false);
      setLoader(false);
      close();
      console.log("error", err);
    }
  };

  const onChangeDateOfBirth = (date, dateString) => {
    setDateOfBirth(dateString);

    if (errorMsg == 'Date of birth should match with OHIP') {
      setShowError(false);
    }

    const today = moment();
    const age = today.diff(dateString, 'years');

    if (age < 18) {
      console.log('minor');
      setIsMinor(true);
    } else {
      console.log('major');
      setIsMajor(true);
    }

  };

  const handleChangePhonenumber = (event) => {
    setPhoneNumber(event.target.value);

    var mobile = document.getElementById('mobile');
    if (title == "Add Family Member") {
      var message = document.getElementById('message');
    } else {
      var message1 = document.getElementById('message1');
    }

    var badColor = "#FF0000";

    if (event.target.value.length != 10) {
      if (title == "Add Family Member") {
        message.style.color = badColor;
        message.innerHTML = "Please Enter 10 Digit Phone Number!"
      } else {
        message1.style.color = badColor;
        message1.innerHTML = "Please Enter 10 Digit Phone Number!"
      }
    } else {
      if (title == "Add Family Member") {
        message.innerHTML = ""
      } else {
        message1.innerHTML = ""
      }
    }
  };

  const addressSelect = (datee) => {
    setAddress(datee.formatted_address);

    if (datee && datee.address_components) {
      const state = find(datee.address_components, {
        types: ['administrative_area_level_1'],
      })
      if (state) {
        form.setFieldsValue({
          province: state.long_name
        });
      }
    }

  }

  const getUserImageDoc = (imageUrll) => {
    if (imageUrll?.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) != null) {
      return imageUrll
    }
    return `${process.env.REACT_APP_ASSET_URL}/${imageUrll}`
  }

  const openModal = async () => {
    console.log("Avyu", editFamilyMember);

    let logbody = {
      message: `User - ${userData?.sub} - Get Consent of Family Member - Add Family Member Component`,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${userData?.sub} - Add Family Member Component`,
    }
    logEvents(logbody);

    try {
      let resultConsentGet = await UPDATEDGET("consent/" + editFamilyMember?.familymemberid);
      console.log(resultConsentGet, "resultConsentGet");

      if (resultConsentGet?.data?.body?.length != 0) {
        if (resultConsentGet?.data?.body[0]?.type == 'Minor') {
          setIsMinorVisibleEdit(true);
        } else {
          setIsMajorVisibleEdit(true);
        }
      } else {
        notification.info({
          message: "No Consent form found..",
        });
      }
    } catch (err) {
      console.log("error", err);
    }
  }


  const openFile = async (file) => {

    const url = await s3GetPrivate(file)

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <>
      <Modal
        title={title}
        visible={open}
        footer={null}
        onOk={close}
        onCancel={close}
        width={1100}
        centered>

        {
          showError ? <Alert style={{ marginBottom: "15px", width: "70%" }} message={errorMsg} type="error" /> : null
        }
        {/* {dateOfBirth} */}

        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinish}
          initialValues={{
            birth_date: dateOfBirth == undefined ? undefined : moment(dateOfBirth),
            familyDoctor: familyDoc == undefined ? "" : familyDoc,
          }}
        >
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="FirstName"
                    label="First Name"

                    rules={[
                      {
                        required: true,
                        message: "Please Enter First Name",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" className={style.inputStyle} name="FirstName" onChange={handleChange} disabled={editFamilyMember?.ohipNumber != null} />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="LastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Last Name",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" name="LastName" className={style.inputStyle} onChange={handleChange} disabled={editFamilyMember?.ohipNumber != null} />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="Email"
                    label="Email"
                    rules={[
                      {
                        type: "email"
                      },
                    ]}
                  >
                    <Input placeholder="Email" name="Email" className={style.inputStyle} />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    label="Date Of Birth"
                    name="birth_date"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Date Of Birth"
                      },
                    ]}>

                    {dateOfBirth ? <DatePicker
                      disabled={editFamilyMember?.ohipNumber != null && editFamilyMember?.ohipNumber != 'null'}
                      style={{ width: "100%" }}
                      name="birth_date"
                      onChange={onChangeDateOfBirth}
                      format="YYYY/MM/DD"
                      // format={"MM-DD-YY"}
                      // defaultValue={
                      //   dateOfBirth != undefined ? moment(dateOfBirth) : undefined
                      // }
                      disabledDate={(current) => {
                        return current && current > moment().endOf('day')
                        // return moment().add(-1, "days") <= current;
                      }}
                    /> :
                      <DatePicker
                        disabled={editFamilyMember?.ohipNumber != null && editFamilyMember?.ohipNumber != 'null'}
                        style={{ width: "100%" }}
                        name="birth_date"
                        onChange={onChangeDateOfBirth}
                        format="YYYY/MM/DD"
                        disabledDate={(current) => {
                          return current && current > moment().endOf('day')
                        }}
                      />
                    }

                  </Form.Item>
                </div>
              </div>
              <div className="row">

                {/* <div className="col-sm-6">
              <Form.Item name="familyPhysician" label="Family Physicians">
                <Select
                  showSearch
                  placeholder="Family Physicians"
                  optionFilterProp="children"
                >
                  {doctorNames.map((doctorData) => (
                    <Option value={doctorData.id}>{doctorData.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div> */}
                {/* <div className="col-sm-6">
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter phone number",
                      },
                    ]}
                  >
                    <p className="p-0 mb-1">Phone number</p>
                    <PhoneInput
                      country={"us"}
                      value={formValues.phoneNumber || ""}
                      onChange={(phone) => setPhoneNumber(phone)}
                    />
                  </Form.Item>
                </div> */}

                <div className="col-sm-6">
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                  >
                    {config?.code == 'ca' ? <label style={{ position: "absolute", top: 11, left: 15 }}>+1</label> : <label style={{ position: "absolute", top: 11, left: 15 }}>+91</label>}
                    <input type="text" className={`${style.inputbox}`} name="phoneNumber"
                      value={phoneNumber || ""} style={{ paddingLeft: 40 }} pattern="\d{10}" id="mobile"
                      onChange={handleChangePhonenumber}
                    />
                    {
                      title == "Add Family Member" ? <span id="message"></span> : <span id="message1"></span>
                    }

                  </Form.Item>
                </div>

                <div className="col-sm-6">
                  {
                    relationship == "other" ?
                      <div className="row">
                        <div className="col-sm-12" style={{ marginTop: "10px" }}>
                          <Form.Item
                            name="addRelation"
                            label="Add Relationship"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Relationship"
                              },
                            ]}>
                            <Input placeholder="Enter Relationship" name="addRelation" />
                          </Form.Item>
                        </div>
                      </div> : null
                  }
                  <Form.Item
                    name="relationship"
                    label="Choose Relationship"
                    rules={[
                      { required: true, message: "Please Select Relationship" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="RelationShip"
                      optionFilterProp="children"
                      onChange={(e) => {
                        console.log(e);
                        setRelationship(e);
                      }}
                    >
                      {relationShip.map((relation) => (
                        <Option value={relation.id} key={relation.id}>
                          {relation.name}
                        </Option>
                      ))}
                    </Select>

                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[{ required: true, message: "Please Select Gender" }]}
                  >

                    <Select
                      showSearch
                      placeholder="Select Gender"
                      optionFilterProp="children"
                    >
                      {/* {patientGender.map((genderData) => (
                        <Option value={genderData.id}>{genderData.name}</Option>
                      ))} */}

                      {/* <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="intersex">Other</Option> */}
                      {
                        !form.getFieldValue("relationship") || form.getFieldValue("relationship") == "cousin" || form.getFieldValue("relationship") == null || form.getFieldValue("relationship") == '' ?
                          <>
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                            <Option value="other">Other</Option>
                          </> :

                          <>
                            {
                              form.getFieldValue("relationship") == "brother" || form.getFieldValue("relationship") == "father" ||
                                form.getFieldValue("relationship") == "grandfather" || form.getFieldValue("relationship") == "husband" || form.getFieldValue("relationship") == "nephew" ||
                                form.getFieldValue("relationship") == "uncle" || form.getFieldValue("relationship") == "son" ?
                                <>
                                  <Option value="male">Male</Option>
                                  {/* <Option value="intersex">Other</Option> */}
                                  <Option value="other">Other</Option>
                                </>
                                :
                                <>
                                  <Option value="female">Female</Option>
                                  <Option value="other">Other</Option>
                                </>
                            }
                          </>
                      }


                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="province"
                    label="Choose Province"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Company Province",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Province"
                      optionFilterProp="children"
                    >
                      {province.map((provinceData) => (
                        <Option value={provinceData.id}>{provinceData.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <label>
                    {" "}
                    Address
                  </label>
                  <Form.Item>
                    <SearchLocationInput
                      styleProps={{ minHeight: 30 }}
                      name="address"
                      address={address}
                      setlat={(e) => setLat(e)}
                      setlng={(e) => setLng(e)}
                      setAddress={(e) => addressSelect(e)}
                      onBlur={(e) => setAddress(e)}
                    />
                    {/* {address == null ?
                      <span style={{ color: "#ff0000" }}>Please Enter Address</span>
                      : null} */}
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              {/* <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="fname"
                    label="Provider First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Insurance Service Number",
                      }
                    ]}
                  >
                    <Input
                      placeholder="Firstname"
                      name="fname"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="lname"
                    label="Provider Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Insurance Service Number",
                      }
                    ]}
                  >
                    <Input
                      placeholder="Lastname"
                      name="lname"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
              </div> */}
              <div className="row">
                <div className="col-sm-6">
                  <Form.Item
                    name="ohipNumber_name"
                    label="Ohip Number"
                    rules={[
                      // { required: true, message: "Please Enter Ohip Number" },
                      {
                        min: 10,
                        message: "OHIP number must be minimum 12 characters.",
                      },
                    ]}
                  >
                    {/* <Input
                      placeholder="Ohip Number"
                      name="ohipNumber"
                      maxLength={10}
                      onChange={handleChange}
                    /> */}

                    {/* <MaskInput alwaysShowMask maskChar="_" mask="0000-000-000" value="ohipNumber" onChange={handleChange} /> */}
                    <MaskInput alwaysShowMask maskChar="_" mask="0000-000-000" onChange={handleChange} />
                  </Form.Item>
                </div>
                <div className="col-sm-6">
                  <Form.Item
                    name="insuranceVersionCode"
                    label="Insurance Version Code"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Insurance Version Code",
                  //   },
                  // ]}
                  >
                    <Input
                      style={{ textTransform: "uppercase" }}
                      placeholder="Insurance Version Code"
                      name="insuranceVersionCode"
                      maxLength={2}
                      onChange={handleChange}
                      className={style.inputStyle}
                    />
                  </Form.Item>
                </div>
              </div>



              {/* card design start */}
              <div className={style.div_row_flex}>

                <div className={style.div_card_main} style={{
                  backgroundImage: `url("Images/problems/card.png")`
                }}>
                  <div>
                    <div className={style.div_card_below} style={{ paddingTop: 0 }}>
                      <div style={{ width: "100%" }}>
                        <span className={style.lbl_card}>Name</span>
                        <div className={style.div_row_name}>
                          <span className={style.lbl_card_name}>{formValues.FirstName || familyMemberDetails?.firstname} &nbsp;</span>
                          {/* <label className={style.lbl_card_name}>{formValues.middlename} &nbsp;</label> */}
                          <span className={style.lbl_card_name}>{formValues.LastName || familyMemberDetails?.lastname}</span>
                        </div>
                      </div>
                    </div>
                    <div className={style.div_card_below} style={{paddingTop:'10px'}}>
                      <div style={{ width: "67%" }}>
                        <span className={style.lbl_card}>Card Number</span>
                        <Form.Item style={{ marginBottom: "5px" }}
                          // name="ohipNumber">
                          name="ohipNumber_name">
                          <Input size="large" onChange={handleChange} className={style.input_card} disabled={true} />
                        </Form.Item>
                      </div>
                      <div style={{ width: "25%" }}>
                        <span className={style.lbl_card}>Version Code</span>
                        <Form.Item style={{ marginBottom: "5px" }}
                          name="insuranceVersionCode"
                        >
                          <Input size="large" onChange={handleChange} className={style.input_card_vcode} disabled={true} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={style.div_card_below} style={{ paddingTop: 0 }}>
                      <div style={{ width: "100%" }}>
                        <span className={style.lbl_card}>DOB</span>
                        <div className={style.div_row_name}>
                          {/* <label className={style.lbl_card_name}>{getDateFormated(dateOfBirth)}</label> */}
                          <span className={style.lbl_card_name}>{moment(dateOfBirth).utc().format("YYYY-MM-DD") != moment().utc().format("YYYY-MM-DD") ? moment(dateOfBirth).utc().format("YYYY-MM-DD") : ''}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* card design end */}

              {!_.isEmpty(editFamilyMember) ?
                <div className={style.div_row_flex}>
                  <label className={style.lbl_carconsent}>Consent Form</label>
                  <a onClick={openModal}>
                    <img
                      style={{ width: 20, marginTop: 10 }}
                      src={IMG02}
                    />
                  </a>
                </div>
                : null}

            </div>
          </div>
          <hr></hr>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-xl-6 ">
              <div className={style.addressHeading}>
                <h4>Health insurance and benefits</h4>
              </div>
              <div>
                <div className="row" style={{ marginTop: 30 }}>
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        name="insurer"
                        label="Name of Insurer"
                      >
                        <Input
                          autoComplete="off"
                          type="text"
                          // className={`${style.inputbox}`}
                          name="insurer"
                          onChange={handleChange}
                          className={style.inputStyle}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        name="insuranceNumber"
                        label="Numbers on card"
                        rules={[
                          {
                            required:
                              (form.getFieldValue('insurer') != undefined &&
                                form.getFieldValue('insurer') != '') ||
                                (form.getFieldValue('lastname_cardholder') != undefined &&
                                  form.getFieldValue('lastname_cardholder') != '') ||
                                (form.getFieldValue('relation') != undefined &&
                                  form.getFieldValue('relation') != ''
                                ) ? true : false,
                            message: "Please Enter First Name!",
                          },
                        ]}

                      >
                        <Input
                          autoComplete="off"
                          type="text"
                          // className={`${style.inputbox}`}
                          name="insuranceNumber"
                          onChange={handleChange}
                          className={style.inputStyle}

                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        name="lastname_cardholder"
                        label="Cardholder last name"
                        rules={[
                          {
                            required:
                              (form.getFieldValue('insurer') != undefined &&
                                form.getFieldValue('insurer') != '') ||
                                (form.getFieldValue('insuranceNumber') != undefined &&
                                  form.getFieldValue('insuranceNumber') != '') ||
                                (form.getFieldValue('relation') != undefined &&
                                  form.getFieldValue('relation') != ''
                                ) ? true : false,

                            message: "Please Enter First Name!",
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          type="text"
                          // className={`${style.inputbox}`}
                          name="lastname_cardholder"
                          onChange={handleChange}
                          className={style.inputStyle}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Form.Item
                        label="Relationship"
                        name="relation"
                      >
                        <Select
                          showSearch
                          placeholder="Select Relatonship"
                          optionFilterProp="children"
                          size="large"
                          style={{ marginRight: 10 }}
                          defaultActiveFirstOption
                          onChange={(e) => {
                            setSelFamilyMember(e);
                          }}
                          value={selFamilyMember}
                        >
                          {relationShip.map((relation) => (
                            <Option value={relation.id} key={relation.id}>
                              {relation.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-sm-6 col-md-6 col-xl-6">

              <Tabs
                onChange={onKeyChange}
                activeKey={activeTab}
                className={` ${style.tabsW} vb-tabs-bold`}
              >

                <Tabs.TabPane tab="Front side insurace Card" key="1">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12" >
                          1. Choose Front Insurance card image
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                          <Dragger
                            {...props1}
                            style={{ width: "100%" }}
                            showUploadList={false}
                          >
                           
                            {" "}
                            <p className="mt-1">
                              {" "}
                              <img
                                src="Images/cloud-upload.png"
                                alt="Upload icon"
                              />
                            </p>{" "}
                            <p
                              className="mt-2 "
                              style={{ fontSize: "16px" }}
                            >
                              Drag and Drop file Here
                            </p>{" "}
                            <Button
                              type="primary"
                              className={`${style.btnBrowse} mt-2`}
                            >
                              Browse Files
                            </Button>
                          </Dragger>
                        </div>
                      </div>

                    </div>

                    {privateInsuranceData?.frontcard_path ? <>
                      <div className="col-sm-12 col-md-12 col-xl-12 mt-2" >
                        <a
                          onClick={(e) => openFile(privateInsuranceData?.frontcard_path)}
                        >
                          See uploaded front card image <img style={{ width: 30, marginLeft: 8 }} src={IMG02} />
                        </a>
                      </div></> : null}

                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Back side insurance Card" key="2">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12" >
                          2. Choose Back Insurance card image
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                          <Dragger
                            {...props2}
                            style={{ width: "100%" }}
                            showUploadList={false}
                          >
                            {" "}
                            <p className="mt-1">
                              {" "}
                              <img
                                src="Images/cloud-upload.png"
                                alt="Upload icon"
                              />
                            </p>{" "}
                            <p
                              className="mt-2 "
                              style={{ fontSize: "16px" }}
                            >
                              Drag and Drop file Here
                            </p>{" "}
                            <Button
                              type="primary"
                              className={`${style.btnBrowse} mt-2`}
                            >
                              Browse Files
                            </Button>
                          </Dragger>
                        </div>
                      </div>
                    </div>

                    {privateInsuranceData?.backcard_path ? <>
                      <div className="col-sm-12 col-md-12 col-xl-12 mt-2" >
                        <a
                          onClick={(e) => openFile(privateInsuranceData?.backcard_path)}
                        >
                          See uploaded back card image<img style={{ width: 30, marginLeft: 8 }} src={IMG02} />
                        </a>
                      </div>
                    </> : null}



                  </div>
                </Tabs.TabPane>
              </Tabs>

            </div>
          </div>

          <div
            className="row ml-1 mr-1 border-top"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="pt-4 pr-3">
              <Form.Item>
                <Button
                  style={{ width: 155, height: "auto" }}
                  htmlType="submit"
                  className="btn btn-primary px-5"
                  loading={btnLoader}
                  onClick={checkAddress}
                >
                  {/* {_.isEmpty(editFamilyMember) ? "Submit" : "Edit"} */}
                  Submit
                </Button>
              </Form.Item>
            </div>
            <div className="pt-4 pr-3">
              <Form.Item>
                <Button
                  style={{ width: 155, height: "auto" }}
                  htmlType="button"
                  onClick={close}
                  className="btn btn-light px-5"
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form >
      </Modal >

      {/* Add consent Modal Major */}
      <Modal title="Consent form for the Family Member addition"
        visible={isMajorVisible}
        onOk={familyOK}
        okText="Agree"
        onCancel={majorClose}
        confirmLoading={loader}
        width={1100}
        centered>

        <label className={style.lblDate}>{moment().format("DD, MMM YYYY")}</label>
        <label className={style.lblDate}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName}</label><br /><br />

        <label className={style.lblnormal}>Subject: Consent for Adding Family Member to Onrx</label>
        <label className={style.lblnormal}>Dear <span className={style.spann}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName}</span></label>

        <label className={style.lblnormal}>I, <span className={style.spann}>{user?.FirstName} {user?.LastName} </span>
          , hereby request your consent to add you as a family member on the Onrx. By providing this consent, you acknowledge and agree to the following terms and conditions:
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Purpose:</i> The purpose of adding you to the telehealth app is to facilitate communication, access to medical information, and the coordination of healthcare services between us.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Nature of the App:</i> Onrx is a telehealth application that allows users to securely communicate with healthcare professionals, schedule appointments, access medical records, and receive virtual medical consultations.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Access to Information:</i> By adding you to Onrx, I will have access to certain personal health information, including but not limited to medical records, appointment details, prescriptions, and communication history. This access will enable me to assist in managing your healthcare needs and participating in telehealth consultations on your behalf.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Confidentiality and Privacy:</i> I assure you that I will handle all your personal health information in accordance with applicable privacy laws and regulations. I understand the importance of maintaining the confidentiality and security of your health data, and I will take reasonable measures to protect it from unauthorized access, use, or disclosure.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Revocation of Consent:</i> You have the right to revoke this consent at any time by providing written notice. Upon revocation, I will no longer have access to your information on the telehealth app, and any further access or use of the app will be solely your responsibility.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Limitations:</i> Please note that adding you to the telehealth app does not create a professional relationship between me and any healthcare providers or substitute for professional medical advice. All medical decisions and consultations should be discussed with your healthcare provider.
        </label>

        <label className={style.lblnormal}>
          By signing below, you indicate that you understand the terms outlined in this consent form and willingly grant me permission to add you as a family member on the telehealth app.
        </label><br />

        <div className={style.div_flexbox}>
          <div className={style.div_first}>
            <label className={style.lblnormal}>
              <span className={style.spann}>User's Name:</span> {user?.FirstName} {user?.LastName}
            </label>
            <label className={style.lblnormal}>
              <span className={style.spann}>Family Member's Name:</span> {valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName}
            </label>
          </div>

          <div className={style.div_first}>
            <label className={style.lblnormal}>
              <span className={style.spannDate}>Date:</span> {moment().format("DD, MMM YYYY")}
            </label>
            <label className={style.lblnormal}>
              <span className={style.spannDate}>Date:</span> {moment().format("DD, MMM YYYY")}
            </label>
          </div>
        </div>

      </Modal>

      {/* Add consent Modal Minor*/}
      <Modal title="Consent form for the Minor addition"
        visible={isMinorVisible}
        confirmLoading={loader}
        onOk={familyOK}
        okText="Agree"
        onCancel={minorClose}
        width={1100}
        centered>

        <label className={style.lblDate}>{moment().format("DD, MMM YYYY")}</label>
        <label className={style.lblDate}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName}</label><br /><br />

        <label className={style.lblnormal}>Subject: Consent for Adding Minor to Onrx</label>
        <label className={style.lblnormal}>Dear <span className={style.spann}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName}</span></label>

        <label className={style.lblnormal}>I, <span className={style.spann}>{user?.FirstName} {user?.LastName} </span>
          as the legal guardian of <span className={style.spann}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName} </span>, hereby provide my consent to add <span className={style.spann}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName} </span> as a participant on Onrx. By signing this consent form, I acknowledge and agree to the following terms and conditions on behalf of the minor:
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Purpose:</i> The purpose of adding <span className={style.spann}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName} </span> to the telehealth app is to facilitate communication, access to medical information, and the coordination of healthcare services for the benefit of their healthcare needs.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Nature of the App:</i> Onrx is a telehealth application that enables secure communication with healthcare professionals, appointment scheduling, access to medical records, and virtual medical consultations.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Access to Information:</i> By adding <span className={style.spann}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName} </span> to the app, I will have access to certain personal health information related to their healthcare, including medical records, appointment details, prescriptions, and communication history. This access will enable me to assist in managing their healthcare needs and participating in telehealth consultations on their behalf.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Confidentiality and Privacy:</i> I assure you that I will handle all personal health information of the minor in accordance with applicable privacy laws and regulations. I understand the importance of maintaining the confidentiality and security of their health data and will take reasonable measures to protect it from unauthorized access, use, or disclosure.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Revocation of Consent:</i> As the legal guardian, I understand that I have the right to revoke this consent at any time by providing written notice. Upon revocation, I acknowledge that I will no longer have access to the minor's information on the telehealth app, and any further access or use of the app will be solely my responsibility.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Limitations:</i> Please note that adding <span className={style.spann}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName} </span> to the onrx does not create a professional relationship between me and any healthcare providers, nor does it substitute for professional medical advice. All medical decisions and consultations should be discussed with the minor's healthcare provider.
        </label>

        <label className={style.lblnormal}>
          By signing below, I indicate that I understand the terms outlined in this consent form and grant permission to add <span className={style.spann}>{valuesForFirstForm?.FirstName} {valuesForFirstForm?.LastName} </span> as a participant on Onrx.
        </label>
        <br />

        <div className={style.div_flexbox}>
          <div className={style.div_first}>
            <label className={style.lblnormal}>
              <span className={style.spann}>User's Name:</span> {user?.FirstName} {user?.LastName}
            </label>
          </div>

          <div className={style.div_first}>
            <label className={style.lblnormal}>
              <span className={style.spannDate}>Date:</span> {moment().format("DD, MMM YYYY")}
            </label>
          </div>
        </div>
      </Modal>

      {/* Edit consent Modal Major */}
      <Modal title="Consent form for the Family Member addition"
        visible={isMajorVisibleEdit}
        onCancel={() => setIsMajorVisibleEdit(false)}
        footer={null}
        width={1100}
        centered>

        <label className={style.lblDate}>{moment().format("DD, MMM YYYY")}</label>
        <label className={style.lblDate}>{editFamilyMember?.userName}</label><br /><br />

        <label className={style.lblnormal}>Subject: Consent for Adding Family Member to Onrx</label>
        <label className={style.lblnormal}>Dear <span className={style.spann}>{editFamilyMember?.userName}</span></label>

        <label className={style.lblnormal}>I, <span className={style.spann}>{user?.FirstName} {user?.LastName} </span>
          , hereby request your consent to add you as a family member on the Onrx. By providing this consent, you acknowledge and agree to the following terms and conditions:
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Purpose:</i> The purpose of adding you to the telehealth app is to facilitate communication, access to medical information, and the coordination of healthcare services between us.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Nature of the App:</i> Onrx is a telehealth application that allows users to securely communicate with healthcare professionals, schedule appointments, access medical records, and receive virtual medical consultations.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Access to Information:</i> By adding you to Onrx, I will have access to certain personal health information, including but not limited to medical records, appointment details, prescriptions, and communication history. This access will enable me to assist in managing your healthcare needs and participating in telehealth consultations on your behalf.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Confidentiality and Privacy:</i> I assure you that I will handle all your personal health information in accordance with applicable privacy laws and regulations. I understand the importance of maintaining the confidentiality and security of your health data, and I will take reasonable measures to protect it from unauthorized access, use, or disclosure.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Revocation of Consent:</i> You have the right to revoke this consent at any time by providing written notice. Upon revocation, I will no longer have access to your information on the telehealth app, and any further access or use of the app will be solely your responsibility.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Limitations:</i> Please note that adding you to the telehealth app does not create a professional relationship between me and any healthcare providers or substitute for professional medical advice. All medical decisions and consultations should be discussed with your healthcare provider.
        </label>

        <label className={style.lblnormal}>
          By signing below, you indicate that you understand the terms outlined in this consent form and willingly grant me permission to add you as a family member on the telehealth app.
        </label><br />

        <div className={style.div_flexbox}>
          <div className={style.div_first}>
            <label className={style.lblnormal}>
              <span className={style.spann}>User's Name:</span> {user?.FirstName} {user?.LastName}
            </label>
            <label className={style.lblnormal}>
              <span className={style.spann}>Family Member's Name:</span> {editFamilyMember?.userName}
            </label>
          </div>

          <div className={style.div_first}>
            <label className={style.lblnormal}>
              <span className={style.spannDate}>Date:</span> {moment(editFamilyMember?.createdate).format("DD, MMM YYYY")}
            </label>
            <label className={style.lblnormal}>
              <span className={style.spannDate}>Date:</span> {moment(editFamilyMember?.createdate).format("DD, MMM YYYY")}
            </label>
          </div>
        </div>

      </Modal>

      {/* Edit consent Modal Minor*/}
      <Modal title="Consent form for the Minor addition"
        visible={isMinorVisibleEdit}
        onCancel={() => setIsMinorVisibleEdit(false)}
        footer={null}
        width={1100}
        centered>

        <label className={style.lblDate}>{moment().format("DD, MMM YYYY")}</label>
        <label className={style.lblDate}>{editFamilyMember?.userName}</label><br /><br />

        <label className={style.lblnormal}>Subject: Consent for Adding Minor to Onrx</label>
        <label className={style.lblnormal}>Dear <span className={style.spann}>{editFamilyMember?.userName}</span></label>

        <label className={style.lblnormal}>I, <span className={style.spann}>{user?.FirstName} {user?.LastName} </span>
          as the legal guardian of <span className={style.spann}>{editFamilyMember?.userName} </span>, hereby provide my consent to add <span className={style.spann}>{editFamilyMember?.userName} </span> as a participant on Onrx. By signing this consent form, I acknowledge and agree to the following terms and conditions on behalf of the minor:
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Purpose:</i> The purpose of adding <span className={style.spann}>{editFamilyMember?.userName} </span> to the telehealth app is to facilitate communication, access to medical information, and the coordination of healthcare services for the benefit of their healthcare needs.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Nature of the App:</i> Onrx is a telehealth application that enables secure communication with healthcare professionals, appointment scheduling, access to medical records, and virtual medical consultations.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Access to Information:</i> By adding <span className={style.spann}>{editFamilyMember?.userName} </span> to the app, I will have access to certain personal health information related to their healthcare, including medical records, appointment details, prescriptions, and communication history. This access will enable me to assist in managing their healthcare needs and participating in telehealth consultations on their behalf.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Confidentiality and Privacy:</i> I assure you that I will handle all personal health information of the minor in accordance with applicable privacy laws and regulations. I understand the importance of maintaining the confidentiality and security of their health data and will take reasonable measures to protect it from unauthorized access, use, or disclosure.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Revocation of Consent:</i> As the legal guardian, I understand that I have the right to revoke this consent at any time by providing written notice. Upon revocation, I acknowledge that I will no longer have access to the minor's information on the telehealth app, and any further access or use of the app will be solely my responsibility.
        </label>

        <label className={style.lblnormal}>
          <i className={style.spann}>Limitations:</i> Please note that adding <span className={style.spann}>{editFamilyMember?.userName} </span> to the onrx does not create a professional relationship between me and any healthcare providers, nor does it substitute for professional medical advice. All medical decisions and consultations should be discussed with the minor's healthcare provider.
        </label>

        <label className={style.lblnormal}>
          By signing below, I indicate that I understand the terms outlined in this consent form and grant permission to add <span className={style.spann}>{editFamilyMember?.userName} </span> as a participant on Onrx.
        </label>
        <br />

        <div className={style.div_flexbox}>
          <div className={style.div_first}>
            <label className={style.lblnormal}>
              <span className={style.spann}>User's Name:</span> {user?.FirstName} {user?.LastName}
            </label>
          </div>

          <div className={style.div_first}>
            <label className={style.lblnormal}>
              <span className={style.spannDate}>Date:</span> {moment(editFamilyMember?.createdate).format("DD, MMM YYYY")}
            </label>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddFamilyMember;
