import style from "./style.module.css";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import RegisterComponent from "../../components/Register";

const Register = () => {
  const history = useHistory();
  const [hideAlert, setHideAlert] = useState(false);

  const handleBack = () => {
    setHideAlert(false);
    history.push("/");
  };
  const onCancel = () => {
    setHideAlert(false);
  };

  return (
    <div className={style.main_container}>
      {hideAlert == true ? (
        <SweetAlert
          success
          title="Signup successful!"
          onConfirm={handleBack}
          onCancel={onCancel}
          confirmBtnText="Go Login"
        >
          Please check your Email and Validate your Account
        </SweetAlert>
      ) : null}
      <div className={style.row}>
        <div className={style.col}>
          <div className={style.leftWrapper}>
            <img
              src="Images/logo.png"
              className={style.imgFluid}
              alt="Doccure Login"
            />
            <h1 className="mt-3"  style={{ color: "var(--primary-color)" }}>
              Sign Up
            </h1>
            <div className="container ">
              <RegisterComponent />
            </div>
            <div className={`text-center ${style.dontHave}`}>
              Already Have Account ?
              <Link to="/" style={{ color: "var(--primary-color)" ,fontWeight:'500' }}>
                {" "}
                Login Now
              </Link>
            </div>
            <div className={`text-center ${style.copy}`}>© 2023 ONRx. All rights reserved.</div>
          </div>
        </div>

        <div className={style.col} style={{ padding: "20px" }}>
          <div className={style.imgStyle}></div>
        </div>

        {/* <div className="col-sm-0 col-md-2 col-lg-3 col-xl-4"></div> */}
        {/* <div className="col-sm-12 col-md-8 col-lg-8 col-xl-4">
          <div >
            <div className={style.div_content}>
              
              <div className="container pt-5">
                <RegisterComponent />
              </div>
            </div>
            <div className={`text-center ${style.dontHave}`}>
              Already Have Account ?
              <Link to="/" style={{ color: "#1a6ab1" }}>
                {" "}
                Login Now
              </Link>
            </div>
            <div className={`text-center ${style.copy}`}>© 2023 ONRx. All rights reserved.</div>
          </div>
        </div>
        <div className="col-sm-0 col-md-4 col-lg-4 col-xl-4">
        <div className={style.imgStyle}>

        </div>
        </div> */}
      </div>
    </div>
  );
};

export default Register;
